import qs from "querystringify";
import {api} from "./index";


export let
    getGalleries,
    getGalleriesAll,
    createGallery,
    updateGallery,
    deleteGallery;

getGalleries = (userhash) => api.get('gallery/galleries' + qs.stringify({uh: userhash}, '?'));
getGalleriesAll = (userhash) => api.get('gallery/v2galleriesall' + qs.stringify({uh: userhash}, '?'));
createGallery = (gallery) => api.post('gallery/galleries', gallery);
updateGallery = (gallery) => api.put('gallery/galleries', gallery);
deleteGallery = (mainuserhash,galleryhash) => api.delete('gallery/galleries.json?mainuserhash=' + mainuserhash + '&galleryhash=' + galleryhash);



