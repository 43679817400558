import {Types} from '../actions/data';

// create initial state for reducers
const INIT_STATE = {
    fetching : false,
    fetchingPassword : false,
    list : [],
    listByClef : [],
    listByCodeFolder : [],
    uniqueClefUserIndex : null,
    start : null,
    end : null,
    started : null,
    ended : null,
    password : null,
    dataFilter : [],
    globalDataFilter : null,
    globalApiDataFilter : null,
    isDataSearchActive : false,
    modelClef : null,
    modelCodeFolder : null,
    uniqueModelClef : null,
    unique:null,
    fieldToRefresh : null,
    plugnoterefresh : null,
};

// reducer function to transform state
export default function data(state = INIT_STATE, action) {
    switch(action.type) {
        case Types.REDUX_GENERATE_DATA_PASSWORD_RESET :{
            return{
                ...state,
                password : null,
            }
        }
        case Types.RESET_ALL_DATA : return INIT_STATE;
        case Types.REDUX_DEL_GLOBAL_DATA_FILTER_REQUEST :{
            return{
                ...state,
                dataGlobalFilter : null,
                isDataSearchActive : false
            }
        }
        case Types.REDUX_SET_GLOBAL_DATA_FILTER_REQUEST :{
            return{
                ...state,
                dataGlobalFilter : action.payload,
                isDataSearchActive : true

            }
        }

        case Types.REDUX_DEL_GLOBAL_API_DATA_FILTER_REQUEST :{
            return{
                ...state,
                globalApiDataFilter : null,
                isDataSearchActive : false
            }
        }
        case Types.REDUX_SET_GLOBAL_API_DATA_FILTER_REQUEST :{
            return{
                ...state,
                globalApiDataFilter : action.payload,
                isDataSearchActive : true

            }
        }

        case Types.REDUX_DEL_ALL_DATA_FILTER_REQUEST :{
            return{
                ...state,
                dataFilter : [],
            }
        }
        case Types.REDUX_SET_DATA_FILTER_REQUEST: {
            let listFilter = state.dataFilter;
            let data =  listFilter.filter((item, index) => {
                if(!item.hasOwnProperty(`field${action.key}`)){
                    return item
                }
            });
            data.push(action.payload);

            return {
                ...state,
                dataFilter : data,
            }
        }
        case Types.REDUX_DEL_DATA_FILTER_REQUEST: {
            let listFilter = state.dataFilter;

            return {
                ...state,
                dataFilter: listFilter.filter((item, index) => {
                    if(!item.hasOwnProperty(`field${action.payload}`)){
                        return item
                    }
                }),
            }
        }
        case Types.GET_DATAS_REQUEST: {
            return {
                ...state,
                list : [],
                end : null,
                fetching: true,
                dataFilter : state.dataFilter
            }
        }

        case Types.GET_DATAS_LIGHT_REQUEST: {
            return {
                ...state,
                list : [],
                end : null,
                fetching: true,
                dataFilter : state.dataFilter
            }
        }

        case Types.GET_DATAS_SHARED_LIGHT_REQUEST: {
            return {
                ...state,
                list : [],
                end : null,
                fetching: true,
                dataFilter : state.dataFilter
            }
        }

        case Types.GET_DATAS_BY_CLEF_PAGINATE_REQUEST: {
            return {
                ...state,
                listByClef : [],
                started: null,
                ended : null,
                fetching: true,
                modelClef : null,
                dataFilter : state.dataFilter
            }
        }

        case Types.GET_DATAS_BY_CODE_FOLDER_REQUEST: {
            return {
                ...state,
                listByCodeFolder : [],
                modelCodeFolder : null,
            }
        }

        case Types.GET_DATA_BY_CLEF_USER_INDEX_REQUEST: {
            return {
                ...state,
                uniqueClefUserIndex : null,
                fetching: true,
                uniqueModelClef : null,
                dataFilter : state.dataFilter
            }
        }


        case Types.DEL_DELETE_DATAS_SUCCESS:{

            let list = state.list.filter((item, index) => {
                return !action.payload.data.includes(item.hash)
            });

            return {
                ...state,
                list : list,
            }
        }

        case Types.PUT_UPDATE_DATAS_ARCHIVE_SUCCESS:{

            let dataUpdated = action.payload.data;

            const list = state.list.map((plug) => {
                if (dataUpdated.includes(plug.hash)) {
                    return {
                        ...plug,
                        isarchived : true
                    }
                } else {
                    return plug
                }
            });

            return {
                ...state,
                list : list,
                fetching : false
            }
        }


        case Types.PUT_UPDATE_DATAS_UNARCHIVE_SUCCESS:{

            let dataUpdated = action.payload.data;

            const list = state.list.map((plug) => {
                if (dataUpdated.includes(plug.hash)) {
                    return {
                        ...plug,
                        isarchived : false
                    }
                } else {
                    return plug
                }
            });

            return {
                ...state,
                list : list,
                fetching : false
            }
        }

        case Types.GET_DATAS_PAGINATE_REQUEST: {
            return {
                ...state,
                fetching: true,
                dataFilter : state.dataFilter
            }
        }

        case Types.GET_DATAS_PAGINATE_SUCCESS: {
            return {
                ...state,
                list: state.list.concat(action.payload.data),
                fetching: true,
                dataFilter : state.dataFilter
            }
        }

        case Types.GET_DATAS_FILTER_SUCCESS: {
            return {
                ...state,
                list: action.payload.data,
                fetching: true,
                dataFilter : state.dataFilter
            }
        }


        case Types.GET_DATAS_FILTER_LIGHT_SUCCESS: {
            return {
                ...state,
                list: action.payload.data,
                fetching: true,
                dataFilter : state.dataFilter
            }
        }


        case Types.GET_DATAS_BY_CLEF_PAGINATE_SUCCESS: {
            return {
                ...state,
                listByClef: action.payload.data.length > 0 ?  state.listByClef.concat(action.payload.data) : state.listByClef,
                modelClef : action.payload2,
                fetching: true,
                dataFilter : state.dataFilter
            }
        }

        case Types.GET_DATAS_BY_CODE_FOLDER_SUCCESS: {
            return {
                ...state,
                listByCodeFolder: action.payload,
                modelCodeFolder : action.payload2
            }
        }

        case Types.GET_DATA_BY_CLEF_USER_INDEX_SUCCESS: {
            return {
                ...state,
                uniqueClefUserIndex: action.payload.data,
                uniqueModelClef : action.payload2,
                fieldToRefresh : action.payload3,
                fetching: false,
                dataFilter : state.dataFilter
            }
        }

        case Types.GET_DATAS_SUCCESS: {
            return {
                ...state,
                list: action.payload.data,
                fetching : false,
                dataFilter : state.dataFilter
            }
        }

        case Types.GET_DATAS_LIGHT_SUCCESS: {
            return {
                ...state,
                list: action.payload.data,
                fetching : false,
                dataFilter : state.dataFilter
            }
        }

        case Types.GET_DATAS_SHARED_LIGHT_SUCCESS: {
            return {
                ...state,
                list: action.payload.data,
                fetching : false,
                dataFilter : state.dataFilter
            }
        }

        case Types.GET_DATAS_END_SUCCESS: {
            return {
                ...state,
                start : action.payload.start,
                end: action.payload2.end,
                fetching : false,
            }
        }

        case Types.GET_DATAS_BY_CLEF_END_SUCCESS: {
            return {
                ...state,
                started : action.payload.start,
                ended: action.payload2.end,
                fetching : false,
            }
        }

        case Types.GET_DECRYPT_PASSWORD_SUCCESS: {
            return {
                ...state,
                list: state.list,
                password: action.payload.data,
                fetchingPassword : false,
            }
        }
        case Types.GET_DECRYPT_PASSWORD_REQUEST: {
            return {
                ...state,
                list: state.list,
                password: null,
                fetchingPassword : true,
            }
        }
        case Types.PUT_UPDATE_DATA_FAVORIS_SUCCESS : {
            let dataUpdated = action.payload.data;

            const list = state.list.map((plug) => {
                if (plug.hash === dataUpdated.hash) {
                    return {
                        ...dataUpdated,
                    }
                } else {
                    return plug
                }
            });

            return {
                ...state,
                list: list,
                fetching : false
            }
        }
        case Types.PUT_UPDATE_DATA_LOCK_SUCCESS : {
            let dataUpdated = action.payload.data;

            const list = state.list.map((plug) => {
                if (plug.hash === dataUpdated.hash) {
                    return {
                        ...dataUpdated,
                    }
                } else {
                    return plug
                }
            });

            return {
                ...state,
                list: list,
                fetching : false
            }
        }
        case Types.PUT_UPDATE_DATA_ARCHIVE_SUCCESS : {
            let dataUpdated = action.payload.data;

            const list = state.list.map((plug) => {
                if (plug.hash === dataUpdated.hash) {
                    return {
                        ...dataUpdated,
                    }
                } else {
                    return plug
                }
            });

            return {
                ...state,
                list: list,
                fetching : false
            }
        }
        case Types.DEL_DELETE_DATA_SUCCESS : {
            let dataDeleted = action.payload.data;

            const list = state.list.filter((plug) => {
                if (plug.hash !== dataDeleted) {
                    return {
                        ...plug,
                    }
                }
            })

            return {
                ...state,
                list: list,
                fetching : false
            }
        }
        case Types.POST_CREATE_SHARED_DATA_WITHOUT_REDIRECT_SUCCESS:
        case Types.POST_CREATE_DATA_WITHOUT_REDIRECT_SUCCESS:{
            return {
                ...state,
            }
        }

        case Types.PUT_DATA_UNIQUE_SUCCESS : {
            let dataUpdated = action.payload;

            const list = state.list.map((data) => {
                if (data.hash === dataUpdated.hash) {
                    return {
                        ...dataUpdated,
                    }
                } else {
                    return data
                }
            });

            return {
                ...state,
                list: list,
                fetching : false
            }
        }

        case Types.GET_DATA_UNIQUE_USERINDEX_SUCCESS:{
            return {
                ...state,
                unique: action.payload.data,
                fetching : false
            }
        }

        case Types.GET_DATA_UNIQUE_USERINDEX_REQUEST:{
            return {
                ...state,
                unique: null,
            }
        }

        case Types.GET_DATA_UNIQUE_SUCCESS:{
            return {
                ...state,
                plugnoterefresh: action.payload.data,
                fetching : false
            }
        }

        case Types.GET_DATA_UNIQUE_REQUEST:{
            return {
                ...state,
                plugnoterefresh: null,
            }
        }

        case Types.GET_DATA_UNIQUE_RESET:{
            return {
                ...state,
                plugnoterefresh: null,
            }
        }

        case Types.REDUX_GET_DATA_BY_CLEF_USER_INDEX_RESET: {
            return {
                ...state,
                uniqueClefUserIndex : null,
                fetching: false,
                uniqueModelClef : null,
                dataFilter : state.dataFilter,
                fieldToRefresh : null,
            }
        }

        case Types.PUT_UPDATE_DATA_WITHOUT_REDIRECT_MODAL_SUCCESS : {
            let dataUpdated = action.payload.data;

            const list = state.listByCodeFolder.map((plug) => {
                if (plug.hash === dataUpdated.hash) {
                    return {
                        ...dataUpdated,
                    }
                } else {
                    return plug
                }
            });

            return {
                ...state,
                listByCodeFolder: list,
                fetching : false
            }
        }

        case Types.PUT_UPDATE_SHARED_DATA_WITHOUT_REDIRECT_MODAL_SUCCESS : {
            let dataUpdated = action.payload.data;

            const list = state.listByCodeFolder.map((plug) => {
                if (plug.hash === dataUpdated.hash) {
                    return {
                        ...dataUpdated,
                    }
                } else {
                    return plug
                }
            });

            return {
                ...state,
                listByCodeFolder: list,
                fetching : false
            }
        }

        default: return state;
    }
};
