// create initial state for reducers
import {Types} from "../actions/repertory";

const INIT_STATE = {
    list_contact : []
};


// reducer function to transform state
export default function repertory(state = INIT_STATE, action) {


    switch(action.type) {
        case Types.RESET_ALL_DATA : return INIT_STATE;

        case Types.GET_LIST_CONTACTS_SUCCESS: {
            return {
                ...state,
                list_contact: action.payload,
                fetching: true,
            }
        }



        default: return state;
    }

};