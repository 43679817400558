// actions types is object with the
// key: GET_DATA_REQUEST -> value: describes the action
export const Types = {

    RESET_ALL_DATA: 'reset_all_data',



    POST_SEND_LIST_FILTER_REQUEST: 'post_send_list_filter_request',
    POST_SEND_LIST_FILTER_SUCCESS: 'post_send_list_filter_success',
    POST_SEND_LIST_FILTER_FAILURE: 'post_send_list_filter_failure',
    POST_SEND_LIST_FILTER_ERROR: 'post_send_list_filter_error',


    GET_LIST_FILTER_REQUEST: 'get_list_filter_request',
    GET_LIST_FILTER_SUCCESS: 'get_list_filter_success',
    GET_LIST_FILTER_FAILURE: 'get_list_filter_failure',
    GET_LIST_FILTER_ERROR: 'get_list_filter_error',
    GET_LIST_FILTER_RESET: 'get_list_filter_reset',

    DELETE_FILTER_REQUEST: 'delete_filter_request',
    DELETE_FILTER_SUCCESS: 'delete_filter_success',
    DELETE_FILTER_FAILURE: 'delete_filter_failure',
    DELETE_FILTER_ERROR: 'delete_filter_error',

};

/*
Token
 */
// function that returns an object literal
export const resetAllData = () => ({
    type: Types.RESET_ALL_DATA,
});


/*
Token
 */
// function that returns an object literal
export const postSendListFilterRequest = (payload) => ({
    type: Types.POST_SEND_LIST_FILTER_REQUEST,
    payload : payload
});

// function that returns an object literal
export const postSendListFilterSuccess = () => ({
    type: Types.POST_SEND_LIST_FILTER_SUCCESS,
});

// function that returns an object literal
export const postSendListFilterFailure = () => ({
    type: Types.POST_SEND_LIST_FILTER_FAILURE,
});

// function that returns an object literal
export const postSendListFilterError = () => ({
    type: Types.POST_SEND_LIST_FILTER_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const getListFilterRequest = (payload) => ({
    type: Types.GET_LIST_FILTER_REQUEST,
    payload : payload
});

// function that returns an object literal
export const getListFilterSuccess = (data) => ({
    type: Types.GET_LIST_FILTER_SUCCESS,
    payload : data
});

// function that returns an object literal
export const getListFilterFailure = () => ({
    type: Types.GET_LIST_FILTER_FAILURE,
});

// function that returns an object literal
export const getListFilterError = () => ({
    type: Types.GET_LIST_FILTER_ERROR,
});

// function that returns an object literal
export const getListFilterReset = () => ({
    type: Types.GET_LIST_FILTER_RESET,
});


/*
Token
 */
// function that returns an object literal
export const deleteFilterRequest = (payload) => ({
    type: Types.DELETE_FILTER_REQUEST,
    payload : payload
});

// function that returns an object literal
export const deleteFilterSuccess = (data) => ({
    type: Types.DELETE_FILTER_SUCCESS,
    payload : data
});

// function that returns an object literal
export const deleteFilterFailure = () => ({
    type: Types.DELETE_FILTER_FAILURE,
});

// function that returns an object literal
export const deleteFilterError = () => ({
    type: Types.DELETE_FILTER_ERROR,
});
