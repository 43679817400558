// actions types is object with the
// key: GET_DATA_REQUEST -> value: describes the action
export const Types = {
    RESET_ALL_DATA: 'reset_all_data',
};

/*
Token
 */
// function that returns an object literal
export const resetAllData = () => ({
    type: Types.RESET_ALL_DATA,
});

