import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {connect} from "react-redux";
import {getCurrentEnvironnement} from "../../utils/Common";
import _, {camelCase} from 'lodash'
import {getModelsCategoriesRequest, postCreateModelBiblioV2Request} from "../../actions/model";
import Images from "../../assets/themes/Images";
import {fieldTypes, formatTypes} from "../../utils/Compatibility";
import {AppBar, Dialog, Tab} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import {TabContext, TabList, TabPanel} from "@material-ui/lab";

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Box from "@material-ui/core/Box";


const colors =  ["red", "yellow", "blue", "green", "purple", "pink"];

function getModalStyle() {
    const top = 5;

    return {
        top: `${top}%`,
        margin:'auto',
        overflow: 'auto',
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    indicator:{
        backgroundColor: '#58C3C1'
    },
    popup: {
        position: 'absolute',
        //width: '50%',
        //height : '50%',
        //backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        //boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        width: '50%',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    marginLeft:{
        marginLeft : '10px'
    },
    fieldImage: {
        height: 18,
        width: 18
    },
    fieldIcon : {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    fieldMandatoryIcon : {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    toolbarButtons: {
        marginLeft: 'auto',
        marginRight: -12
    },
}));

const useCardStyles = makeStyles({
    root: {
        maxWidth: 232,
        minWidth : 232,
    },
    media: {
        height: 60,
    },
    content: {
        height: 75,
    },
    action: {
        height: 50,
        justifyContent : 'right'
    },
    contentModelGrid : {
        //flexGrow: 1,
        //padding: theme.spacing(3),
        display: 'flex',
        backgroundColor : 'white',
        flexWrap : 'wrap',
        gridColumnGap: '20px',
        columnGap: '20px',
        padding: '0 20px',
    },
});

const toolBarStyles = makeStyles({
    root:{
        alignItems : 'inherit !important',
        width : '100% !important'
    },
})

const ModalBiblioPreviewImportComponent = (props) => {

    const classes = useStyles();

    const [modalStyle] = React.useState(getModalStyle);
    const [t, i18n] = useTranslation('common');

    let viewModel = props.viewModel;
    let viewFields = props.viewFields;
    let listFields = props.listFields;

    const [currentLanguage, setCurrentLanguage] = useState(i18n.language.charAt(0).toUpperCase() + i18n.language.charAt(1).toUpperCase());
    const [currentLanguageLower, setCurrentLanguageLower] = useState(i18n.language.charAt(0).toLowerCase() + i18n.language.charAt(1).toLowerCase());
    const [currentLanguageFirstUpper, setCurrentLanguageFirstUpper] = useState(i18n.language.charAt(0).toUpperCase() + i18n.language.charAt(1).toLowerCase());
    const environment = getCurrentEnvironnement();


    const renderFormatText = (field) => {
        let fieldFormat = field.format;
        // Vérifier les préselections
        let template = '';
        if (typeof field.presel !== 'undefined') {

            if(field.multiselect && !field.editable){
                fieldFormat = "multi-select";
            }else if(!field.multiselect && !field.editable){
                fieldFormat = "single-select";

            }else if(field.multiselect && field.editable){
                fieldFormat = "free-multi-select";

            }else if(!field.multiselect && field.editable){
                fieldFormat = "free-single-select";
            }

        }

        switch (fieldFormat) {
            case fieldTypes.QRCODE:
                template = <span>{formatTypes.QRCODE}</span>; break;
            case fieldTypes.BIRTHDAY:
                template = <span>{formatTypes.BIRTHDAY}</span>; break;
            case fieldTypes.TEXT:
                template = <span>{formatTypes.TEXT}</span>; break;
            case fieldTypes.TEXTAREA:
                template = <span>{formatTypes.TEXTAREA}</span>; break;
            case fieldTypes.PHONE:
                template = <span>{formatTypes.PHONE}</span>; break;
            case fieldTypes.MAIL:
                template = <span>{formatTypes.MAIL}</span>; break;
            case fieldTypes.URL:
                template = <span>{formatTypes.URL}</span>; break;
            case fieldTypes.LOCALIS:
                template = <span>{formatTypes.LOCALIS}</span>; break;
            case fieldTypes.NUMERIC:
                template = <span>{formatTypes.NUMERIC}</span>; break;
            case fieldTypes.FREE_MULTI_SELECT:
                template = <span>{formatTypes.FREE_MULTI_SELECT}</span>; break;
            case fieldTypes.MULTI_SELECT:
                template = <span>{formatTypes.MULTI_SELECT}</span>; break;
            case fieldTypes.SINGLE_SELECT:
                template = <span>{formatTypes.SINGLE_SELECT}</span>; break;
            case fieldTypes.FREE_SINGLE_SELECT:
                template = <span>{formatTypes.FREE_SINGLE_SELECT}</span>; break;
            case fieldTypes.LIST:
                template = <span>{formatTypes.LIST}</span>; break;
            case fieldTypes.NOW:
                template = <span>{formatTypes.NOW}</span>; break;
            case fieldTypes.AGENDA:
                template = <span>{formatTypes.AGENDA}</span>; break;
            case fieldTypes.DATE:
                template = <span>{formatTypes.DATE}</span>; break;
            case fieldTypes.BOOLEAN:
                template = <span>{formatTypes.BOOLEAN}</span>; break;
            case fieldTypes.TABLEMULTI:
                template = <span>{formatTypes.TABLEMULTI}</span>; break;
            case fieldTypes.INDICE:
                template = <span>{formatTypes.INDICE}</span>; break;
        }

        return template;
    }

    const handlePressMultipleCategoryCreate = () => {

        // {"askingChoice":[{"id":30},{"id":30}],"models":[{"id":8,"group":30},{"id":75,"group":30}],"hidden":true}
        let askingChoice = [];
        let models = [];
        let hidden = true;

        let updateCategorie = props.listCategoriePro;
        if(!props.viewPro){
            updateCategorie = props.listCategoriePrivate;
        }


        let params = {
            askingChoice : askingChoice,
            models : models,
            hidden : hidden
        }



        updateCategorie.map((categorie, index) => {
            categorie.bacategories_bbgroups.map((group, index) => {
                group.bacategories_bcmodels.map((model, index) => {
                    if(model === viewModel){
                        askingChoice.push({"id" : group.bbgroups_idGroup});
                        models.push({"id" : model.bcmodels_idModel, "group" : group.bbgroups_idGroup})
                    }
                });
            });
        });

        if(models.length > 0){
            props.attemptCreateModelBiblioAsking(params, environment.userhash,currentLanguage);
            props.close();
        }
    }



    return (
        <Modal
            keepMounted={false}
            open={props.open}
            onClose={props.close}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div key={props.mid} style={modalStyle} className={classes.popup}>
                <div className="" style={{flex:1, width: '100%'}}/>
                { viewModel !== null ?
                    <div>
                        <div className="liste-champ-modele_apercu">
                            <div className="champ-apercu grey">{viewModel.bcmodelslgs_titreModel}</div>
                            {
                                viewFields && listFields.map((field,index) => {
                                    return (
                                        <div id="liste-champ-modele_apercu-ligne" key={index}>
                                            <div className={field.mandatory ? classes.fieldMandatoryIcon : classes.fieldIcon}>
                                                <img
                                                    className={classes.fieldImage}
                                                    src ={ !field.mandatory ? Images[camelCase(field.format.toLowerCase())+"IconSvg"] : Images[camelCase(field.format.toLowerCase())+"IconSvgRed"]}
                                                    alt={t('fields.format-choice-'+field.format.toLowerCase())}
                                                />
                                            </div>
                                            <div>
                                                    <span className="references">
                                                        {field.label}
                                                    </span>
                                            </div>
                                            <div>
                                                    <span className="references">
                                                        {
                                                            (field.position > 9) ?
                                                                <span>n°{field.position} {renderFormatText(field)}</span>
                                                                :
                                                                <span>n°{field.position <=9 && <span>0</span>}{field.position} {renderFormatText(field)}</span>
                                                        }

                                                    </span>
                                            </div>
                                        </div>
                                    )
                                })

                            }
                        </div>
                        <div className="float_right">
                            <button className="button" onClick={(event) => handlePressMultipleCategoryCreate()}>{t('common:models.create-selected-models')}</button>
                        </div>
                    </div>
                    :
                    <div className="liste-champ-modele_apercu">
                    </div>
                }
            </div>
        </Modal>
    )
}

const ModalBiblioTemplateComponent = (props) => {

    const classes = useStyles();
    const cardClasses = useCardStyles();
    const toolBarClasses = toolBarStyles();


    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [t, i18n] = useTranslation('common');

    const [currentLanguage, setCurrentLanguage] = useState(i18n.language.charAt(0).toUpperCase() + i18n.language.charAt(1).toUpperCase());
    const [currentLanguageLower, setCurrentLanguageLower] = useState(i18n.language.charAt(0).toLowerCase() + i18n.language.charAt(1).toLowerCase());
    const [currentLanguageFirstUpper, setCurrentLanguageFirstUpper] = useState(i18n.language.charAt(0).toUpperCase() + i18n.language.charAt(1).toLowerCase());

    const [newTemplateModel, setNewTemplateModel] = useState([]);
    const [listCategoriePrivate, setListCategoriePrivate] = useState([]);
    const [listCategoriePro, setListCategoriePro] = useState([]);
    const [viewPro, setViewPro] = useState(true);
    const [selectedView, setSelectedView] = useState("3");
    const [viewModel, setViewModel] = useState(null);
    const [viewFields, setViewFields] = useState(false);
    const [listFields, setListFields] = useState([]);


    const [open, setOpen] = React.useState(true);

    const [subOpen, setSubOpen] = React.useState(true);

    const [expanded, setExpanded] = React.useState('');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleClick = () => {
        setOpen(!open);
    };

    const [code, setCode] = useState(props.code || "");

    const environment = getCurrentEnvironnement();


    useEffect(() => {

        let currentCategoryPrivate = props.categories.filter((item, index) => {
            if(item.bacategories_type === 2){
                return item;
            }
        });
        currentCategoryPrivate.filter(Boolean);
        let currentCategoryPrivateSorted = currentCategoryPrivate.sort((a, b) => {
            return a['bacategories_catAsk' + currentLanguageFirstUpper].substring(0, 2) - b['bacategories_catAsk' + currentLanguageFirstUpper].substring(0, 2)
        });

        setListCategoriePrivate(currentCategoryPrivateSorted);

        let currentCategoryPro = props.categories.filter((item, index) => {
            if(item.bacategories_type === 3){
                return item;
            }
        });
        currentCategoryPro.filter(Boolean);
        let currentCategoryProSorted = currentCategoryPro.sort((a, b) => {
            return a['bacategories_catAsk' + currentLanguageFirstUpper].substring(0, 2) - b['bacategories_catAsk' + currentLanguageFirstUpper].substring(0, 2)
        });

        setListCategoriePro(currentCategoryProSorted);

        setCode(props.code);

    }, [props.categories]);

    useEffect(() => {
        if(open){
            props.attemptGetModelsCategories(currentLanguage);
        }
    }, [open])


    const handleChangeBiblioType = (event, newValue) => {
        setSubOpen(false);
        setViewModel(null);
        setListFields([]);
        setViewFields(false);
        setViewPro(newValue === '3');
        setSelectedView(newValue)
    }

    const handleChangeSelectionTemplateModel = (indexCategorie, indexGroup, indexModel) => {
        let updateCategorie = listCategoriePro;
        let checked = false;
        if(!viewPro){
            updateCategorie = listCategoriePrivate;
        }

        updateCategorie[indexCategorie].bacategories_bbgroups[indexGroup].bacategories_bcmodels[indexModel].bcmodels_isChecked = !updateCategorie[indexCategorie].bacategories_bbgroups[indexGroup].bacategories_bcmodels[indexModel].bcmodels_isChecked;

        updateCategorie[indexCategorie].bacategories_bbgroups[indexGroup].bacategories_bcmodels.map((item, index) => {
            if(item.bcmodels_isChecked){
                checked = true;
            }
        });

        updateCategorie[indexCategorie].bacategories_bbgroups[indexGroup].bacategories_isChecked = checked;

        //updateCategorie[indexCategorie].bbgroups_models[indexModel].bcmodels_isChecked = !updateCategorie[indexCategorie].bbgroups_models[indexModel].bcmodels_isChecked;
        if(viewPro){
            setListCategoriePro(updateCategorie);
        }else{
            setListCategoriePrivate(updateCategorie);
        }
    }

    const handleChangeSelectionTemplateGroup = (indexCategorie, indexGroup) => {
        let updateCategorie = listCategoriePro;
        if(!viewPro){
            updateCategorie = listCategoriePrivate;
        }

        if(typeof updateCategorie[indexCategorie].bacategories_bbgroups[indexGroup].bacategories_isChecked !== "undefined"){
            updateCategorie[indexCategorie].bacategories_bbgroups[indexGroup].bacategories_isChecked = !updateCategorie[indexCategorie].bacategories_bbgroups[indexGroup].bacategories_isChecked;
        }else{
            updateCategorie[indexCategorie].bacategories_bbgroups[indexGroup].bacategories_isChecked = true;
        }


        updateCategorie[indexCategorie].bacategories_bbgroups[indexGroup].bacategories_bcmodels.map((item, index) => {
            item.bcmodels_isChecked = updateCategorie[indexCategorie].bacategories_bbgroups[indexGroup].bacategories_isChecked === true;
        });

        //updateCategorie[indexCategorie].bbgroups_models[indexModel].bcmodels_isChecked = !updateCategorie[indexCategorie].bbgroups_models[indexModel].bcmodels_isChecked;
        if(viewPro){
            setListCategoriePro(updateCategorie);
        }else{
            setListCategoriePrivate(updateCategorie);
        }
    }

    const handleCloseModal = () => {
        setListCategoriePrivate([]);
        setListCategoriePro([]);
        setCode("");
        props.close();
    }

    const handlePressMultipleCategoryCreate = () => {

        // {"askingChoice":[{"id":30},{"id":30}],"models":[{"id":8,"group":30},{"id":75,"group":30}],"hidden":true}
        let askingChoice = [];
        let models = [];
        let hidden = true;

        let updateCategorie = listCategoriePro;
        if(!viewPro){
            updateCategorie = listCategoriePrivate;
        }


        let params = {
            askingChoice : askingChoice,
            models : models,
            hidden : hidden
        }

        updateCategorie.map((categorie, index) => {
            categorie.bacategories_bbgroups.map((group, index) => {
                if(group.bacategories_isChecked){
                    askingChoice.push({"id" : group.bbgroups_idGroup});
                    group.bacategories_bcmodels.map((model, index) => {
                            if(model.bcmodels_isChecked){
                                models.push({"id" : model.bcmodels_idModel, "group" : group.bbgroups_idGroup})
                            }
                    });
                }
            });
        });

        if(askingChoice.length > 0 && models.length > 0){
            props.attemptCreateModelBiblioAsking(params, environment.userhash,currentLanguage);
        }

    }

    const handleSelectedModelToPreview = (model) => {
        setViewModel(model);
        setViewFields(model.bcmodels_bdfields);
        setListFields([]);
        if(model.bcmodels_bdfields === true){
            for (let i = 1; i <= 15 ; i++) {
                if(model.bcmodels_bdfields_lists['bdfields_field'+i+'format'] !== null && model.bcmodels_bdfields_lists['bdfields_field'+i+'position'] === i){
                    let format = model.bcmodels_bdfields_lists['bdfields_field'+i+'format'];
                    if(format === 'select'){
                        switch (model.bcmodels_bdfields_lists['bdfields_field'+i+'FormatOptionMultiselect']) {
                            case true :
                                if (model.bcmodels_bdfields_lists['bdfields_field'+i+'FormatOptionEditable']) {
                                    format = "free-multi-select";
                                } else {
                                    format = "multi-select";
                                }
                                break;
                            default:
                                if (model.bcmodels_bdfields_lists['bdfields_field'+i+'FormatOptionEditable']) {
                                    format = "free-single-select";
                                } else {
                                    format = "single-select";
                                }
                        }
                    }

                    let element = {
                        format : format,
                        label : model.bcmodels_bdfields_lists['bdfields_field'+i+'label'+currentLanguageLower],
                        mandatory : model.bcmodels_bdfields_lists['bdfields_field'+i+'mandatory'],
                        presel : model.bcmodels_bdfields_lists['bdfields_field'+i+'format'] === 'select' ? model.bcmodels_bdfields_lists['bdfields_field'+i+'content'+currentLanguageLower].split(';') : undefined,
                        multiselect : model.bcmodels_bdfields_lists['bdfields_field'+i+'FormatOptionMultiselect'],
                        editable : model.bcmodels_bdfields_lists['bdfields_field'+i+'FormatOptionEditable'],
                        position : i,
                    };
                    setListFields(oldArray => [...oldArray, element]);
                }
            }
        }
        setSubOpen(true)
    };

    const handleDeleteSelectedModelToPreview = (event, newValue) => {
        setViewModel(null);
        setListFields([]);
        setViewFields(false);
        setSubOpen(false);
    }

    const renderFormatText = (field) => {
        let fieldFormat = field.format;
        // Vérifier les préselections
        let template = '';
        if (typeof field.presel !== 'undefined') {

            if(field.multiselect && !field.editable){
                fieldFormat = "multi-select";
            }else if(!field.multiselect && !field.editable){
                fieldFormat = "single-select";

            }else if(field.multiselect && field.editable){
                fieldFormat = "free-multi-select";

            }else if(!field.multiselect && field.editable){
                fieldFormat = "free-single-select";
            }

        }

        switch (fieldFormat) {
            case fieldTypes.QRCODE:
               template = <span>{formatTypes.QRCODE}</span>; break;
            case fieldTypes.BIRTHDAY:
                template = <span>{formatTypes.BIRTHDAY}</span>; break;
            case fieldTypes.TEXT:
                template = <span>{formatTypes.TEXT}</span>; break;
            case fieldTypes.TEXTAREA:
                template = <span>{formatTypes.TEXTAREA}</span>; break;
            case fieldTypes.PHONE:
                template = <span>{formatTypes.PHONE}</span>; break;
            case fieldTypes.MAIL:
                template = <span>{formatTypes.MAIL}</span>; break;
            case fieldTypes.URL:
                template = <span>{formatTypes.URL}</span>; break;
            case fieldTypes.LOCALIS:
                template = <span>{formatTypes.LOCALIS}</span>; break;
            case fieldTypes.NUMERIC:
                template = <span>{formatTypes.NUMERIC}</span>; break;
            case fieldTypes.FREE_MULTI_SELECT:
                template = <span>{formatTypes.FREE_MULTI_SELECT}</span>; break;
            case fieldTypes.MULTI_SELECT:
                template = <span>{formatTypes.MULTI_SELECT}</span>; break;
            case fieldTypes.SINGLE_SELECT:
                template = <span>{formatTypes.SINGLE_SELECT}</span>; break;
            case fieldTypes.FREE_SINGLE_SELECT:
                template = <span>{formatTypes.FREE_SINGLE_SELECT}</span>; break;
            case fieldTypes.LIST:
                template = <span>{formatTypes.LIST}</span>; break;
            case fieldTypes.NOW:
                template = <span>{formatTypes.NOW}</span>; break;
            case fieldTypes.AGENDA:
                template = <span>{formatTypes.AGENDA}</span>; break;
            case fieldTypes.DATE:
                template = <span>{formatTypes.DATE}</span>; break;
            case fieldTypes.BOOLEAN:
                template = <span>{formatTypes.BOOLEAN}</span>; break;
        }

        return template;
    }

    return (
        <Dialog
            fullScreen
            keepMounted={false}
            open={props.open}
            onClose={handleCloseModal}
        >
            <div key={props.countable}>

                <div>
                    <AppBar position="fixed"  color="default">
                        <Toolbar className={toolBarClasses.root}>
                            <Box display='flex' flexGrow={1}>
                                {/* whatever is on the left side */}
                            </Box>
                            <IconButton color="inherit" onClick={handleCloseModal} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </div>

                <div style={{marginLeft : '50px', marginRight : '50px', marginTop : '75px'}}>
                    <TabContext value={selectedView}>
                        <TabList onChange={handleChangeBiblioType} aria-label="simple tabs example" indicatorColor="primary" classes={{ indicator: classes.indicator }}>
                            <Tab label="Pro" value= "3"/>
                            <Tab label="Private" value= "2"/>
                        </TabList>

                        <TabPanel value="2">
                            <div style={{width: '100%'}}>
                                {
                                    listCategoriePrivate.map((item, indexCategorie) => {

                                        let selectionSorted = item.bacategories_bbgroups.sort((a, b) => {
                                            if(a.bbgroupslgs_titreGroup.substring(0, 1) < b.bbgroupslgs_titreGroup.substring(0, 1)) { return -1; }
                                            if(a.bbgroupslgs_titreGroup.substring(0, 1) > b.bbgroupslgs_titreGroup.substring(0, 1)) { return 1; }
                                            return 0;
                                        });

                                        let tmpSelection = selectionSorted.filter((item, value) => {
                                            if(typeof item.bacategories_isChecked !== "undefined"){
                                                if(item.bacategories_isChecked === true){
                                                    return item;
                                                }
                                            }
                                        });

                                        tmpSelection.filter(Boolean);

                                        return(
                                            <div key={indexCategorie}>
                                                <div>
                                                    {
                                                        selectionSorted.map((group,indexGroup) => {
                                                            //group.bacategories_bcmodels

                                                            let selectionModelsSorted = group.bacategories_bcmodels.sort((a, b) => {
                                                                if(a.bcmodelslgs_titreModel < b.bcmodelslgs_titreModel) { return -1; }
                                                                if(a.bcmodelslgs_titreModel > b.bcmodelslgs_titreModel) { return 1; }
                                                                return 0;
                                                            });

                                                            let tmpModelsSelection = selectionModelsSorted.filter((item, value) => {
                                                                if(item.bcmodels_isChecked){
                                                                    return item;
                                                                }
                                                            });

                                                            tmpModelsSelection.filter(Boolean);

                                                            return (
                                                                <div key={indexGroup}>
                                                                    <Typography variant="h6" component="h5">
                                                                        {group.bbgroupslgs_titreGroup}
                                                                    </Typography>
                                                                    <div className={cardClasses.contentModelGrid} >
                                                                        {
                                                                            selectionModelsSorted.map((model,indexModel) => {
                                                                                return(
                                                                                    <Box m={2} pt={3} key={indexModel}>
                                                                                        <Card className={cardClasses.root}>
                                                                                            <CardContent className={cardClasses.media} style={{backgroundColor : colors[indexGroup]}}>
                                                                                            </CardContent>
                                                                                            <CardContent className={cardClasses.content}>
                                                                                                <Typography variant="body1" color="textPrimary" component="p">
                                                                                                    {model.bcmodelslgs_titreModel}
                                                                                                </Typography>
                                                                                            </CardContent>
                                                                                            <CardActions className={cardClasses.action}>
                                                                                                <Button size="small" color="primary" onClick={(event) => {handleSelectedModelToPreview(model);}}>
                                                                                                    IMPORT
                                                                                                </Button>
                                                                                            </CardActions>
                                                                                        </Card>
                                                                                    </Box>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </TabPanel>
                        <TabPanel value="3">
                            <div style={{width: '100%'}}>
                                {
                                    listCategoriePro.map((item, indexCategorie) => {

                                        let selectionSorted = item.bacategories_bbgroups.sort((a, b) => {
                                            if(a.bbgroupslgs_titreGroup.substring(0, 1) < b.bbgroupslgs_titreGroup.substring(0, 1)) { return -1; }
                                            if(a.bbgroupslgs_titreGroup.substring(0, 1) > b.bbgroupslgs_titreGroup.substring(0, 1)) { return 1; }
                                            return 0;
                                        });

                                        let tmpSelection = selectionSorted.filter((item, value) => {
                                            if(typeof item.bacategories_isChecked !== "undefined"){
                                                if(item.bacategories_isChecked === true){
                                                    return item;
                                                }
                                            }
                                        });

                                        tmpSelection.filter(Boolean);

                                        return(
                                            <div key={indexCategorie}>
                                                <div>
                                                    {
                                                        selectionSorted.map((group,indexGroup) => {
                                                            //group.bacategories_bcmodels

                                                            let selectionModelsSorted = group.bacategories_bcmodels.sort((a, b) => {
                                                                if(a.bcmodelslgs_titreModel < b.bcmodelslgs_titreModel) { return -1; }
                                                                if(a.bcmodelslgs_titreModel > b.bcmodelslgs_titreModel) { return 1; }
                                                                return 0;
                                                            });

                                                            let tmpModelsSelection = selectionModelsSorted.filter((item, value) => {
                                                                if(item.bcmodels_isChecked){
                                                                    return item;
                                                                }
                                                            });

                                                            tmpModelsSelection.filter(Boolean);

                                                            return (
                                                                <div key={indexGroup}>
                                                                    <Typography variant="h6" component="h5">
                                                                        {group.bbgroupslgs_titreGroup}
                                                                    </Typography>
                                                                    <div className={cardClasses.contentModelGrid} >
                                                                        {
                                                                            selectionModelsSorted.map((model,indexModel) => {
                                                                                return(
                                                                                    <Box m={2} pt={3} key={indexModel}>
                                                                                        <Card className={cardClasses.root}>
                                                                                            <CardContent className={cardClasses.media} style={{backgroundColor : colors[indexGroup]}}>
                                                                                            </CardContent>
                                                                                            <CardContent className={cardClasses.content}>
                                                                                                <Typography variant="body1" color="textPrimary" component="p">
                                                                                                    {model.bcmodelslgs_titreModel}
                                                                                                </Typography>
                                                                                            </CardContent>
                                                                                            <CardActions className={cardClasses.action}>
                                                                                                <Button size="small" color="primary" onClick={(event) => {handleSelectedModelToPreview(model);}}>
                                                                                                    IMPORT
                                                                                                </Button>
                                                                                            </CardActions>
                                                                                        </Card>
                                                                                    </Box>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </TabPanel>
                    </TabContext>

                </div>


                { subOpen && viewModel !== null &&
                    <ModalBiblioPreviewImportComponent
                        open={subOpen}
                        close={handleDeleteSelectedModelToPreview}
                        listFields={listFields}
                        viewFields={viewFields}
                        viewModel={viewModel}
                        listCategoriePro={listCategoriePro}
                        listCategoriePrivate={listCategoriePrivate}
                        viewPro={viewPro}
                        attemptCreateModelBiblioAsking={props.attemptCreateModelBiblioAsking}
                    />
                }
            </div>
        </Dialog>
    )
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        mainModels : state.model.datas,
        categories : _.cloneDeep(state.model.categories),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        //attemptCreateModelFromGroupTemplate: (model, models, unique, userhash) => dispatch(postModelFromTemplateGroupRequest(model, models, unique, userhash)),
        attemptCreateModelBiblioAsking: (payload,userhash,language) => dispatch(postCreateModelBiblioV2Request(payload,userhash,language)),
        attemptGetModelsCategories: (language) => dispatch(getModelsCategoriesRequest(language)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalBiblioTemplateComponent)
