import {buffers, END, eventChannel} from 'redux-saga';
import {getToken} from "../utils/Common";

export function createUploadFileChannel(endpoint: string, file: File) {
    return eventChannel(emitter => {
        const xhr = new XMLHttpRequest();
        const onProgress = (e: ProgressEvent) => {
            if (e.lengthComputable) {
                const progress = e.loaded / e.total*100;
                emitter({ progress });
            }
        };
        const onFailure = (e: ProgressEvent) => {
            emitter({ err: new Error('Upload failed') });
            emitter(END);
        };
        xhr.upload.addEventListener("progress", onProgress);
        xhr.upload.addEventListener("error", onFailure);
        xhr.upload.addEventListener("abort", onFailure);
        xhr.onreadystatechange = () => {
            const { readyState, status, response } = xhr;
            if (readyState === 4) {
                if (status === 200) {
                    emitter({ success: true, response : response });
                    emitter(END);
                }
                else {
                    onFailure(null);
                }
            }
        };
        xhr.open("POST", endpoint, true);
        xhr.setRequestHeader('X-AUTH-TOKEN', getToken());
        //xhr.setRequestHeader('content-type', 'multipart/form-data');
        xhr.send(file);
        return () => {
            xhr.upload.removeEventListener("progress", onProgress);
            xhr.upload.removeEventListener("error", onFailure);
            xhr.upload.removeEventListener("abort", onFailure);
            xhr.onreadystatechange = null;
            xhr.abort();
        };
    }, buffers.sliding(2));
}