// create initial state for reducers
import {Types} from "../actions/filter";

const INIT_STATE = {
    list : []
};


// reducer function to transform state
export default function utils(state = INIT_STATE, action) {


    switch(action.type) {

        case Types.RESET_ALL_DATA : return INIT_STATE;

        case Types.POST_SEND_LIST_FILTER_REQUEST:
        case Types.POST_SEND_LIST_FILTER_FAILURE:
        case Types.POST_SEND_LIST_FILTER_SUCCESS:
        case Types.POST_SEND_LIST_FILTER_ERROR: {
            return {
                ...INIT_STATE,
            }
        }

        case Types.GET_LIST_FILTER_RESET:
        case Types.GET_LIST_FILTER_FAILURE:
        case Types.GET_LIST_FILTER_ERROR: {
            return {
                ...INIT_STATE,
                list : []
            }
        }

        case Types.GET_LIST_FILTER_SUCCESS: {
            return {
                ...INIT_STATE,
                list : action.payload,
            }
        }
        default: return state;
    }

};
