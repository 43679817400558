import React, {useState} from 'react';
import {Redirect, Route} from 'react-router-dom';
import ApplicationConfig from "../config/ApplicationConfig";

// handle the public routes
function RedirectRoute({ component: Component, ...rest }) {
    const [codeModelFiche, setCodeModelFiche] = useState(rest?.location?.pathname?.split('/')?.filter(Boolean));
    if(typeof codeModelFiche !== "undefined"){
        window.location.replace(ApplicationConfig.plugcodesUrl + codeModelFiche[1]+ '/' + codeModelFiche[2]+ '/' + codeModelFiche[3]);
    } else {
        window.location.replace(ApplicationConfig.baseUrl)

    }

    return null;
}
export default RedirectRoute
