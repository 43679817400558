import {call, fork, put, takeEvery} from 'redux-saga/effects';
import * as actions from '../actions/field';
import * as api from '../api/fieldApi';
import {trackPromise} from "react-promise-tracker";

const myGenericTrackedWrapper = (fn, ...args) => trackPromise(fn(...args));


export function* fetchFields(action) {
    try {
        const response = yield call(api.getFields);
        yield put(actions.getFieldsSuccess(response));
    }catch(e) {
        yield put(actions.getFieldsError());
    }
}

function* watchFetchField() {
    // create watcher of fetchData function
    yield takeEvery(actions.Types.GET_FIELDS_REQUEST, fetchFields);
}

const FieldSagas = [
    fork(watchFetchField)
];

export default FieldSagas;