import React from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Images from "../../assets/themes/Images";

import {history} from "../../hooks/history";
import {useTranslation} from "react-i18next";
import LanguageComponent from "../language/LanguageComponent";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    menuButtonAccount: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2),
        color : '#58C3C1',
    },
    menuButtonHelp: {
        marginLeft: theme.spacing(1),
        //marginRight: theme.spacing(2),
        color : '#DADADA',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    li:{
        borderLeft: '1px solid #DADADA',
    },
    nav: {
        display: 'inline-block',
        padding: '0px 10px'
    },
    navLast: {
        display: 'inline-block',
        paddingLeft : '10px',
        paddingRight: '20px'
    }
}));

const plugcodesUrl = [
    '/plugcodes',
    '/plugcode-complete-gallery',
    '/plugcode-complete',
    '/plugcode-consult-gallery',
    '/plugcode-consult',
    '/plugcode-copy'
]

const modelsUrl = [
    '/models',
    '/create-model',
    '/update-model',
    '/pluglist',
    '/create-plugnote',
    '/update-plugnote',
    '/plugnote-copy-move'
]

const modelsSharedUrl = [
    '/models-shared',
    '/pluglist-shared',
    '/create-plugnote-shared',
    '/update-plugnote-shared',
    '/plugnote-view-shared'
]

const boxUrl = [
    '/inbox',
]


const MenuOfflineComponent = (props) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation(['common', 'menu']);

    const handlelogin = () => {
        history.push('/')
    };

    return (

            <header className={classes.appBar}>
                <img className="logo" src={Images.logo} alt="logo" onClick={(event) => handlelogin()} />
                <LanguageComponent/>
            </header>
    );
};


// redux providing state takeover
const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuOfflineComponent);





