import React, {useEffect} from "react"
import {useTranslation} from "react-i18next";
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {connect} from "react-redux";
import '@contentful/forma-36-react-components/dist/styles.css';
import {getMailInRequest} from "../../actions/mailbox";

function getModalStyle() {
    const top = 5;

    return {
        top: `${top}%`,
        margin:'auto',
        overflow: 'auto',
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    popup: {
        position: 'absolute',
        //width: '50%',
        //height : '50%',
        //backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        //boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        width: '820px',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    marginLeft:{
        marginLeft : '10px'
    }
}));

const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: [],
};

const ModalViewMailComponent = (props) => {

    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [t, i18n] = useTranslation('common');


    const [open, setOpen] = React.useState(true);
    const [title, setTitle] = React.useState("" );
    const [content, setContent] = React.useState("");
    const [annexes, setAnnexes] = React.useState([]);
    const [allAnnexes, setAllAnnexes] = React.useState([]);

    const handleCloseModal = () => {
        setTitle("");
        setContent("");
        props.close();
    };

    useEffect(() => {
        props.attemptGetMailIn(props.mid,props.mainuser);
    },[props.mid]);

    useEffect(() => {
        if(props.mail !== null){
            setTitle(props.mail.DecodedSubject);
            setContent(props.mail.HtmlContent);
            setAnnexes(props.mail.AllAttachsInfo);
            setAllAnnexes(props.mail.AllAttach);
        }
    },[props.mail]);

    const downloadEmployeeData = (annexe) => {

        let response = allAnnexes[annexe.name];
        var a = document.createElement("a"); //Create <a>
        a.href = response; //Image Base64 Goes here
        a.download = annexe.name; //File name Here
        a.click(); //Downloaded file
    }

    return (
        <Modal
            keepMounted={false}
            open={props.open}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div key={props.mid} style={modalStyle} className={classes.popup}>
                <div className = "scrollbar-hidden popup_wrapper-email" >
                    <div className="popup_wrapper_content">
                        <div className="sous-header_modal_content">
                            <div>
                                <span className="titres">
                                    <span dangerouslySetInnerHTML={ { __html: title } }/>
                                     <br/>
                                </span>
                            </div>
                            <div onClick={handleCloseModal}>
                                    <span id="icon-close" className="material-icons md-50 lightgrey">
                                    close
                                    </span>
                            </div>

                        </div>
                        <div className="sous-header_content lightgrey">
                            <div className="line flex_style">
                            </div>
                        </div>
                    </div>
                    <div className="popup_wrapper_content">
                        <div dangerouslySetInnerHTML={ { __html: content } }/>
                    </div>
                    { annexes.map((annexe, index) => {
                            return (
                                <div key={index} className="popup_wrapper_content">
                                    <span>{annexe.name}</span>
                                    <a href={`data:application/octet-stream;base64,${allAnnexes[annexe.name]}`} download={annexe.name}>
                                    <span id="icon-file_copy" className="material-icons md-25" >
                                        get_app
                                    </span>
                                    </a>
                                </div>
                            )
                    })
                    }
                </div>
            </div>
        </Modal>
    )
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        mainuser : state.user.profil.hash,
        mid : props.mid,
        mail : state.mailbox.mail,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        attemptGetMailIn: (mid,userhash) => dispatch(getMailInRequest(mid,userhash)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalViewMailComponent)
