import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {fieldTypes} from "../../utils/Compatibility";
import {camelCase} from "lodash";
import {useTranslation} from "react-i18next";
import {Close, Search} from "@material-ui/icons";
import Images from "../../assets/themes/Images";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {sortValue} from "../../utils/utils";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%'
    },
    input_add_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    margin_left_5:{
        marginLeft : '5px',
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F1F1F1',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    fieldImage: {
        height: 10,
        width: 10
    },
    fieldIcon : {
        width: 20,
        height: 20,
        backgroundColor: '#57bbb8',
        borderRadius: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    display: {
        "& .MuiSelect-select": {
            display : 'flex'
        }
    }
}));


const FilterSelectComponent = (props) => {

    const { t, i18n } = useTranslation(['common', 'models', 'global', 'plugnote']);

    const options = [
        {
            id: 1,
            keyName: "from",
            label: t("plugnote.plug-search-date-from-option-label"),
            defaultValue: true,
            visible : true
        },
        {
            id: 2,
            keyName: "equal",
            label: t("plugnote.plug-search-date-equal-option-label"),
            defaultValue: false,
            visible : true
        },
        {
            id: 3,
            keyName: "to",
            label: t("plugnote.plug-search-date-until-option-label"),
            defaultValue: false,
            visible : true
        }
    ];

    const classes = useStyles();

    const [fieldObject, setFieldObject] = useState(null);
    const [selected, setSelected] = useState(null);
    const [disabled, setDisabled] = React.useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    const [selectedDate, setSelectedDate] = useState(null);
    const [text, setText] = useState("");
    const [textSimple, setTextSimple] = useState("");
    const [textArray, setTextArray] = useState([]);
    const [textKeywordArray, setTextKeywordArray] = useState([]);
    const [field, setField] = useState('');
    const [optionsFields, setOptionsFields] = useState([]);
    const [query, setQuery] = useState(null);
    const [option, setOption] = useState(0);
    const [newValue, setNewValue] = useState("");
    const [initComponent, setInitComponent] = React.useState(true);


    const handleChangeText = (value) => {
        setText(value);
        let payload = {
            field : selected,
            value,
            query
        };

        if(value.length > 0){
            props.attemptSetDataFilter({[`field${selected.key}`] : payload , simpleSearch : true}, selected.key);
        }else{
            props.attemptDelDataFilter(selected.key);
        }
    };

    const handleChangeSetMultipleList = (value) => {

        if(!textArray.includes(value)){
            let tmpArray = textArray;
            tmpArray.push(value);
            setTextArray(tmpArray);
            handleChangeMultipleList(tmpArray);
        }else{
            let tmp = textArray.filter(item => item !== value);
            setTextArray(tmp);
            handleChangeMultipleList(tmp);
        }
    };

    const handleChangeSetSingleList = (value) => {

        if(!textSimple.includes(value)){
            setTextSimple(value);
        }else{
            setTextSimple("");
        }

        handleChangeSingleList(value);

    };

    const handleChangeKeywordList = (value) => {

        if(selected){
            let payload = {
                field : selected,
                value : value,
                query
            };

            if(value.length > 0){
                props.attemptSetDataFilter({[`field${selected.key}`] : payload , simpleSearch : true}, selected.key);
            }else{
                props.attemptDelDataFilter(selected.key);
            }
        }
    };

    const handleChangeMultipleList = (value) => {

        if(selected){
            let payload = {
                field : selected,
                value : value,
                query
            };

            if(value.length > 0){
                props.attemptSetDataFilter({[`field${selected.key}`] : payload , simpleSearch : true}, selected.key);
            }else{
                props.attemptDelDataFilter(selected.key);
            }
        }
    };

    const handleChangeSingleList = (value) => {

        if(selected){
            let payload = {
                field : selected,
                value : [value],
                query
            };

            if(value.length > 0){
                props.attemptSetDataFilter({[`field${selected.key}`] : payload , simpleSearch : true}, selected.key);
            }else{
                props.attemptDelDataFilter(selected.key);
            }
        }
    };

    const handleChangeDate = (date, value) => {
        setSelectedDate(date);
        setText(value);
        if(query === null) setQuery('from');
        let payload = {
            field : selected,
            value,
            query : query !== null ? query : 'from'
        };

        if(value){
            if(value.length > 0){
                props.attemptSetDataFilter({[`field${selected.key}`] : payload , simpleSearch : true}, selected.key);
            }else{
                props.attemptDelDataFilter(selected.key);
            }
        }else{
            props.attemptDelDataFilter(selected.key)
        }
    };

    const handleChangeNumber = (value) => {
        setText(value);
        if(query === null) setQuery('from');

        let payload = {
            field : selected,
            value : value,
            query : query !== null ? query : 'from'
        };

        if(value.length > 0){
            props.attemptSetDataFilter({[`field${selected.key}`] : payload , simpleSearch : true}, selected.key);
        }else{
            props.attemptDelDataFilter(selected.key);
        }

    };

    const handleAddSingleValue = (value) => {
        setNewValue('');
        handleChangeSingleList(value);
    }

    const handleAddMultipleValue = (value) => {

        if(!textArray.includes(value)){
            let tmpArray = textArray;
            tmpArray.push(value);
            setTextArray(tmpArray);
            setOptionsFields(oldArray => [...oldArray, value]);
            handleChangeMultipleList(tmpArray);
        }else{
            let tmp = textArray.filter(item => item !== value);
            setOptionsFields(optionsFields.filter(item => item !== value));
            setTextArray(tmp);
            handleChangeMultipleList(tmp);
        }

        setNewValue('');
    };

    const handleAddValue = (value) => {
        if(!textKeywordArray.includes(value)){
            let tmpArray = textKeywordArray;
            tmpArray.push(value);
            setTextKeywordArray(tmpArray);
            handleChangeKeywordList(tmpArray);
        }
        setNewValue('');
    };

    const handleRemoveValue = (value) => {
        if(textKeywordArray.includes(value)){
            let tmpArray = textKeywordArray.filter(item => item !== value);
            setTextKeywordArray(tmpArray);
            handleChangeKeywordList(tmpArray);
        }
    };

    const handleResetSearch = () => {
        props.attemptDelDataFilter(selected.key);
        setText("");
    };

    const handleResetNumberSearch = () => {
        props.attemptDelDataFilter(selected.key);
        setText("");
    };

    const handleChangeChooseFieldOptions = (value) => {
        setOption(value);
        setQuery(options[value].keyName);
    };

    const handleChangeChooseFieldSearch = (id, index) => {
        let value = props.fields.find((field, indexField) => field.id === id);

        if(typeof value !== "undefined"){
            setFieldObject({field : value, value : null, query : null});
        }
    };

    const handleDelFiltersLine = (index) => {


        if(selected !== null){
            let field = selected;
            let fieldFormat = selected.format;

            // Vérifier les préselections
            if (typeof field.options.presel !== 'undefined') {

                if(field.options.multiselect && !field.options.editable){
                    fieldFormat = "multi-select";
                }else if(!field.options.multiselect && !field.options.editable){
                    fieldFormat = "single-select";

                }else if(field.options.multiselect && field.options.editable){
                    fieldFormat = "free-multi-select";

                }else if(!field.options.multiselect && field.options.editable){
                    fieldFormat = "free-single-select";
                }
            }

            switch (fieldFormat) {
                case fieldTypes.QRCODE:
                case fieldTypes.BIRTHDAY:
                case fieldTypes.TEXT:
                case fieldTypes.TEXTAREA:
                case fieldTypes.PHONE:
                case fieldTypes.MAIL:
                case fieldTypes.URL:
                case fieldTypes.LOCALIS:
                    props.attemptDelDataFilter(selected.key);
                    setText("");
                    break;

                case fieldTypes.NUMERIC:
                    props.attemptDelDataFilter(selected.key);
                    setText("");
                    break;

                case fieldTypes.FREE_MULTI_SELECT:
                case fieldTypes.MULTI_SELECT:
                    props.attemptDelDataFilter(selected.key);
                    setTextArray([]);
                    break;

                case fieldTypes.SINGLE_SELECT:
                case fieldTypes.FREE_SINGLE_SELECT:
                    props.attemptDelDataFilter(selected.key);
                    setTextSimple("");
                    break;

                case fieldTypes.LIST:
                    props.attemptDelDataFilter(selected.key);
                    setTextKeywordArray("");
                    break;

                case fieldTypes.NOW:
                case fieldTypes.AGENDA:
                case fieldTypes.DATE:
                    props.attemptDelDataFilter(selected.key);
                    setText("");
                    break;
            }
        }

        props.handleDelFiltersLine(index);

    };

    const renderSpecialField = (field) => {

        let template;

        let fieldFormat = field.format;

        // Vérifier les préselections
        if (typeof field.options.presel !== 'undefined' && fieldFormat === "select") {

            if(field.options.multiselect && !field.options.editable){
                fieldFormat = "multi-select";
            }else if(!field.options.multiselect && !field.options.editable){
                fieldFormat = "single-select";

            }else if(field.options.multiselect && field.options.editable){
                fieldFormat = "free-multi-select";

            }else if(!field.options.multiselect && field.options.editable){
                fieldFormat = "free-single-select";
            }
        }

        if (typeof field.options.presel !== 'undefined' && fieldFormat === "table") {

            if(field.options.multiselect && !field.options.editable){
                fieldFormat = "multi-table";
            }else if(!field.options.multiselect && !field.options.editable){
                fieldFormat = "single-table";

            }else if(field.options.multiselect && field.options.editable){
                fieldFormat = "free-multi-table";

            }else if(!field.options.multiselect && field.options.editable){
                fieldFormat = "free-single-table";
            }
        }

        switch (fieldFormat) {
            case fieldTypes.QRCODE:
            case fieldTypes.BIRTHDAY:
            case fieldTypes.TEXT:
            case fieldTypes.TEXTAREA:
            case fieldTypes.PHONE:
            case fieldTypes.MAIL:
            case fieldTypes.URL:
            case fieldTypes.LOCALIS:

                template = (
                    <div>
                        <Input
                            id={`standard-full-width-${field.id}`}
                            label=""
                            //style={{ margin: 8 }}
                            //placeholder={props.placeholder}
                            fullWidth={true}
                            margin="none"
                            disableUnderline={true}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>}
                            endAdornment={ text.length > 0 &&
                            <InputAdornment position="end" onClick={handleResetSearch} style={{cursor : 'pointer'}}>
                                <Close />
                            </InputAdornment>
                            }
                            inputlabelprops={{
                                className: classes.input,
                            }}
                            className={classes.textField}
                            onChange={(event) => handleChangeText(event.target.value)}
                            value={text}
                        />
                    </div>
                );
                break;

            case fieldTypes.NUMERIC:

                template = (
                    <div className="flex_display_direction_column">
                        <Select
                            key={props.index}
                            fullWidth
                            labelId={`option-label-search-plug-field-value-${field.id}`}
                            id={`option-id-search-plug-field-value-${field.id}`}
                            value={option ? option : 0}
                            onChange={(event) => handleChangeChooseFieldOptions(event.target.value)}
                            className={classes.display}
                        >
                            { options.map((option, index) => {
                                return(
                                    <MenuItem value={index} key={index}>
                                        <span>{option.label}</span>
                                    </MenuItem>
                                )
                            })}
                        </Select>
                        <Input
                            id={`standard-full-width-${field.id}`}
                            type="number"
                            onWheel={event => event.target.blur()}
                            label=""
                            //style={{ margin: 8 }}
                            //placeholder={props.placeholder}
                            fullWidth={true}
                            margin="none"
                            disableUnderline={true}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>}
                            endAdornment={ text.length > 0 &&
                            <InputAdornment position="end" onClick={handleResetNumberSearch} style={{cursor : 'pointer'}}>
                                <Close />
                            </InputAdornment>
                            }
                            inputlabelprops={{
                                className: classes.input,
                            }}
                            className={classes.textField}
                            onChange={(event) => handleChangeNumber(event.target.value)}
                            value={text}
                        />
                    </div>
                );
                break;
            case fieldTypes.MULTI_SELECT:
            case fieldTypes.FREE_MULTI_SELECT:
            case fieldTypes.MULTI_TABLE:
            case fieldTypes.FREE_MULTI_TABLE:
                template = (
                    <div className="flex_display_direction_column">
                        {
                            field.options.editable &&
                            <div className={classes.input_add_item}>
                                <Input
                                    disabled={disabled}
                                    autoComplete='off'
                                    id="standard-full-width"
                                    //label="Label"
                                    style={{ margin: 8 }}
                                    placeholder={t('common:multiple.add-item')}
                                    //helpertext="Full width!"
                                    fullWidth={true}
                                    margin="none"
                                    inputlabelprops={{
                                        shrink: true,
                                    }}
                                    disableUnderline={true}
                                    endAdornment={ !disabled &&
                                    <InputAdornment position="end">
                                        <div onClick={handleAddMultipleValue.bind(this,newValue)}>
                                    <span className="material-icons">
                                        add
                                    </span>
                                        </div>
                                    </InputAdornment>}
                                    className={classes.fieldRight}
                                    value={newValue}
                                    onChange={(event) =>  setNewValue(event.target.value)}

                                />
                            </div>
                        }
                        {
                            optionsFields.map((value,indexation) => {
                                return (
                                    <div key={indexation}>
                                        <input
                                            type="checkbox"
                                            id={value + '-' + indexation}
                                            //name={props.value}
                                            checked={textArray.includes(value)}
                                            onChange={(event) => {
                                                handleChangeSetMultipleList(value)
                                            }}
                                        /><label className={classes.margin_left_5} htmlFor={value + '-' + indexation}>{value}</label>
                                    </div>
                                )
                            })
                        }
                    </div>
                );
                break;

            case fieldTypes.SINGLE_SELECT:
            case fieldTypes.FREE_SINGLE_SELECT:
            case fieldTypes.SINGLE_TABLE:
            case fieldTypes.FREE_SINGLE_TABLE:
                template = (
                    <div className="flex_display_direction_column">
                        {
                            field.options.editable &&
                            <div className={classes.input_add_item}>
                                <Input
                                    disabled={disabled}
                                    autoComplete='off'
                                    id="standard-full-width"
                                    label="Label"
                                    style={{ margin: 8 }}
                                    placeholder={t('common:multiple.add-item')}
                                    helpertext="Full width!"
                                    fullWidth={true}
                                    margin="none"
                                    disableUnderline={true}
                                    inputlabelprops={{
                                        shrink: true,
                                    }}
                                    endAdornment={ !disabled &&
                                    <InputAdornment position="end">
                                        <div onClick={handleAddSingleValue.bind(this,newValue)}>
                                    <span className="material-icons">
                                        add
                                    </span>
                                        </div>
                                    </InputAdornment>}
                                    className={classes.fieldRight}
                                    value={newValue}
                                    onChange={(event) => setNewValue(event.target.value)}

                                />
                            </div>
                        }
                        {
                            optionsFields.map((value,indexation) => {
                                return (
                                    <div key={indexation}>
                                        <input
                                            disabled={disabled}
                                            type="checkbox"
                                            id={value + '-' + indexation}
                                            checked={textSimple === value}
                                            onChange={(event) => {
                                                handleChangeSetSingleList(value)
                                            }}
                                        /><label className={classes.margin_left_5} htmlFor={value + '-' + indexation}>{value}</label>
                                    </div>
                                )
                            })
                        }
                    </div>
                );
                break;

            case fieldTypes.LIST:

                let valueList = sortValue(textKeywordArray);

                template = (
                    <div className="flex_display_direction_column">
                        <Input
                            disabled={disabled}
                            autoComplete='off'
                            id="standard-full-width"
                            //label="Label"
                            style={{ margin: 8 }}
                            placeholder={t('common:multiple.add-item')}
                            //helpertext="Full width!"
                            fullWidth={true}
                            margin="none"
                            inputlabelprops={{
                                shrink: true,
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <div onClick={handleAddValue.bind(this,newValue)}>
                                    <span className="material-icons">
                                        add
                                    </span>
                                    </div>
                                </InputAdornment>}
                            className={classes.fieldRight}
                            value={newValue}
                            disableUnderline={true}
                            onChange={(event) => setNewValue(event.target.value)}

                        />
                        {
                            valueList.map((value,indexation) => {
                                return (
                                    <div key={indexation} className={classes.input_view_item}>
                                        <Input
                                            autoComplete='off'
                                            disabled={disabled}
                                            readOnly={true}
                                            id="standard-full-width"
                                            //label="Label"
                                            style={{ margin: 8 }}
                                            placeholder={t('common:multiple.add-item')}
                                            //helpertext="Full width!"
                                            fullWidth={true}
                                            margin="none"
                                            inputlabelprops={{
                                                shrink: true,
                                            }}
                                            endAdornment={
                                                !disabled &&
                                                <InputAdornment position="end">
                                                    <div onClick={handleRemoveValue.bind(this,value)}>
                                                        <span className="material-icons">
                                                            delete
                                                        </span>
                                                    </div>
                                                </InputAdornment>
                                            }
                                            className={classes.fieldRight}
                                            value={value}
                                            disableUnderline={true}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                );
                break;

            case fieldTypes.NOW:
            case fieldTypes.AGENDA:
            case fieldTypes.DATE:

                template = (
                    <div className="flex_display_direction_column">
                        <Select
                            key={props.index}
                            fullWidth
                            labelId={`option-label-search-plug-field-value-${field.id}`}
                            id={`option-id-search-plug-field-value-${field.id}`}
                            value={option ? option : 0}
                            onChange={(event) => handleChangeChooseFieldOptions(event.target.value)}
                            className={classes.display}
                        >
                            { options.map((option, index) => {
                                return(
                                    <MenuItem value={index} key={index}>
                                        <span>{option.label}</span>
                                    </MenuItem>
                                )
                            })}
                        </Select>
                        <KeyboardDatePicker
                            disabled={disabled}
                            className={classes.fullWidth}
                            variant="inline"
                            format="yyyy-MM-dd"
                            margin="normal"
                            fullWidth={true}
                            id={`date-picker-inline-${field.id}`}
                            //label={props.field.title}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            value={selectedDate}
                            //onChange={handleDateChange}
                            onChange={(date, value) => {
                                handleChangeDate(date, value);
                            }}
                        />
                    </div>
                );
                break;

            default :
                template =
                    (
                        <div><span>{t('global.coming-soon')}</span></div>
                    );
                break;
        }

        return template;
    };


    useEffect(() => {
        if(props.filter !== undefined && props.filter){
            setFieldObject(props.filter);
        }
    }, [props.filter]);

    useEffect(() => {
        if(fieldObject){

            if(fieldObject.field){
                let fieldFormat = fieldObject.field.format;
                // Vérifier les préselections
                if (typeof fieldObject.field.options.presel !== 'undefined' && fieldFormat === "select") {

                    if(fieldObject.field.options.multiselect && !fieldObject.field.options.editable){
                        fieldFormat = "multi-select";
                    }else if(!fieldObject.field.options.multiselect && !fieldObject.field.options.editable){
                        fieldFormat = "single-select";

                    }else if(fieldObject.field.options.multiselect && fieldObject.field.options.editable){
                        fieldFormat = "free-multi-select";

                    }else if(!fieldObject.field.options.multiselect && fieldObject.field.options.editable){
                        fieldFormat = "free-single-select";
                    }

                }

                if (typeof fieldObject.field.options.presel !== 'undefined' && fieldFormat === "table") {

                    if(fieldObject.field.options.multiselect && !fieldObject.field.options.editable){
                        fieldFormat = "multi-table";
                    }else if(!fieldObject.field.options.multiselect && !fieldObject.field.options.editable){
                        fieldFormat = "single-table";

                    }else if(fieldObject.field.options.multiselect && fieldObject.field.options.editable){
                        fieldFormat = "free-multi-table";

                    }else if(!fieldObject.field.options.multiselect && fieldObject.field.options.editable){
                        fieldFormat = "free-single-table";
                    }

                }

                switch (fieldFormat) {
                    case fieldTypes.QRCODE:
                    case fieldTypes.BIRTHDAY:
                    case fieldTypes.TEXT:
                    case fieldTypes.TEXTAREA:
                    case fieldTypes.PHONE:
                    case fieldTypes.MAIL:
                    case fieldTypes.URL:
                    case fieldTypes.LOCALIS:
                    case fieldTypes.NUMERIC:
                        setField(fieldObject.field.id);
                        setQuery(fieldObject.query);
                        setOption(Object.keys(options).find(key => options[key].keyName === fieldObject.query));
                        setOptionsFields([]);
                        if(fieldObject.value){
                            setText(fieldObject.value);
                        }
                        break;

                    case fieldTypes.FREE_MULTI_SELECT:
                        setField(fieldObject.field.id);
                        setQuery(fieldObject.query);
                        setOption(Object.keys(options).find(key => options[key].keyName === fieldObject.query));
                        if(!!fieldObject.field.options && !!fieldObject.field.options.presel){
                            let presel = fieldObject.field.options.presel.split(';');
                            if(fieldObject.value && !fieldObject.field.options.presel.includes(fieldObject.value[0])){
                                presel.push(fieldObject.value[0]);
                            }
                            setOptionsFields(presel);
                        }else{
                            setOptionsFields([]);
                        }
                        if(fieldObject.value){
                            setTextArray(fieldObject.value);
                        }
                        break;
                    case fieldTypes.MULTI_SELECT:
                        setField(fieldObject.field.id);
                        setQuery(fieldObject.query);
                        setOption(Object.keys(options).find(key => options[key].keyName === fieldObject.query));
                        setOptionsFields(!!fieldObject.field.options && !!fieldObject.field.options.presel ? fieldObject.field.options.presel.split(';') : []);
                        if(fieldObject.value){
                            setTextArray(fieldObject.value);
                        }
                        break;

                    case fieldTypes.SINGLE_SELECT:
                        setField(fieldObject.field.id);
                        setQuery(fieldObject.query);
                        setOption(Object.keys(options).find(key => options[key].keyName === fieldObject.query));
                        setOptionsFields(!!fieldObject.field.options && !!fieldObject.field.options.presel ? fieldObject.field.options.presel.split(';') : []);
                        if(fieldObject.value){
                            setTextSimple(fieldObject.value[0]);
                        }
                        break;
                    case fieldTypes.FREE_SINGLE_SELECT:
                        setField(fieldObject.field.id);
                        setQuery(fieldObject.query);
                        setOption(Object.keys(options).find(key => options[key].keyName === fieldObject.query));
                        if(!!fieldObject.field.options && !!fieldObject.field.options.presel){
                            let presel = fieldObject.field.options.presel.split(';');
                            if(fieldObject.value &&!fieldObject.field.options.presel.includes(fieldObject.value[0])){
                                presel.push(fieldObject.value[0]);
                            }
                            setOptionsFields(presel);
                        }else{
                            setOptionsFields([]);
                        }
                        if(fieldObject.value){
                            setTextSimple(fieldObject.value[0]);
                        }
                        break;

                    case fieldTypes.LIST:
                        setField(fieldObject.field.id);
                        setQuery(fieldObject.query);
                        setOption(Object.keys(options).find(key => options[key].keyName === fieldObject.query));
                        setOptionsFields([]);
                        if(fieldObject.value){
                            setTextKeywordArray(fieldObject.value);
                        }
                        break;

                    case fieldTypes.NOW:
                    case fieldTypes.AGENDA:
                    case fieldTypes.DATE:
                        setField(fieldObject.field.id);
                        setQuery(fieldObject.query);
                        setOption(Object.keys(options).find(key => options[key].keyName === fieldObject.query));
                        setOptionsFields([]);
                        if(fieldObject.value){
                            setText(fieldObject.value);
                        }
                        break;
                }

                setSelected(fieldObject.field);
            }
        }
    }, [fieldObject]);

    return (
        <div className="left-menu_contents_wrapper inputWrapper" key={props.index}>
            <div>
                <div>
                    <label className="references" htmlFor="prénom">{t('common:pluglist.filter-plugnote-fields')}</label>
                    <span className="material-icons md-25 grey position-absolute-right" onClick={(event) => handleDelFiltersLine(props.index)}>
                                    close
                                </span>
                    <div className="select champ fullWidth">

                        <Select
                            key={props.index}
                            fullWidth
                            labelId={`label-search-plug-field-value-${props.index}`}
                            id={`id-search-plug-field-value-${props.index}`}
                            value={field.toString() ? field.toString() : ''}
                            onChange={(event) => handleChangeChooseFieldSearch(event.target.value)}
                            className={classes.display}
                        >
                            {
                                props.fields.map((field, fieldIndex) => {
                                    return (
                                        <MenuItem value={field.id} key={fieldIndex}>
                                            <div className={classes.fieldIcon}>
                                                <img
                                                    className={classes.fieldImage}
                                                    src ={Images[camelCase(field.format.toLowerCase())+"Icon"]}
                                                    alt={t('fields.format-choice-'+field.format.toLowerCase())}
                                                />
                                            </div>
                                            <span className="l1">{field.label}</span>
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </div>

                </div>
            </div>
            {
                selected !== null &&
                <div>
                    <div className="flex_display_direction_column">
                        <label className="references" htmlFor="prénom">{t('common:pluglist.filter-plugnote-fields-keywords')}</label>
                        {
                            renderSpecialField(selected)
                        }
                    </div>
                </div>
            }
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
    }
};

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterSelectComponent);





