import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import _ from "lodash";
import {postModelFromTemplateGroupRequest} from "../../actions/model";
import {connect} from "react-redux";
import {getCurrentEnvironnement} from "../../utils/Common";
import TemplateCategoryMultipleSelectComponent from "../specials/template/TemplateCategoryMultipleSelectComponent";

function getModalStyle() {
    const top = 5;

    return {
        top: `${top}%`,
        margin:'auto',
        overflow: 'auto',
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    popup: {
        position: 'absolute',
        //width: '50%',
        //height : '50%',
        //backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        //boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        width: '820px',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    }
}));

const ModalModelTemplateComponent = (props) => {

    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [t, i18n] = useTranslation('common');

    const [currentLanguage, setCurrentLanguage] = useState(i18n.language.toUpperCase());
    const [currentLanguageLower, setCurrentLanguageLower] = useState(i18n.language.toLowerCase());
    const [listModels, setListModels] = useState([]);
    const [listGroup, setListGroup] = useState([]);
    const [listCategorie, setListCategorie] = useState([]);
    const [code, setCode] = useState(props.code);

    const [newTemplateModel, setNewTemplateModel] = useState([]);

    const environment = getCurrentEnvironnement();

    useEffect(() => {

        if(props.open && props.model){
            let currentListModels = props.list_models.filter((item, index) => {
                if(item.belangues_shortName === currentLanguage){
                    return item;
                }
            });
            currentListModels.filter(Boolean);
            setListModels(currentListModels);
            setCode(props.code);
        }else if(props.open && props.group){
            let currentGroup = props.list_group.filter((item, index) => {
                if(item.belangues_shortName === currentLanguage){
                    return item;
                }
            });
            currentGroup.filter(Boolean);
            setListGroup(currentGroup);
            setCode(props.code);
        }else if(props.open && props.categorie){
            setListCategorie(props.list_categorie);
            setCode(props.code);
        }

    }, [props.open]);

    const handleListItem = (format,label,mandatory,editable,multiselect,presel) => {
        if(newTemplateModel.length < 15){

            let options = {};
            // Récupération du dernier item, avec le tri des valeurs en fonction de la clé
            let lastField = _.orderBy(Object.values(newTemplateModel), 'key', 'desc')[0];

            // Si c'est le premier champ on initialise à 0
            if (!lastField) lastField = { key: 0, id: 0 };

            if(format === 'select'){
                setNewTemplateModel(oldArray => [...oldArray, {
                    dragid : 'item-' + lastField.id,
                    key: lastField.key + 1,
                    id: lastField.id + 1,
                    label: label,
                    format: format,
                    options: {
                        mandatory: mandatory,
                        editable : editable,
                        multiselect : multiselect,
                        presel : presel.split(';'),
                    },
                    newField : true,
                }]);
            }
            else{
                setNewTemplateModel(oldArray => [...oldArray, {
                    dragid : 'item-' + lastField.id,
                    key: lastField.key + 1,
                    id: lastField.id + 1,
                    label: label,
                    format: format,
                    options: {
                        mandatory: mandatory,
                        ...options || {}
                    },
                    newField : true,
                }]);
            }
        }
    }

    const handleImportThisForm = (fields) => {

        let i;
        for (i = 1; i < 16; i++) {
            if(fields['bdfields_field' + i +'format'] && fields['bdfields_field' + i +'position'] === i){
                let format = fields['bdfields_field' + i +'format'];
                let label = fields['bdfields_field' + i +'label' + currentLanguageLower];
                let mandatory = fields['bdfields_field' + i +'mandatory'];
                let editable = fields['bdfields_field' + i +'FormatOptionEditable'];
                let multiselect = fields['bdfields_field' + i +'FormatOptionMultiselect'];
                let presel = fields['bdfields_field' + i +'content' + currentLanguageLower];

                handleListItem(format,label,mandatory,editable,multiselect,presel);
            }

        }

        if(newTemplateModel.length > 0){
            props.setNewModel(newTemplateModel);
            props.close();
        }

    }

    const handlePressCreate = (model) => {

        let regexp = new RegExp('^[A-Z]{2}[0-9]{2}$');
        let regexp2 = new RegExp('^[A-Z]{3}[0-9]{1}$');
        let  test = regexp.test(code);
        let  test2 = regexp2.test(code);
        let mainModels = props.mainModels;

        if (test && !mainModels.some(e => e.clef === model.bcmodels_modelKey)) {
            let uniquemodel = model;

            if(uniquemodel){
                let fields = createField(uniquemodel.bcmodels_bdfields);

                if(fields !== true){
                    let model = {
                        model : {
                            name: uniquemodel.bcmodelslgs_titreModel,
                            clef: uniquemodel.bcmodels_modelKey,
                            infos: {
                                color: "#58C3C1"
                            },
                            fields: fields,
                            is_structure_editable : uniquemodel.bcmodels_structureEditable
                        },
                        code : code,
                    }
                    props.attemptCreateModelFromGroupTemplate(model, null, true, environment.userhash);
                }
            }else{
                //alert(I18n.t('group-model-template-message'))
            }
        }
        else if(test2 && !mainModels.some(e => e.clef === model.bcmodels_modelKey)){
            let uniquemodel = model;

            if(uniquemodel){
                let fields = createField(uniquemodel.bcmodels_bdfields);

                if(fields !== true){
                    let model = {
                        model : {
                            name: uniquemodel.bcmodelslgs_titreModel,
                            clef: uniquemodel.bcmodels_modelKey,
                            infos: {
                                color: "#58C3C1"
                            },
                            fields: fields,
                            is_structure_editable : uniquemodel.bcmodels_structureEditable
                        },
                        code : code,
                    }
                    props.attemptCreateModelFromGroupTemplate(model, null, true, environment.userhash);
                }
            }else{
                //alert(I18n.t('group-model-template-message'))
            }
        }
        else {

        }
    }

    const handlePressMultipleCategoryCreate = () => {
        let regexp = new RegExp('^[A-Z]{2}[0-9]{2}$');
        let  test = regexp.test(code);

        if (!test) {
            let multipleModels = [];
            let mainModels = props.mainModels;
            listCategorie.forEach(function(element){
                if(typeof element.bbgroups_models !== 'undefined'){
                    element.bbgroups_models.forEach(function(elm){
                        if(elm.bcmodels_isChecked && elm.belangues_shortName === currentLanguage && !mainModels.some(e => e.clef === elm.bcmodels_modelKey)){
                            multipleModels.push(elm);
                        }
                    })
                }
            });

            if(multipleModels.length > 0){
                let nonUniqueModel = [];

                multipleModels.forEach((uniquemodel) => {
                    let fields = createField(uniquemodel.bcmodels_bdfields);
                    if(fields !== true){
                        let model = {
                            model : {
                                name: uniquemodel.bcmodelslgs_titreModel,
                                clef: uniquemodel.bcmodels_modelKey,
                                infos: {
                                    color: "#58C3C1"
                                },
                                fields: fields,
                                is_structure_editable : uniquemodel.bcmodels_structureEditable
                            }
                        };

                        nonUniqueModel.push(model);
                    }
                });

                setTimeout(() => {
                    let payload = {
                        code : code
                    };
                    nonUniqueModel.push(payload);
                    props.attemptCreateModelFromGroupTemplate(null, nonUniqueModel, false, environment.userhash);
                },1000);
            }
            else{
                //alert(I18n.t('group-model-template-message'))
            }

        }
        else {

        }
    }

    const handlePressMultipleGroupCreate = () => {
        let regexp = new RegExp('^[A-Z]{2}[0-9]{2}$');
        let  test = regexp.test(code);

        if (!test) {
            let multipleModels = [];
            let mainModels = props.mainModels;
            listGroup.forEach(function(element){
                if(typeof element.bbgroups_models !== 'undefined'){
                    element.bbgroups_models.forEach(function(elm){
                        if(elm.bcmodels_isChecked && elm.belangues_shortName === currentLanguage && !mainModels.some(e => e.clef === elm.bcmodels_modelKey)){
                            multipleModels.push(elm);
                        }
                    })
                }
            });

            if(multipleModels.length > 0){
                let nonUniqueModel = [];

                multipleModels.forEach((uniquemodel) => {
                    let fields = createField(uniquemodel.bcmodels_bdfields);
                    if(fields !== true){
                        let model = {
                            model : {
                                name: uniquemodel.bcmodelslgs_titreModel,
                                clef: uniquemodel.bcmodels_modelKey,
                                infos: {
                                    color: "#58C3C1"
                                },
                                fields: fields,
                                is_structure_editable : uniquemodel.bcmodels_structureEditable
                            }
                        };

                        nonUniqueModel.push(model);
                    }
                });

                setTimeout(() => {
                    let payload = {
                        code : code
                    };
                    nonUniqueModel.push(payload);
                    props.attemptCreateModelFromGroupTemplate(null, nonUniqueModel, false, environment.userhash);
                },1000);
            }
            else{
                //alert(I18n.t('group-model-template-message'))
            }

        }
        else {

        }
    }


    const createField = (bcmodels_bdfields) => {
        const language = currentLanguageLower;
        let bdfields = [bcmodels_bdfields];
        let fields = {};
        let error = false;

        bdfields.forEach(function(field){
            for (let index = 0; index < 15; index++) {
                if(typeof field['bdfields_field' + (index + 1) + 'format'] !== "undefined"){
                    if(!error){
                        error = field['bdfields_field' + (index + 1) + 'format'] === "";
                    }
                    let fieldName = 'field' + field['bdfields_field' + (index + 1) + 'position'];
                    fields [ fieldName ]  = {
                        key: field['bdfields_field' + (index + 1) + 'position'],
                        label: field['bdfields_field' + (index + 1) + 'label' + language],
                        format: field['bdfields_field' + (index + 1) + 'format'],
                        options: {
                            mandatory: !!field['bdfields_field' + (index + 1) + 'mandatory'],
                            multiselect: !!field['bdfields_field' + (index + 1) + 'FormatOptionMultiselect'],
                            editable: !!field['bdfields_field' + (index + 1) + 'FormatOptionEditable'],
                            presel: !!field['bdfields_field' + (index + 1) + 'content' + language] ? field['bdfields_field' + (index + 1) + 'content' + language] : ''
                        }
                    }
                }
            }
        });
        if(error){
            return error;
        }
        return fields
    }

    const handleChangeSelectionTemplateCategory = (indexCategorie, indexModel) => {
        let updateCategorie = listCategorie;
        updateCategorie[indexCategorie].bbgroups_models[indexModel].bcmodels_isChecked = !updateCategorie[indexCategorie].bbgroups_models[indexModel].bcmodels_isChecked;
        setListCategorie(updateCategorie);
    }

    const handleChangeSelectionTemplateGroup = (indexGroup, indexModel) => {
        let updateGroup = listGroup;
        updateGroup[indexGroup].bbgroups_models[indexModel].bcmodels_isChecked = !updateGroup[indexGroup].bbgroups_models[indexModel].bcmodels_isChecked;
        setListGroup(updateGroup);
    }

    const body = (
        <div style={modalStyle} className={classes.popup}>
            <div className = "scrollbar-hidden popup_wrapper flexSpacebetween" >
                <div className="popup_wrapper_content">
                    <div className="sous-header_modal_content">
                        <div>
                                    <span className="titres">
                                    Titre 1 <br/>
                                    </span>
                            <span className="grey">
                                    titre 2
                                    </span>
                        </div>
                        <div onClick={props.close}>
                                    <span id="icon-close" className="material-icons md-50 lightgrey">
                                    close
                                    </span>
                        </div>

                    </div>
                    <div className="sous-header_content lightgrey">
                        <div className="line flex_style">
                        </div>
                    </div>
                </div>
                <div className="popup_wrapper_content overflow_y">
                { props.model &&
                    listModels.map((item, index) => {
                        return(
                            <div key={index}>
                                <h6>{item.bcmodelslgs_titreModel}</h6>
                                <button onClick={handlePressCreate.bind(this,item)}>Créer ce formulaire</button>
                            </div>
                        )
                    })
                }
                { props.group &&
                    <div>
                        <h6>group list</h6>
                        {
                            listGroup.map((item, index) => {
                                return(
                                    <div>
                                        <div key={index}>
                                            <h6>{item.bbgroupslgs_titreGroup}</h6>
                                            <TemplateCategoryMultipleSelectComponent
                                                selection={item.bbgroups_models}
                                                handleChangeSelectionTemplate={handleChangeSelectionTemplateGroup}
                                                indexCategorie={index}
                                            />
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <button onClick={handlePressMultipleGroupCreate.bind(this)}>Créer ce formulaire</button>
                    </div>
                }
                { props.categorie &&
                    <div>
                        <h6>categorie list - ({listCategorie.length})</h6>
                        {
                            listCategorie.map((item, index) => {
                                return(
                                    <div key={index}>
                                            <h6>{item.bbgroupslgs_titreGroup}</h6>
                                            <TemplateCategoryMultipleSelectComponent
                                                selection={item.bbgroups_models}
                                                handleChangeSelectionTemplate={handleChangeSelectionTemplateCategory}
                                                indexCategorie={index}
                                            />
                                    </div>
                                )
                            })
                        }
                        <button onClick={handlePressMultipleCategoryCreate.bind(this)}>Créer ce formulaire</button>
                    </div>
                }
                </div>
            </div>
        </div>
    );

    return (
        <Modal
            keepMounted={false}
            open={props.open}
            onClose={props.close}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            {body}
        </Modal>
    )
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        mainModels : state.model.datas,
        code : props.code,
        model : props.models,
        categorie : props.categorie,
        group : props.group,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        attemptCreateModelFromGroupTemplate: (model, models, unique, userhash) => dispatch(postModelFromTemplateGroupRequest(model, models, unique, userhash)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalModelTemplateComponent)