import {Types} from '../actions/translate';

// create initial state for reducers
const INIT_STATE = {
    list: [],
    translations: [],
    fetching: false,
    error: null
};

// reducer function to transform state
export default function translate(state = INIT_STATE, action) {
    switch(action.type) {
        case Types.RESET_ALL_DATA_TRANSLATE : return INIT_STATE;
        case Types.GET_ALL_TRANSLATIONS_BY_MODEL_REQUEST: {
            return {
                ...state,
                list: [],
                fetching : true
            }
        }
        case Types.GET_ALL_TRANSLATIONS_BY_MODEL_SUCCESS: {
            return {
                translations : action.payload2,
                list: action.payload,
                fetching : false
            }
        }
        default: return state;
    }
};
