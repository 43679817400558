import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import { ScatterPlot } from "../../../node_modules/@material-ui/icons/index";
import TextField from "@material-ui/core/TextField";
import {Checkbox, withStyles} from "@material-ui/core";
import colors from "../../assets/themes/Colors";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        //width : '100%',
        alignItems: 'center',
        justifyContent: 'flex-start',
        fontSize: '25px',
        display: 'flex',
        '& .MuiSvgIcon-root': {
            color: `${colors.main}!important`
        }
    },
    input_add_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    fieldRight:{
        display: 'flex',
        alignItems: 'center',
        width : '50px',
    }
}));


const DarkerDisabledTextField = withStyles({
    root: {
        "& .MuiInputBase-root.Mui-disabled": {
            color: "rgba(0, 0, 0)" // (default alpha is 0.38)
        }
    }
})(TextField);

const FormatBooleanComponent = (props) => {

    const classes = useStyles();
    const [selected, setSelected] = useState(props.field.value === '1');
    const [disabled, setDisabled] = React.useState(typeof props.disabled === 'undefined' ? false: props.disabled);

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    return (
        <div className={classes.margin_5}>
            <Checkbox
                style={{ margin: 0, width: '20px', height: '20px', marginRight : '5px', accentColor: colors.selectedAlpha, color: 'white' }}
                disabled={disabled}
                type="checkbox"
                id={selected + '-' + props.index}
                name={props.value}
                checked={selected}
                value={selected === true ? '0' : '1'}
                onChange={(event) => {
                    setSelected(event.target.value === '1');
                    props.handleChangeBoolean(event.target.value,props.field,props.index);
                }}
            />

            {
                1 === 0 && (
                    disabled === true ?
                        <TextField
                            id="yes-no-value"
                            //label={field.title}
                            style={{ margin: 0, marginLeft: '5px' }}
                            type="text"
                            //placeholder={t('fields.format-choice-placeholder-'+field.visuelFormat.toLowerCase())}
                            helperText=""
                            fullWidth={false}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            className={classes.fieldRight}
                            value={selected ? 'YES' : 'NO'}
                            disabled={true}
                            InputProps={{ disableUnderline: true }}
                        />
                        :
                        <DarkerDisabledTextField
                            id="yes-no-value-dark"
                            //label={field.title}
                            style={{ margin: 0, marginLeft: '5px' }}
                            type="text"
                            //placeholder={t('fields.format-choice-placeholder-'+field.visuelFormat.toLowerCase())}
                            helperText=""
                            fullWidth={false}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            className={classes.fieldRight}
                            value={selected ? 'YES' : 'NO'}
                            disabled={true}
                            InputProps={{ disableUnderline: true }}
                        />)
            }
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatBooleanComponent);





