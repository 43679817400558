// actions types is object with the
// key: GET_DATA_REQUEST -> value: describes the action
export const Types = {
    RESET_ALL_DATA: 'reset_all_data',

    REDUX_GENERATE_SHARE_RESET: 'redux_generate_share_reset',


    GET_MODEL_SHARES_REQUEST: 'get_model_shares_request',
    GET_MODEL_SHARES_SUCCESS: 'get_model_shares_success',
    GET_MODEL_SHARES_FAILURE: 'get_model_shares_failure',
    GET_MODEL_SHARES_ERROR: 'get_model_shares_error',

    POST_MODEL_SHARES_REQUEST: 'post_model_shares_request',
    POST_MODEL_SHARES_SUCCESS: 'post_model_shares_success',
    POST_MODEL_SHARES_FAILURE: 'post_model_shares_failure',
    POST_MODEL_SHARES_ERROR: 'post_model_shares_error',

    PUT_MODEL_SHARES_REQUEST: 'put_model_shares_request',
    PUT_MODEL_SHARES_SUCCESS: 'put_model_shares_success',
    PUT_MODEL_SHARES_FAILURE: 'put_model_shares_failure',
    PUT_MODEL_SHARES_ERROR: 'put_model_shares_error',

    DEL_MODEL_SHARE_REQUEST: 'del_model_share_request',
    DEL_MODEL_SHARE_SUCCESS: 'del_model_share_success',
    DEL_MODEL_SHARE_FAILURE: 'del_model_share_failure',
    DEL_MODEL_SHARE_ERROR: 'del_model_share_error',

    DEL_ALL_INVITE_MODEL_SHARES_REQUEST: 'del_all_invite_model_shares_request',
    DEL_ALL_INVITE_MODEL_SHARES_SUCCESS: 'del_all_invite_model_shares_success',
    DEL_ALL_INVITE_MODEL_SHARES_FAILURE: 'del_all_invite_model_shares_failure',
    DEL_ALL_INVITE_MODEL_SHARES_ERROR: 'del_all_invite_model_shares_error',

};

/*
Token
 */
// function that returns an object literal
export const getModelSharesRequest = (modelhash, userhash, corporate,model) => ({
    type: Types.GET_MODEL_SHARES_REQUEST,
    modelhash: modelhash,
    userhash: userhash,
    corporate: corporate,
    model: model
});

// function that returns an object literal
export const getModelSharesSuccess = (data) => ({
    type: Types.GET_MODEL_SHARES_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getModelSharesFailure = () => ({
    type: Types.GET_MODEL_SHARES_FAILURE,
});

// function that returns an object literal
export const getModelSharesError = () => ({
    type: Types.GET_MODEL_SHARES_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const postModelSharesRequest = (payload, userhash,modelhash, corporate,model) => ({
    type: Types.POST_MODEL_SHARES_REQUEST,
    payload: payload,
    userhash: userhash,
    modelhash: modelhash,
    corporate: corporate,
    model: model
});

// function that returns an object literal
export const postModelSharesSuccess = (data) => ({
    type: Types.POST_MODEL_SHARES_SUCCESS,
    payload: {data}
});

// function that returns an object literal
export const postModelSharesFailure = () => ({
    type: Types.POST_MODEL_SHARES_FAILURE,
});

// function that returns an object literal
export const postModelSharesError = () => ({
    type: Types.POST_MODEL_SHARES_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const putModelSharesRequest = (payload, userhash,modelhash, corporate,model) => ({
    type: Types.PUT_MODEL_SHARES_REQUEST,
    payload: payload,
    userhash: userhash,
    modelhash: modelhash,
    corporate: corporate,
    model: model
});

// function that returns an object literal
export const putModelSharesSuccess = (data) => ({
    type: Types.PUT_MODEL_SHARES_SUCCESS,
    payload: {data}
});

// function that returns an object literal
export const putModelSharesFailure = () => ({
    type: Types.PUT_MODEL_SHARES_FAILURE,
});

// function that returns an object literal
export const putModelSharesError = () => ({
    type: Types.PUT_MODEL_SHARES_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const delAllInviteModelSharesRequest = (modelid, ownerid,modelhash, corporate,model) => ({
    type: Types.DEL_ALL_INVITE_MODEL_SHARES_REQUEST,
    modelid: modelid,
    ownerid: ownerid,
    modelhash: modelhash,
    corporate: corporate,
    model: model
});

// function that returns an object literal
export const delAllInviteModelSharesSuccess = () => ({
    type: Types.DEL_ALL_INVITE_MODEL_SHARES_SUCCESS,
});

// function that returns an object literal
export const delAllInviteModelSharesFailure = () => ({
    type: Types.DEL_ALL_INVITE_MODEL_SHARES_FAILURE,
});

// function that returns an object literal
export const delAllInviteModelSharesError = () => ({
    type: Types.DEL_ALL_INVITE_MODEL_SHARES_ERROR,
});

/*
Token
*/
// function that returns an object literal
export const delModelShareRequest = (modelsharedhash,model) => ({
    type: Types.DEL_MODEL_SHARE_REQUEST,
    modelsharedhash: modelsharedhash,
    model: model
});

// function that returns an object literal
export const delModelShareSuccess = (data) => ({
    type: Types.DEL_MODEL_SHARE_SUCCESS,
    payload: {data}
});

// function that returns an object literal
export const delModelShareFailure = () => ({
    type: Types.DEL_MODEL_SHARE_FAILURE,
});

// function that returns an object literal
export const delModelShareError = () => ({
    type: Types.DEL_MODEL_SHARE_ERROR,
});

export const reduxGenerateShareReset = () => ({
    type: Types.REDUX_GENERATE_SHARE_RESET,
});

export const resetAllData = () => ({
    type: Types.RESET_ALL_DATA,
});

