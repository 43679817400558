import {call, fork, put, takeEvery} from 'redux-saga/effects';
import * as actions from '../actions/data';
import * as actionsModel from '../actions/model';
import * as api from '../api/dataApi';
import {history} from '../hooks/history'
import {trackPromise} from "react-promise-tracker";
import {deleteHistoryPlugAction, setAnnexes, setPlugnote} from "../utils/Common";
import * as dataActions from "../actions/data";

const myGenericTrackedWrapper = (fn, args1, args2, args3) => trackPromise(fn(args1,args2, args3));
const myGenericTrackedWrapper2 = (fn, args1, args2, args3, args4) => trackPromise(fn(args1,args2, args3,args4));
const myGenericTrackedWrapper3 = (fn, args1, args2, args3, args4, args5) => trackPromise(fn(args1,args2, args3,args4, args5));
const myGenericTrackedWrapper4 = (fn, args1, args2, args3, args4, args5, args6) => trackPromise(fn(args1,args2, args3,args4, args5,args6));

function forwardTo(location) {
    history.push(location);
}

function* fetchDatas(action) {
    try {
        const response = yield call(myGenericTrackedWrapper,api.getDatas,action.modelhash, action.userhash);
        if(response.status === 200){
            yield put(actions.getDatasEndSuccess(1,response.data.end));
            yield put(actions.getDatasSuccess(response.data.datas));
        }else{
            yield put(actions.getDatasFailure());
        }
    }catch(e) {
        yield put(actions.getDatasError());
    }
}

//

function* fetchDatasSharedLight(action) {
    try {
        const response = yield call(myGenericTrackedWrapper3,api.getDatasSharedLight,action.modelhash, action.userhash,undefined, undefined,action.mode);
        if(response.status === 200){
            yield put(actionsModel.getModelUniqueSuccess(response.data.model));
            yield put(actions.getDatasEndSuccess(1,response.data.end));
            yield put(actions.getDatasSharedLightSuccess(response.data.datas));
        }else{
            yield put(actions.getDatasSharedLightFailure());
        }
    }catch(e) {
        yield put(actions.getDatasSharedLightError());
    }
}

function* fetchDatasLight(action) {
    try {
        const response = yield call(myGenericTrackedWrapper3,api.getDatasLight,action.modelhash, action.userhash,undefined, undefined,action.mode);
        if(response.status === 200){
            yield put(actionsModel.getModelUniqueSuccess(response.data.model));
            yield put(actions.getDatasEndSuccess(1,response.data.end));
            yield put(actions.getDatasLightSuccess(response.data.datas));
        }else{
            yield put(actions.getDatasLightFailure());
        }
    }catch(e) {
        yield put(actions.getDatasLightError());
    }
}

function* fetchFilterDatas(action) {
    try {
        const response = yield call(myGenericTrackedWrapper3,api.getDatas,action.modelhash, action.userhash, action.page, action.filter);
        if(response.status === 200){
            yield put(actions.getDatasEndSuccess(1,response.data.end));
            yield put(actions.getDatasFilterSuccess(response.data.datas));
        }else{
            yield put(actions.getDatasFilterFailure());
        }
    }catch(e) {
        yield put(actions.getDatasFilterError());
    }
}

function* fetchFilterDatasLight(action) {
    try {
        const response = yield call(myGenericTrackedWrapper2,api.getDatasLight,action.modelhash, action.userhash, action.page, action.filter);
        if(response.status === 200){
            yield put(actionsModel.getModelUniqueSuccess(response.data.model));
            yield put(actions.getDatasEndSuccess(1,response.data.end));
            yield put(actions.getDatasFilterSuccess(response.data.datas));
        }else{
            yield put(actions.getDatasFilterFailure());
        }
    }catch(e) {
        yield put(actions.getDatasFilterError());
    }
}

function* fetchPaginateDatas(action) {
    try {
        const response = yield call(myGenericTrackedWrapper,api.getDatas,action.modelhash, action.userhash, action.page, action.filter);
        if(response.status === 200){
            yield put(actions.getDatasEndSuccess(action.page, response.data.end));
            yield put(actions.getDatasPaginateSuccess(response.data.datas));
        }else{
            yield put(actions.getDatasPaginateFailure());
        }
    }catch(e) {
        yield put(actions.getDatasPaginateError());
    }
}

function* fetchPaginateDatasLight(action) {
    try {
        const response = yield call(myGenericTrackedWrapper3,api.getDatasLight,action.modelhash, action.userhash, action.page, action.filter,action.mode);
        if(response.status === 200){
            yield put(actions.getDatasEndSuccess(action.page, response.data.end));
            yield put(actions.getDatasPaginateSuccess(response.data.datas));
        }else{
            yield put(actions.getDatasPaginateFailure());
        }
    }catch(e) {
        yield put(actions.getDatasPaginateError());
    }
}

function* fetchPaginateDatasSharedLight(action) {
    try {
        const response = yield call(myGenericTrackedWrapper3,api.getDatasSharedLight,action.modelhash, action.userhash, action.page, action.filter,action.mode);
        if(response.status === 200){
            yield put(actions.getDatasEndSuccess(action.page, response.data.end));
            yield put(actions.getDatasPaginateSuccess(response.data.datas));
        }else{
            yield put(actions.getDatasPaginateFailure());
        }
    }catch(e) {
        yield put(actions.getDatasPaginateError());
    }
}

function* fetchPaginateDatasByModelClef(action) {
    try {
        const response = yield call(myGenericTrackedWrapper4,api.getDatasByClef,action.clef, action.userhash, action.page, action.filter,action.view);
        if(response.status === 200){
            yield put(actions.getDatasByClefEndSuccess(action.page, response.data.end));
            yield put(actions.getDatasByClefPaginateSuccess(response.data.datas, response.data.model));
        }else{
            yield put(actions.getDatasByClefPaginateFailure());
        }
    }catch(e) {
        yield put(actions.getDatasByClefPaginateError());
    }
}

function* fetchDatasByCodeFolder(action) {
    try {
        const response = yield call(myGenericTrackedWrapper,api.getDatasWithCodeAndFolder, action.plugcode, action.folder, action.fiche);
        // success?
        if (response.data.result === 'ok') {
            yield put(actions.getDatasByCodeFolderSuccess(response.data.data, response.data.model));
        } else {
            yield put(actions.getDatasByCodeFolderFailure())
        }
    }catch(e) {
        yield put(actions.getDatasByCodeFolderError());
    }
}

function* fetchDataByModelClefAndUserIndex(action) {
    try {
        const response = yield call(myGenericTrackedWrapper,api.getDataByClefAndUserIndex,action.clef, action.userhash, action.userindex);
        if(response.status === 200){
            yield put(actions.getDataByClefUserIndexSuccess(response.data.datas, response.data.model, action.fieldId));
        }else{
            yield put(actions.getDataByClefUserIndexFailure());
        }
    }catch(e) {
        yield put(actions.getDataByClefUserIndexError());
    }
}

function* fetchData(action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.getDataUnique,action.datahash);
        if(response.statusText == "OK" && response.data && response.data.id){
            yield put(actions.getDataUniqueSuccess(response.data));
            setPlugnote(response.data);
            setAnnexes(response.data.Annexes);
        }else{
            yield put(actions.getDataUniqueFailure());
        }
    }catch(e) {
        yield put(actions.getDataUniqueError());
    }
}

function* fetchDataByUserIndex(action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.getDataUniqueByUserindex,action.userindex, action.userhash);
        if(response.statusText == "OK" && response.data.result === true){
            yield put(actions.getDataUniqueUserindexSuccess(response.data.data));
        }else{
            yield put(actions.getDataUniqueUserindexFailure());
        }
    }catch(e) {
        yield put(actions.getDataUniqueUserindexError());
    }
}

function* createData(action){

    try {
        const response = yield call(myGenericTrackedWrapper,api.postData,action.modelhash, action.userhash, action.formData);

        if(response.status === 200){
            yield put(actions.postCreateDataSuccess(response.data));
            yield call(forwardTo, '/pluglist');
            deleteHistoryPlugAction();
        }else{
            yield put(actions.postCreateDataFailure());
        }
    }catch (e) {
        yield put(actions.postCreateDataError());

    }
}

function* updateData(action){

    try {
        const response = yield call(myGenericTrackedWrapper,api.putData,action.modelhash, action.userhash, action.formData);

        if(response.status === 200){
            yield put(actions.putUpdateDataSuccess(response.data));
            if(typeof action.forward === "undefined"){
                yield call(forwardTo, '/pluglist');
            }else{
                yield call(forwardTo, action.forward);
            }
            deleteHistoryPlugAction();
        }else{
            yield put(actions.putUpdateDataFailure());
        }
    }catch (e) {
        yield put(actions.putUpdateDataError());

    }
}

function* createWithoutRedirectData(action){

    try {
        const response = yield call(myGenericTrackedWrapper,api.postData,action.modelhash, action.userhash, action.formData);

        if(response.status === 200){
            const currentPlugnote = response.data;
            setPlugnote(currentPlugnote);
            yield put(actions.postCreateDataWithoutRedirectSuccess(currentPlugnote));
        }else{
            yield put(actions.postCreateDataWithoutRedirectFailure());
        }
    }catch (e) {
        yield put(actions.postCreateDataWithoutRedirectError());

    }
}

function* updateWithoutRedirectData(action){

    try {
        const response = yield call(myGenericTrackedWrapper,api.putData,action.modelhash, action.userhash, action.formData);

        if(response.status === 200){
            yield put(actions.putUpdateDataWithoutRedirectSuccess(response.data));
            setPlugnote(response.data);
        }else{
            yield put(actions.putUpdateDataWithoutRedirectFailure());
        }
    }catch (e) {
        yield put(actions.putUpdateDataWithoutRedirectError());

    }
}

function* updateWithoutRedirectDataModal(action){

    try {
        const response = yield call(myGenericTrackedWrapper,api.putData,action.modelhash, action.userhash, action.formData);

        if(response.status === 200){
            yield put(actions.putUpdateDataWithoutRedirectModalSuccess(response.data));
        }else{
            yield put(actions.putUpdateDataWithoutRedirectModalFailure());
        }
    }catch (e) {
        yield put(actions.putUpdateDataWithoutRedirectModalError());

    }
}

function* createSharedData(action){

    try {
        const response = yield call(myGenericTrackedWrapper,api.postData,action.modelhash, action.userhash, action.formData);

        if(response.status === 200){
            yield put(actions.postCreateSharedDataSuccess(response.data));
            yield call(forwardTo, '/pluglist-shared');
            deleteHistoryPlugAction();
        }else{
            yield put(actions.postCreateSharedDataFailure());
        }
    }catch (e) {
        yield put(actions.postCreateSharedDataError());

    }
}

function* updateSharedData(action){

    try {
        const response = yield call(myGenericTrackedWrapper,api.putData,action.modelhash, action.userhash, action.formData);

        if(response.status === 200){
            yield put(actions.putUpdateSharedDataSuccess(response.data));
            yield call(forwardTo, '/pluglist-shared');
            deleteHistoryPlugAction();
        }else{
            yield put(actions.putUpdateSharedDataFailure());
        }
    }catch (e) {
        yield put(actions.putUpdateSharedDataError());

    }
}


function* createSharedWithoutRedirectData(action){

    try {
        const response = yield call(myGenericTrackedWrapper,api.postData,action.modelhash, action.userhash, action.formData);

        if(response.status === 200){
            const currentPlugnote = response.data;
            setPlugnote(currentPlugnote);
            yield put(actions.postCreateSharedDataWithoutRedirectSuccess(currentPlugnote));
        }else{
            yield put(actions.postCreateSharedDataWithoutRedirectFailure());
        }
    }catch (e) {
        yield put(actions.postCreateSharedDataWithoutRedirectError());

    }
}


function* updateSharedWithoutRedirectData(action){

    try {
        const response = yield call(myGenericTrackedWrapper,api.putData,action.modelhash, action.userhash, action.formData);

        if(response.status === 200){
            yield put(actions.putUpdateSharedDataWithoutRedirectSuccess(response.data));
            setPlugnote(response.data);
        }else{
            yield put(actions.putUpdateSharedDataWithoutRedirectFailure());
        }
    }catch (e) {
        yield put(actions.putUpdateSharedDataWithoutRedirectError());

    }
}

function* updateSharedWithoutRedirectDataModal(action){

    try {
        const response = yield call(myGenericTrackedWrapper,api.putData,action.modelhash, action.userhash, action.formData);

        if(response.status === 200){
            yield put(actions.putUpdateSharedDataWithoutRedirectModalSuccess(response.data));
        }else{
            yield put(actions.putUpdateSharedDataWithoutRedirectModalFailure());
        }
    }catch (e) {
        yield put(actions.putUpdateSharedDataWithoutRedirectModalError());

    }
}

/**
 * Permet de masquer ou non le password
 * @param api
 * @param action
 */
function* decryptPassword(action) {

    try {
        const respDecrypt = yield call(myGenericTrackedWrapper,api.decryptPassword,action.datahash, action.fieldId);

        if(respDecrypt.status === 200){
            yield put(actions.getDecryptPasswordSuccess(respDecrypt.data));
        }else{
            yield put(actions.getDecryptPasswordFailure());
        }
    }catch (e) {
        yield put(actions.getDecryptPasswordError());

    }
}

/**
 * Permet de supprimer une fiche
 * @param api
 * @param action
 */
function* deleteData(action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.deleteData,action.datahash);

        if(response.status === 200){
            if(typeof action.back !== 'undefined'){
                forwardTo(action.back)
            }
            yield put(actions.delDeleteDataSuccess(action.datahash));
        }else{
            yield put(actions.delDeleteDataFailure());
        }
    }catch (e) {
        yield put(actions.delDeleteDataError());

    }
}

/**
 * Parmet de archiver/ déarchiver une fiche
 * @param api
 * @param action
 */
export function* archiveData(action) {

    try {
        let data = {
            hash: action.datahash,
            isarchived: action.isarchived,
            locked: !!action.locked
        };
        const response = yield call(myGenericTrackedWrapper,api.archiveAndLockData,data);

        if(response.status === 200){
            yield put(actions.putUpdateDataArchiveSuccess(response.data));
        }else{
            yield put(actions.putUpdateDataArchiveFailure());
        }
    }catch (e) {
        yield put(actions.putUpdateDataArchiveError());

    }

}

/**
 * Parmet de verrouiller/ déverrouiller une fiche
 * @param api
 * @param action
 */
function* lockData(action) {

    try {
        let data = {
            hash: action.datahash,
            isarchived: !!action.isarchived,
            locked: action.locked
        };
        const response = yield call(myGenericTrackedWrapper,api.archiveAndLockData,data);

        if(response.status === 200){
            yield put(actions.putUpdateDataLockSuccess(response.data));
        }else{
            yield put(actions.putUpdateDataLockFailure());
        }
    }catch (e) {
        yield put(actions.putUpdateDataLockError());

    }
}

/**
 * Parmet de favoriser/ defavoriser une fiche
 * @param api
 * @param action
 */
function* favorisData(action) {

    try {
        let data = {
            hash: action.datahash,
            favorite : action.favorite
        };
        const response = yield call(myGenericTrackedWrapper,api.archiveAndLockData,data);

        if(response.status === 200){
            yield put(actions.putUpdateDataFavorisSuccess(response.data));
        }else{
            yield put(actions.putUpdateDataFavorisFailure());
        }
    }catch (e) {
        yield put(actions.putUpdateDataFavorisError());

    }

}


export function* deleteDatas(action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.deleteDatas, action.payloadhash);

        if(response.status === 200 && response.data.datahash === "success delete"){
            yield put(actions.delDeleteDatasSuccess(action.payloadhash));
        }else{
            yield put(actions.delDeleteDatasFailure());
        }
    }catch (e) {
        yield put(actions.delDeleteDatasError());

    }
}

export function* archiveDatas(action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.archiveDatas, action.payloadhash);

        if(response.status === 200){
            yield put(actions.putUpdateDatasArchiveSuccess(action.payloadhash));
        }else{
            yield put(actions.putUpdateDatasArchiveFailure());
        }
    }catch (e) {
        yield put(actions.putUpdateDatasArchiveError());

    }

}

export function* unarchiveDatas(action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.unarchiveDatas, action.payloadhash);

        if(response.status === 200){
            yield put(actions.putUpdateDatasUnArchiveSuccess(action.payloadhash));
        }else{
            yield put(actions.putUpdateDatasUnArchiveFailure());
        }
    }catch (e) {
        yield put(actions.putUpdateDatasUnArchiveError());

    }

}

export function* updateDataField(action) {
    try {
        const response = yield call(api.putDataField,action.modelhash, action.userhash, action.datahash, action.formData);

        if(response.status === 200){
            yield put(actions.putUpdateDataSuccess(response.data));
        }else{
            yield put(actions.putUpdateDataFailure());
        }
    }catch (e) {
        yield put(actions.putUpdateDataError());

    }
}

export function* updateDataFieldManual(action) {
    try {
        const response = yield call(myGenericTrackedWrapper2,api.putDataField,action.modelhash, action.userhash, action.datahash, action.formData);

        if(response.status === 200){
            yield put(actions.putUpdateDataSuccess(response.data));
        }else{
            yield put(actions.putUpdateDataFailure());
        }
    }catch (e) {
        yield put(actions.putUpdateDataError());

    }
}

function* watchFetchData() {
    // create watcher of fetchData function
    yield takeEvery(actions.Types.GET_DATAS_REQUEST, fetchDatas);
    yield takeEvery(actions.Types.GET_DATAS_LIGHT_REQUEST, fetchDatasLight);
    yield takeEvery(actions.Types.GET_DATAS_SHARED_LIGHT_REQUEST, fetchDatasSharedLight);
    yield takeEvery(actions.Types.GET_DATAS_FILTER_REQUEST, fetchFilterDatas);
    yield takeEvery(actions.Types.GET_DATAS_FILTER_LIGHT_REQUEST, fetchFilterDatasLight);
    yield takeEvery(actions.Types.GET_DATAS_PAGINATE_REQUEST, fetchPaginateDatas);
    yield takeEvery(actions.Types.GET_DATAS_PAGINATE_LIGHT_REQUEST, fetchPaginateDatasLight);
    yield takeEvery(actions.Types.GET_DATAS_SHARED_PAGINATE_LIGHT_REQUEST, fetchPaginateDatasSharedLight);
    yield takeEvery(actions.Types.GET_DATAS_BY_CLEF_PAGINATE_REQUEST, fetchPaginateDatasByModelClef);
    yield takeEvery(actions.Types.GET_DATA_BY_CLEF_USER_INDEX_REQUEST, fetchDataByModelClefAndUserIndex);
    yield takeEvery(actions.Types.GET_DATA_UNIQUE_REQUEST, fetchData);
    yield takeEvery(actions.Types.GET_DATA_UNIQUE_USERINDEX_REQUEST, fetchDataByUserIndex);
    yield takeEvery(actions.Types.POST_CREATE_DATA_REQUEST, createData);
    yield takeEvery(actions.Types.POST_CREATE_SHARED_DATA_REQUEST, createSharedData);
    yield takeEvery(actions.Types.PUT_UPDATE_DATA_REQUEST, updateData);
    yield takeEvery(actions.Types.PUT_UPDATE_DATA_WITHOUT_REDIRECT_REQUEST, updateWithoutRedirectData);
    yield takeEvery(actions.Types.POST_CREATE_DATA_WITHOUT_REDIRECT_REQUEST, createWithoutRedirectData);
    yield takeEvery(actions.Types.PUT_UPDATE_DATA_WITHOUT_REDIRECT_MODAL_REQUEST, updateWithoutRedirectDataModal);
    yield takeEvery(actions.Types.PUT_UPDATE_SHARED_DATA_REQUEST, updateSharedData);
    yield takeEvery(actions.Types.PUT_UPDATE_SHARED_DATA_WITHOUT_REDIRECT_REQUEST, updateSharedWithoutRedirectData);
    yield takeEvery(actions.Types.POST_CREATE_SHARED_DATA_WITHOUT_REDIRECT_REQUEST, createSharedWithoutRedirectData);
    yield takeEvery(actions.Types.PUT_UPDATE_SHARED_DATA_WITHOUT_REDIRECT_MODAL_REQUEST, updateSharedWithoutRedirectDataModal);
    yield takeEvery(actions.Types.GET_DECRYPT_PASSWORD_REQUEST, decryptPassword);
    yield takeEvery(actions.Types.DEL_DELETE_DATA_REQUEST, deleteData);
    yield takeEvery(actions.Types.PUT_UPDATE_DATA_FAVORIS_REQUEST, favorisData);
    yield takeEvery(actions.Types.PUT_UPDATE_DATA_ARCHIVE_REQUEST, archiveData);
    yield takeEvery(actions.Types.PUT_UPDATE_DATA_LOCK_REQUEST, lockData);
    yield takeEvery(actions.Types.DEL_DELETE_DATAS_REQUEST, deleteDatas);
    yield takeEvery(actions.Types.PUT_UPDATE_DATAS_ARCHIVE_REQUEST, archiveDatas);
    yield takeEvery(actions.Types.PUT_UPDATE_DATAS_UNARCHIVE_REQUEST, unarchiveDatas);
    yield takeEvery(actions.Types.PUT_UPDATE_DATA_FIELD_REQUEST, updateDataField);
    yield takeEvery(actions.Types.PUT_UPDATE_DATA_FIELD_MANUAL_REQUEST, updateDataFieldManual);
    yield takeEvery(actions.Types.GET_DATAS_BY_CODE_FOLDER_REQUEST, fetchDatasByCodeFolder);

}

const DataSagas = [
    fork(watchFetchData)
];

export default DataSagas;
