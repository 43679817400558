// actions types is object with the
// key: GET_DATA_REQUEST -> value: describes the action
import React from "react";
import {toast} from "react-toastify";
import {Translation} from 'react-i18next';

export const Types = {

    RESET_ALL_DATA: 'reset_all_data',

    GET_TEMPLATE_ALL_REQUEST: 'get_template_all_request',
    GET_TEMPLATE_ALL_SUCCESS: 'get_template_all_success',
    GET_TEMPLATE_ALL_FAILURE: 'get_template_all_failure',
    GET_TEMPLATE_ALL_ERROR: 'get_template_all_error',

    GET_TEMPLATE_CODE_GROUP_REQUEST: 'get_template_code_group_request',
    GET_TEMPLATE_CODE_GROUP_SUCCESS: 'get_template_code_group_success',
    GET_TEMPLATE_CODE_GROUP_FAILURE: 'get_template_code_group_failure',
    GET_TEMPLATE_CODE_GROUP_ERROR: 'get_template_code_group_error',

    GET_TEMPLATE_CODE_MODEL_REQUEST: 'get_template_code_model_request',
    GET_TEMPLATE_CODE_MODEL_SUCCESS: 'get_template_code_model_success',
    GET_TEMPLATE_CODE_MODEL_FAILURE: 'get_template_code_model_failure',
    GET_TEMPLATE_CODE_MODEL_ERROR: 'get_template_code_model_error',

    GET_TEMPLATE_CODE_CATEGORIE_REQUEST: 'get_template_code_categorie_request',
    GET_TEMPLATE_CODE_CATEGORIE_SUCCESS: 'get_template_code_categorie_success',
    GET_TEMPLATE_CODE_CATEGORIE_FAILURE: 'get_template_code_categorie_failure',
    GET_TEMPLATE_CODE_CATEGORIE_ERROR: 'get_template_code_categorie_error',

    GET_TEMPLATE_MODEL_CODE_REQUEST: 'get_template_model_code_request',
    GET_TEMPLATE_MODEL_CODE_SUCCESS: 'get_template_model_code_success',
    GET_TEMPLATE_MODEL_CODE_FAILURE: 'get_template_model_code_failure',
    GET_TEMPLATE_MODEL_CODE_ERROR: 'get_template_model_code_error',

    RESET_TEMPLATE_HISTORY_REQUEST: 'reset_template_history_request',
    RESET_TEMPLATE_OPEN_LIST_MODELS_REQUEST: 'reset_template_open_list_models_request',
    RESET_TEMPLATE_OPEN_LIST_GROUP_REQUEST: 'reset_template_open_list_group_request',
    RESET_TEMPLATE_OPEN_LIST_CATEGORIE_REQUEST: 'reset_template_open_list_categorie_request',
    RESET_TEMPLATE_MODEL_CODE_REQUEST: 'reset_template_model_code_request'
};

const ErrorToastMessage = ({ title, message }) => (
    <div>
        <Translation ns="common">
            {
                (t, { i18n }) => <h6>{t(title)}</h6>
            }
        </Translation>
        <Translation ns="common">
            {
                (t, { i18n }) => <p>{t(message)}</p>
            }
        </Translation>
    </div>

)


/*
Token
 */
// function that returns an object literal
export const getTemplateAllRequest = () => ({
    type: Types.GET_TEMPLATE_ALL_REQUEST,
});

// function that returns an object literal
export const getTemplateAllSuccess = (data) => ({
    type: Types.GET_TEMPLATE_ALL_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getTemplateAllFailure = () => ({
    type: Types.GET_TEMPLATE_ALL_FAILURE,
});

// function that returns an object literal
export const getTemplateAllError = () => ({
    type: Types.GET_TEMPLATE_ALL_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const getTemplateCodeGroupRequest = (codeGroup,userhash) => ({
    type: Types.GET_TEMPLATE_CODE_GROUP_REQUEST,
    codeGroup : codeGroup,
    userhash : userhash
});

// function that returns an object literal
export const getTemplateCodeGroupSuccess = (data) => ({
    type: Types.GET_TEMPLATE_CODE_GROUP_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getTemplateCodeGroupFailure = () => {
    return function(dispatch) {
        dispatch({
            type: Types.GET_TEMPLATE_CODE_GROUP_FAILURE,
        });
        toast.error(<ErrorToastMessage title='global.plugnotes' message='template.code-error' />)
    }
};

// function that returns an object literal
export const getTemplateCodeGroupError = () => {
    return function(dispatch) {
        dispatch({
            type: Types.GET_TEMPLATE_CODE_GROUP_ERROR,
        });
        toast.error(<ErrorToastMessage title='global.plugnotes' message='template.code-error' />)
    }
};


/*
Token
 */
// function that returns an object literal
export const getTemplateCodeModelRequest = (codeModel,userhash) => ({
    type: Types.GET_TEMPLATE_CODE_MODEL_REQUEST,
    codeModel : codeModel,
    userhash : userhash
});

// function that returns an object literal
export const getTemplateCodeModelSuccess = (data) => ({
    type: Types.GET_TEMPLATE_CODE_MODEL_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getTemplateCodeModelFailure = () => {
    return function(dispatch) {
        dispatch({
            type: Types.GET_TEMPLATE_CODE_MODEL_FAILURE,
        });
        toast.error(<ErrorToastMessage title='global.plugnotes' message='template.code-error' />)
    }
};

// function that returns an object literal
export const getTemplateCodeModelError = () => {
    return function(dispatch) {
        dispatch({
            type: Types.GET_TEMPLATE_CODE_MODEL_ERROR,
        });
        toast.error(<ErrorToastMessage title='global.plugnotes' message='template.code-error' />)
    }
};




/*
Token
 */
// function that returns an object literal
export const getTemplateModelCodeRequest = (code,userhash, langue) => ({
    type: Types.GET_TEMPLATE_MODEL_CODE_REQUEST,
    code : code,
    userhash : userhash,
    langue : langue
});

// function that returns an object literal
export const getTemplateModelCodeSuccess = (data) => ({
    type: Types.GET_TEMPLATE_MODEL_CODE_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getTemplateModelCodeFailure = () => {
    return function(dispatch) {
        dispatch({
            type: Types.GET_TEMPLATE_MODEL_CODE_FAILURE,
        });
        toast.error(<ErrorToastMessage title='global.plugnotes' message='template.code-error' />)
    }
};

// function that returns an object literal
export const getTemplateModelCodeError = () => {
    return function(dispatch) {
        dispatch({
            type: Types.GET_TEMPLATE_MODEL_CODE_ERROR,
        });
        toast.error(<ErrorToastMessage title='global.plugnotes' message='template.code-error' />)
    }
};

/*
Token
 */
// function that returns an object literal
export const getTemplateCodeCategorieRequest = (codeCategorie,userhash, langue) => ({
    type: Types.GET_TEMPLATE_CODE_CATEGORIE_REQUEST,
    codeCategorie : codeCategorie,
    userhash : userhash,
    langue : langue
});

// function that returns an object literal
export const getTemplateCodeCategorieSuccess = (data) => ({
    type: Types.GET_TEMPLATE_CODE_CATEGORIE_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getTemplateCodeCategorieFailure = () => {
    return function(dispatch) {
        dispatch({
            type: Types.GET_TEMPLATE_CODE_CATEGORIE_FAILURE,
        });
        toast.error(<ErrorToastMessage title='global.plugnotes' message='template.code-error' />)
    }
};

// function that returns an object literal
export const getTemplateCodeCategorieError = () => {
    return function(dispatch) {
        dispatch({
            type: Types.GET_TEMPLATE_CODE_CATEGORIE_ERROR,
        });
        toast.error(<ErrorToastMessage title='global.plugnotes' message='template.code-error' />)
    }
};

// function that returns an object literal
export const resetTemplateHistoryRequest = () => ({
    type: Types.RESET_TEMPLATE_HISTORY_REQUEST,
});

// function that returns an object literal
export const resetTemplateOpenListModelsRequest = () => ({
    type: Types.RESET_TEMPLATE_OPEN_LIST_MODELS_REQUEST,
});

// function that returns an object literal
export const resetTemplateOpenListGroupRequest = () => ({
    type: Types.RESET_TEMPLATE_OPEN_LIST_GROUP_REQUEST,
});

// function that returns an object literal
export const resetTemplateOpenListCategorieRequest = () => ({
    type: Types.RESET_TEMPLATE_OPEN_LIST_CATEGORIE_REQUEST,
});

// function that returns an object literal
export const resetTemplateModelCodeRequest = () => ({
    type: Types.RESET_TEMPLATE_MODEL_CODE_REQUEST,
});

export const resetAllData = () => ({
    type: Types.RESET_ALL_DATA,
});



