// actions types is object with the
// key: GET_DATA_REQUEST -> value: describes the action
export const Types = {

    RESET_ALL_DATA: 'reset_all_data',

    GET_LIST_CONTACTS_REQUEST: 'get_list_contacts_request',
    GET_LIST_CONTACTS_SUCCESS: 'get_list_contacts_success',
    GET_LIST_CONTACTS_FAILURE: 'get_list_contacts_failure',
    GET_LIST_CONTACTS_ERROR: 'get_list_contacts_error',

};

/*
Token
 */
// function that returns an object literal
export const resetAllData = () => ({
    type: Types.RESET_ALL_DATA,
});


/*
Token
 */
// function that returns an object literal
export const getListContactsRequest = (userhash) => ({
    type: Types.GET_LIST_CONTACTS_REQUEST,
    userhash : userhash
});

// function that returns an object literal
export const getListContactsSuccess = (data) => ({
    type: Types.GET_LIST_CONTACTS_SUCCESS,
    payload: data
});

// function that returns an object literal
export const getListContactsFailure = () => ({
    type: Types.GET_LIST_CONTACTS_FAILURE,
});

// function that returns an object literal
export const getListContactsError = () => ({
    type: Types.GET_LIST_CONTACTS_ERROR,
});

