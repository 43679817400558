import React, { useEffect, useState } from 'react';
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import { connect } from 'react-redux';
import { Modal } from '@material-ui/core';
import { formatTextTypes } from '../../../utils/Compatibility';
import Data0000Printable from '../../printable/Data0000Printable';

const useStyles = makeStyles((theme) => ({
    popup: {
        position: 'absolute',
        padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        width: '95%',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    modalFile: {
        border: "none",
        width: "100%",
        height: "75vh",
    },
    titre: {
        fontSize: 20,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    optionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
}))
function getModalStyle() {
    const top = 5;
    return {
        top: `${top}%`,
        margin:'auto',
        overflow: 'auto',
    };
}


const ModalPrintPluglinkCompononent = (props) => {
    //Variables
    const classes = useStyles()
    const [modalStyle] = useState(getModalStyle);
    const [t, ] = useTranslation('common');
    const [fromField, setFromField] = useState([])
    const [toField, setToField] = useState([])
    const [arrayFilled, setArrayFilled] = useState(false)
    const [pdfLoading, setPdfLoading] = useState(false)
    const [path, setPath] = useState("")

    //Methods
    const handleCloseModal = () => {
        props.close();
    };


    useEffect(() => {
        const mapFields = () => {
            let fromArray = []
            let toArray = []
            for(var i = 0; i < 16; i++) {
                fromArray.push({'title':'', 'format': '', 'formatLabel': ''});
                toArray.push({'title':'', 'format': '', 'formatLabel': ''});
            }
            props.fromFields.forEach(field => {
                // console.log('from mapping', field);
                let item = {'title': field.label, 'format': field.format, 'id': field.id, 'formatLabel': t('fields.format-choice-' + field.format.toLowerCase())}
                fromArray[field.id] = item
            });
            props.toFields.forEach(field => {
                let item = {'title': field.title, 'format': field.format, 'id': field.id, 'formatLabel': t('fields.format-choice-' + field.format.toLowerCase())}
                toArray[field.id] = item
            });
            setFromField(fromArray)
            setToField(toArray)
            setArrayFilled(true)
        }
        if(fromField.length === 0 && toField.length === 0 )
            mapFields()
        // console.log('arrays', fromField, toField);
    }, [fromField, props, toField])

    useEffect(() => {
        console.log('url', path);
        console.log('loading', pdfLoading);
    }, [pdfLoading])

    return(
    <Modal
        key={13}
        keepMounted={false}
        open={props.open}
        // onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
        >
        <div style={modalStyle} className={classes.popup}>
            <div className="sous-header_modal_content">
                <span className={classes.titre}>
                    {t('fields.format-choice-pluglink') + " " + props.field.label}
                    <Data0000Printable
                        field={props.field}
                        filename={props.fileName}
                        fromFields={fromField}
                        fromModel={props.fromModel}
                        options={props.field.options}
                        setPdfLoading={setPdfLoading}
                        setPath={setPath}
                        toFields={toField}
                        toModel={props.toModel}
                        user={props.user}
                    />
                </span>
                <div onClick={handleCloseModal}>
                    <span id="icon-close" className="material-icons md-50 lightgrey">
                        close
                    </span>
                </div>
            </div>
            <div className="sous-header_content lightgrey">
                <div className="line flex_style">
                </div>
                <br/>
            </div>
            {!pdfLoading?
            <iframe src={path !== null ? path.concat('#&navpanes=0') : ""} className={classes.modalFile} title="pdf-preview"/>
            :
            <div>Your pdf is being generated</div>
            }


            {/* <div className="sous-header_content ">
                <div className="display_flex flex_display_direction_row div_width_100">
                    <div className="div_width_45">
                        {props.fromFields.map((item, index) => {
                            return(
                            <div key={index} className="flex_display_direction_row flexSpacebetween" >
                                <span>{item.id} - {item.label} </span>
                                <span style={{fontSize : 12, textAlign : 'right' , marginRight : '5px'}}> {formatTextTypes[item.format.toUpperCase()]} - {t('fields.format-choice-'+item.format.toLowerCase())}</span>
                            </div>
                            )
                        })}
                    </div>
                    <div className="div_width_10_centered">
                        <span/>
                    </div>
                    <div className="div_width_45">
                        {props.toFields.map((item, index) => {
                            return(
                            <div key={index} className="flex_display_direction_row flexSpacebetween">
                                <span>{item.id} - {item.title} </span>
                                <span style={{fontSize : 12, textAlign : 'right', marginRight : '10px'}}> {formatTextTypes[item.format.toUpperCase()]} - {t('fields.format-choice-' + item.format.toLowerCase())}</span>
                            </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className="sous-header_content lightgrey">
                <div className="line flex_style">
                </div>
                <br/>
            </div>
            <div className={classes.optionsContainer}>
                {arrayFilled?
                props.field.options.fields.map((option, index) => {
                let fIndex = parseInt(option.from)
                let tIndex = parseInt(option.to)
                return(
                <div className="display_flex flex_display_direction_row div_width_100" key={index + option.dragid}>
                    <span className="div_width_45">{t('common:fields.format-choice-copy-content-right')} {fIndex} - {fromField[fIndex].title}</span>
                    <div className="div_width_10_centered">
                        <span/>
                    </div>
                    <span className="div_width_45">{t('common:fields.format-choice-complete-content-left')} {tIndex} - {toField[tIndex].title}</span>
                </div>)
                }):
                null}
            </div> */}
        </div>
    </Modal>
    )
}
// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        modelUniqueRefresh : state.model.modelUniqueRefresh || null
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // attemptGetModelUnique: (code, userhash) => dispatch(getModelUniqueByClefRequest(code,userhash)),
        // attemptGetModelUniqueReset: () => dispatch(getModelUniqueByClefReset()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalPrintPluglinkCompononent)
