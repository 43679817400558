import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";

function HeaderSearch(props) {

    const { t, i18n } = useTranslation(['common', 'models', 'global']);

    const [search, setSearch] = useState(typeof props.query === 'object' && props.query !== null ? props.query.value : props.query);


    useEffect(() => {
        setSearch(typeof props.query === 'object' && props.query !== null ? props.query.value : props.query);
    }, [props.query])

    return (
        <div style={{
            height : '30px',
            backgroundColor: '#384C5A',
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                color: 'white'
            }}>
                <span>{t('global.search-models-active-2')} {search}</span>
            </div>
        </div>
    );
}


// redux providing state takeover
const mapStateToProps = (state) => {

    let query = "";

    if(state.search.isSearchActive){
        if(typeof state.search.searchParams.label !== 'undefined'){
            query = state.search.searchParams.label.keyword.list[0] ;
        }else if(typeof state.search.searchParams.content !== 'undefined'){
            query = state.search.searchParams.content.keyword.list[0] ;

        }else if(typeof state.search.searchParams.pnnumber !== 'undefined'){
            query = state.search.searchParams.pnnumber.keyword ;

        }else if(typeof state.search.searchParams.plugkey !== 'undefined'){
            query = state.search.searchParams.plugkey.keyword ;
        }
    }

    if(state.data.globalApiDataFilter !== null){
        query = state.data.globalApiDataFilter ;
    }

    return {
        search : state.search,
        query : query
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderSearch)
