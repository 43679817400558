import {Types} from '../actions/search';

// create initial state for reducers
const INIT_STATE = {
    selectedTypeList: [],
    searchParams: {},
    searchResult: [],
    isSearchResult: false,
    isSearchActive: false,
    fetching: false,
    error: null,
    plugcode: null
};


// reducer function to transform state
export default function search(state = INIT_STATE, action) {
    switch(action.type) {
        case Types.RESET_ALL_DATA : return INIT_STATE;
        case Types.SET_SEARCH_PLUG_PARAMS_REQUEST:
        case Types.SET_SEARCH_MODEL_PARAMS_REQUEST: {
            return {
                ...state,
                isSearchActive: true,
                searchParams: {[action.paramName]: action.data }
            }
        }

        case Types.DEL_SEARCH_KEY_PARAMS_REQUEST: {

            let newSearchParams = {};
            Object.keys(state.searchParams).map(function(key, index) {
                if (key != action.paramName) {
                    newSearchParams[key] = state.searchParams[key];
                }
            });

            return {
                ...state,
                searchParams: newSearchParams
            }
        }

        case Types.POST_SEARCH_SUCCESS:
        case Types.POST_SEARCH_PLUG_SUCCESS:
        case Types.POST_SEARCH_MODEL_SUCCESS:{

            return {
                ...state,
                isSearchActive: true,
                isSearchResult: action.payload.data.length > 0,
                searchResult: action.payload.data
            }
        }

        case Types.POST_SEARCH_FAILURE:
        case Types.POST_SEARCH_PLUG_FAILURE:
        case Types.POST_SEARCH_MODEL_FAILURE:{

            return {
                ...state,
            }
        }

        case Types.POST_SEARCH_ERROR:
        case Types.POST_SEARCH_PLUG_ERROR:
        case Types.POST_SEARCH_MODEL_ERROR: return INIT_STATE;

        case Types.DEL_ALL_SEARCH_PARAMS_REQUEST: return INIT_STATE;

        default: return state;
    }

};