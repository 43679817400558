import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {connect} from "react-redux";
import {getCurrentEnvironnement, getToken, setModel} from "../../utils/Common";
import '@contentful/forma-36-react-components/dist/styles.css';
import {Input} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import {confirmAlert} from "react-confirm-alert";
import ModalSearchInRepertoriesComponent from "./ModalSearchInRepertoriesComponent";
import {postSendMailRequest} from "../../actions/utils";
import {getBool, urlify} from "../../utils/utils";
import TagsInputEmptyObjectMails from "../specials/input/TagsInputEmptyObjectMails";
import {trackPromise} from "react-promise-tracker";
import axios from "axios";
import ApplicationConfig from "../../config/ApplicationConfig";
import {useGridApiRef} from "@material-ui/x-grid";
import {DesignedGrid} from "../custom/CustomComponents";
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';

function getModalStyle() {
    const top = 10;

    return {
        top: `${top}%`,
        margin:'auto',
        //overflow: 'auto',
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        margin : 0,
    },
    grid:{
        minHeight : '10vh',
    },
    popup: {
        position: 'absolute',
        width: '95%',
        //height : '50%',
        //backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        //boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        //width: '820px',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
    },
    urlButton:{
        /* border: '1px red solid', */
        display: 'inline-block',
        color: "white",
        backgroundColor: "#58c3c1",
        padding: "3px 15px",
        borderRadius: "3px",
        cursor: "pointer",
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    marginLeft:{
        marginLeft : '10px'
    },
    textFieldChips : {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        minHeight: '38px',
        minLineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F3F3F3',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F3F3F3',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    textFieldArea: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        paddingLeft: '10px',
        backgroundColor: '#F3F3F3',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    }
}));

const useGriddCellExpendStyles = makeStyles(() => ({
    root: {
        alignItems: 'center',
        lineHeight: '24px',
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        '& .cellValue': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
}));

function isOverflown(element) {
    return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
    );
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
    const { width, value } = props;
    const wrapper = React.useRef(null);
    const cellDiv = React.useRef(null);
    const cellValue = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useGriddCellExpendStyles();
    const [showFullCell, setShowFullCell] = React.useState(false);
    const [showPopper, setShowPopper] = React.useState(false);

    const handleMouseEnter = () => {
        const isCurrentlyOverflown = isOverflown(cellValue.current);
        setShowPopper(isCurrentlyOverflown);
        setAnchorEl(cellDiv.current);
        setShowFullCell(true);
    };

    const handleMouseLeave = () => {
        setShowFullCell(false);
    };

    React.useEffect(() => {
        if (!showFullCell) {
            return undefined;
        }

        function handleKeyDown(nativeEvent) {
            // IE11, Edge (prior to using Bink?) use 'Esc'
            if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
                setShowFullCell(false);
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [setShowFullCell, showFullCell]);


    return (
        <div
            ref={wrapper}
            className={classes.root}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div
                ref={cellDiv}
                style={{
                    height: 1,
                    width,
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                }}
            />
            <div ref={cellValue} className="cellValue">
                {value}
            </div>
            {showPopper && (
                <Popper
                    open={showFullCell && anchorEl !== null}
                    anchorEl={anchorEl}
                    style={{ width : "50%", zIndex : 2000, wordBreak: "break-all"}}
                >
                    <Paper
                        elevation={1}
                        style={{ minHeight: wrapper.current.offsetHeight - 3 }}
                    >
                        <Typography variant="body2" style={{ padding: 8 }}>
                            {value}
                        </Typography>
                    </Paper>
                </Popper>
            )}
        </div>
    );
});

GridCellExpand.propTypes = {
    value: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
};


const ModalHelpComponent = (props) => {



    const classes = useStyles();
    const { t, i18n } = useTranslation(['common']);

    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);

    const [title, setTitle] = React.useState("");
    const [datas, setDatas] = React.useState([]);


    function renderCellExpand(params) {
        return (
            <GridCellExpand
                value={params.value ? params.value.toString() : ''}
                width={params.colDef.expandWidth}
            />
        );
    }

    renderCellExpand.propTypes = {
        /**
         * The column of the row that the current cell belongs to.
         */
        colDef: PropTypes.object.isRequired,
        /**
         * The cell value, but if the column has valueGetter, use getValue.
         */
        value: PropTypes.oneOfType([
            PropTypes.instanceOf(Date),
            PropTypes.number,
            PropTypes.object,
            PropTypes.string,
            PropTypes.bool,
        ]),
    };


    const urlAction = (object) => {
        return (
            <div>
                <span className={classes.plugcodeCtn}>
                    <a href={object.row.url.toString()} target="_blank" className={classes.urlButton} rel="noreferrer"><span className="material-icons md-small white">link</span></a>
                </span>
            </div>
        )
    };


    let initColumns = [];

    initColumns.push(
        // { key: 'view', name:  '', resizable: true, sortable : false , width: '5%', formatter : viewAction },
        { field: 'id', key: 'id', headerName: 'ID', resizable: false, sortable : true,flex: 1,minWidth: 150, headerAlign: 'center' , align : "center", hide: true},
        { field: 'numero', key: 'numero', headerName: 'NUMERO', resizable: false, sortable : true,flex: 1,minWidth: 150, headerAlign: 'center' , align : "center"},
        { field: 'info', key: 'info', headerName: 'INFO', resizable: false, sortable : true, flex:1,  minWidth: 150, expandWidth: 200 , renderCell : renderCellExpand, headerAlign: 'center' , align : "center"},
        { field: 'code', key: 'code', headerName: 'CODE', resizable: false, sortable : true,flex: 1,minWidth: 150, headerAlign: 'center' , align : "center", hide: true},
        { field: 'url', key: 'url', headerName: 'URL', resizable: false, sortable : true , renderCell : urlAction,flex: 1,minWidth: 50, headerAlign: 'center' , align : "center"},
    );



    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState(initColumns);

    const apiRef = useGridApiRef();


    const handleCloseModal = () => {
        props.close();
    };


    useEffect(() => {
        const initialRows = [];

        if(datas.length > 0){

            datas.map((data, index) => {
                let finalObject = {};

                finalObject['id'] = data['id'];
                finalObject['numero'] = data['numero'];
                finalObject['info'] = data['info'];
                finalObject['code'] = data['code'];
                finalObject['url'] = 'https://plugcodes.com/' + '?code=' + data['code'];

                initialRows.push(finalObject);
            });

            setRows(initialRows);


        }else{
            setRows([]);
        }

    }, [datas])



    const fetchData = () => {
        let lang = i18n.language.substr(0, 2).toLowerCase();
        let from = props.from;

        trackPromise(
            axios.get(ApplicationConfig.apiUrl + `help/list/help.json?lang=${lang}&from=${from}`
                , {
                    crossdomain: true,
                    'headers': {
                        'X-AUTH-TOKEN': getToken(),
                    }
                }).then((response) => {
                if(response.data.result === true){
                    setDatas(response.data.data)
                }
            }).catch((response) => {
                //console.log(response);
            }))
    }


    useEffect(() => {
        if(props.open){
            setTitle(t('common:global.help-manuels'));
            fetchData();
        }else{
            setTitle("");
            setDatas([]);
        }

    }, [props.open])

    return (
        <Modal
            keepMounted={false}
            open={props.open}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div key={props.countable} style={modalStyle} className={classes.popup}>
                <div className="sous-header_modal_content">
                    <div>
                        <span className="titres">
                            <h3>{ title }</h3>
                        </span>
                    </div>
                    <div onClick={handleCloseModal}>
                        <span id="icon-close" className="material-icons md-50 lightgrey">
                            close
                        </span>
                    </div>
                </div>
                <br/>

                <div className="line flex_style"/>
                <br/>
                <div style={{ height: 350, width: '100%' }}>
                    <DesignedGrid
                        {...rows}
                        apiRef={apiRef}
                        //autoHeight={true}
                        className={classes.grid}
                        columns={columns}
                        //disableExtendRowFullWidth={false}
                        hideFooter={true}
                        hideFooterPagination={true}
                        loading={false}
                        rows={rows}
                        rowHeight={50}
                        showCellRightBorder={true}
                    />
                </div>
            </div>
        </Modal>
    )
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        mainProfil : state.user.profil,
        pseudo : state.user.profil.pseudo
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        attemptSendMailSimple: (payload, userhash) => dispatch(postSendMailRequest(payload,userhash)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalHelpComponent)
