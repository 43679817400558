import React, {useState, useEffect } from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import { isJsonStringObject } from "../../utils/utils";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import {deleteFilterRequest, getListFilterRequest, getListFilterReset} from "../../actions/filter";
import {getCurrentEnvironnement} from "../../utils/Common";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";


const useStyles = makeStyles((theme) => ({
    popup: {
        position: "absolute",
        //width: '50%',
        //height : '50%',
        //backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        //boxShadow: theme.shadows[5],
        //padding: theme.spacing(2, 4, 3),
        minWidth: "50%",
        maxWidth: "90%",
        backgroundColor: "white",
        width: "820px",
        border: "1px solid #DEDEDE",
        boxSizing: "border-box",
        boxShadow:
            "0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)",
        borderRadius: "20px",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "90%",
        margin: "auto",
    },
    marginLeft: {
        marginLeft: "10px",
    },
    buttonSaveComment: {
        width: "91%",
        padding: '8px 20px',
        color: 'white',
        backgroundColor: '#58c3c1',
        border: 'none',
        borderRadius: '3px',
        margin: '5px auto',
        cursor: 'pointer',
    },
    filterListContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    }
}));

function getModalStyle() {
    const top = 5;
    return {
        top: `${top}%`,
        margin: "auto",
        overflow: "auto",
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

const FilterSelectionLoadComponent = (props) => {

    const { t, i18n } = useTranslation(['common', 'models', 'global', 'plugnote']);

    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = useState(getModalStyle);
    const environment = getCurrentEnvironnement();
    const [open, setOpen] = useState(false);
    const [filterSet, setFilterSet] = useState(props.filterSet);
    const [modelhash, setModelhash] = useState(props.modelHash);
    const [userhash, setUserhash] = useState(environment.userhash);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(open && options.length === 0);
    const [value, setValue] = useState("");

    const handleLoad = () => {
        props.attemptResetListFilterSaveByModel();
        if(isJsonStringObject(value.filters)){
            let filters = JSON.parse(value.filters);
            props.setFilterSet(filters);
        }
        props.close();
    }

    const handleDelete = (object) => {
        setValue("");
        let optionsTmp = options.filter((element, index) => {
            return element.id !== object.id;
        });
        setOptions(optionsTmp);
        props.attemptDeleteFilter({id : object.id});
    }

    const onChange = (_, value) => {
        setValue(value);
    };

    const handleSelect = () => {
        setValue(value);
        setTimeout(() => handleLoad(), 200)
    };


    useEffect(() => {
        let active = true;
        if (!loading) {
            return undefined;
        }
        const fetchFilters = async () => {
            // const response = await fetch('https://country.register.gov.uk/records.json?page-size=5000');
            // await sleep(1e3); // For demo purposes.
            // const countries = await response.json();
            let payload = {
                modelhash : modelhash,
                userhash : userhash
            }
            props.attemptGetListFilterSaveByModel(payload);
        }

        fetchFilters()
            .catch(console.error);
        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {
        if (!open) {
             setOptions([]);
        }
    }, [open]);

    useEffect(() => {
        if(typeof props.list !== "undefined" && props.list.length > 0){
            setOptions(props.list);
            setLoading(false);
        }else{
            setOptions([]);
            setLoading(false);
        }
    }, [props.list])

    return (
        <Modal
            keepMounted={false}
            open={props.open}
            onClose={props.close}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div style={modalStyle} className={classes.popup}>
                <div className="scrollbar-hidden popup_wrapper flexSpacebetween">
                    <div className="popup_wrapper_content">
                        <div className="sous-header_modal_content">
                            <div>
                                <span className="titres">
                                {t("common:filters.title")} <br />
                                </span>
                            </div>
                            <div onClick={props.close}>
                                <span
                                    id="icon-close"
                                    className="material-icons md-50 lightgrey"
                                >
                                  close
                                </span>
                            </div>
                        </div>
                        <div className="sous-header_content lightgrey">
                            <div className="line flex_style"></div>
                        </div>

                        <div className={classes.filterListContainer}>
                            {/* <InputLabel style={{fontWeight : 'bold'}} shrink>{ t('common:filters.label') }</InputLabel> */}
                            <Autocomplete
                            multiple={false}
                            limitTags={1}
                            id="asynchronous-demo"
                            open={open || false}
                            onOpen={() => {
                                setOpen(true);
                                setLoading(true);
                            }}
                            onClose={() => {
                                setOpen(false);
                                setLoading(false);
                            }}
                            filterSelectedOptions
                            onChange={onChange}
                            getOptionLabel={(option) => {
                                if(typeof option !== "undefined" && option !== ""){
                                    if(option.description.length > 0){
                                        return option.label + ' ' + option.clef + ' ('+ option.description + ')'
                                    } else return option.label + ' ' + option.clef;
                                }else return "";
                            }}
                            value={value}
                            options={options}
                            loading={loading}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        //label="Langue"
                                        variant="outlined"
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                                <React.Fragment>
                                                    {value !== null && value !== "" && (
                                                        <span id="icon-delete-playlist" className="material-icons md-small" onClick={(event) => handleDelete(value)}>
                                                            delete
                                                        </span>
                                                    )}
                                                </React.Fragment>
                                            ),
                                            endAdornment: (
                                                <React.Fragment>
                                                    {loading ? (
                                                        <CircularProgress
                                                            color="inherit"
                                                            size={20}
                                                        />
                                                    ) : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                );
                            }}
                            />

                        {/* {
                            !loading && props.list.length > 0 ?
                            props.list.map((params) => {
                                return (
                                    <TextField
                                        // key={params.label + '-' + params.clef}
                                        //label="Langue"
                                        variant="outlined"
                                        onClick={() => handleSelect()}
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                                <React.Fragment>
                                                    <span id="icon-delete-playlist" className="material-icons md-small" onClick={(event) => handleDelete(value)}>
                                                        delete
                                                    </span>
                                                </React.Fragment>
                                            ),
                                            endAdornment: (
                                                <React.Fragment>
                                                    {loading ? (
                                                        <CircularProgress
                                                            color="inherit"
                                                            size={20}
                                                        />
                                                    ) : null}
                                                    {/* {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                        value={
                                        (typeof params !== "undefined" && params !== "")?
                                            (params.length > 0)?
                                                params.label + ' ' + params.clef + ' ('+ params.description + ')'
                                                : params.label + ' ' + params.clef
                                            : ""}
                                    />
                                );
                            })
                        : null
                        } */}
                        </div>
                    </div>
                    {
                        value !== null && value !== "" &&
                        <button
                            className={classes.buttonSaveComment}
                            onClick={ handleLoad }
                            disabled={value.length === 0}
                        >
                            {t("common:global.confirm")}
                        </button>
                    }
                </div>
            </div>
        </Modal>
    );
};




// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        mainHash : state?.user?.profil?.hash || null,
        list: state?.filter?.list || [],
    }
};

const mapDispatchToProps = (dispatch) => ({
    attemptGetListFilterSaveByModel: (payload) => dispatch(getListFilterRequest(payload)),
    attemptResetListFilterSaveByModel: () => dispatch(getListFilterReset()),
    attemptDeleteFilter: (payload) => dispatch(deleteFilterRequest(payload)),

});

export default connect(mapStateToProps, mapDispatchToProps)(FilterSelectionLoadComponent);





