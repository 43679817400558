import React, {useEffect, useState} from "react"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import {HyperFormula} from "hyperformula";
import axios from "axios";
import ApplicationConfig from "../../config/ApplicationConfig";
import {getCurrentEnvironnement, getToken} from "../../utils/Common";
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Tooltip } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%'
    },
    input_add_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    fieldRight:{
        width : '100%',
        display : 'flex'
    }
}));

const FormatFormulaComponent = (props) => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const environment = getCurrentEnvironnement();
    const [hash, setHash] = useState(environment !== null ? environment.userhash : null);

    const { t, i18n } = useTranslation(['common', 'models']);
    const [disabled, setDisabled] = useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    const [formula, setFormula] = useState(props.field.options.formula !== "undefined" ?  props.field.options.formula : null);
    const [result, setResult] = useState(typeof props.field.value !== "undefined" && props.field.value !==  "" ? props.field.value : "");

    const options = { licenseKey: 'gpl-v3' }

    async function getContentValue (value) {
        // Fetch the dynamically generated excel document from the server.
        return axios.post(ApplicationConfig.apiUrl + 'datacodevalues.json?uh=' + hash + '&muh='+props.mainHash, {
            code: value
        },{crossdomain: true,
            'headers': {
                'X-AUTH-TOKEN': getToken(),
            }
        }).then((response) => {
            return response.data.data;
        }).catch((response) => {
            return response.data.data;
        })
    }

    const _handleFormula = async () => {
        let tmp = [];
        if(typeof formula!== "undefined" && formula !== null){

            let newFormula =  formula;

            let intermediate = props.fields.length;
            let intermediateRecap = props.fields.length + 1;

            props.fields.forEach((field, indexFormat) => {

                let value = field.value;
                if(Object.prototype.toString.call(field.value) === '[object Array]' || Object.prototype.toString.call(field.value) === '[object Object]') {
                    value = "";
                }

                if(isNaN(value) && Object.prototype.toString.call(field.value) !== '[object String]'){
                    value = "";
                }

                switch (field.format) {
                    case 'formula' :
                        try {
                            value = field.value.toString();
                        }catch (e) {
                            value = "";
                        }
                        tmp[field.id - 1] = [value];
                        break;
                    case "single-select":
                    case "free-single-select":
                        if(Object.prototype.toString.call(field.value) && field.value.length > 0){
                            value = field.value[0];
                        }
                        tmp[field.id - 1] = [value];
                        break;
                    case "single-table":
                    case "free-table-select":
                        if(Object.prototype.toString.call(field.value) && field.value.length > 0){
                            value = field.value[0]?.text + ' : ' +  field.value[0]?.value;
                        }
                        tmp[field.id - 1] = [value];
                        break;
                    case "tablemulti":
                        try {
                            let obj = field?.value?.find(o => typeof o.recap !== "undefined") || false;

                            let copyFormula = formula;
                            let regexF32Total = 'PN!A'+field.id+'-[0-9]{1,}';
                            let regexF32TotalRegex = new RegExp(regexF32Total, 'g');
                            let extractFormula = [...copyFormula.matchAll(regexF32TotalRegex)];

                            try {

                                if(obj){
                                    extractFormula.map((ext, index) => {
                                        let match = ext[0].split('-');
                                        if(index === 0){
                                            newFormula = newFormula.replace(ext[0] , match[0])
                                        }else{
                                            newFormula = newFormula.replace(ext[0] , 'PN!A'+intermediateRecap);
                                            intermediateRecap++;
                                        }
                                        try {
                                            let id = field.options?.lines ? field.options.lines[match[1] - 1].id : field.options[match[1] - 1].id;
                                            let length = field.value.length - 1;
                                            let tmpValue = field.value[length][id];
                                            value = tmpValue
                                            if(index === 0){
                                                tmp[field.id - 1] = [value];
                                            }else{
                                                tmp[intermediate] = [value];
                                                intermediate++;
                                            }
                                        }catch (e) {
                                            value = "";
                                            tmp[field.id - 1] = [value];
                                        }
                                    });
                                }else{
                                    try {
                                        let copyFormula = formula;
                                        let regexF32Total = 'PN!A'+field.id+'-[0-9]{1,}';
                                        let regexF32TotalRegex = new RegExp(regexF32Total, 'g');
                                        let extractFormula = [...copyFormula.matchAll(regexF32TotalRegex)];

                                        extractFormula.map((ext, index) => {
                                            let match = ext[0].split('-');
                                            if(index === 0) {
                                                newFormula = newFormula.replace(ext[0], match[0])
                                            }
                                        })
                                        value = "";
                                        tmp[field.id - 1] = [value];
                                    }catch (e) {
                                        value = "";
                                        tmp[field.id - 1] = [value];
                                    }
                                }
                            }
                            catch (e) {
                                value = "";
                                tmp[field.id - 1] = [value];
                            }
                        }catch (e) {
                            value = "";
                            tmp[field.id - 1] = [value];
                        }

                        break;
                    case "tablemultin":
                        try {
                            let objn = field?.value?.list?.find(o => typeof o.recap !== "undefined") || false;

                            if(objn){
                                let copyFormulan = formula;
                                let regexF32Totaln = 'PN!A'+field.id+'-[0-9]{1,}';
                                let regexF32TotalnRegex = new RegExp(regexF32Totaln, 'g');
                                let extractFormulan = [...copyFormulan.matchAll(regexF32TotalnRegex)];
                                try {

                                    if(objn){
                                        extractFormulan.map((ext, index) => {
                                            let match = ext[0].split('-');
                                            if(index === 0){
                                                newFormula = newFormula.replace(ext[0] , match[0])
                                            }else{
                                                newFormula = newFormula.replace(ext[0] , 'PN!A'+intermediateRecap);
                                                intermediateRecap++;
                                            }
                                            try {
                                                let id = field.options.lines[match[1] - 1].id;
                                                let length = field.value.list.length - 1;
                                                let tmpValue = field.value.list[length][id];
                                                value = tmpValue
                                                if(index === 0){
                                                    tmp[field.id - 1] = [value];
                                                }else{
                                                    tmp[intermediate] = [value];
                                                    intermediate++;
                                                }
                                            }catch (e) {
                                                value = "";
                                                tmp[field.id - 1] = [value];
                                            }
                                        });
                                    }else{
                                        value = "";
                                        tmp[field.id - 1] = [value];
                                    }
                                }
                                catch (e) {
                                    value = "";
                                    tmp[field.id - 1] = [value];
                                }
                            }
                            else {

                                try {
                                    let copyFormulan = formula;
                                    let regexF32Totaln = 'PN!A'+field.id+'-[0-9]{1,}';
                                    let regexF32TotalnRegex = new RegExp(regexF32Totaln, 'g');
                                    let extractFormulan = [...copyFormulan.matchAll(regexF32TotalnRegex)];

                                    extractFormulan.map((ext, index) => {
                                        let match = ext[0].split('-');
                                        if(index === 0) {
                                            newFormula = newFormula.replace(ext[0], match[0])
                                        }
                                    })
                                    value = "";
                                    tmp[field.id - 1] = [value];
                                }catch (e) {
                                    value = "";
                                    tmp[field.id - 1] = [value];
                                }
                            }
                        }catch (e) {
                            value = "";
                            tmp[field.id - 1] = [value];
                        }
                        break;
                    default:
                        tmp[field.id - 1] = [value];
                        break;
                }
            })

            let data = Array.from(tmp, item => item || []);
            const sheetInfo = {
                pnSheet: {
                    sheetName: "PN"
                },
                formulas: {
                    sheetName: "Formulas"
                }
            };

            let sheetAData = data;

            const hfInstance = HyperFormula.buildEmpty(options);

            const subFormula =  newFormula.match(/PN-[0-9]+-[0-9]+/) !== null ?  newFormula.match(/PN-[0-9]+-[0-9]+/) : [];
            
            if(subFormula.length > 0){
                subFormula.map((sub,index) => {
                    let newname = sub.replaceAll('-', '');
                    sheetInfo["pnSheet" + (index + 1)] = {
                        sheetName : newname
                    }

                    newFormula = newFormula.replace(sub.toString(), newname.toString());
                    //sheetInfo["PN"+index].sheetName = sub
                });
            }

            let formulasData = [[
                newFormula
            ]];

            // add 'TeamA' sheet
            hfInstance.addSheet(sheetInfo.pnSheet.sheetName);
            // insert playersA content into targeted 'TeamA' sheet
            hfInstance.setSheetContent(hfInstance.getSheetId(sheetInfo.pnSheet.sheetName), sheetAData);

            if(subFormula.length > 0){
                await Promise.all(
                    subFormula.map(async (sub,index) =>{
                        let finalContentValue = [];
                        let contentValue = await getContentValue(sub);

                        if(props.mainHash !== null){
                            contentValue.map((content) => {
                                finalContentValue.push([Number(content)])
                            })
                        }

                        // add a sheet named 'Custom'
                        hfInstance.addSheet(sheetInfo["pnSheet"+(index+1)].sheetName);
                        // add Custom to that sheet
                        hfInstance.setSheetContent(hfInstance.getSheetId(sheetInfo["pnSheet"+(index+1)].sheetName), finalContentValue);
                    }));

                //test in https://codesandbox.io/s/friendly-lake-x08p77?file=/src/App.js
            }

            // add a sheet named 'Formulas'
            hfInstance.addSheet(sheetInfo.formulas.sheetName);
            // add formulas to that sheet
            hfInstance.setSheetContent(hfInstance.getSheetId(sheetInfo.formulas.sheetName), formulasData);

            const cellAddress = hfInstance.simpleCellAddressFromString(
                `${sheetInfo.formulas.sheetName}!A1`, hfInstance.getSheetId(sheetInfo.formulas.sheetName)
            );
            try {
                const mySum = hfInstance.getCellValue(cellAddress);

                if(!mySum.hasOwnProperty('type')){
                    setResult(mySum);
                }
                else {
                    setResult("");
                }
            }
            catch (e) {
                setResult("");
                console.error(`❌ Formula  ${e}`)
            }
            hfInstance.destroy();
        }
    }

    const _handleCalculateValue = async () => {
        setLoading((prevLoading) => !prevLoading);
        await _handleFormula();
        setLoading((prevLoading) => !prevLoading);
    }

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    useEffect(() => {

        if(result !== undefined){
            props.handleEditValue(result.toString(), props.field, props.index);
        }

    }, [result])

    useEffect(async () => {
        await _handleFormula();
    }, [props.fields])

    return (
        <div className={classes.fieldRight}>

            <div style={{width : loading ?  '100%' : '0%'}}>
                <Fade
                    in={loading}
                    style={{
                        transitionDelay: loading ? '800ms' : '0ms',
                    }}
                    unmountOnExit
                >
                    <CircularProgress size={25}/>
                </Fade>
            </div>

            { !loading &&
                <TextField
                    value={result}
                    onChange={(event) => null}
                    disabled={true}
                    id={'formula-value-' + props.field.id}
                    //label={field.title}
                    style={{ margin: 0 }}
                    helperText=""
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    className={classes.fieldRight}
                    InputProps={{ disableUnderline: true }}
                />
            }
            {
                <Tooltip title={`${formula}`} placement="top">
                    {
                        !disabled ?
                            <div onClick={(event) => _handleCalculateValue()}>
                                <span className="material-icons md-medium green">calculate</span>
                            </div>
                            :
                            <span className={`material-icons ${classes.disabledIcon}`}>visibility</span>
                    }
                </Tooltip>
            }
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        mainHash : state?.user?.profil?.hash || null
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatFormulaComponent);
