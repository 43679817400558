import React, {useEffect} from "react"
import {useTranslation} from "react-i18next";
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {connect} from "react-redux";
import {getCurrentEnvironnement} from "../../utils/Common";
import '@contentful/forma-36-react-components/dist/styles.css';
import {Input} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import {confirmAlert} from "react-confirm-alert";
import ModalSearchInRepertoriesComponent from "./ModalSearchInRepertoriesComponent";
import {postSendMailRequest} from "../../actions/utils";
import {urlify} from "../../utils/utils";
import {isFirefox, isSafari} from "react-device-detect";
import {toast} from "react-toastify";
import TagsInputEmptyObjectMails from "../specials/input/TagsInputEmptyObjectMails";
import * as moment from "moment";

function getModalStyle() {
    const top = 5;

    return {
        top: `${top}%`,
        margin:'auto',
        overflow: 'auto',
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    popup: {
        position: 'absolute',
        //width: '50%',
        //height : '50%',
        //backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        //boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        width: '820px',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
    },
    textFieldChips : {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        minHeight: '38px',
        minLineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F3F3F3',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    marginLeft:{
        marginLeft : '10px'
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F3F3F3',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    textFieldArea: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        paddingLeft: '10px',
        backgroundColor: '#F3F3F3',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    }
}));

const getCurrentDate=()=>{

    let date = new Date().getDate();
    let month = new Date().getMonth() + 1;
    let year = new Date().getFullYear();

    //Alert.alert(date + '-' + month + '-' + year);
    // You can turn it in to your desired format
    return date + '/' + month + '/' + year;//format: dd-mm-yyyy;
}

const checkDoublon = (list, text) => {
    return list.findIndex((item) => item === text) > -1
};

const ModalSimpleSendMailV2PlugnoteCodeComponent = (props) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation(['common']);

    let languageCode = i18n.language.substr(0, 2).toLowerCase();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);

    const environment = getCurrentEnvironnement();

    const [body, setBody] = React.useState("");
    const [sujet, setSujet] = React.useState("");

    const [expediteur, setExpediteur] = React.useState("");
    const [mailNotification, setMailNotification] = React.useState([props.mainProfil.email]);
    const [mailNotificationCc, setMailNotificationCc] = React.useState([]);
    const [mail, setMail] = React.useState("");
    const [mailCc, setMailCc] = React.useState("");
    const [method, setMethod] = React.useState('');
    const [openRepertory, setOpenRepertory] = React.useState(false);


    const handleChange = (value) => {
        switch (method){
            case 'setMailNotification':
                value.map((item, index) => {
                    handleAddNotificationValue(item.email);
                })
                break;
            case 'setMailNotificationCc':
                value.map((item, index) => {
                    handleAddNotificationCcValue(item.email);
                })
                break;
        }
        setMethod('');
    }

    const handleCloseSubModal = () => {
        setOpenRepertory(false);
    }

    const handleCloseModal = () => {
        props.close();
    };

    const handleSendMail = () => {

    }

    /**
     * Ajout d'une vérif pour le type 'email'
     */
    const isEmail = (value) => {
        return value.match(new RegExp( /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) != null;
        //return /(.)+@(.)+/.test(value)
    }

    /**
     * Permet d'ajouter un mail pour le notifier
     * @private
     */
    const handleAddNotification = (mail) => {
        let email = mail;
        let tmpMailNotification = mailNotification;

        if(isEmail(email)){
            if (!checkDoublon(tmpMailNotification, email)) {
                mailNotification.push(email);
            }

            setMailNotification(mailNotification);
            setMail("");
        }else{
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('global.wrong-email'),
                buttons: [
                    {
                        label: t('global.ok'),
                    },
                ]
            });
        }
    };

    /**
     * Permet d'ajouter un mail pour le notifier
     * @private
     */
    const handleAddNotificationCc = (mailCc) => {
        let emailCC = mailCc;
        let tmpMailNotificationCc = mailNotificationCc;

        if(isEmail(emailCC)){
            if (!checkDoublon(tmpMailNotificationCc, emailCC)) {
                mailNotificationCc.push(emailCC);
            }

            setMailNotificationCc(mailNotificationCc);
            setMailCc("");
        }else{
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('global.wrong-email'),
                buttons: [
                    {
                        label: t('global.ok'),
                    },
                ]
            });
        }
    }

    /**
     * Permet d'ajouter un mail pour le notifier
     * @private
     */
    const handleAddNotificationValue = (value) => {
        let email = value;
        let tmpMailNotification = mailNotification;

        if(isEmail(email)){
            if (!checkDoublon(tmpMailNotification, email)) {
                mailNotification.push(email);
            }

            setMailNotification(mailNotification);
            setMail("");
        }else{
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('global.wrong-email'),
                buttons: [
                    {
                        label: t('global.ok'),
                    },
                ]
            });
        }
    };

    /**
     * Permet d'ajouter un mail pour le notifier
     * @private
     */
    const handleAddNotificationCcValue = (value) => {
        let emailCC = value;
        let tmpMailNotificationCc = mailNotificationCc;

        if(isEmail(emailCC)){
            if (!checkDoublon(tmpMailNotificationCc, emailCC)) {
                mailNotificationCc.push(emailCC);
            }

            setMailNotificationCc(mailNotificationCc);
            setMailCc("");
        }else{
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('global.wrong-email'),
                buttons: [
                    {
                        label: t('global.ok'),
                    },
                ]
            });
        }
    }

    /**
     * Permet de supprimer un mail pour la notification
     * @private
     */
    const handleDeleteNotification = (mail, mailIndex) => {
        setMailNotification(mailNotification.filter((item , index) => {
            return item !== mail
        }))
    };

    /**
     * Permet de supprimer un mail pour la notification
     * @private
     */
    const handleDeleteNotificationCc = (mail, index) => {
        setMailNotificationCc(mailNotificationCc.filter((item , index) => {
            return item !== mail
        }))
    };

    const handlePressValidate = () => {

        if(mailNotification.length > 0){
            let message = t('export.plug-list-export-csv');
            message += ' ' + t('export.plug-list-export-csv-2');
            confirmAlert({
                title: t('global.plugnotes'),
                message: message,
                buttons: [
                    {
                        label: t('global.ok'),
                        onClick: () => {
                            let bodyReplace = body.replace(/(?:\r\n|\r|\n)/g, '<br>');
                            let bodyUrlReplace = urlify(bodyReplace);
                            let payload = {};
                            payload.mailNotification = mailNotification;
                            payload.mailNotificationCc = mailNotificationCc.length > 0 ? mailNotificationCc : null;
                            payload.subject = sujet.length > 0 ?  sujet : null ;
                            payload.body = bodyUrlReplace.length > 0 ? bodyUrlReplace : null;
                            props.close();
                            props.attemptSendMailSimple(payload,environment.userhash);
                        }
                    },
                    {
                        label: t('global.cancel'),
                    }
                ]
            });
        }else{
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('global.missing-email'),
                buttons: [
                    {
                        label: t('global.ok'),
                    },
                ]
            });
        }
    };

    function handleSelecetedTags(items) {
    }

    const copyPfCodeToClipboard = () => {


        if(isFirefox || isSafari){
            let pfCode = props.code;
            navigator.clipboard.writeText(pfCode);
            document.execCommand('copy');
            toast.success(i18n.t('common:global.plugcodes-to-clipboard'));
        } else{
            navigator.permissions.query({name: "clipboard-write"}).then(result => {
                if (result.state == "granted" || result.state == "prompt") {
                    let pfCode = props.code;
                    navigator.clipboard.writeText(pfCode);
                    toast.success(i18n.t('common:global.plugcodes-to-clipboard'));
                }
            });
        }
    };

    const getCodePNCode = (plugnote) => {
        let code = 'PN';
        switch (plugnote.publicpncode) {
            case 0:
                code = 'PP';
                break;
            case 1:
                code = 'PN';
                break;
            case 2:
                code = 'PE';
                break;
            case 3:
                code = 'PS';
                break;
        }

        return (code) + '-' +
            moment(plugnote.creationdate).format('YYMMDD') + '-' +
            plugnote.codepncode
    }

    useEffect(() => {
        if(props.open){
            setSujet(props.title);
            setBody(props.content);
        }else{
            setBody("");
            setSujet("");
        }

    }, [props.open])

    return (
        <Modal
            keepMounted={false}
            open={props.open}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div key={props.countable} style={modalStyle} className={classes.popup}>
                <div className = "scrollbar-hidden popup_wrapper flexSpacebetween" >
                    <div className="popup_wrapper_content">
                        <div className="sous-header_modal_content">
                            <div>
                                    <span className="titres">
                                        {t('common:plugcode.send-form-title')}
                                    </span>
                                <span className="grey margin-left-20">
                                    { getCodePNCode(props.plugnote) }
                                    </span>
                            </div>
                            <div onClick={copyPfCodeToClipboard} className="cursor grey">
                                        <span className=" md-25 ">
                                            {
                                                props.code
                                            }
                                        </span>
                                <span id="icon-close" className="material-icons md-25 lightgrey">
                                        content_copy
                                    </span>
                            </div>
                            <div onClick={handleCloseModal}>
                                    <span id="icon-close" className="material-icons md-25 lightgrey">
                                    close
                                    </span>
                            </div>

                        </div>
                        <div className="sous-header_content lightgrey">
                            <div className="line flex_style">
                            </div>
                        </div>
                    </div>
                    <div className="popup_wrapper_content">
                        <div className="flex_display_direction_row justify_content_space align-items-center">
                            <InputLabel style={{fontWeight : 'bold'}} shrink>{ t('common:mails.destinataire') }</InputLabel>
                            <span style={{cursor : "pointer" , color : 'black', marginRight : '5px', float : 'right'}} onClick={(event) => {setOpenRepertory(true); setMethod('setMailNotification')}}>
                                    <span id="icon-listForm" className={`material-icons md-25 `}>
                                        search
                                    </span>
                                </span>
                        </div>
                        <div className={classes.textFieldChips}>
                            <TagsInputEmptyObjectMails
                                handleRemove={handleDeleteNotification}
                                handleAdd={handleAddNotification}
                                selectedTags={handleSelecetedTags}
                                tags={mailNotification}
                                id="tags"
                                name="tags-shared"
                                placeholder=""
                                label=""
                            />
                        </div>
                        <div className="flex_display_direction_row justify_content_space align-items-center">
                            <InputLabel style={{fontWeight : 'bold'}} shrink>{ t('common:mails.destinatairecc') }</InputLabel>
                            <span style={{cursor : "pointer" , color : 'black', marginRight : '5px'}} onClick={(event) => {setOpenRepertory(true); setMethod('setMailNotificationCc')}}>
                                        <span id="icon-listForm" className={`material-icons md-25 `}>
                                            search
                                        </span>
                                    </span>
                        </div>
                        <div className={classes.textFieldChips}>
                            <TagsInputEmptyObjectMails
                                handleRemove={handleDeleteNotificationCc}
                                handleAdd={handleAddNotificationCc}
                                selectedTags={handleSelecetedTags}
                                tags={mailNotificationCc}
                                id="tags-2"
                                name="tags-shared-2"
                                placeholder=""
                                label=""
                            />
                        </div>
                        <div className="flex_display_direction_row justify_content_space align-items-center">
                            <InputLabel style={{fontWeight : 'bold'}} shrink>{ t('common:mails.sujet') }</InputLabel>
                            <span style={{height : '25px'}}/>
                        </div>

                        <Input
                            id="standard-full-width-bis"
                            label=""
                            style={{ marginTop: 10, marginBottom : 10 }}
                            //placeholder={props.placeholder}
                            fullWidth={true}
                            margin="none"
                            disableUnderline={true}
                            inputlabelprops={{
                                className: classes.input,
                            }}
                            className={classes.textField}
                            onChange={(event) => setSujet(event.target.value)}
                            value={sujet}
                        />

                        <div className="flex_display_direction_row justify_content_space align-items-center">
                            <InputLabel style={{fontWeight : 'bold'}} shrink>{ t('common:mails.message') }</InputLabel>
                        </div>

                        <Input
                            id="outlined-multiline-static"
                            //label="Commentaire d'annexe"
                            multiline
                            style={{ marginTop: 10, marginBottom : 10 }}
                            rows={10}
                            defaultValue={body}
                            disableUnderline={true}
                            fullWidth={true}
                            inputlabelprops={{
                                className: classes.input,
                            }}
                            className={classes.textFieldArea}
                            onChange={(event) => setBody(event.target.value)}
                        />

                    </div>
                    <div className="popup_wrapper_content justify_content_space">
                        <button className="button-exit" onClick={handleCloseModal}>{t('common:global.back')}</button>
                        <button className="button float_right" onClick={handlePressValidate}>{t('common:global.send')}</button>
                    </div>
                </div>
                <ModalSearchInRepertoriesComponent open={openRepertory} multiple={true} onChange={handleChange} close={handleCloseSubModal}/>
            </div>
        </Modal>
    )
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        mainProfil : state.user.profil
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        attemptSendMailSimple: (payload, userhash) => dispatch(postSendMailRequest(payload,userhash)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalSimpleSendMailV2PlugnoteCodeComponent)
