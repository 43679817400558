import axios from 'axios';
import ApplicationConfig from "../config/ApplicationConfig";

export const api = axios.create({
    baseURL: ApplicationConfig.apiUrl
});

// Set default headers to common_axios ( as Instance )
// if(getToken() !== null){
//     api.defaults.headers.common['X-AUTH-TOKEN'] = getToken();
// }

api.interceptors.request.use(function (config) {
    const token = sessionStorage.getItem('token');
    config.headers.common['X-AUTH-TOKEN'] =  token ? `${token}` : '';
    return config;
});

api.interceptors.response.use(response => {
    if(typeof response.data !== 'undefined'){
        if(typeof response.data.message !== 'undefined'){
            if(response.data.message === "Invalid credentials"){
                //fetchForceLogoutUser();
            }
        }
    }

    return response;
});
