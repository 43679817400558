import {call, fork, put, select, takeEvery} from 'redux-saga/effects';

import * as actions from '../actions/mailbox';
import * as api from '../api/mailboxApi';
import {trackPromise} from "react-promise-tracker";

const myGenericTrackedWrapper = (fn, args1, args2, args3) => trackPromise(fn(args1,args2, args3));

/**
 * Permet de récupérer  les mails in à classer
 * @param api
 * @param action
 */
export function * getMailsIn (action) {

    const uhm = yield select((state) => state.user.profil.hash);
    const userHash = action.userhash;

    try {
        const response = yield call(myGenericTrackedWrapper,api.getInboxMailsIn,userHash, uhm, 1);
        if(response.data.result === 'ok'){
            yield put(actions.getMailsInEndSuccess(1, response.data.end));
            yield put(actions.getMailsInSuccess(response.data.datas));
        }else{
            yield put(actions.getMailsInFailure());
        }
    }catch(e) {
        yield put(actions.getMailsInError());
    }
}

export function * getMailsInPaginate (action) {


    const uhm = yield select((state) => state.user.profil.hash);
    const userHash = action.userhash;

    try {
        const response = yield call(myGenericTrackedWrapper,api.getInboxMailsIn,userHash, uhm, action.page);
        if(response.data.result === 'ok'){
            yield put(actions.getMailsInEndSuccess(action.page, response.data.end));
            yield put(actions.getMailsInPaginateSuccess(response.data.datas));
        }else{
            yield put(actions.getMailsInPaginateFailure());
        }
    }catch(e) {
        yield put(actions.getMailsInPaginateError());
    }
}


export function * getMailIn (action) {


    const uhm = yield select((state) => state.user.profil.hash);
    const userHash = action.userhash;

    try {
        const response = yield call(myGenericTrackedWrapper,api.getInboxMailInUnique, action.mid, userHash, uhm);
        if(response.data.result === 'ok'){
            yield put(actions.getMailInSuccess(response.data.data));
        }else{
            yield put(actions.getMailInFailure());
        }
    }catch(e) {
        yield put(actions.getMailInError());
    }
}



function* watchFetchExport() {
    // create watcher of fetchData function
    yield takeEvery(actions.Types.GET_MAILS_IN_REQUEST, getMailsIn);
    yield takeEvery(actions.Types.GET_MAILS_IN_PAGINATE_REQUEST, getMailsInPaginate);
    yield takeEvery(actions.Types.GET_MAIL_IN_REQUEST, getMailIn);

}

const MailboxSagas = [
    fork(watchFetchExport)
];

export default MailboxSagas;
