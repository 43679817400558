import {api} from "./index";
import qs from "querystringify";


export let getAllTranslationsByModel,getAllTranslationsByModelOffline,getOneTranslationByModel,postCreateTranslationByModel,putUpdateTranslationByModel,deleteOneTranslationByModel;

deleteOneTranslationByModel = (modelhash,userhash,id) => api.delete('translations/models/deletes' + qs.stringify({uh: userhash,mh: modelhash, id : id}, '?'));
getOneTranslationByModel = (modelhash,userhash) => api.get('translations/model/unique' + qs.stringify({uh: userhash,mh: modelhash}, '?'));
getAllTranslationsByModel = (modelhash,userhash,translateid) => api.get('translations/model/list' + qs.stringify({uh: userhash,mh: modelhash, id : translateid}, '?'));
getAllTranslationsByModelOffline = (modelhash,userhash,translateid) => api.get('translations/offline/model/list' + qs.stringify({uh: userhash,mh: modelhash, id : translateid}, '?'));
postCreateTranslationByModel = (payload) => api.post('translations/models/creates', payload);
putUpdateTranslationByModel = (payload) => api.put('translations/models/updates', payload);
