import {Types} from '../actions/params';

// create initial state for reducers
const INIT_STATE = {
    list : [],
};

// reducer function to transform state
export default function params(state = INIT_STATE, action) {
    switch(action.type) {
        case Types.RESET_ALL_DATA : return INIT_STATE;
        case Types.GET_PARAMS_SUCCESS:{
            return {
                ...state,
                list : action.payload.data,
            }
        }
        case Types.GET_PARAMS_ERROR:{
            return {
                ...state,
                list : []
            }
        }
        case Types.GET_PARAMS_FAILURE:{
            return {
                ...state,
                list : []
            }
        }

        default: return state;
    }
};
