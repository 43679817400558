import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import {getCurrentEnvironnement, getModel, getPlugnote, getToken, setPlugnote} from "../../utils/Common";
import axios from "axios";
import ApplicationConfig from "../../config/ApplicationConfig";
import qs from "querystringify";
import {trackPromise} from "react-promise-tracker";
import {api} from "../../api";
import {isNull} from "lodash";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%'
    },
    input_add_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    fieldRight:{
        width : '100%',
    },
    memoContainer:{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        gap: 5,
    },
    memoText:{
        flexGrow: 1,
        /* border: '1px solid blue', */
    },
    memoCount:{
        width: '50px',
        fontSize : '10px',
        textAlign: 'center',
        //lineHeight: '10px',
    },

    mailButton:{
        /* border: '1px red solid', */
        display: 'inline-block',
        color: "white",
        backgroundColor: "#58c3c1",
        padding: "3px 15px",
        borderRadius: "3px",
        cursor: "pointer",
    },
}));

const FormatIndiceComponent = (props) => {

    const classes = useStyles();
    const environment = getCurrentEnvironnement();
    const [value, setValue] = useState(props.field.value);

    const { t, i18n } = useTranslation(['common', 'models']);

    const [editable, setEditable] = React.useState(typeof props.disabled === 'undefined' ? props.field.options?.editable  : props.disabled);
    const [disabled, setDisabled] = React.useState(typeof props.disabled === 'undefined' ? false : props.disabled);

    useEffect(() => {
        setEditable(typeof props.disabled === 'undefined' ? props.field.options?.editable : props.disabled);
        setDisabled(typeof props.disabled === 'undefined' ? false : props.disabled);

    },[ props.disabled]);

    useEffect(() => {
        if(!isNull(getPlugnote())){
            if(getPlugnote()['field'+props.field.id] !== props.field.value && typeof props.field.value !== "undefined"){
                setValue(getPlugnote()['field'+props.field.id]);
                handleEditValue(getPlugnote()['field'+props.field.id], props.field, props.index);
            }
        }

    },[getPlugnote()])

    const handleEditValue = (value, field, index) => {
        setValue(value);
        props.handleEditValue(value, field, index)
    }

    const handleGenerateIndice = () => {
        if(typeof props.shared !== "undefined"){
            if(!props.shared){
                handleCreatePlugnoteIndice()
            }else if(props.shared){
                handleCreatePlugnoteSharedIndice()
            }
        }
    }



    const buildNewAnnexe = (fields) => {
        let newData = {};

        const modelForHash = getModel();

        fields.map((field) => {
            if (field.format === "multi-select" || field.format === "free-multi-select" || field.format === "single-select" || field.format === "free-single-select") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else if (Array.isArray(field.value)) {
                    newData["field" + field.id] = JSON.stringify(field.value);
                } else {
                    newData["field" + field.id] = JSON.stringify([field.value]);
                }
            }
            else if (field.format === "multi-table" || field.format === "free-multi-table" || field.format === "single-table" || field.format === "free-single-table") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else if (Array.isArray(field.value)) {
                    newData["field" + field.id] = JSON.stringify(field.value);
                } else {
                    newData["field" + field.id] = JSON.stringify([field.value]);
                }
            }
            else if(field.format === "list"){
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else if (Array.isArray(field.value)) {
                    let correctValue = [];
                    field.value.map((item, index) => {
                        correctValue.push({"text" : item})
                    });
                    newData["field" + field.id] = JSON.stringify(correctValue);
                } else {
                    let transformValue = [field.value];
                    let correctValue = [];
                    transformValue.map((item, index) => {
                        correctValue.push({"text" : item})
                    });
                    newData["field" + field.id] = correctValue;
                }
            }
            else if(field.format === "plugform"){
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }
            else if (field.format === "qrcodeint") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = JSON.stringify({"code" : "", "model" : "", "data" : "", "comment" : ""});
                } else {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }
            else if (field.format === "intdatamaj") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = JSON.stringify({"value" : "", "dataUpdated" : ""});
                } else {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }
            else if(field.format === "tablemulti") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else if (Array.isArray(field.value)) {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }
            else if(field.format === "tablemultin") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = {"value" : "", "list" : []};
                } else {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }
            else if(field.format === "document") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = {"value" : "", "list" : []};
                } else {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }
            else if(field.format === "smailvalidmulti") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = {"value" : "", "list" : []};
                } else {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }
            else {
                if(Array.isArray(field.value) && field.value.length === 0){
                    newData["field" + field.id] = ""
                }else{
                    newData["field" + field.id] = field.value;
                }
            }

            if (field.format === "multi-select" || field.format === "free-multi-select" || field.format === "single-select" || field.format === "free-single-select") {
                newData["field" + field.id + "format"] = "select";
            }
            else if(field.format === "multi-table" || field.format === "free-multi-table" || field.format === "single-table" || field.format === "free-single-table"){
                newData["field" + field.id + "format"] = "table";
            }
            else {
                newData["field" + field.id + "format"] = field.format;
            }

            if (modelForHash && field.format === "indice") {
                let editable = JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.editable || false;
                if(!editable && field.value === "" || (editable && field.value === "")){
                    newData["field" + field.id] = (JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.racine || "") +  (JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.indice || 0);
                }
            }

            if(field.format && field.format === 'agenda' || field.format && field.format === 'birthday'){
                newData["field" + field.id + "options"] = field.event;
            }

            newData["field" + field.id + "label"] = field.title;

        });
        newData.Annexes = [];
        newData.lastversion_model = modelForHash ? modelForHash.version : null;
        newData.modid = modelForHash.id;
        newData.offline = modelForHash.offline;

        return newData;
    };

    const handleCreatePlugnoteIndice = () => {
        const modelForHash = getModel();
        let dataForHash = buildNewAnnexe(props.fields);

        trackPromise(
            axios.post(ApplicationConfig.apiUrl + 'datas.json' + qs.stringify({userhash: environment.userhash, modelhash: modelForHash.hash}, '?') +`&internal=${environment.userhash}`, dataForHash,
            {crossdomain: true,
                'headers': {
                    'X-AUTH-TOKEN': getToken(),
                }
            }).then((response) => {
                if(response.status === 200){
                    setPlugnote(response.data);
                    handleEditValue(response.data['field'+props.field.id], props.field, props.index);
                }
            }).catch((error) => {
                //console.log(error.message);
            })
        )
    };

    const handleCreatePlugnoteSharedIndice = () => {
        const modelForHash = getModel();
        let dataForHash = buildNewAnnexe(props.fields);

        trackPromise(
            axios.post(ApplicationConfig.apiUrl + 'datas.json' + qs.stringify({userhash: environment.userhash, modelhash: modelForHash.hash}, '?') +`&internal=${environment.userhash}`, dataForHash,
                {crossdomain: true,
                    'headers': {
                        'X-AUTH-TOKEN': getToken(),
                    }
                }).then((response) => {
                if(response.status === 200){
                    setPlugnote(response.data);
                    handleEditValue(response.data['field'+props.field.id], props.field, props.index);
                }
            }).catch((error) => {
                //console.log(error.message);
            })
        )
    }

    useEffect(() => {
        setValue(props.field.value);
    },[props.field.value])


    return (
        <div className={classes.memoContainer} key={"memo-"+props.index}>
            <TextField
                disabled={!editable}
                onChange={(event) => {
                    handleEditValue(event.target.value, props.field, props.index);
                }}
                id={'index-value-' + props.field.id}
                //label={field.title}
                style={{ margin: 0 }}
                multiline={false}
                placeholder={t('fields.format-choice-placeholder-'+props.field.visuelFormat.toLowerCase())}
                onFocus={(e) => e.target.placeholder = ''}
                onBlur={(e) => e.target.placeholder = t('fields.format-choice-placeholder-'+props.field.visuelFormat.toLowerCase())}
                helperText=""
                fullWidth
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
                className={classes.memoText}
                value={value}
                InputProps={{ disableUnderline: true }}
            />
            { (typeof value === 'undefined' || value == '') && typeof props.control !== "undefined" && props.control !== null && !disabled &&
                <div onClick={(event) => handleGenerateIndice()} className={`${classes.mailButton}`}>
                <span className={`material-icons md-small white`}>
                    plus_one
                </span>
                </div>
            }
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatIndiceComponent);
