import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {connect} from "react-redux";
import {getCurrentEnvironnement} from "../../utils/Common";
import TextField from "@material-ui/core/TextField";
import '@contentful/forma-36-react-components/dist/styles.css';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import {postPlugcodeGalleryRequest} from "../../actions/gallery";

function getModalStyle() {
    const top = 5;

    return {
        top: `${top}%`,
        margin:'auto',
        overflow: 'auto',
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    popup: {
        position: 'absolute',
        //width: '50%',
        //height : '50%',
        //backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        //boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        width: '820px',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    marginLeft:{
        marginLeft : '10px'
    }
}));


const ModalSavePlugcodeComponent = (props) => {

    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [t, i18n] = useTranslation('common');

    const [choice, setChoice] = useState("save");
    const [title, setTitle] = useState(t("common:plugcode.choice-save-plugcode-title"));
    const [comment, setComment] = useState("");
    const [buttonTitle, setButtonTitle] = useState(t("common:plugcode.choice-save-plugcode-button"));

    const model = props.plugform;

    const plugnote = props.plugnote;

    const environment = getCurrentEnvironnement();

    const handleCloseModal = () => {
        props.close();
    };

    const handleChangeTypeSelect = (value) => {
        setChoice(value);
    };

    const handleSavePlugcode = () => {
        let gallery = {
            plugcode : getCodePnPpCodeTemplate(),
            comment : comment,
        };
        props.attemptCreateGalleryRequest(gallery, props.user.profil.hash);
        props.close();
    };

    const getCodePnPpCodeTemplate = () => {
        const {hash, idOffline, userindex, creationdate, codepfcode, publicpncode, label,clef} = model;

        let codePF= 'PF';

        return codepfcode ? `${codePF}-${moment(creationdate).format('YYMMDD')}-${codepfcode}` : "";
    };


    useEffect(() => {
        setTitle(choice === "save" ?  t("common:plugcode.choice-save-plugcode-title") : t("common:plugcode.choice-copy-plugcode-title"));
        setButtonTitle(choice === "save" ?  t("common:plugcode.choice-save-plugcode-button") : t("common:plugcode.choice-copy-plugcode-button"));
    }, [choice]);

    return (
        <Modal
            keepMounted={false}
            open={props.open}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div key={props.countable} style={modalStyle} className={classes.popup}>
                <div className = "scrollbar-hidden popup_wrapper flexSpacebetween" >
                    <div className="popup_wrapper_content">
                        <div className="sous-header_modal_content">
                            <div>
                                <span className="titres">
                                    {title}
                                     <br/>
                                </span>
                                <span className="grey">
                                    [{model.clef}] {model.label} > {getCodePnPpCodeTemplate()}
                                </span>
                            </div>
                            <div onClick={handleCloseModal}>
                                    <span id="icon-close" className="material-icons md-50 lightgrey">
                                    close
                                    </span>
                            </div>

                        </div>
                        <div className="sous-header_content lightgrey">
                            <div className="line flex_style">
                                <div className={classes.selectType}>
                                    <Select
                                        disabled={true}
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={choice}
                                        onChange={(event) => handleChangeTypeSelect(event.target.value)}
                                    >
                                        <MenuItem value={'save'}>{t("common:plugcode.choice-save-plugcode")}</MenuItem>
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="popup_wrapper_content">
                        { choice !== "save" ?
                            <div>

                            </div>
                            :
                            <div>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Commentaire"
                                    multiline
                                    minRows={4}
                                    defaultValue={comment}
                                    variant="outlined"
                                    fullWidth={true}
                                    onChange={(event) => setComment(event.target.value)}
                                />
                            </div>
                        }
                        {
                            choice === 'save' &&
                                <div className="text_align_right">
                                    <button className="button" onClick={handleSavePlugcode}>{buttonTitle}</button>
                                </div>

                                    }

                    </div>
                </div>
            </div>
        </Modal>
    )
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        plugnote : state.plugcode.plugnote,
        plugform : state.plugcode.plugform,
        user : state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        attemptCreateGalleryRequest: (gallery, userhash) => dispatch(postPlugcodeGalleryRequest(gallery, userhash)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalSavePlugcodeComponent)