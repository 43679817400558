import {call, fork, put, takeEvery} from 'redux-saga/effects';
import * as actions from '../actions/share';
import * as modelActions from '../actions/model';
import * as api from '../api/shareApi';
import {trackPromise} from "react-promise-tracker";

const myGenericTrackedWrapper = (fn, args1, args2, args3) => trackPromise(fn(args1,args2, args3));


export function* getModelShares(action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.getModelShares, action.modelhash, action.corporate);

        if(response.statusText === 'OK'){
            yield put(actions.getModelSharesSuccess(response.data));

        }else{
            yield put(actions.getModelSharesFailure());

        }
    }catch(e) {
        yield put(actions.getModelSharesError());
    }
}

/**
 * Permet d'ajouter un partage à un modèle
 * @param api
 * @param action
 */
export function * postModelShares (action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.postModelShares, action.payload);

        if((response.data.result === "ok" || response.data.result_duplicate === "ok")){

            yield put(actions.postModelSharesSuccess(response.data.data));
            yield put(modelActions.putModelPostShareSuccess(action.model));
        }else{
            yield put(actions.postModelSharesFailure());

        }
    }catch(e) {
        yield put(actions.postModelSharesError());
    }

}

/**
 * Permet de modifier le partage d'un modèle
 * @param api
 * @param action
 */
export function * putModelShares (action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.putModelShares, action.payload);

        try {
            let data = JSON.parse(response.data);
            if((data.result === "ok")){
                yield put(actions.putModelSharesSuccess(action.payload));

            }else{
                yield put(actions.putModelSharesFailure());
            }
        } catch (err) {
            yield put(actions.putModelSharesError());
        }

    }catch(e) {
        yield put(actions.putModelSharesError());
    }
}

export function * deleteModelShare (action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.deleteModelShares, action.modelsharedhash);

        if((response.data.result === "ok" || response.data.result_duplicate === "ok")){
            yield put(actions.delModelShareSuccess(action.modelsharedhash));
            yield put(modelActions.putModelDeleteShareSuccess(action.model));
        }else{
            yield put(actions.delModelShareFailure());

        }
    }catch(e) {
        yield put(actions.delModelShareError());
    }
}

/**
 * Permet de supprimer le partage complet d'un modèle
 * @param api
 * @param action
 */
export function * deleteAllInviteModelShares (action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.deleteAllInviteModelShares, action.modelid, action.ownerid);

        if((response.data.result === "ok")){

            yield put(actions.delAllInviteModelSharesSuccess());

        }else{
            yield put(actions.delAllInviteModelSharesFailure());

        }
    }catch(e) {
        yield put(actions.delAllInviteModelSharesError());
    }
}

function* watchFetchField() {
    // create watcher of fetchData function
    yield takeEvery(actions.Types.GET_MODEL_SHARES_REQUEST, getModelShares);
    yield takeEvery(actions.Types.POST_MODEL_SHARES_REQUEST, postModelShares);
    yield takeEvery(actions.Types.PUT_MODEL_SHARES_REQUEST, putModelShares);
    yield takeEvery(actions.Types.DEL_ALL_INVITE_MODEL_SHARES_REQUEST, deleteAllInviteModelShares);
    yield takeEvery(actions.Types.DEL_MODEL_SHARE_REQUEST, deleteModelShare);
}

const ShareSagas = [
    fork(watchFetchField)
];

export default ShareSagas;
