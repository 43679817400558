import {call, fork, put, take, takeEvery} from 'redux-saga/effects';
import {createUploadFileChannel} from './createFileUploadChannel';

import * as actions from '../actions/annexe';
import * as dataActions from '../actions/data';
import ApplicationConfig from "../config/ApplicationConfig";

import qs from "querystringify";
import * as apiData from "../api/dataApi";
import {trackPromise} from "react-promise-tracker";
import {setAnnexes, setModalAnnexes, setModalPlugnote, setPlugnote} from "../utils/Common";
import * as api from "../api/annexeApi";
import * as apiExternal from "../api/externalApi";
import {getDataUniqueError} from "../actions/data";

const myGenericTrackedWrapper = (fn, args1, args2, args3,args4, args5, args6) => trackPromise(fn(args1,args2, args3,args4, args5, args6));

const promise1 = new Promise((resolve, reject) => {
    setTimeout(() => {
        resolve('foo');
    }, 300);
});

// Upload the specified file
function* uploadFileSaga(file: File, query) {
    const channel = yield call(createUploadFileChannel, ApplicationConfig.apiUrl + `annexes.json`+ qs.stringify(query, "?"), file);
    while (true) {
        const { progress = 0, err, success,response } = yield take(channel);
        if (err) {
            yield put(actions.postAnnexesFailure(file, err));
            return;
        }
        if (success) {
            if(typeof response.result !== 'undefined'  && response.result === 'nok'){
                yield put(actions.postAnnexesFailure(response.result));
            }else{
                yield put(actions.postAnnexesSuccess(response));
            }
            return;
        }
        yield put(actions.postAnnexesProgress(file, progress));
        trackPromise(promise1).then(r => console.log('plugnotes'));
    }
}

function* uploadFileSagaShared(file: File, query) {
    const channel = yield call(createUploadFileChannel, ApplicationConfig.apiUrl + `annexes.json`+ qs.stringify(query, "?"), file);
    while (true) {
        const { progress = 0, err, success,response } = yield take(channel);
        if (err) {
            yield put(actions.postAnnexesSharedFailure(file, err));
            return;
        }
        if (success) {
            if(typeof response.result !== 'undefined'  && response.result === 'nok'){
                yield put(actions.postAnnexesSharedFailure(response.result));
            }else{
                yield put(actions.postAnnexesSharedSuccess(response));
            }
            return;
        }
        yield put(actions.postAnnexesSharedProgress(file, progress));
    }
}

/**
 * Permet la suppression d'une annexe
 * @param api
 * @param action
 */
function* deleteAnnexe(action) {

    if(action.formdata !== null){
        try {
            const response = yield call(myGenericTrackedWrapper,apiData.putData,action.modelhash, action.userhash, action.formdata);

            if(response.status === 200){
                setPlugnote(response.data);
                yield put(dataActions.putUpdateDataSuccess(response.data));
                action.datahash = response.data.hash;
            }else{
                yield put(dataActions.putUpdateDataFailure());
            }
        }catch (e) {
            yield put(dataActions.putUpdateDataError());

        }
    }

    try {
        const response = yield call(myGenericTrackedWrapper,api.deleteAnnexe,action.userhash, action.annexehash);

        if(response.status === 200){
            yield put(actions.delDeleteAnnexeSuccess(action.annexehash));
        }else{
            yield put(actions.delDeleteAnnexeFailure());
        }
    }catch (e) {
        yield put(actions.delDeleteAnnexeError());

    }
}

/**
 * Permet la suppression d'une annexe
 * @param api
 * @param action
 */
function* deleteAnnexeModal(action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.deleteAnnexe,action.userhash, action.annexehash);

        if(response.status === 200){
            yield put(actions.delDeleteAnnexeModalSuccess(action.annexehash));
        }else{
            yield put(actions.delDeleteAnnexeModalFailure());
        }
    }catch (e) {
        yield put(actions.delDeleteAnnexeModalError());

    }
}

/**
 * Permet la suppression d'une annexe
 * @param api
 * @param action
 */
function* deleteAnnexeVersion(action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.deleteAnnexeVersion,action.userhash, action.annexehash, action.version);

        if(response.status === 200 && response.data.result === 'ok'){
            yield put(actions.delDeleteAnnexeVersionSuccess(response.data.data,action.version));
        }else{
            yield put(actions.delDeleteAnnexeVersionFailure());
        }
    }catch (e) {
        yield put(actions.delDeleteAnnexeVersionError());

    }
}

/**
 * Permet la suppression d'une annexe
 * @param api
 * @param action
 */
function* deleteAnnexeVersionModal(action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.deleteAnnexeVersion,action.userhash, action.annexehash, action.version);

        if(response.status === 200 && response.data.result === 'ok'){
            yield put(actions.delDeleteAnnexeVersionModalSuccess(response.data.data,action.version));
        }else{
            yield put(actions.delDeleteAnnexeVersionModalFailure());
        }
    }catch (e) {
        yield put(actions.delDeleteAnnexeVersionModalError());

    }
}

/**
 * Permet la mise à jour d'une annexe
 * @param api
 * @param action
 */
function* updateAnnexe(action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.updateAnnexe,action.annexehash, action.comment);

        if(response.status === 200){
            yield put(actions.putAnnexeCommentSuccess(action.annexehash, action.comment));
        }else{
            yield put(actions.putAnnexeCommentFailure());
        }
    }catch (e) {
        yield put(actions.putAnnexeCommentFailure());

    }
}/**
 * Permet la mise à jour d'une annexe
 * @param api
 * @param action
 */

function* updateAnnexeModal(action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.updateAnnexe,action.annexehash, action.comment);

        if(response.status === 200){
            yield put(actions.putAnnexeCommentModalSuccess(action.annexehash, action.comment));
        }else{
            yield put(actions.putAnnexeCommentModalFailure());
        }
    }catch (e) {
        yield put(actions.putAnnexeCommentModalFailure());

    }
}

export function* postAddNote(action) {

    try {
        if(action.newdata){
            try {
                const response = yield call(myGenericTrackedWrapper,apiData.postData,action.modelhash, action.userhash, action.formdata);

                if(response.status === 200){
                    yield put(dataActions.postCreateDataSuccess(response.data));
                    setPlugnote(response.data);
                    action.datahash = response.data.hash;
                }else{
                    yield put(dataActions.postCreateDataFailure());
                }
            }catch (e) {
                yield put(dataActions.postCreateDataError());

            }
        }

        try {
            const response = yield call(myGenericTrackedWrapper,api.postAddNote, action.userhash, action.modelhash, action.datahash, {name: action.name, note: action.note, comment: ''});

            if(response.statusText === "OK" && typeof response.data.modid !== "undefined"){
                yield put(actions.postAddNoteSuccess(response.data));
            }else{
                yield put(actions.postAddNoteFailure());
            }
        }catch (e) {
            yield put(actions.postAddNoteError());
        }

    }catch (e) {
        yield put(dataActions.postCreateDataError());
        yield put(actions.postAddNoteError());
    }
}

export function* postEditNote(action) {

    try {

        const response = yield call(myGenericTrackedWrapper,api.updateNote, action.annexehash, action.notehash, {content: action.note, annexename: action.name});

        if(response.statusText === "OK" && typeof response.data.dataid !== "undefined"){
            yield put(actions.putEditNoteSuccess(response.data));
        }else{
            yield put(actions.putEditNoteFailure());
        }
    }catch (e) {
        yield put(actions.putEditNoteError());
    }
}

export function* postAddNoteModal(action) {

    try {

        try {
            const response = yield call(myGenericTrackedWrapper,api.postAddNote, action.userhash, action.modelhash, action.datahash, {name: action.name, note: action.note, comment: ''});

            if(response.statusText === "OK" && typeof response.data.modid !== "undefined"){
                yield put(actions.postAddNoteModalSuccess(response.data));
            }else{
                yield put(actions.postAddNoteModalFailure());
            }
        }catch (e) {
            yield put(actions.postAddNoteModalError());
        }

    }catch (e) {
        yield put(actions.postAddNoteModalError());
    }
}

export function* postEditNoteModal(action) {

    try {

        const response = yield call(myGenericTrackedWrapper,api.updateNote, action.annexehash, action.notehash, {content: action.note, annexename: action.name});

        if(response.statusText === "OK" && typeof response.data.dataid !== "undefined"){
            yield put(actions.putEditNoteModalSuccess(response.data));
        }else{
            yield put(actions.putEditNoteModalFailure());
        }
    }catch (e) {
        yield put(actions.putEditNoteModalError());
    }
}

export function* getAnnexesVersion(action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.getAnnexesVersion, action.annexehash);

        if(response.statusText === "OK" && response.data.result === 'ok'){
            yield put(actions.getAnnexesVersionSuccess(response.data.data));
        }else{
            yield put(actions.getAnnexesVersionFailure());
        }
    }catch (e) {
        yield put(actions.getAnnexesVersionError());
    }
}

export function* getAnnexesVersionModal(action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.getAnnexesVersion, action.annexehash);

        if(response.statusText === "OK" && response.data.result === 'ok'){
            yield put(actions.getAnnexesVersionModalSuccess(response.data.data));
        }else{
            yield put(actions.getAnnexesVersionModalFailure());
        }
    }catch (e) {
        yield put(actions.getAnnexesVersionModalError());
    }
}

export function* postAnnexesVersion(action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.postAnnexesVersion, action.modelhash, action.userhash, action.datahash, action.annexehash,action.modelshared,action.formfile);

        if(response.statusText === "OK" && response.data.result === 'ok'){
            yield put(actions.postAnnexesVersionSuccess(response.data.data));
        }else{
            yield put(actions.postAnnexesVersionFailure());
        }
    }catch (e) {
        yield put(actions.postAnnexesVersionError());
    }
}

export function* postAnnexesVersionModal(action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.postAnnexesVersion, action.modelhash, action.userhash, action.datahash, action.annexehash,action.modelshared,action.formfile);

        if(response.statusText === "OK" && response.data.result === 'ok'){
            yield put(actions.postAnnexesVersionModalSuccess(response.data.data));
        }else{
            yield put(actions.postAnnexesVersionModalFailure());
        }
    }catch (e) {
        yield put(actions.postAnnexesVersionModalError());
    }
}

export function* postAnnexesVersionShared(action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.postAnnexesVersion, action.modelhash, action.userhash, action.datahash, action.annexehash,action.modelshared,action.formfile);

        if(response.statusText === "OK" && response.data.result === 'ok'){
            yield put(actions.postAnnexesVersionSharedSuccess(response.data.data));
        }else{
            yield put(actions.postAnnexesVersionSharedFailure());
        }
    }catch (e) {
        yield put(actions.postAnnexesVersionSharedError());
    }
}

export function* postAnnexesVersionSharedModal(action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.postAnnexesVersion, action.modelhash, action.userhash, action.datahash, action.annexehash,action.modelshared,action.formfile);

        if(response.statusText === "OK" && response.data.result === 'ok'){
            yield put(actions.postAnnexesVersionSharedModalSuccess(response.data.data));
        }else{
            yield put(actions.postAnnexesVersionSharedModalFailure());
        }
    }catch (e) {
        yield put(actions.postAnnexesVersionSharedModalError());
    }
}

export function* postAnnexes(action) {

    if(action.newdata){
        try {
            const response = yield call(myGenericTrackedWrapper,apiData.postData,action.modelhash, action.userhash, action.formdata);

            if(response.status === 200){
                setPlugnote(response.data);
                yield put(dataActions.postCreateDataSuccess(response.data));
                action.datahash = response.data.hash;
            }else{
                yield put(dataActions.postCreateDataFailure());
            }
        }catch (e) {
            yield put(dataActions.postCreateDataError());

        }
    }
    else if(!action.newdata && action.formdata !== null){
        try {
            const response = yield call(myGenericTrackedWrapper,apiData.putData,action.modelhash, action.userhash, action.formdata);

            if(response.status === 200){
                setPlugnote(response.data);
                yield put(dataActions.putUpdateDataSuccess(response.data));
                action.datahash = response.data.hash;
            }else{
                yield put(dataActions.putUpdateDataFailure());
            }
        }catch (e) {
            yield put(dataActions.putUpdateDataError());

        }
    }

    try {
        const response = yield call(myGenericTrackedWrapper,api.postAnnexes, action.modelhash, action.userhash, action.datahash,action.modelshared,action.formfile);
        if(response.statusText === "OK" && typeof response.data.result === 'undefined'){
            if(response.data.type === 'html'){
                let responseData = yield call(myGenericTrackedWrapper,apiData.getDataUnique,action.datahash);
                if (response.statusText == "OK" && responseData.data && responseData.data.id) {
                    const currentPlugnote = responseData.data;
                    setPlugnote(currentPlugnote);
                    setAnnexes(currentPlugnote.Annexes);
                    yield put(dataActions.getDataUniqueSuccess(currentPlugnote));
                }
            }
            yield put(actions.postAnnexesSuccess(response.data));
        }else{
            yield put(actions.postAnnexesFailure());
        }
    }catch (e) {
        yield put(actions.postAnnexesError());
    }
}

export function* postAnnexesModal(action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.postAnnexes, action.modelhash, action.userhash, action.datahash,action.modelshared,action.formfile);
        if(response.statusText === "OK" && typeof response.data.result === 'undefined'){
            if(response.data.type === 'html'){
                let responseData = yield call(myGenericTrackedWrapper,apiData.getDataUnique,action.datahash);
                if (response.statusText == "OK" && responseData.data && responseData.data.id) {
                    const currentPlugnote = responseData.data;
                    setModalPlugnote(currentPlugnote);
                    setModalAnnexes(currentPlugnote.Annexes);
                    yield put(dataActions.getDataUniqueSuccess(currentPlugnote));
                }
            }
            yield put(actions.postAnnexesModalSuccess(response.data));
        }else{
            yield put(actions.postAnnexesModalFailure());
        }
    }catch (e) {
        yield put(actions.postAnnexesModalError());
    }
}

export function* postAnnexesShared(action) {

    if(action.newdata){
        try {
            const response = yield call(myGenericTrackedWrapper,apiData.postData,action.modelhash, action.userhash, action.formdata);

            if(response.status === 200){
                setPlugnote(response.data);
                yield put(dataActions.postCreateSharedDataSuccess(response.data));
                action.datahash = response.data.hash;
            }else{
                yield put(dataActions.postCreateSharedDataFailure());
            }
        }catch (e) {
            yield put(dataActions.postCreateSharedDataError());

        }
    }else if(!action.newdata && action.formdata !== null){
        try {
            const response = yield call(myGenericTrackedWrapper,apiData.putData,action.modelhash, action.userhash, action.formdata);

            if(response.status === 200){
                setPlugnote(response.data);
                yield put(dataActions.putUpdateSharedDataSuccess(response.data));
                action.datahash = response.data.hash;
            }else{
                yield put(dataActions.putUpdateSharedDataFailure());
            }
        }catch (e) {
            yield put(dataActions.putUpdateSharedDataError());

        }
    }

    try {
        const response = yield call(myGenericTrackedWrapper,api.postAnnexes, action.modelhash, action.userhash, action.datahash,action.modelshared,action.formfile);
        if(response.statusText === "OK" && typeof response.data.result === 'undefined'){
            if(response.data.type === 'html'){
                let responseData = yield call(myGenericTrackedWrapper,apiData.getDataUnique,action.datahash);
                if (response.statusText == "OK" && responseData.data && responseData.data.id) {
                    const currentPlugnote = responseData.data;
                    setPlugnote(currentPlugnote);
                    setAnnexes(currentPlugnote.Annexes);
                    yield put(dataActions.getDataUniqueSuccess(currentPlugnote));
                }
            }
            yield put(actions.postAnnexesSharedSuccess(response.data));
        }else{
            yield put(actions.postAnnexesSharedFailure());
        }
    }catch (e) {
        yield put(actions.postAnnexesSharedError());
    }
}

export function* postAnnexesSharedModal(action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.postAnnexes, action.modelhash, action.userhash, action.datahash,action.modelshared,action.formfile);
        if(response.statusText === "OK" && typeof response.data.result === 'undefined'){
            if(response.data.type === 'html'){
                let responseData = yield call(myGenericTrackedWrapper,apiData.getDataUnique,action.datahash);
                if (response.statusText == "OK" && responseData.data && responseData.data.id) {
                    const currentPlugnote = responseData.data;
                    setModalPlugnote(currentPlugnote);
                    setModalAnnexes(currentPlugnote.Annexes);
                    yield put(dataActions.getDataUniqueSuccess(currentPlugnote));
                }
            }
            yield put(actions.postAnnexesSharedModalSuccess(response.data));
        }else{
            yield put(actions.postAnnexesSharedModalFailure());
        }
    }catch (e) {
        yield put(actions.postAnnexesSharedModalError());
    }
}

export function* sendMail(action) {


    try {
        if(action.newData){
            try {
                const response = yield call(myGenericTrackedWrapper,apiData.postData,action.modelhash, action.userhash, action.formData);

                if(response.status === 200){
                    yield put(dataActions.postCreateDataSuccess(response.data));
                    setPlugnote(response.data);
                    action.datahash = response.data.hash;
                    action.dataUserIndex = response.data.userindex;
                }else{
                    yield put(dataActions.postCreateDataFailure());
                }
            }catch (e) {
                yield put(dataActions.postCreateDataError());

            }
        }

        const userHash = action.userhash;
        const sendMailData = action.sendMailData;
        const attachments = action.attachments;
        const ficheNum = action.dataUserIndex;

        let formData = [];
        if (attachments && attachments.length) {
            attachments.map((file) => {
                const comment = !!file.comment ? file.comment : '';
                formData.push({
                    name: 'file[]',
                    filename: file.name,
                    comment : comment,
                    data: file.data
                })
            });
        }

        let newFormData = new FormData();

        if(formData.length > 0){
            formData.map((item, index) => {
                newFormData.append('file[]', item.data, item.filename);
            })
        }

        newFormData.append('content', JSON.stringify({
            mail_ficheNum: ficheNum,
            mail_cc: sendMailData.cc || '',
            mail_cci: sendMailData.cci || '',
            mail_sendTo: sendMailData.to || '',
            mail_subject: sendMailData.subject || '',
            mail_body: sendMailData.body || '',
            userhash: userHash
        }));

        try {
            const response = yield call(myGenericTrackedWrapper,apiExternal.postSendMails,newFormData);

            if(response.status === 200 && response.data.result === 'ok'){
                let responseData = yield call(myGenericTrackedWrapper,apiData.getDataUnique,action.datahash);
                if (response.statusText == "OK" && responseData.data && responseData.data.id) {
                    const currentPlugnote = responseData.data;
                    setPlugnote(currentPlugnote);
                    setAnnexes(currentPlugnote.Annexes);
                    yield put(dataActions.getDataUniqueSuccess(currentPlugnote));
                }
                yield put(actions.postAnnexesSendMailSuccess(response.data.data[0]));
            }else{
                yield put(actions.postAnnexesSendMailFailure());
            }

        }catch (e){
            yield put(actions.postAnnexesSendMailError());
        }

    }catch (e){
        yield put(actions.postAnnexesSendMailError());
    }

}

export function* sendMailModal(action) {


    try {

        const userHash = action.userhash;
        const sendMailData = action.sendMailData;
        const attachments = action.attachments;
        const ficheNum = action.dataUserIndex;

        let formData = [];
        if (attachments && attachments.length) {
            attachments.map((file) => {
                const comment = !!file.comment ? file.comment : '';
                formData.push({
                    name: 'file[]',
                    filename: file.name,
                    comment : comment,
                    data: file.data
                })
            });
        }

        let newFormData = new FormData();

        if(formData.length > 0){
            formData.map((item, index) => {
                newFormData.append('file[]', item.data, item.filename);
            })
        }

        newFormData.append('content', JSON.stringify({
            mail_ficheNum: ficheNum,
            mail_cc: sendMailData.cc || '',
            mail_cci: sendMailData.cci || '',
            mail_sendTo: sendMailData.to || '',
            mail_subject: sendMailData.subject || '',
            mail_body: sendMailData.body || '',
            userhash: userHash
        }));

        try {
            const response = yield call(myGenericTrackedWrapper,apiExternal.postSendMails,newFormData);

            if(response.status === 200 && response.data.result === 'ok'){
                let responseData = yield call(myGenericTrackedWrapper,apiData.getDataUnique,action.datahash);
                if (response.statusText == "OK" && responseData.data && responseData.data.id) {
                    const currentPlugnote = responseData.data;
                    setModalPlugnote(currentPlugnote);
                    setModalAnnexes(currentPlugnote.Annexes);
                    yield put(dataActions.getDataUniqueSuccess(currentPlugnote));
                }
                yield put(actions.postAnnexesSendMailModalSuccess(response.data.data[0]));
            }else{
                yield put(actions.postAnnexesSendMailModalFailure());
            }

        }catch (e){
            yield put(actions.postAnnexesSendMailModalError());
        }

    }catch (e){
        yield put(actions.postAnnexesSendMailModalError());
    }

}


// Watch for an upload request and then
// defer to another saga to perform the actual upload
function* uploadRequestWatcherSaga() {
    yield takeEvery(actions.Types.POST_ANNEXES_REQUEST, postAnnexes);
    yield takeEvery(actions.Types.POST_ANNEXES_MODAL_REQUEST, postAnnexesModal);
    yield takeEvery(actions.Types.POST_ANNEXES_SHARED_REQUEST, postAnnexesShared);
    yield takeEvery(actions.Types.POST_ANNEXES_SHARED_MODAL_REQUEST, postAnnexesSharedModal);
    yield takeEvery(actions.Types.DEL_DELETE_ANNEXE_REQUEST, deleteAnnexe);
    yield takeEvery(actions.Types.DEL_DELETE_ANNEXE_MODAL_REQUEST, deleteAnnexeModal);
    yield takeEvery(actions.Types.DEL_DELETE_ANNEXE_VERSION_REQUEST, deleteAnnexeVersion);
    yield takeEvery(actions.Types.DEL_DELETE_ANNEXE_VERSION_MODAL_REQUEST, deleteAnnexeVersionModal);
    yield takeEvery(actions.Types.PUT_ANNEXE_COMMENT_REQUEST, updateAnnexe);
    yield takeEvery(actions.Types.PUT_ANNEXE_COMMENT_MODAL_REQUEST, updateAnnexeModal);
    yield takeEvery(actions.Types.POST_ADD_NOTE_REQUEST, postAddNote);
    yield takeEvery(actions.Types.POST_ADD_NOTE_MODAL_REQUEST, postAddNoteModal);
    yield takeEvery(actions.Types.POST_ANNEXES_SEND_MAIL_REQUEST, sendMail);
    yield takeEvery(actions.Types.POST_ANNEXES_SEND_MAIL_MODAL_REQUEST, sendMailModal);
    yield takeEvery(actions.Types.PUT_EDIT_NOTE_REQUEST, postEditNote);
    yield takeEvery(actions.Types.PUT_EDIT_NOTE_MODAL_REQUEST, postEditNoteModal);
    yield takeEvery(actions.Types.GET_ANNEXES_VERSION_REQUEST, getAnnexesVersion);
    yield takeEvery(actions.Types.GET_ANNEXES_VERSION_MODAL_REQUEST, getAnnexesVersionModal);
    yield takeEvery(actions.Types.POST_ANNEXES_VERSION_REQUEST, postAnnexesVersion);
    yield takeEvery(actions.Types.POST_ANNEXES_VERSION_MODAL_REQUEST, postAnnexesVersionModal);
    yield takeEvery(actions.Types.POST_ANNEXES_VERSION_SHARED_REQUEST, postAnnexesVersionShared);
    yield takeEvery(actions.Types.POST_ANNEXES_VERSION_SHARED_MODAL_REQUEST, postAnnexesVersionSharedModal);
    /*
    yield takeEvery(actions.Types.POST_ANNEXES_REQUEST, function*(action) {

        if(action.newdata){
            try {
                const response = yield call(myGenericTrackedWrapper,apiData.postData,action.modelhash, action.userhash, action.formdata);

                if(response.status === 200){
                    yield put(dataActions.postCreateDataSuccess(response.data));
                    setPlugnote(response.data);
                    action.datahash = response.data.hash;
                }else{
                    yield put(dataActions.postCreateDataFailure());
                }
            }catch (e) {
                yield put(dataActions.postCreateDataError());

            }
        }

        const file = action.formfile;
        const query = {userhash : action.userhash, modelhash : action.modelhash, datahash : action.datahash, modelshared : action.modelshared};

        yield call(uploadFileSaga, file, query);
    });
*/
    /*
    yield takeEvery(actions.Types.POST_ANNEXES_SHARED_REQUEST, function*(action) {

        if(action.newdata){
            try {
                const response = yield call(myGenericTrackedWrapper,apiData.postData,action.modelhash, action.userhash, action.formdata);

                if(response.status === 200){
                    yield put(dataActions.postCreateSharedDataSuccess(response.data));
                    setPlugnote(response.data);
                    action.datahash = response.data.hash;
                }else{
                    yield put(dataActions.postCreateSharedDataFailure());
                }
            }catch (e) {
                yield put(dataActions.postCreateSharedDataError());

            }
        }

        const file = action.formfile;
        const query = {userhash : action.userhash, modelhash : action.modelhash, datahash : action.datahash, modelshared : true};

        yield call(uploadFileSagaShared, file, query);
    });
*/
}


const AnnexeSagas = [
    fork(uploadRequestWatcherSaga)
];

export default AnnexeSagas;
