import React, {useEffect, useLayoutEffect, useState} from 'react';
import _ from 'lodash';
import {connect} from "react-redux";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import UserIcon from "@material-ui/icons/PeopleAlt";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import {Content, Header} from "rsuite";
import {getCurrentEnvironnement} from "../../utils/Common";
import {isAlmostCompatible, isCompatible, isCompatibleItself} from "../../utils/Compatibility";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Colors from "../../assets/themes/Colors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FaAngleDoubleLeft, FaAngleLeft} from 'react-icons/fa';
import {MdClose} from "react-icons/md";
import {confirmAlert} from "react-confirm-alert";
import {postClassifyInAnotherPlugformMoveRequest, postClassifyInAnotherPlugformRequest} from "../../actions/plugcode";
import ApplicationConfig from "../../config/ApplicationConfig";
import {
    faFileArchive,
    faFileAudio,
    faFileCode,
    faFileExcel,
    faFilePdf,
    faFilePowerpoint,
    faFileVideo,
    faFileWord,
    faStickyNote
} from "@fortawesome/free-regular-svg-icons";
import Tooltip from "@material-ui/core/Tooltip";
import {isJsonString} from "../../utils/utils";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor : 'white',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        padding: '15px 0px 0px 30px'
    },
    paperAnchorDockedLeft : {
        boxShadow: '0px 0px 6px 4px rgba(136, 136, 136, 0.12)',
        border : 0,
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: '70px'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    openClose: {
        bottom: 0,
        // position: 'absolute',
        width : '100%'
    },
    openCloseButton : {
        float : 'right'
    },
    content: {
        width : `calc(100% - ${drawerWidth}px)`,
        backgroundColor : 'white',
        padding: '64px 20px',
    },
    contentFull: {
        width : '100%',
        backgroundColor : 'white',
        padding: '64px 20px',
    },
    contentModelGrid : {
        //flexGrow: 1,
        //padding: theme.spacing(3),
        display: 'flex',
        backgroundColor : 'white',
        flexWrap : 'wrap',
        justifyContent: 'space-evenly',
        columnGap: '20px',
        maxHeight: '85vh',
    },
    contentModelList : {
        //flexGrow: 1,
        //padding: theme.spacing(3),
        backgroundColor : 'white',
        flexWrap : 'wrap',
        justifyContent: 'space-evenly',
        columnGap: '20px',
        maxHeight: '85vh',
    },
    icon:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : '#DADADA'
    },
    iconActive:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : '#58C3C1'
    },
    gridHeader : {
        padding: '10px 20px !important',
        width: '100%',
    },
    selectEnvironnement : {
    },
    iconSelect : {
        color : '#58C3C1 !important'
    },
    pointer:{
        cursor: 'pointer'
    },
    transferContainerNotCompatible: {
        color: Colors.redDark,
    },
    transferContainerNotTriggered: {
        color: Colors.redDark,
    },
    transferBackGroundColorNotCompatible: {
        color: Colors.redDark,
    },
    transferText: {
        fontSize: 16
    },
    transferReceiverContainer: {
        color: Colors.greenLighter,
    },
    transferReceiverBackGroundColor: {
        color: Colors.mainLight,
    },
    receiverText: {
        color: Colors.greenLighter,
        fontWeight: 'bold'
    },
    transferReceiverContainerOrange: {
        color: Colors.bloodOrange,
    },
    transferReceiverBackGroundColorOrange: {
        color: Colors.bloodOrange,
    },
    receiverTextOrange: {
        color: Colors.bloodOrange,
        fontWeight: 'bold'
    },
    notCompatibleText: {
        color: Colors.redDark,
        fontWeight: 'bold'
    },
    compatibleItselfIconText: {
        color: Colors.greenLight
    },
    notCompatibleIconText: {
        color: Colors.red
    },
    almostCompatibleIconText: {
        color: Colors.bloodOrange
    },
    receiverCompatibleIconText: {
        color: Colors.greenLight
    },
    listHeight:{
        height: '100%'
    }
}));

function PlugnoteCopyMove(props) {


    const classes = useStyles();
    const { t, i18n } = useTranslation(['common', 'models']);

    const theme = useTheme();
    const [open, setOpen] = React.useState(true);

    const [confirmedFields, setConfirmedFields] = React.useState([]);
    const [receiverFields, setReceiverFields] = React.useState([]);
    const [selectedAnnexes, setSelectedAnnexes] = React.useState(!!props.plugnote.Annexes ? props.plugnote.Annexes : []);
    const [fields, setFields] = React.useState([]);

    const [listView, setListView] = useState(false);

    const environment = getCurrentEnvironnement();

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        //padding: grid * 2,
        //margin: `0 0 ${grid}px 0`,

        // change background colour if dragging
        background: isDragging ? "lightgreen" : "grey",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            receiverFields,
            result.source.index,
            result.destination.index
        );

        setReceiverFields(items);
    }

    const onDragEndFrom = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            fields,
            result.source.index,
            result.destination.index
        );

        setFields(items);
    }

    const transfertFields = () => {

        let tmpEmeterFields = fields;
        let tmpReceiverFields = receiverFields;
        let tmpConfirmedFields = [];

        tmpEmeterFields.map((emeterField, indexEmeterField) => {
            tmpReceiverFields.map((receiverField, indexReceiverField) => {
                if(indexEmeterField === indexReceiverField){
                    if(!!receiverField.oldLabel){
                        receiverField.label = receiverField.oldLabel;
                        delete receiverField['oldLabel'];
                    }
                    if(tmpEmeterFields[indexEmeterField] && tmpReceiverFields[indexReceiverField]) {
                        let compatible = isCompatible(tmpReceiverFields[indexReceiverField] ,tmpEmeterFields[indexEmeterField]);
                        let almostCompatible = isAlmostCompatible(tmpReceiverFields[indexReceiverField] ,tmpEmeterFields[indexEmeterField]);
                        let searchId = tmpEmeterFields[indexEmeterField].id;
                        const findIndex = _.findIndex(tmpConfirmedFields, (v) => v.from === searchId);
                        if((!compatible && almostCompatible) || (compatible && almostCompatible) || (compatible && !almostCompatible)){
                            if(!!findIndex){
                                let tmpConfirmedField = tmpReceiverFields[indexReceiverField];
                                tmpConfirmedField.label = !!tmpReceiverFields[indexReceiverField].oldLabel ? tmpReceiverFields[indexReceiverField].oldLabel : tmpReceiverFields[indexReceiverField].label;
                                tmpConfirmedField.value = tmpEmeterFields[indexEmeterField].value;
                                tmpConfirmedField.from = tmpEmeterFields[indexEmeterField].id;
                                tmpConfirmedFields.push(tmpConfirmedField);
                            }
                        }
                    }
                }
                return receiverField;
            });
        });

        setReceiverFields(tmpReceiverFields);
        setConfirmedFields(tmpConfirmedFields);
    }

    const compatibleField = (index) => {
        let emeterField = fields[index];
        let receiverField = receiverFields[index];

        if(emeterField && receiverField) {

            let compatible = isCompatible(receiverField ,emeterField);
            let almostCompatible = isAlmostCompatible(receiverField ,emeterField);

            if(!compatible && !almostCompatible && confirmedFields.find(confirmedField => confirmedField.value === receiverField.value && confirmedField.id === receiverField.id)){
                return classes.transferContainerNotCompatible;
            }else if(!compatible && almostCompatible && confirmedFields.find(confirmedField => confirmedField.value === receiverField.value && confirmedField.id === receiverField.id)){
                return classes.transferReceiverContainerOrange;
            }else if(compatible && almostCompatible && confirmedFields.find(confirmedField => confirmedField.value === receiverField.value && confirmedField.id === receiverField.id)){
                return classes.transferReceiverContainerOrange;
            }else if(compatible && !almostCompatible && confirmedFields.find(confirmedField => confirmedField.value === receiverField.value && confirmedField.id === receiverField.id)){
                return classes.transferReceiverContainer;
            }

        }

        return classes.transferContainerNotTriggered;
    }

    const compatibleFieldText = (index) => {
        let tmpEmeterField = fields[index];
        let tmpReceiverField = receiverFields[index];

        if(tmpEmeterField && tmpReceiverField) {

            if(tmpReceiverField.format !== '' && tmpEmeterField.format !== ''){
                let compatible = isCompatible(tmpReceiverField ,tmpEmeterField);
                let almostCompatible = isAlmostCompatible(tmpReceiverField ,tmpEmeterField);

                if(!compatible && !almostCompatible && confirmedFields.find(confirmedField => confirmedField.value === tmpReceiverField.value && confirmedField.id === tmpReceiverField.id)){
                    return 'X';
                }else if(!compatible && almostCompatible && confirmedFields.find(confirmedField => confirmedField.value === tmpReceiverField.value && confirmedField.id === tmpReceiverField.id)){
                    return 'OK?';
                }else if(compatible && almostCompatible && confirmedFields.find(confirmedField => confirmedField.value === tmpReceiverField.value && confirmedField.id === tmpReceiverField.id)){
                    return 'OK?';
                }else if(compatible && !almostCompatible && confirmedFields.find(confirmedField => confirmedField.value === tmpReceiverField.value && confirmedField.id === tmpReceiverField.id)){
                    return 'OK';
                }else{
                    return 'X'
                }
            }else{
                return '';
            }
        }

        return '';
    }

    const compatibleFieldsText = () => {
        let tmpEmeterFields = fields;
        let tmpReceiverFields = receiverFields;

        let countOK = 0;
        let countOKM = 0;
        let countNotOK = 0;


        tmpEmeterFields.map((emeterField, indexEmeterField) => {
            tmpReceiverFields.map((receiverField, indexReceiverField) => {
                if(indexEmeterField === indexReceiverField && receiverField.format !== '' && emeterField.format !== '' && emeterField.exclude === false){
                    if(tmpEmeterFields[indexEmeterField] && tmpReceiverFields[indexReceiverField]) {
                        let compatible = isCompatibleItself(tmpReceiverFields[indexReceiverField] ,tmpEmeterFields[indexEmeterField]);
                        let almostCompatible = isAlmostCompatible(tmpReceiverFields[indexReceiverField] ,tmpEmeterFields[indexEmeterField]);
                        if(compatible){
                            countOK++;
                        }else if(almostCompatible){
                            countOKM++;

                        }else{
                            countNotOK++
                        }
                    }
                }
            });
        });


        return {
            'OK' : countOK,
            'OK?' : countOKM,
            'X' : countNotOK
        };
    }

    const countFieldsText = () => {
        let tmpEmeterFields = fields;
        let tmpReceiverFields = receiverFields;

        let countOK = 0;
        let countOKM = 0;
        let countNotOK = 0;
        let countTotal = 0;


        tmpEmeterFields.map((emeterField, indexEmeterField) => {
            tmpReceiverFields.map((receiverField, indexReceiverField) => {
                if(indexEmeterField === indexReceiverField && receiverField.format !== '' && emeterField.format !== '' && emeterField.exclude === false){
                    if(tmpEmeterFields[indexEmeterField] && receiverFields[indexReceiverField]) {
                        let compatible = isCompatibleItself(receiverFields[indexReceiverField] ,tmpEmeterFields[indexEmeterField]);
                        let almostCompatible = isAlmostCompatible(receiverFields[indexReceiverField] ,tmpEmeterFields[indexEmeterField]);
                        if(compatible){
                            countOK++;
                        }else if(almostCompatible){
                            countOKM++;

                        }else{
                            countNotOK++
                        }
                        countTotal++;
                    }
                }
            });
        });


        return {
            'OK' : countOK+countOKM,
            'TOTAL' : countTotal,
        };
    }

    const renderCompatibleIcon = (field, index) => {

        //event.preventDefault();
        //event.stopPropagation();

        let itemFieldToTransfert = field;
        let itemFieldToReceive = receiverFields[index];
        const _isCompatibleItself = isCompatibleItself(itemFieldToReceive, itemFieldToTransfert);
        const _isAlmostCompatible = isAlmostCompatible(itemFieldToReceive, itemFieldToTransfert);
        const isCompatible = _isCompatibleItself;

        let backgroundIconStyle = Colors.redDark;

        if(_isAlmostCompatible){
            backgroundIconStyle = Colors.bloodOrange
        }else if (_isCompatibleItself) {
            backgroundIconStyle = Colors.greenLight
        }

        if(_isAlmostCompatible || _isCompatibleItself){

            if(_isAlmostCompatible){
                return <FaAngleLeft color={backgroundIconStyle}/>
            }else{
                    return <FaAngleDoubleLeft color={backgroundIconStyle}/>
            }
        }else {
            return  <MdClose color={backgroundIconStyle}/>
        }
    };


    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "lightblue" : "white",
        //padding: grid,
    });

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleExitPlugnote = () => {
        props.history.push("/models");
    };

    const handleExcludedField = (field, index) => {
        let emeterField = Object.assign({}, fields[index]);
        if(emeterField) {
            let searchId = emeterField.id;

            let tmpFields =  Object.assign([], fields);
            let tmpReceiverFields =  Object.assign([], receiverFields);
            //let selectSearch = prevState.selectSearch;
            const findIndex = _.findIndex(tmpFields, (v) => v.id === searchId);
            //if(findIndex){
            //remplacer le champs à excluse par un vide et copier celui-ci quelques part.

            tmpFields[findIndex].value = '';
            tmpFields[findIndex].format = '';
            tmpFields[findIndex].options = '';
            tmpFields[findIndex].label = '';

            let index = tmpFields.length > 0 ? tmpFields[tmpFields.length - 1].id : 0;

            tmpFields.push({
                dragid : 'item-' + index + 1,
                value :emeterField.value,
                format : emeterField.format,
                options : emeterField.options,
                label : emeterField.label,
                exclude : true,
                id : index+1
            });

            tmpReceiverFields.push({
                dragid : 'item-' + index + 1,
                value :'',
                format : '',
                options : '',
                label : '',
                exclude : true,
                id : index+1
            });

            //}

            setFields(tmpFields);
            setReceiverFields(tmpReceiverFields);

            transfertFields();

        }
    }

    const handleIncludeField = (field, index) => {
        let emeterField = Object.assign({}, fields[index]);
        if(emeterField) {
            let searchId = emeterField.id;

            let tmpFields =  Object.assign([], fields);
            let tmpReceiverFields =  Object.assign([], receiverFields);
            let tmpConfirmedFields =  Object.assign([], confirmedFields);
            //let selectSearch = prevState.selectSearch;
            const findIndex = _.findIndex(tmpFields, (v) => v.value == '' && v.format == '' && v.label == '');

            //if(findIndex){
            //remplacer le champs à excluse par un vide et copier celui-ci quelques part.
            tmpReceiverFields.splice(index, 1);
            tmpFields.splice(index, 1);

            tmpFields[findIndex].value = emeterField.value;
            tmpFields[findIndex].format = emeterField.format;
            tmpFields[findIndex].options = emeterField.options;
            tmpFields[findIndex].label = emeterField.label;
            tmpFields[findIndex].exclude = false;
            //}

            setFields(tmpFields);
            setConfirmedFields(tmpConfirmedFields);
            setReceiverFields(tmpReceiverFields);

            transfertFields()

        }
    }

    const handlePressValidateTransferFieldsMove = (received, noreceived) => {

        let total =  countFieldsText();

        let params = {
            received: total.OK,
            noreceived: total.TOTAL
        }

        let selectedModel = props.model;
        let modelFromPlugcode = props.plugform;
        let dataFromPlugcode = props.plugnote;


        let copyReceiverFields = [...receiverFields];
        copyReceiverFields = JSON.parse(JSON.stringify(copyReceiverFields));
        let copyConfirmedFields = [...confirmedFields];
        copyConfirmedFields = JSON.parse(JSON.stringify(copyConfirmedFields));

        let editLabel = false;

        let finalFields = copyReceiverFields.map((item, index) => {
            let find = copyConfirmedFields.find(confirmedField => confirmedField.id === item.id);
            if(find){
                if(!!item.oldLabel){
                    editLabel = true;
                }
            }
            if(!!item.format){
                if(item.format !== 'list' && !item.format.includes('select') && !item.format.includes('table') && item.format !== 'plugform'){
                    item.value = find ? find.value : '';
                    return item;
                }
                else{
                    if(item.format === 'list'){
                        if(find){
                            let value = [];
                            let listvalue = find.value.split(',');
                            listvalue.forEach((fieldListValue, fieldListIndex) => {
                                value.push({"text" : fieldListValue});
                            });
                            item.value = value;
                        }else{
                            item.value = [];
                        }

                    }else if(item.format === 'plugform'){
                        let tmp = JSON.stringify({"value" : item.value, "index" : "", "name" : ""})
                        item.value = tmp
                    } else if(item.format.includes('select')){
                        item.format = "select";
                        if(find){
                            let value = [];
                            let listvalue = find.value.split(',');
                            listvalue.forEach((fieldListValue, fieldListIndex) => {
                                value.push(fieldListValue);
                            });
                            item.value = value;
                        }else{
                            item.value = [];
                        }
                    } else if(item.format.includes('table')){
                        item.format = "table";
                        if(find){
                            let value = [];
                            let listvalue = find.value.split(',');
                            listvalue.forEach((fieldListValue, fieldListIndex) => {
                                let split = fieldListValue.split(':');
                                value.push({"text" : split[0] , "value" : Number(split[1])});
                            });
                            item.value = value;
                        }else{
                            item.value = [];
                        }
                    }
                    return item;
                }
            }
        });

        finalFields = finalFields.filter(Boolean);

        confirmAlert({
            title: t('global.confirm'),
            message: t('models.model-confirm-move'),
            buttons: [
                {
                    label: t('global.yes'),
                    onClick: () => props.attemptClassifyInAnotherPlugformMove(dataFromPlugcode, selectedModel.hash, selectedAnnexes, finalFields, editLabel, environment.userhash, true)
                },
                {
                    label: t('global.cancel'),
                }
            ]
        });
    };

    const handlePressValidateTransferFields = (received, noreceived) => {

        let total =  countFieldsText();

        let params = {
            received: total.OK,
            noreceived: total.TOTAL
        }

        let selectedModel = props.model;
        let modelFromPlugcode = props.plugform;
        let dataFromPlugcode = props.plugnote;


        let copyReceiverFields = [...receiverFields];
        copyReceiverFields = JSON.parse(JSON.stringify(copyReceiverFields));
        let copyConfirmedFields = [...confirmedFields];
        copyConfirmedFields = JSON.parse(JSON.stringify(copyConfirmedFields));

        let editLabel = false;

        let finalFields = copyReceiverFields.map((item, index) => {
            let find = copyConfirmedFields.find(confirmedField => confirmedField.id === item.id);
            if(find){
                if(!!item.oldLabel){
                    editLabel = true;
                }
            }
            if(!!item.format){
                if(item.format !== 'list' && !item.format.includes('select') && !item.format.includes('table') && item.format !== 'plugform'){
                    item.value = find ? find.value : '';
                    return item;
                }
                else{
                    if(item.format === 'list'){
                        if(find){
                            let value = [];
                            let listvalue = find.value.split(',');
                            listvalue.forEach((fieldListValue, fieldListIndex) => {
                                value.push({"text" : fieldListValue});
                            });
                            item.value = value;
                        }else{
                            item.value = [];
                        }

                    }else if(item.format === 'plugform'){
                        let tmp = JSON.stringify({"value" : item.value, "index" : "", "name" : ""})
                        item.value = tmp
                    } else if(item.format.includes('select')){
                        item.format = 'select';
                        if(find){
                            let value = [];
                            let listvalue = find.value.split(',');
                            listvalue.forEach((fieldListValue, fieldListIndex) => {
                                value.push(fieldListValue);
                            });
                            item.value = value;
                        }else{
                            item.value = [];
                        }
                    } else if(item.format.includes('table')){
                        item.format = 'table';
                        if(find){
                            let value = [];
                            let listvalue = find.value.split(',');
                            listvalue.forEach((fieldListValue, fieldListIndex) => {
                                let split = fieldListValue.split(':');
                                value.push({"text" : split[0] , "value" : Number(split[1])});
                            });
                            item.value = value;
                        }else{
                            item.value = [];
                        }
                    }
                    return item;
                }
            }
        });

        finalFields = finalFields.filter(Boolean);

        confirmAlert({
            title: t('global.confirm'),
            message: t('models.model-confirm-copy'),
            buttons: [
                {
                    label: t('global.yes'),
                    onClick: () => props.attemptClassifyInAnotherPlugformMove(dataFromPlugcode, selectedModel.hash, selectedAnnexes, finalFields, editLabel, environment.userhash,false)
                },
                {
                    label: t('global.cancel'),
                }
            ]
        });
    };

    const handleSelectAnnexe = (annexe, index) => {
        setSelectedAnnexes(oldArray => [...oldArray, annexe]);
    };

    const handleUnSelectAnnexe = (annexe, index) => {
        const removeSelected = selectedAnnexes.filter((item, index) => item.hash !== annexe.hash);
        removeSelected.filter(Boolean);
        setSelectedAnnexes(removeSelected);
    };


    const getUrlPreviewAnnexe = (annexe) => {
        return ApplicationConfig.downloadUrl + props.plugform.userhash + "/" + props.plugnote.hash + "/" + encodeURIComponent(annexe.name) + "." + annexe.type
    }

    const renderAnnexePreview = (annexeToPreview) => {
        switch (annexeToPreview.type.toLowerCase()) {
            case 'jpg':
            case 'JPG':
            case 'jpeg':
            case 'png':
            case 'PNG':
            case 'gif':
                return <img src={getUrlPreviewAnnexe(annexeToPreview)} alt={annexeToPreview.name} />;
                break;

            case 'mp4':
            case 'mkv':
            case 'avi':
            case 'mov':
                return <FontAwesomeIcon icon={faFileVideo} size="lg" style={{fontSize : '50', marginRight : '10'}}/>;
                break;

            case 'pdf':
                return <FontAwesomeIcon icon={faFilePdf} size="lg" style={{fontSize : '50', marginRight : '10'}}/>;
                break;

            case 'zip':
            case 'rar':
                return <FontAwesomeIcon icon={faFileArchive} size="lg" style={{fontSize : '50', marginRight : '10'}}/>;
                break;

            case 'mp3':
            case 'aac':
                return <FontAwesomeIcon icon={faFileAudio} size="lg" style={{fontSize : '50', marginRight : '10'}}/>;
                break;

            case 'html':
                return <FontAwesomeIcon icon={faFileCode} size="lg" style={{fontSize : '50', marginRight : '10'}}/>;
                break;

            case 'txt':
            case 'sheet':
            case 'doc':
            case 'docx':
                return <FontAwesomeIcon icon={faFileWord} size="lg" style={{fontSize : '50', marginRight : '10'}}/>;
                break;

            case 'xls':
            case 'xlsx':
            case 'csv':
                return <FontAwesomeIcon icon={faFileExcel} size="lg" style={{fontSize : '50', marginRight : '10'}}/>;
                break;

            case 'note':
                return <FontAwesomeIcon icon={faStickyNote} size="lg" style={{fontSize : '50', marginRight : '10'}}/>;
                break;

            case 'pages':
                return <FontAwesomeIcon icon={faFileWord} size="lg" style={{fontSize : '50', marginRight : '10'}}/>;
                break;

            case 'pptx':
            case 'ppt':
                return <FontAwesomeIcon icon={faFilePowerpoint} size="lg" style={{fontSize : '50', marginRight : '10'}}/>;
                break;
        }
    };

    const isAnnexeSelected = (annexe) => {
        let find = selectedAnnexes.find(item => item.hash === annexe.hash);
        return typeof find !== "undefined";
    };

    const buildCurrentData = () => {

        let currentModel = props.model;
        let modelForHash = props.plugform;
        let dataForHash = props.plugnote;

        const labels = Object.keys(modelForHash).filter((key) => {
            const regex = /field[0-9]+label/gi;
            return regex.test(key);
        });

        let fields = labels
            //.filter((item) => modelForHash[item] != null)
            .map((item, index) => {
                const fieldIndex = item.replace('label', '').replace('field', '');
                let options = [];
                let format = modelForHash[item.replace('label', 'format')];
                let value = dataForHash[item.replace('label', '')];

                if(typeof value === "undefined"){
                    if(options != null && (options.presel || format === "list")){
                        value = [];
                    }else{
                        value = "";
                    }
                }


                if(format !== null){

                    if (modelForHash[`field${fieldIndex}formatoptions`]) {
                        options = modelForHash[`field${fieldIndex}formatoptions`];
                        if (typeof options === 'string') {
                            options = JSON.parse(modelForHash[`field${fieldIndex}formatoptions`]);
                        }
                    }


                    if(format === "select"){
                        // Vérifier les préselections
                        if (options.presel) {
                            if(options.multiselect && !options.editable){
                                format = "multi-select";
                            }else if(!options.multiselect && !options.editable){
                                format = "single-select";

                            }else if(options.multiselect && options.editable){
                                format = "free-multi-select";

                            }else if(!options.multiselect && options.editable){
                                format = "free-single-select";
                            }
                            options = options.presel.split(";");
                        }
                    }

                    if(format === "table"){
                        // Vérifier les préselections
                        if (options.presel) {
                            if(options.multiselect && !options.editable){
                                format = "multi-table";
                            }else if(!options.multiselect && !options.editable){
                                format = "single-table";

                            }else if(options.multiselect && options.editable){
                                format = "free-multi-table";

                            }else if(!options.multiselect && options.editable){
                                format = "free-single-table";
                            }
                            options = options.presel.split(";");
                        }
                    }



                    if(format === 'list'){
                        if(value.length === 0){
                            value = [];
                        }else if (!Array.isArray(value) && value !== '""' && value !== '[]') {
                            value = JSON.parse(value);
                        }
                        else{
                            value = [];
                        }
                        let listValue = ``;
                        if(value.length > 0){
                            value.forEach((fieldListValue, fieldListIndex) => {
                                if(fieldListIndex === (value.length - 1)){
                                    listValue += `${fieldListValue.text}`;
                                }else{
                                    listValue += `${fieldListValue.text}, `;
                                }
                            });
                        }
                        value = listValue;
                    }
                    else if(format.includes('select')){
                        if (value && value != null && value.length && /^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                            value = JSON.parse(value);
                        }
                        else {
                            if (value && value != null) {
                                value = [value];
                            }
                            else {
                                value = [];
                            }
                        }
                        let listValue = ``;
                        if(value.length > 0){
                            value.forEach((fieldListValue, fieldListIndex) => {
                                if(fieldListIndex === (value.length - 1)){
                                    listValue += `${fieldListValue}`;
                                }else{
                                    listValue += `${fieldListValue}, `;
                                }
                            });
                        }
                        value = listValue;
                    } else if(format.includes('table')){
                        if (value && value != null && value.length && /^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                            value = JSON.parse(value);
                        }
                        else {
                            if (value && value != null) {
                                value = [value];
                            }
                            else {
                                value = [];
                            }
                        }
                        let listValue = ``;
                        if(value.length > 0){
                            value.forEach((fieldListValue, fieldListIndex) => {
                                if(fieldListIndex === (value.length - 1)){
                                    listValue += `${fieldListValue.text + ':' + fieldListValue.value}`;
                                }else{
                                    listValue += `${fieldListValue.text + ':' + fieldListValue.value}, `;
                                }
                            });
                        }
                        value = listValue;
                    }
                    else if(format === 'plugform'){
                        value = isJsonString(value) ? JSON.parse(value).value : "";
                    }
                    else if(format === 'intdatamaj'){
                        value = isJsonString(value) ? JSON.parse(value).value : "";
                    }
                    else if(format === 'qrcodeint'){
                        value = isJsonString(value) ? JSON.parse(value).value : "";
                    }

                    return {
                        dragid : 'item-' + index + 1,
                        exclude: false,
                        value,
                        label: modelForHash[item],
                        format,
                        options,
                        id: index + 1
                    }

/*                    if(!!value && value.length > 0){
                        return {
                            dragid : 'item-' + index + 1,
                            exclude: false,
                            value,
                            label: modelForHash[item],
                            format,
                            options,
                            id: index + 1
                        }
                    }*/
                }
            });

        fields = fields.filter(Boolean);

        const receiverLabels = Object.keys(currentModel).filter((key) => {
            const regex = /field[0-9]+label/gi;
            return regex.test(key);
        });

        let tmpReceiverFields = receiverLabels
            .filter((item) => currentModel[item] != null)
            .map((item, index) => {
                const fieldIndex = item.replace('label', '').replace('field', '')
                let options = [];
                let format = currentModel[item.replace('label', 'format')]

                if (currentModel[`field${fieldIndex}formatoptions`]) {
                    options = currentModel[`field${fieldIndex}formatoptions`];
                    if (typeof options === 'string') {
                        options = JSON.parse(currentModel[`field${fieldIndex}formatoptions`]);
                    }
                }

                if(format === 'select'){
                    // Vérifier les préselections
                    if (options.presel) {
                        if(options.multiselect && !options.editable){
                            format = "multi-select";
                        }else if(!options.multiselect && !options.editable){
                            format = "single-select";

                        }else if(options.multiselect && options.editable){
                            format = "free-multi-select";

                        }else if(!options.multiselect && options.editable){
                            format = "free-single-select";
                        }
                        options = options.presel.split(";");
                    }
                }

                if(format === 'table'){
                    // Vérifier les préselections
                    if (options.presel) {
                        if(options.multiselect && !options.editable){
                            format = "multi-table";
                        }else if(!options.multiselect && !options.editable){
                            format = "single-table";

                        }else if(options.multiselect && options.editable){
                            format = "free-multi-table";

                        }else if(!options.multiselect && options.editable){
                            format = "free-single-table";
                        }
                        options = options.presel.split(";");
                    }
                }


                return {
                    value : "",
                    dragid : 'item-' + index + 1,
                    exclude: false,
                    label: currentModel[item],
                    format,
                    options,
                    id: index + 1
                }
            });

        let tmpConfirmedFields = [];

        tmpReceiverFields.map((item,index) => {
            if(!!fields[index]){
                if(isCompatible(item, fields[index])){
                    let confirmedField = item;
                    if(!!fields[index].value){
                        confirmedField.value = fields[index].value;
                        confirmedField.from = fields[index].id;
                        tmpConfirmedFields.push(confirmedField);
                    }
                }
            }
        });


        if(fields.length < tmpReceiverFields.length){

            let countPush = tmpReceiverFields.length - fields.length;
            let index = fields.length > 0 ? fields[fields.length - 1].id : 0;
            for (let i = 1 ; i <= countPush; i++){
                let pushValue =   {
                    exclude : false,
                    value : '',
                    label: '',
                    format : '',
                    options  : '',
                    id: index + 1,
                    dragid : 'item-' + index + 1,
                };
                fields.push(pushValue);
                index++;
            }

        }
        else if(tmpReceiverFields.length < fields.length){
            let countPush = fields.length - tmpReceiverFields.length;
            let index = tmpReceiverFields.length;
            for (let i = 1 ; i <= countPush; i++){
                let pushValue =   {
                    exclude: false,
                    value : '',
                    label: '',
                    format : '',
                    options  : '',
                    id: index + 1,
                    dragid : 'item-' + index + 1,
                };
                tmpReceiverFields.push(pushValue);
                index++;
            }
        }

        setFields(fields);
        setReceiverFields(tmpReceiverFields);
        setConfirmedFields(tmpConfirmedFields);
    };

    useEffect(() => {
        buildCurrentData();
    }, [props.model, props.plugform, props.plugnote]);

    useLayoutEffect(() => {
        transfertFields();
    }, [fields]);

    let count = compatibleFieldsText();
    let countAnnexes = props.plugnote.Annexes.length;
    let countSelectedAnnexes = selectedAnnexes.length;


    return (
        <div className={classes.root}>
            <CssBaseline />
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx(classes.paperAnchorDockedLeft,{
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.toolbar}/>
                <List className={classes.listHeight}>
                    <div className="left-menu_contents">
                        <ListItem button>
                            <ListItemIcon><UserIcon /></ListItemIcon>
                            <ListItemText primary={environment.name} />
                        </ListItem>
                    </div>
                </List>
                <Divider />
                <div className={classes.openClose}>
                    <IconButton className={classes.openCloseButton} onClick={open ? handleDrawerClose : handleDrawerOpen}>
                        {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </div>
            </Drawer>
            <main className={classes.contentFull}>
                <Header>
                    <div id="btnContainer">

                        <div className="sous-header p1 m2 grey">

                            <div className="sous-header_content">
                                <div className="div_width_50">
                                    <span className="titres black">transfert <br/></span>
                                </div>
                                <div>
                                    <button className="button champs"  onClick={(event) => handlePressValidateTransferFieldsMove(parseInt(confirmedFields.length),  parseInt(fields.length) - parseInt(confirmedFields.length))}>{t('common:multiple.move')}</button>
                                    <button className="button champs"  onClick={(event) => handlePressValidateTransferFields(parseInt(confirmedFields.length),  parseInt(fields.length) - parseInt(confirmedFields.length))}>{t('common:multiple.copy')}</button>
                                    <span id="icon-close" className="material-icons md-30" onClick={handleExitPlugnote}>
                                    close
                                </span>
                                </div>
                            </div>

                            <div className="sous-header_content black">
                                <div className="line flex_style">
                                </div>
                            </div>

                            <div className="sous-header_content lightgrey md-30">
                                <div className="split flexSpacebetween grey">
                                    <div>
                                <span className="champs">
                                    TO : {props.model.clef} {props.model.label}

                                </span>
                                    </div>
                                </div>
                                <div className="split flexSpacebetween grey">
                                    <div>
                                <span className="champs">
                                    FROM : {props.plugform.clef} {props.plugform.label}
                                </span>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </div>

                            <div className="">
                                {
                                    <span>{t('common:move.transfert-fields')} </span>
                                }
                                <span className="green">{t('common:move.compatibility-perfect')} ({parseInt(count["OK"])})</span>
                                {
                                    parseInt(count["OK?"]) > 0 &&
                                    <span> - </span>
                                }
                                { parseInt(count["OK?"]) > 0 &&
                                    <span className="bloodOrange">{t('common:move.compatibility')} ({parseInt(count["OK?"])})</span>
                                }
                                {
                                    parseInt(count["X"]) > 0 &&
                                    <span> - </span>
                                }
                                { parseInt(count["X"]) > 0 &&
                                    <span className="red">{t('common:move.compatibility-no')} ({parseInt(count["X"])})</span>
                                }
                                {
                                    countAnnexes > 0 &&
                                    <span> | {t('common:move.transfert-annexes')} </span>
                                }
                                {
                                    countAnnexes > 0 &&
                                    <span className="green">{t('common:move.files-selected')} ({parseInt(countSelectedAnnexes)})</span>
                                }

                                {
                                    parseInt(countAnnexes - countSelectedAnnexes) > 0 && countAnnexes > 0 &&
                                    <span> - </span>
                                }

                                { parseInt(countAnnexes - countSelectedAnnexes) > 0 && countAnnexes > 0 &&
                                    <span className="red">{t('common:move.files-no-selected')} ({parseInt(countAnnexes - countSelectedAnnexes)})</span>
                                }

                            </div>

                        </div>

                    </div>
                </Header>
                <Content className={!listView ? classes.contentModelGrid : classes.contentModelList}>
                    { props.plugnote.Annexes.length > 0 &&
                        <div className="flex_display_list_annexe">
                            {
                                props.plugnote.Annexes.map((annexe, index) => {
                                    return (
                                        <div key={index} className={`${isAnnexeSelected(annexe) === true ? "active_selected_annexe" : "active_unselected_annexe"} cursor`}  onClick={(event) => isAnnexeSelected(annexe) === true ?  handleUnSelectAnnexe(annexe, index) : handleSelectAnnexe(annexe, index)}>
                                            <div className={`flexbox-item_img flex_grow_annexe_center`} >
                                                {
                                                    renderAnnexePreview(annexe)
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }

                    <div className="splitContent flexSpacebetween p1">

                        <div className="split-50 formulaireGauche scrollbar-actif">
                            <DragDropContext onDragEnd={onDragEnd} key="left">
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}
                                        >
                                            {receiverFields.map((receiverField, index) => (
                                                <Draggable key={receiverField.dragid} draggableId={receiverField.dragid} index={index} isDragDisabled={true}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                        >
                                                            <div key={index} className="champs_apercu flexbox-item-plugcode m2 cursor" >

                                                                <div className="flexbox-item-plugcode_texte Plugcode">

                                                                    <div className="infos-supplementaires grey"  style={{minHeight : '25px'}}>

                                                                        <div>
                                                                            <span className="references green">
                                                                                {receiverField.label}
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                            <span className="references">
                                                                {receiverField.format}
                                                            </span>
                                                                        </div>

                                                                    </div>

                                                                    <div className="sous-header_content" style={{height:'12px'}}>
                                                                        <div className="line lightgrey flex_style">
                                                                        </div>
                                                                    </div>

                                                                    <span className="titres black" style={{minHeight : '32px'}}>
                                                                        {receiverField.value}
                                                                    </span>

                                                                    <div className=" grey flexSpacebetween flexStartEnd">
                                                                        <span id="icon-more"
                                                                              className="material-icons md-small" style={{visibility : 'hidden'}}>
                                                                            drag_handle
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                        <div className="split-50 formulaireDroite scrollbar-actif">
                            <DragDropContext onDragEnd={onDragEndFrom} key="right">
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}
                                        >
                                            {fields.map((field, index) => (
                                                <Draggable key={field.dragid} draggableId={field.dragid} index={index}>
                                                    {(provided, snapshot, event) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                        >
                                                            <div key={index} style={{display: 'flex', backgroundColor: 'white'}}>
                                                                <div className="champs_apercu flexbox-item-plugcode_compatibility m2 cursor" style={{minHeight : '60px', backgroundColor : 'white'}}>
                                                                    <div className="flexbox-item-plugcode_texte_compatibility Plugcode">
                                                                        {
                                                                            renderCompatibleIcon(field,index)
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div className="champs_apercu flexbox-item-plugcode m2 cursor" style={{width : '100%'}}>
                                                                    <div className="flexbox-item-plugcode_texte Plugcode">

                                                                        <div className="infos-supplementaires grey" style={{minHeight : '25px'}}>

                                                                            <div>
                                                                            <span className="references green">
                                                                                {field.label}
                                                                            </span>
                                                                            </div>
                                                                            <div>
                                                                            <span className="references">
                                                                                {field.format}
                                                                            </span>
                                                                            </div>

                                                                        </div>

                                                                        <div className="sous-header_content" style={{height:'12px'}}>
                                                                            <div className="line lightgrey flex_style">
                                                                            </div>
                                                                        </div>

                                                                        <span className="titres black" style={{minHeight : '32px'}}>
                                                                            {field.value}
                                                                        </span>

                                                                        <div className=" grey flexSpacebetween flexStartEnd">
                                                                        <span id="icon-more"
                                                                              className="material-icons md-small">
                                                                            drag_handle
                                                                        </span>
                                                                            {
                                                                                field.format !== '' && !field.exclude &&
                                                                                <span id="icon-more" className="material-icons md-small" onClick={(event) => handleExcludedField(field, index)}>
                                                                                    clear
                                                                                </span>
                                                                            }
                                                                            {
                                                                                field.format !== '' && field.exclude &&
                                                                                <span id="icon-more" className="material-icons md-small" onClick={(event) => handleIncludeField(field, index)}>
                                                                                    add
                                                                                </span>
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    </div>
                </Content>
            </main>
        </div>

    );
}


const mapStateToProps = (state, props) => {
    return {
        model : props.location.state.model,
        plugnote : props.location.state.plugnote,
        plugform : props.location.state.plugform,
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        attemptClassifyInAnotherPlugform: (plugnote, modelhash, annexes, fields, editLabel, userhash) => dispatch(postClassifyInAnotherPlugformRequest(plugnote, modelhash, annexes, fields, editLabel, userhash)),
        attemptClassifyInAnotherPlugformMove: (plugnote, modelhash, annexes, fields, editLabel, userhash, move) => dispatch(postClassifyInAnotherPlugformMoveRequest(plugnote, modelhash, annexes, fields, editLabel, userhash, move)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PlugnoteCopyMove)
