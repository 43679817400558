import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {connect} from "react-redux";
import {getCurrentEnvironnement, getModel, getPlugnote} from "../../utils/Common";
import '@contentful/forma-36-react-components/dist/styles.css';
import ApplicationConfig from "../../config/ApplicationConfig";
import {confirmAlert} from "react-confirm-alert";
import ReactDataGrid from "react-data-grid";
import PopupState, {bindPopover, bindTrigger} from "material-ui-popup-state";
import Popover from "@material-ui/core/Popover";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel} from "@fortawesome/free-regular-svg-icons";
import * as XLSX from "xlsx";

const zeroPad = (num, places) => String(num).padStart(places, '0');

function getModalStyle() {
    const top = 5;

    return {
        top: `${top}%`,
        margin:'auto',
        overflow: 'auto',
        height: '90%',
        width: '90%'
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    popup: {
        position: 'absolute',
        //width: '50%',
        //height : '50%',
        //backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        //boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        width: '80%',
        height: '50%',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    marginLeft:{
        marginLeft : '10px'
    },
    pointer:{
        cursor: 'pointer'
    }
}));

const ModalListAnnexesComponent = (props) => {

    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [t, i18n] = useTranslation('common');

    const plugnote = getPlugnote();
    const environment = getCurrentEnvironnement();

    const handleCloseModal = () => {
        props.close();
    };

    const handleExportCsv = () => {

        let binary = []
        if(rows.length > 0){

            rows.map((row, index) => {
                let finalObject = {};
                initColumns.map((colum, index) => {
                    if(colum.key !== 'actions'){
                        finalObject[colum.realName] = row[colum.key];
                    }
                });
                binary.push(finalObject);
            })
        }

        if(binary.length){
/*            let objectMaxLength = [];
            for (let i = 0; i < binary.length; i++) {
              let value = Object.values(binary[i]);
              for (let j = 0; j < value.length; j++) {
                if (typeof value[j] == "number") {
                  objectMaxLength[j] = 10;
                } else {
                  objectMaxLength[j] =
                    objectMaxLength[j] >= value[j].length
                      ? objectMaxLength[j]
                      : value[j].length;
                }
              }
            }
            console.log(objectMaxLength);*/

            let wscols = [
                { width: 10},
                { width: 100 },
                { width: 10  },
                { width: 100 },
                { width: 10  },
                { width: 10  }
            ];

            const binaryWS: XLSX.WorkSheet = XLSX.utils.json_to_sheet(binary);
            binaryWS["!cols"] = wscols;

            // Create a new Workbook
            let wb = XLSX.utils.book_new()

            // Name your sheet
            XLSX.utils.book_append_sheet(wb, binaryWS, 'Binary values')
            if(getModel().isModelShared){
                XLSX.writeFile(wb, '['+getModel().clef + ']' + getModel().label + '_PN_'+ getPlugnote().userindex +'_'+'.xlsx');
            }else{
                XLSX.writeFile(wb, '['+getCurrentEnvironnement().envIndex+'-'+getModel().clef + ']' + getModel().label + '_PN_'+ getPlugnote().userindex +'_'+'.xlsx');
            }

            // export your excel
        }
    }

    const allActions = (object) => {

        return (
            <div className="">

                { object.row.annexe.type !== 'note' &&
                <Tooltip title={i18n.t('common:actions.download')} aria-label="add" placement="top-start">
                                                <span id="icon-file_copy" className="p3 cursor" onClick={props.downloadEmployeeData.bind(this, object.row.annexe)}>
                                                    <span id="icon-archive" className="material-icons md-25">
                                                        get_app
                                                    </span>
                                                </span>
                </Tooltip>
                }

                { !props.locked && props.isAuthorisedToEdit(object.row.annexe.type)  &&
                <Tooltip title={i18n.t('common:actions.update')} aria-label="add" placement="top-start">
                                                <span id="icon-file_copy" className="p3 cursor" onClick={props.handleUpdateAnnexe.bind(this, object.row.annexe)}>
                                                    <span id="icon-archive" className="material-icons md-25">
                                                        publish
                                                    </span>
                                                </span>
                </Tooltip>
                }

                {
                    !props.locked &&
                    <Tooltip title={i18n.t('common:actions.comment')} aria-label="add" placement="top-start">
                                                <span id="icon-file_copy" className="p3 cursor" onClick={props.handleModalAnnexeOpen.bind(this, object.row.annexe)}>
                                                    <span id="icon-archive" className="material-icons md-25">
                                                        comment
                                                    </span>
                                                </span>
                    </Tooltip>
                }

                {
                    object.row.annexe.type === 'note' && !props.locked &&
                    <Tooltip title={i18n.t('common:actions.notes')} aria-label="add" placement="top-start">
                                                <span id="icon-file_copy" className="p3 cursor" onClick={props.handleNoteOpen.bind(this, object.row.annexe)}>
                                                    <span id="icon-archive" className="material-icons md-25">
                                                        create
                                                    </span>
                                                </span>
                    </Tooltip>

                }
                {
                    !props.locked &&
                    <Tooltip title={i18n.t('common:actions.delete')} aria-label="add" placement="top-start">
                                                <span id="icon-file_copy" className="p3 cursor" onClick={props.handleDeleteAnnexe.bind(this, object.row.annexe)}>
                                                    <span id="icon-archive" className="material-icons md-25">
                                                        delete
                                                    </span>
                                                </span>
                    </Tooltip>
                }
                { !props.locked &&
                object.row. annexe.countVersion > 0 &&
                <Tooltip title={i18n.t('common:actions.versioning')} aria-label="add" placement="top-start">
                                                <span id="icon-file_copy" className="p3 cursor" onClick={props.handleModalListAnnexesVersionOpen.bind(this, object.row.annexe.hash)}>
                                                    <span id="icon-archive" className="material-icons md-25">
                                                        arrow_circle_down
                                                    </span>
                                                </span>
                </Tooltip>
                }
            </div>
        )
    }

    const allOldActions = (object) => {

        return (
            <div className="display_flex float_right grey">
                <PopupState variant="popover" popupId={object.row.annexe.hash}>
                    {(popupState) => (
                        <div>
                            <span aria-describedby={object.row.annexe.hash} className="material-icons md-medium" {...bindTrigger(popupState)}>
                                more_horiz
                            </span>
                            <Popover
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <Box p={2}>
                                    <div className="flex_display_direction_column">

                                        { object.row.annexe.type !== 'note' &&
                                        <Tooltip title={i18n.t('common:actions.download')} aria-label="add" placement="top-start">
                                                <span id="icon-file_copy" className="p3 cursor" onClick={(event) => props.downloadEmployeeData(event, object.row.annexe)}>
                                                    <span id="icon-archive" className="material-icons md-25">
                                                        get_app
                                                    </span>
                                                </span>
                                        </Tooltip>
                                        }

                                        { !props.locked && props.isAuthorisedToEdit(object.row.annexe.type)  &&
                                        <Tooltip title={i18n.t('common:actions.update')} aria-label="add" placement="top-start">
                                                <span id="icon-file_copy" className="p3 cursor" onClick={(event) => props.handleUpdateAnnexe(event, object.row.annexe)}>
                                                    <span id="icon-archive" className="material-icons md-25">
                                                        publish
                                                    </span>
                                                </span>
                                        </Tooltip>
                                        }

                                        {
                                            !props.locked &&
                                            <Tooltip title={i18n.t('common:actions.comment')} aria-label="add" placement="top-start">
                                                <span id="icon-file_copy" className="p3 cursor" onClick={(event) => props.handleModalAnnexeOpen(event, object.row.annexe)}>
                                                    <span id="icon-archive" className="material-icons md-25">
                                                        comment
                                                    </span>
                                                </span>
                                            </Tooltip>
                                        }

                                        {
                                            object.row.annexe.type === 'note' && !props.locked &&
                                            <Tooltip title={i18n.t('common:actions.notes')} aria-label="add" placement="top-start">
                                                <span id="icon-file_copy" className="p3 cursor" onClick={(event) => props.handleNoteOpen(event, object.row.annexe)}>
                                                    <span id="icon-archive" className="material-icons md-25">
                                                        create
                                                    </span>
                                                </span>
                                            </Tooltip>

                                        }
                                        {
                                            !props.locked &&
                                            <Tooltip title={i18n.t('common:actions.delete')} aria-label="add" placement="top-start">
                                                <span id="icon-file_copy" className="p3 cursor" onClick={(event) => props.handleDeleteAnnexe(event, object.row.annexe)}>
                                                    <span id="icon-archive" className="material-icons md-25">
                                                        delete
                                                    </span>
                                                </span>
                                            </Tooltip>
                                        }
                                        { !props.locked &&
                                        object.row. annexe.countVersion > 0 &&
                                        <Tooltip title={i18n.t('common:actions.versioning')} aria-label="add" placement="top-start">
                                                <span id="icon-file_copy" className="p3 cursor" onClick={(event) => props.handleModalListAnnexesVersionOpen(event, object.row.annexe.hash)}>
                                                    <span id="icon-archive" className="material-icons md-25">
                                                        arrow_circle_down
                                                    </span>
                                                </span>
                                        </Tooltip>
                                        }
                                    </div>
                                </Box>
                            </Popover>
                        </div>
                    )}
                </PopupState>
            </div>
        )
    }

    const _handleViewComment = (value) => {
        confirmAlert({
            message: value,
            buttons: [
                {
                    label: i18n.t('common:global.ok'),
                }
            ]
        });
    }

    const commentAction = (object) => {
        let column = object.column.key;
        let value = object.row[column];

        return(
            <div onClick={(event) => _handleViewComment(value)} className={classes.pointer}>
                { typeof value !== "undefined" && value.length > 0 &&
                    <span>{value}</span>
                }
            </div>
        )
    }

    const formatAction = (object) => {
        return(
            <div>{renderAnnexePreview(object)}</div>
        )
    }

    const getUrlPreviewAnnexe = (annexe) => {
        return ApplicationConfig.downloadUrl + environment.userhash + "/" + plugnote.hash + "/" + encodeURIComponent(annexe.name) + "." + annexe.type
    };

    const renderAnnexePreview = (object) => {
        let annexeToPreview = object.row.annexe;
        let column = object.column.key;
        let value = object.row[column];

        switch (annexeToPreview.type.toLowerCase()) {
            case 'jpg':
            case 'JPG':
            case 'jpeg':
            case 'png':
            case 'PNG':
            case 'gif':
                return <span onClick={(event) => props.handlePreviewAnnexe(event,annexeToPreview.hash)} className={classes.pointer}>{value}</span>;
                break;

            case 'mp4':
            case 'mkv':
            case 'avi':
            case 'mov':
                return <span>{value}</span>;
                break;

            case 'pdf':
                return <span onClick={(event) => props.handlePreviewPdfAnnexe(event,getUrlPreviewAnnexe(annexeToPreview))} className={classes.pointer}>{value}</span>;
                break;

            case 'zip':
            case 'rar':
                return <span>{value}</span>;
                break;

            case 'mp3':
            case 'aac':
                return <span>{value}</span>;
                break;

            case 'html':
                return <span>{value}</span>;
                break;

            case 'txt':
            case 'sheet':
            case 'doc':
            case 'docx':
                return <span>{value}</span>;
                break;

            case 'xls':
            case 'xlsx':
            case 'csv':
                return <span>{value}</span>;
                break;

            case 'note':
                return <span>{value}</span>;
                break;

            case 'pages':
                return <span>{value}</span>;
                break;

            case 'pptx':
            case 'ppt':
                return <span>{value}</span>;
                break;
            default:
                return <span>{value}</span>;
                break;
        }
    };

    const initialRows = [];
    let initColumns = [];

    // initColumns.push({ key: 'id-plug', name:  'N°Plugnote', resizable: true, sortable : true});
    initColumns.push({ key: 'id-an', name:  'N°', realName:  'N°', resizable: true, sortable : true, width : '5%'});
    initColumns.push({ key: 'name', name:  'File name', realName:  'File name',  resizable: true, sortable : true, width : '40%'  , formatter : commentAction});
    initColumns.push({ key: 'type', name:  '', realName:  'Format', resizable: false, sortable : true, width : '5%', formatter : formatAction});
    initColumns.push({ key: 'comment', name:  'Comment', realName:  'Comment', resizable: true, sortable : true, width : '30%' , formatter : commentAction});
    initColumns.push({ key: 'size', name:  'Kb', realName:  'Kb', resizable: true, sortable : true, width : '5%'});
    initColumns.push({ key: 'group', name:  '', realName:  'Group', resizable: false, sortable : true, width : '1%'});
    // initColumns.push({ key: 'version', name:  'Import', resizable: true, sortable : true});

    initColumns.push({ key: 'actions', name:  '', resizable: false, sortable : false, formatter : allOldActions, width : '5%'});


    if(props.annexes.length > 0){

        props.annexes.map((annexe, index) => {
            let finalObject = {};

            finalObject['annexe'] =  annexe;
            finalObject['id-plug'] =  plugnote.id;
            finalObject['id-an'] =  annexe.id;
            finalObject['hash'] =  annexe.hash;
            finalObject['name'] =  annexe.name;
            finalObject['type'] =  annexe.type;
            finalObject['group'] = annexe.groupid !== 0 ?   annexe.groupid : "";
            finalObject['comment'] =  annexe.comment;
            finalObject['size'] =  ((annexe.size) / 1000).toFixed(2);
            finalObject['version'] =  annexe.countVersion;

            initialRows.push(finalObject);

        })
    }


    const [rows, setRows] = useState(initialRows);
    const [sortDirection, setSortDirection] = useState("NONE");
    const [sortColumn, setSortColumn] = useState("id");
    const [rowCount, setRowCount] = useState(rows.length);

    const sortRows = (initialRows, sortColumn, sortDirection) => rows => {
        const comparer = (a, b) => {
            if (sortDirection === "ASC") {
                return a[sortColumn] > b[sortColumn] ? 1 : -1;
            } else if (sortDirection === "DESC") {
                return a[sortColumn] < b[sortColumn] ? 1 : -1;
            }
        };
        return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
    };


    useEffect(() => {
        setRows(initialRows);
        setRowCount(initialRows.length);
    }, [props.annexes]);


    return (
        <Modal
            keepMounted={false}
            open={props.open}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div style={modalStyle} className={classes.popup}>
                <div className = "scrollbar-hidden popup_wrapper flexSpacebetween" >
                    <div className="popup_wrapper_content">
                        <div className="sous-header_modal_content">
                            <div>
                                { getModel().isModelShared ?
                                    <span>{'[ '+getModel().clef + ' ] ' + getModel().label + ' PN '+ getPlugnote().userindex}</span>
                                    :
                                    <span>{'[ '+ getCurrentEnvironnement().envIndex+' - '+getModel().clef + ' ] ' + getModel().label + ' PN ' + getCurrentEnvironnement().envIndex + ' - '  + getPlugnote().userindex}</span>
                                }
                            </div>
                            <div onClick={handleExportCsv}>
                                <FontAwesomeIcon icon={faFileExcel} size="lg" style={{fontSize : '40', marginRight : '10', color : 'lightgrey', float : 'right', cursor : 'pointer'}}/>
                            </div>
                            <div onClick={handleCloseModal}>
                                    <span id="icon-close" className="material-icons md-50 lightgrey">
                                    close
                                    </span>
                            </div>

                        </div>
                        <div className="sous-header_content lightgrey">
                            <div className="line flex_style">
                            </div>
                        </div>
                        <div className="popup_wrapper_content">
                            <div className="model">
                                <div className="modelContent">
                                    <ReactDataGrid
                                        style={{height : ((rowCount * 35) + 100)}}
                                        columns={initColumns}
                                        rowGetter={i => rows[i]}
                                        rowsCount={rowCount}
                                        sortDirection={sortDirection}
                                        sortColumn={sortColumn}
                                        onSort={(sortColumn, sortDirection) =>{
                                            setSortDirection(sortDirection);
                                            setSortColumn(sortColumn);
                                            setRows(sortRows(initialRows, sortColumn, sortDirection));
                                        }}
                                        rows={rows || []}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        list : props.annexes
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalListAnnexesComponent)
