// actions types is object with the
// key: GET_DATA_REQUEST -> value: describes the action
export const Types = {
    RESET_ALL_DATA_TRANSLATE: 'reset_all_data_translate',

    GET_ALL_TRANSLATIONS_REQUEST: 'get_all_translations_request',
    GET_ALL_TRANSLATIONS_SUCCESS: 'get_all_translations_success',
    GET_ALL_TRANSLATIONS_FAILURE: 'get_all_translations_failure',
    GET_ALL_TRANSLATIONS_ERROR: 'get_all_translations_error',

    GET_ALL_TRANSLATIONS_BY_MODEL_REQUEST: 'get_all_translations_by_model_request',
    GET_ALL_TRANSLATIONS_BY_MODEL_SUCCESS: 'get_all_translations_by_model_success',
    GET_ALL_TRANSLATIONS_BY_MODEL_FAILURE: 'get_all_translations_by_model_failure',
    GET_ALL_TRANSLATIONS_BY_MODEL_ERROR: 'get_all_translations_by_model_error',

    GET_ALL_TRANSLATIONS_BY_MODEL_OFFLINE_REQUEST: 'get_all_translations_by_model_offline_request',
    GET_ALL_TRANSLATIONS_BY_MODEL_OFFLINE_SUCCESS: 'get_all_translations_by_model_offline_success',
    GET_ALL_TRANSLATIONS_BY_MODEL_OFFLINE_FAILURE: 'get_all_translations_by_model_offline_failure',
    GET_ALL_TRANSLATIONS_BY_MODEL_OFFLINE_ERROR: 'get_all_translations_by_model_offline_error',

    GET_ONE_TRANSLATION_BY_MODEL_REQUEST: 'get_one_translation_by_model_request',
    GET_ONE_TRANSLATION_BY_MODEL_SUCCESS: 'get_one_translation_by_model_success',
    GET_ONE_TRANSLATION_BY_MODEL_FAILURE: 'get_one_translation_by_model_failure',
    GET_ONE_TRANSLATION_BY_MODEL_ERROR: 'get_one_translation_by_model_error',

    POST_CREATE_TRANSLATION_BY_MODEL_REQUEST: 'post_create_translation_by_model_request',
    POST_CREATE_TRANSLATION_BY_MODEL_SUCCESS: 'post_create_translation_by_model_success',
    POST_CREATE_TRANSLATION_BY_MODEL_FAILURE: 'post_create_translation_by_model_failure',
    POST_CREATE_TRANSLATION_BY_MODEL_ERROR: 'post_create_translation_by_model_error',

    PUT_UPDATE_TRANSLATION_BY_MODEL_REQUEST: 'put_update_translation_by_model_request',
    PUT_UPDATE_TRANSLATION_BY_MODEL_SUCCESS: 'put_update_translation_by_model_success',
    PUT_UPDATE_TRANSLATION_BY_MODEL_FAILURE: 'put_update_translation_by_model_failure',
    PUT_UPDATE_TRANSLATION_BY_MODEL_ERROR: 'put_update_translation_by_model_error',

    DELETE_DELETE_TRANSLATION_BY_MODEL_REQUEST: 'delete_delete_translation_by_model_request',
    DELETE_DELETE_TRANSLATION_BY_MODEL_SUCCESS: 'delete_delete_translation_by_model_success',
    DELETE_DELETE_TRANSLATION_BY_MODEL_FAILURE: 'delete_delete_translation_by_model_failure',
    DELETE_DELETE_TRANSLATION_BY_MODEL_ERROR: 'delete_delete_translation_by_model_error',
};


/*
Token
 */
// function that returns an object literal
export const getAllTranslationsRequest = () => ({
    type: Types.GET_ALL_TRANSLATIONS_REQUEST,
});

// function that returns an object literal
export const getAllTranslationsSuccess = (data) => ({
    type: Types.GET_ALL_TRANSLATIONS_SUCCESS,
    payload: data
});

// function that returns an object literal
export const getAllTranslationsFailure = () => ({
    type: Types.GET_ALL_TRANSLATIONS_FAILURE,
});

// function that returns an object literal
export const getAllTranslationsError = () => ({
    type: Types.GET_ALL_TRANSLATIONS_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const getAllTranslationsByModelRequest = (modelhash,userhash) => ({
    type: Types.GET_ALL_TRANSLATIONS_BY_MODEL_REQUEST,
    modelhash : modelhash,
    userhash : userhash
});

// function that returns an object literal
export const getAllTranslationsByModelSuccess = (data, data2) => ({
    type: Types.GET_ALL_TRANSLATIONS_BY_MODEL_SUCCESS,
    payload: data,
    payload2: data2
});

// function that returns an object literal
export const getAllTranslationsByModelFailure = () => ({
    type: Types.GET_ALL_TRANSLATIONS_BY_MODEL_FAILURE,
});

// function that returns an object literal
export const getAllTranslationsByModelError = () => ({
    type: Types.GET_ALL_TRANSLATIONS_BY_MODEL_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const getAllTranslationsByModelOfflineRequest = (modelhash,userhash) => ({
    type: Types.GET_ALL_TRANSLATIONS_BY_MODEL_OFFLINE_REQUEST,
    modelhash : modelhash,
    userhash : userhash
});

// function that returns an object literal
export const getAllTranslationsByModelOfflineSuccess = (data, data2) => ({
    type: Types.GET_ALL_TRANSLATIONS_BY_MODEL_OFFLINE_SUCCESS,
    payload: data,
    payload2: data2
});

// function that returns an object literal
export const getAllTranslationsByModelOfflineFailure = () => ({
    type: Types.GET_ALL_TRANSLATIONS_BY_MODEL_OFFLINE_FAILURE,
});

// function that returns an object literal
export const getAllTranslationsByModelOfflineError = () => ({
    type: Types.GET_ALL_TRANSLATIONS_BY_MODEL_OFFLINE_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const getOneTranslationByModelRequest = (modelhash,userhash, translateid) => ({
    type: Types.GET_ONE_TRANSLATION_BY_MODEL_REQUEST,
    modelhash : modelhash,
    userhash : userhash,
    translateid : translateid
});

// function that returns an object literal
export const getOneTranslationByModelSuccess = (data) => ({
    type: Types.GET_ONE_TRANSLATION_BY_MODEL_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getOneTranslationByModelFailure = () => ({
    type: Types.GET_ONE_TRANSLATION_BY_MODEL_FAILURE,
});

// function that returns an object literal
export const getOneTranslationByModelError = () => ({
    type: Types.GET_ONE_TRANSLATION_BY_MODEL_ERROR,
});


/*
Token
 */
// function that returns an object literal
export const postCreateTranslationByModelRequest = (payload) => ({
    type: Types.POST_CREATE_TRANSLATION_BY_MODEL_REQUEST,
    payload : payload,

});

// function that returns an object literal
export const postCreateTranslationByModelSuccess = () => ({
    type: Types.POST_CREATE_TRANSLATION_BY_MODEL_SUCCESS,
});

// function that returns an object literal
export const postCreateTranslationByModelFailure = () => ({
    type: Types.POST_CREATE_TRANSLATION_BY_MODEL_FAILURE,
});

// function that returns an object literal
export const postCreateTranslationByModelError = () => ({
    type: Types.POST_CREATE_TRANSLATION_BY_MODEL_ERROR,
});


/*
Token
 */
// function that returns an object literal
export const putUpdateTranslationByModelRequest = (payload) => ({
    type: Types.PUT_UPDATE_TRANSLATION_BY_MODEL_REQUEST,
    payload : payload,

});

// function that returns an object literal
export const putUpdateTranslationByModelSuccess = () => ({
    type: Types.PUT_UPDATE_TRANSLATION_BY_MODEL_SUCCESS,
});

// function that returns an object literal
export const putUpdateTranslationByModelFailure = () => ({
    type: Types.PUT_UPDATE_TRANSLATION_BY_MODEL_FAILURE,
});

// function that returns an object literal
export const putUpdateTranslationByModelError = () => ({
    type: Types.PUT_UPDATE_TRANSLATION_BY_MODEL_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const deleteDeleteTranslationByModelRequest = (modelhash, userhash, id) => ({
    type: Types.DELETE_DELETE_TRANSLATION_BY_MODEL_REQUEST,
    modelhash : modelhash,
    userhash : userhash,
    id : id,

});

// function that returns an object literal
export const deleteDeleteTranslationByModelSuccess = () => ({
    type: Types.DELETE_DELETE_TRANSLATION_BY_MODEL_SUCCESS,
});

// function that returns an object literal
export const deleteDeleteTranslationByModelFailure = () => ({
    type: Types.DELETE_DELETE_TRANSLATION_BY_MODEL_FAILURE,
});

// function that returns an object literal
export const deleteDeleteTranslationByModelError = () => ({
    type: Types.DELETE_DELETE_TRANSLATION_BY_MODEL_ERROR,
});

export const resetAllDataTranslate = () => ({
    type: Types.RESET_ALL_DATA_TRANSLATE,
});

