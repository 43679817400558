import {api} from "./index";

export let
    getTemplateGroup,
    getTemplateModelGroup,
    getTemplateCategoryModel,
    getTemplateModelByCode

getTemplateModelByCode = (modelKey,userhash,langue) => api.get('template/modelbycode.json?code=' + modelKey+'&userhash=' + userhash+'&lg=' + langue);
getTemplateGroup = (codeGroup) => api.get('template/groupmodels.json?code=' + codeGroup);
getTemplateModelGroup = (codeModel) => api.get('template/nogroupmodels.json?code=' + codeModel);
getTemplateCategoryModel = (codeCategorie, langue) => api.get('template/categorygroupmodels.json?code=' + codeCategorie+'&lang=' + langue );
