import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%'
    },
    input_add_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    fieldRight:{
       flexGrow: 1,
    },
    mailContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    mailButton:{
    /* border: '1px red solid', */
    display: 'inline-block',
    color: "white",
    backgroundColor: "#58c3c1",
    padding: "3px 15px",
    borderRadius: "3px",
    cursor: "pointer",
    },
}));

const FormatMailComponent = (props) => {

    const classes = useStyles();

    const { t, i18n } = useTranslation(['common', 'models']);
    const [disabled, setDisabled] = React.useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    const [text, setText] = useState(props.field.value);

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    useEffect(() => {
        setText(!!props.field.value ? props.field.value : "");
    },[props.field.value])


    const handleEditValue = (value, field, index) => {
        setText(value);
        props.handleEditValue(value, field, index)
    }


    return (
        <div className={classes.mailContainer}>
                <TextField
                    onChange={(event) => handleEditValue(event.target.value, props.field, props.index)}
                    id={'mail-input-' + props.field.id}
                    type="email"
                    placeholder="E-mail"
                    autoComplete="new-password"
                    onFocus={(e) => e.target.placeholder = ''}
                    onBlur={(e) => e.target.placeholder = 'E-mail'}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    className={`${classes.fieldRight} testeBorder`}
                    value={text}
                    disabled={disabled}
                    InputProps={{ disableUnderline: true }}

                />
            { typeof text !== 'undefined' &&  text !== '' &&
                <div>
                    <a href={`mailto:${text.toString()}`} className={classes.mailButton}><span className="material-icons md-small white">mail</span></a>
                </div>
            }
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatMailComponent);





