import {call, fork, put, takeEvery} from 'redux-saga/effects';
import * as actions from '../actions/gallery';
import * as api from '../api/galleryApi';
import {history} from '../hooks/history'
import {trackPromise} from "react-promise-tracker";
import { setPlugcode } from '../utils/Common';

const myGenericTrackedWrapper = (fn, args1, args2, args3) => trackPromise(fn(args1,args2, args3));

function forwardTo(location) {
    history.push(location);
}

export function * getGalleries (action) {
    try {
        const response = yield call(myGenericTrackedWrapper,api.getGalleriesAll, action.userhash, action.userhash);
        // success?
        if (response.statusText == "OK" && response.data.result === 'ok') {
            yield put(actions.getPlugcodeGallerySuccess(response.data.datas));
        } else {
            yield put(actions.getPlugcodeGalleryFailure())
        }
    }catch(e) {
        yield put(actions.getPlugcodeGalleryError());
    }
}

export function * postCreateGallery (action) {
    try {
        action.gallery.userhash = action.userhash;
        action.gallery.mainuserhash = action.userhash;

        const response = yield call(myGenericTrackedWrapper,api.createGallery, action.gallery);
        // success?
        if (response.data.result === 'ok') {
            //envoi de la réponse au sessionStorage
            setPlugcode(response.data)

            yield put(actions.postPlugcodeGallerySuccess(response.data.datas))
            if(typeof action.redirect !== 'undefined' && action.redirect !== null){
                yield put(actions.savingGalleryPlugcodeSuccess())
                }
                else forwardTo("/plugcodes");
        } else {
            yield put(actions.postPlugcodeGalleryFailure())
        }
    }
    catch(e) {
        yield put(actions.postPlugcodeGalleryError());
    }
}

export function * putUpdateGallery (action) {
    try {
        const response = yield call(myGenericTrackedWrapper,api.updateGallery, action.gallery);
        // success?
        if (response.data.result === 'ok') {
            yield put(actions.putPlugcodeGallerySuccess(response.data.datas));
            try {
                const response = yield call(myGenericTrackedWrapper,api.getGalleriesAll, action.userhash, action.userhash);
                // success?
                if (response.statusText == "OK" && response.data.result === 'ok') {
                    yield put(actions.getPlugcodeGallerySuccess(response.data.datas));
                }
                else {
                    yield put(actions.getPlugcodeGalleryFailure())
                }
            }
            catch(e) {
                yield put(actions.getPlugcodeGalleryError());
            }
        }
        else {
            yield put(actions.putPlugcodeGalleryFailure())
        }
    }
    catch(e) {
        yield put(actions.putPlugcodeGalleryError());
    }
}

export function * delDeleteGallery (action) {

    try {

        const response = yield call(myGenericTrackedWrapper,api.deleteGallery, action.userhash, action.galleryhash);
        // success?
        if (response.data.result === 'ok') {
            yield put(actions.delPlugcodeGallerySuccess(action.galleryhash));
            try {
                const response = yield call(myGenericTrackedWrapper,api.getGalleriesAll, action.userhash, action.userhash);
                // success?
                if (response.statusText == "OK" && response.data.result === 'ok') {
                    yield put(actions.getPlugcodeGallerySuccess(response.data.datas));
                } else {
                    yield put(actions.getPlugcodeGalleryFailure())
                }
            }
            catch(e) {
                yield put(actions.getPlugcodeGalleryError());
            }
        }
        else {
            yield put(actions.delPlugcodeGalleryFailure())
        }
    }
    catch(e) {
        yield put(actions.delPlugcodeGalleryError());
    }
}


function* watchFetchGallery() {
    // create watcher of fetchData function
    yield takeEvery(actions.Types.GET_PLUGCODE_GALLERY_REQUEST, getGalleries);
    yield takeEvery(actions.Types.POST_PLUGCODE_GALLERY_REQUEST, postCreateGallery);
    yield takeEvery(actions.Types.PUT_PLUGCODE_GALLERY_REQUEST, putUpdateGallery);
    yield takeEvery(actions.Types.DEL_PLUGCODE_GALLERY_REQUEST, delDeleteGallery);


}

const GallerySagas = [
    fork(watchFetchGallery)
];

export default GallerySagas;