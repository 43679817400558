import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import {getDecryptPasswordRequest, reduxGenerateDataPasswordReset} from "../../actions/data";
import {getCurrentEnvironnement, getPlugnote} from "../../utils/Common";
import {useTranslation} from "react-i18next";
import {InputLabel, TextField, Tooltip} from "@material-ui/core";
import colors from '../../assets/themes/Colors';
import { RFC_2822 } from "moment";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        border: '1px red solid',
    },
    input_add_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    inputLabel: {
        // transform: "translate(0px, 44px) scale(0.7)",
        backgroundColor: colors.white,
        padding: '0 2px'
    },
    pwField: {
        marginTop: '-15px',
        // marginLeft: 15,
        zIndex: 0,
        // minHeight: 58,
        // paddingRight: 15,

        "& label": {
            // transformOrigin: "top right",
            // color: colors.bloodOrange
        },
    },
    inputBtn: {
        width: 30,
        // height: 22
    },
    inputField: {
        zIndex: 5
    },
    hiddenField: {
        width: 0,
        height: 0
    }
}));



const FormatPasswordComponent = (props) => {

    const classes = useStyles();
    const [encryptPassword, setEncryptPassword] = useState(props.field.value);
    const [decryptPassword, setDecryptPassword] = useState("");
    const shrink = decryptPassword.length > 0;
    const [password, setPassword] = useState("");
    const [editMode, setEditMode] = useState(false)
    const [editPw, setEditPw] = useState("")
    const [visibleEncodable, setVisibleEndable] = useState(false);
    const [retrieved, setRetrieved] = useState(false)
    const [changed, setChanged] = useState(false)
    const [visible, setVisible] = useState(false);
    const [disabled, setDisabled] = React.useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    const [isFromPlugCode, setIsFromPlugCode] = React.useState(typeof props.isFromPlugCode === 'undefined' ? false: props.isFromPlugCode);
    const [isFromPlugFormCode, setIsFromPlugFormCode] = React.useState(typeof props.isFromPlugFormCode === 'undefined' ? false: props.isFromPlugFormCode);

    const environment = getCurrentEnvironnement();
    const plugnote = isFromPlugFormCode ? null : isFromPlugCode ? props.plugnote : getPlugnote() ;
    const visualPW = '••••••••••••'

    const handleViewCryptedPassword = (event) => {
        if(!!encryptPassword && !!plugnote && !isFromPlugFormCode){
            props.attemptGetPasswordField(plugnote.hash, props.field.id, environment.userhash);
            setVisible(true);
            setVisibleEndable(true)
        }
    }

    const handleHidePassword = (event) => {
        setVisible(false);
        setVisibleEndable(false)
        props.attemptResetDataPassword();
    }

    const togglePasswordVisibility = () => {
        setVisibleEndable(!visibleEncodable)
    }

    const toggleEncryptionPasswordVisibility = () => {
        if (visible) {
            handleHidePassword()
        } else handleViewCryptedPassword()
    }

    const handleVisibilityChange = () => {
        if(!retrieved) toggleEncryptionPasswordVisibility();
        handleViewCryptedPassword()
        togglePasswordVisibility();
    }

    const saveEditedPw = () => {
        if(editPw !== password)
        {
            setPassword(editPw);
            setDecryptPassword(editPw)
            props.handleChangePassword(editPw, props.field, props.index)
            setChanged(true)
        }
        if(editPw == "") props.handleChangePassword("", props.field, props.index)
        setEditMode(false)
        // handleViewCryptedPassword()
        if(!isFromPlugFormCode && typeof plugnote !== "undefined" && plugnote !== null && typeof props.handleSavePassword !== "undefined"){
            props.handleSavePassword();
        }
    }

    const handleEditPw = (event) => {
        if(event.key != 'Enter') setEditPw(event.target.value);
        else saveEditedPw()
    }

    const { t, i18n } = useTranslation(['common', 'login']);

    useEffect(() => {
        setDecryptPassword(props.password);
        if(decryptPassword.length > 0) {
            setRetrieved(true);
            // setEditPw(decryptPassword)
        }
    }, [props.password, visible]);

    useEffect(()=> {
        if(props.field.value === "") setEditMode(true);
        else {
            setPassword("");
            setEncryptPassword(props.field.value)
        }
    }, [props.field.value])

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    useEffect(() => {
        props.attemptResetDataPassword();
    }, [])

    const handleCopy = () => {
        if(!disabled) navigator.clipboard.writeText(decryptPassword)
    }

    // upgrade envisageable : en hidden, '•••••••••••••••••••' a la même longueur que le mdp
    return (
        <div>
            {!visible?
            <Input
                autoComplete='new-password'
                id={props.field.id + '-' + props.field.format + '-visible'}
                placeholder="password"
                disabled={disabled || !editMode}
                fullWidth={true}
                style={{ margin: 0, display: editMode? 'none' : null}}
                inputProps={{ maxLength: 40, fontStyle: 'italic', color: `${colors.plugnotesBlueApp}!important`, }}
                type={visibleEncodable ? "text" : "password"}
                margin="none"
                disableUnderline={true}
                className={classes.inputField}
                endAdornment={ !disabled &&
                    <InputAdornment position="end">
                        { !disabled && decryptPassword.length ?
                        <Tooltip title="Copy to clipboard" placement="top">
                            <div className={classes.inputBtn} onClick={(event) => handleCopy(event)}>
                                <span className="material-icons">
                                    content_copy
                                </span>
                            </div>
                        </Tooltip>
                        : null}
                        { !disabled &&
                        <Tooltip title="Edit password" placement="top">
                            <div className={`material-icons ${classes.inputBtn}`} onClick={(event) => {toggleEncryptionPasswordVisibility(event); setEditMode(true); document.getElementById(props.field.id + '-' + props.field.format + '-edit').focus()}}>
                                edit
                            </div>
                        </Tooltip>
                        }
                        {
                            !isFromPlugFormCode && !!plugnote &&
                            <Tooltip title={visible? "Hide password" : "See password"} placement="top">
                                <div className={classes.inputBtn} onClick={(event) => changed? togglePasswordVisibility(event) : toggleEncryptionPasswordVisibility(event)}>
                                <span className="material-icons">
                                    {visibleEncodable? 'visibility_off' : 'visibility'}
                                </span>
                                </div>
                            </Tooltip>
                        }
                        {/* { encryptPassword .length > 0 &&
                            <div onClick={(event) => toggleEncryptionPasswordVisibility(event)}>
                                <span className="material-icons">
                                    {visible? 'no_encryption' : 'enhanced_encryption'}
                                </span>
                            </div>
                        } */}
                    </InputAdornment>}
                onChange={(event) => {
                    setPassword(event.target.value);
                    props.handleChangePassword(event.target.value, props.field, props.index)}
                }
                value={changed? editPw : visible ? null : editPw.length? editPw : visualPW}
            />
            :null}
            {   //Champ montrant le mdp de la db
                (visible && !editMode) || (editMode && decryptPassword.length) ?
                    <TextField
                        label={editMode? ``: "Current password"}
                        InputLabelProps={{
                            shrink: true,
                            className: classes.inputLabel
                        }}
                        fullWidth={true}
                        value={decryptPassword}
                        disabled={true}
                        className={classes.pwField}
                        InputProps={{ disableUnderline: true,
                        endAdornment:!disabled && !editMode &&
                            <InputAdornment position="end">
                                { !disabled && decryptPassword.length ?
                                <Tooltip title="Copy to clipboard" placement="top">
                                    <div onClick={(event) => handleCopy(event)} className={classes.inputBtn}>
                                        <span className="material-icons">
                                            content_copy
                                        </span>
                                    </div>
                                </Tooltip>
                                : null}
                                { !disabled &&
                                <Tooltip title="Edit password" placement="top">
                                    <div className={classes.inputBtn} onClick={(event) => {setEditMode(true); document.getElementById(props.field.id + '-' + props.field.format + '-edit').focus()}}>
                                        <span className="material-icons">
                                            edit
                                        </span>
                                    </div>
                                </Tooltip>
                                }
                                {
                                    !isFromPlugFormCode && !!plugnote &&
                                    <Tooltip title={visible? "Hide password" : "See password"}  placement="top">
                                        <div className={classes.inputBtn} onClick={(event) => toggleEncryptionPasswordVisibility(event)}>
                                        <span className="material-icons">
                                            {visible? 'visibility_off' : 'visibility'}
                                        </span>
                                        </div>
                                    </Tooltip>
                                }
                                {/* { encryptPassword .length > 0 &&
                                    <div onClick={(event) => toggleEncryptionPasswordVisibility(event)}>
                                        <span className="material-icons">
                                            {visible? 'no_encryption' : 'enhanced_encryption'}
                                        </span>
                                    </div>
                                } */}
                            </InputAdornment>}}
                    />
            :null}

            <Input
                autoComplete='new-password'
                id={props.field.id + '-' + props.field.format + '-edit'}
                placeholder="New password"
                fullWidth={true}
                style={{ margin: 0}}
                inputProps={{ maxLength: 40 }}
                // type={visibleEncodable ? "text" : "password"}
                margin="none"
                disableUnderline={true}
                className={editMode?classes.inputField : classes.hiddenField}
                onChange={(event) => handleEditPw(event)}
                onKeyPress={(event) => handleEditPw(event)}
                value={editPw}
                endAdornment={editMode &&
                    <InputAdornment position="end">
                    <Tooltip title="Cancel" placement="top">
                        <div onClick={(event) => {setEditMode(false); }}>
                            <span className="material-icons">
                                highlight_off
                            </span>
                        </div>
                    </Tooltip>
                    <Tooltip title="Save password" placement="top">
                        <div onClick={(event) => saveEditedPw()}>
                            <span className="material-icons">
                                save
                            </span>
                        </div>
                    </Tooltip>
                    </InputAdornment>
                }
            />

        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state, props) => {
    let password = "";
    if(!!state.data.password && state.data.password !== null){
        if(typeof state.data.password[`field`+props.field.id] !== "undefined"){
            password = state.data.password[`field`+props.field.id];
        }
    }
    return {
        password : password,
        fetchingPassword : state.data.fetchingPassword,
        plugnote : state.plugcode.plugnote,
    }
}

const mapDispatchToProps = (dispatch) => ({
    attemptResetDataPassword: () => dispatch(reduxGenerateDataPasswordReset()),
    attemptGetPasswordField: (datahash, fieldId, userhash) => {
        dispatch(getDecryptPasswordRequest(datahash, fieldId, userhash));
        // console.log("getPw",dispatch);
    },

});

export default connect(mapStateToProps, mapDispatchToProps)(FormatPasswordComponent);





