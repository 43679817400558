import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {connect} from "react-redux";
import {getCurrentEnvironnement, getToken} from "../../utils/Common";
import '@contentful/forma-36-react-components/dist/styles.css';
import {getDataUniqueUserindexRequest} from "../../actions/data";
import Images from "../../assets/themes/Images";
import {camelCase} from "lodash";
import Tooltip from "@material-ui/core/Tooltip";
import {trackPromise} from "react-promise-tracker";
import axios from "axios";
import ApplicationConfig from "../../config/ApplicationConfig";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faFile,
    faFileArchive,
    faFileAudio,
    faFileCode,
    faFileExcel,
    faFilePdf,
    faFilePowerpoint,
    faFileVideo,
    faFileWord,
    faStickyNote
} from "@fortawesome/free-regular-svg-icons";
import ModalNoteComponent from "./ModalNoteComponent";
import Lightbox from "react-image-lightbox";
import { api } from "../../api";

function getModalStyle() {
    const top = 5;

    return {
        top: `${top}%`,
        margin:'auto',
        overflow: 'auto',
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

const zeroPad = (num, places) => String(num).padStart(places, '0');

const parseJson = (code) => {
    try {
        return JSON.parse(code);
    } catch (e) {
        return code;
    }
};

const modalStyles = {overlay: {zIndex: 10000}};
const modalStylesClosed = {overlay: {zIndex: 0}};


const useStyles = makeStyles((theme) => ({
    popup: {
        position: 'absolute',
        maxHeight: '90vh',
        padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        width: '90%',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    marginLeft:{
        marginLeft : '10px'
    },
    icon:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : 'black'
    },
    fieldImageSvg: {
        width: 25,
        height: 25,
    },
    fieldImage: {
        height: 10,
        width: 10
    },
    fieldNb: {
        fontSize: 10,
        padding: '0px 10px'
    }
}));

const ModalViewFicheComponent = (props) => {

    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = useState(getModalStyle);
    const [t, i18n] = useTranslation('common');

    const [key, setKey] = useState(0);
    const [fields, setFields] = useState([]);
    const environment = getCurrentEnvironnement();

    const [hash, setHash] = useState(props.owner !== null ? props.owner : environment.userhash);
    const [annexes, setAnnexes] = useState(null);
    const [locked, setLocked] = useState(true);
    const [selectedNote, setSelectedNote] = useState(null);
    const [openNote, setOpenNote] = useState(false);
    const [selectedPreviewAnnexe, setSelectedPreviewAnnexe] = useState(null);
    const [openModalPreview, setOpenModalPreview] = useState(false);
    const [images, setImages] = useState([null]);
    const [model, setModel] = useState(null)
    const [plugnote, setData] = useState(null)

    //      .fetch('GET', baseURL + userHash + "/" + action.dataHash + "/" + encodeURIComponent(action.annexe.name) + "." + action.annexe.type)
    const downloadEmployeeData = (event, annexe) => {
        event.preventDefault();
        event.stopPropagation();
        // Fetch the dynamically generated excel document from the server.
        trackPromise(
            axios.get(ApplicationConfig.apiUrl + 'annexe.json?annexehash=' + annexe.hash, {crossdomain: true,
                'headers': {
                    'X-AUTH-TOKEN': getToken(),
                }
            })
            .then((response) => {
                if(response.data.result){
                    const fileNameHeader = "x-suggested-filename";
                    const suggestedFileName = response.headers[fileNameHeader];
                    const userindex = zeroPad(plugnote.userindex, 5); // "000005"
                    const effectiveFileName = (suggestedFileName === undefined ? 'PN-' + environment.envIndex + '-' + userindex + '-' + annexe.name + '-' + moment().format('YYMMDDHHmmss') + '.' + annexe.type : suggestedFileName);
                    var a = document.createElement("a"); //Create <a>
                    a.href = response.data.base64; //Image Base64 Goes here
                    a.download = effectiveFileName; //File name Here
                    a.click(); //Downloaded file

                }
            })
            .catch((response) => {
                console.error("Could not Download the Excel report from the backend.", response);
            })
        )
    };

    const fetchModel = () => {
        let hash = props.owner === null ? environment.userhash : props.owner
        trackPromise(
            // api.get('modeluniquebyclefcode?clef=' + props.clef + '&uh=' + props.modelKey + '&umh=' + environment.userhash ),
            api.get(`dataindexunique.json?ux=${props.index}&uh=${hash}`),
            {
                crossdomain: true,
                'headers': {
                    'X-AUTH-TOKEN': getToken(),
                }
            })
            .then((response) => {
                // console.log('response', response);
                if(response.data.result) {
                    // console.log('response', response);
                    setModel(response.data.model)
                    setData(response.data.data)
                }})
            .catch( e => console.log(e))
    }

    const fetchData = () => {
        let hash = props.dataHash;
        trackPromise(
            api.get(`datauniquev2s/${hash}.json`),
            {
                crossdomain: true,
                'headers': {
                    'X-AUTH-TOKEN': getToken(),
                }
            })
            .then((response) => {
                // console.log('response', response);
                if(response.data.result) {
                    // console.log('response', response);
                    setModel(response.data.model)
                    setData(response.data.data)
                }})
            .catch( e => console.log(e))
    }

        //  .fetch('GET', baseURL + userHash + "/" + action.dataHash + "/" + encodeURIComponent(action.annexe.name) + "." + action.annexe.type)
    const getUrlPreviewAnnexe = (annexe) => {
        return ApplicationConfig.downloadUrl + environment.userhash + "/" + plugnote.hash + "/" + encodeURIComponent(annexe.name) + "." + annexe.type
    }

    const renderAnnexePreview = (annexeToPreview) => {
        switch (annexeToPreview.type.toLowerCase()) {
            case 'jpg':
            case 'JPG':
            case 'jpeg':
            case 'png':
            case 'PNG':
            case 'gif':
                return <img src={getUrlPreviewAnnexe(annexeToPreview)} alt={annexeToPreview.name} onClick={(event) => handlePreviewAnnexe(event,annexeToPreview.hash)} className={classes.pointer}/>;
                break;

            case 'mp4':
            case 'mkv':
            case 'avi':
            case 'mov':
                return <FontAwesomeIcon icon={faFileVideo} size="lg" style={{fontSize : '50', marginRight : '10'}}  />;
                break;

            case 'pdf':
                return <FontAwesomeIcon icon={faFilePdf} size="lg" style={{fontSize : '50', marginRight : '10'}}   onClick={(event) => handlePreviewPdfAnnexe(event,getUrlPreviewAnnexe(annexeToPreview))} className={classes.pointer}/>;
                break;

            case 'zip':
            case 'rar':
                return <FontAwesomeIcon icon={faFileArchive} size="lg" style={{fontSize : '50', marginRight : '10'}}  />;
                break;

            case 'mp3':
            case 'aac':
                return <FontAwesomeIcon icon={faFileAudio} size="lg" style={{fontSize : '50', marginRight : '10'}}   />;
                break;

            case 'html':
                return <FontAwesomeIcon icon={faFileCode} size="lg" style={{fontSize : '50', marginRight : '10'}}  />;
                break;

            case 'txt':
            case 'sheet':
            case 'doc':
            case 'docx':
                return <FontAwesomeIcon icon={faFileWord} size="lg" style={{fontSize : '50', marginRight : '10'}}  />;
                break;

            case 'xls':
            case 'xlsx':
            case 'csv':
                return <FontAwesomeIcon icon={faFileExcel} size="lg" style={{fontSize : '50', marginRight : '10'}}  />;
                break;

            case 'note':
                return <FontAwesomeIcon icon={faStickyNote} size="lg" style={{fontSize : '50', marginRight : '10'}}   />;
                break;

            case 'pages':
                return <FontAwesomeIcon icon={faFileWord} size="lg" style={{fontSize : '50', marginRight : '10'}}  />;
                break;

            case 'pptx':
            case 'ppt':
                return <FontAwesomeIcon icon={faFilePowerpoint} size="lg" style={{fontSize : '50', marginRight : '10'}}  />;
                break;
            default :
                return <FontAwesomeIcon icon={faFile} size="lg" style={{fontSize : '50', marginRight : '10'}}/>;
                break;
        }
    };

    const handlePreviewAnnexeClose = () => {
        setOpenModalPreview(false);
        setSelectedPreviewAnnexe(null);
        setImages([]);
    }

    const handlePreviewAnnexe = (event,hash) => {
        event.preventDefault();
        event.stopPropagation();
        const images = [];
        plugnote.Annexes.forEach((annexeToPreview, index) => {
            switch (annexeToPreview.type.toLowerCase()) {
                case 'jpg':
                case 'JPG':
                case 'jpeg':
                case 'png':
                case 'PNG':
                case 'gif':
                    let url = getUrlPreviewAnnexe(annexeToPreview);
                    images.push(url);
                    if(hash === annexeToPreview.hash){
                        setSelectedPreviewAnnexe(images.length - 1);
                    }
                    break;
                default:
                    break;
            }
        });
        setImages(images);
        setOpenModalPreview(true);
    };

    const handlePreviewPdfAnnexe = (event,url) => {
        event.preventDefault();
        event.stopPropagation();
        window.open(url, "_blank");
        //setOpenPdfPreview(true);
        //setSelectedPreviewPdfAnnexe(url);
    };

    const handleCloseModal = () => {
        props.close();
    };

    const handleNoteOpen = (event,annexe) => {
        event.preventDefault();
        event.stopPropagation();
        if(typeof annexe !== "undefined"){
            setSelectedNote(annexe);
        }
        else{
            setSelectedNote(null);
        }
        setOpenNote(true);
    };

    const handleNoteClose = () => {
        setOpenNote(false);
        setSelectedNote(null);
    };

    useEffect(() => {
        // props.attemptGetPlugnoteByUserIndexAndUserHash(props.index,hash);
        if(props?.dataHash){
            fetchData();
        }else{
            fetchModel();
        }
    }, [props.open]);

    useEffect(() => {
        let fields = [];
        // console.log('model / plugnote / props', model, plugnote, props);
        if(plugnote !== null) {
            for (let i = 1; i <= 15; i++) {
                if ((props.mode.includes('plugcode') && model.mainOwner !== environment.userhash && model['field' + i + 'visible'] === 1) || model.mainOwner === environment.userhash) { // vérification de confidentialité
                    if (typeof plugnote['field' + i + 'label'] !== "undefined" && typeof plugnote['field' + i] !== "undefined" && plugnote['field' + i + 'label'] !== null && plugnote['field' + i].length > 0) {
                        let value = plugnote['field' + i];
                        if(plugnote['field' + i + 'format'] === 'select'){
                            try {
                                let values = JSON.parse(plugnote['field' + i]).sort((str1, str2) => {
                                    let num1 = parseInt(str1);
                                    let num2 = parseInt(str2);

                                    if (isNaN(num1) && isNaN(num2)) {
                                        if (str1 > str2)
                                            return 1;
                                        else if (str1 < str2)
                                            return -1;
                                        else
                                            return 0;
                                    }

                                    else if (isNaN(num1))
                                        return 1;
                                    else if (isNaN(num2))
                                        return -1;
                                    else if (num1 > num2)
                                        return 1;
                                    else if (num1 < num2)
                                        return -1;
                                    else
                                        return 0;
                                });
                                value = values.join(', ');
                            } catch (error) {
                                value = plugnote['field' + i];
                            }
                        }
                        else if(plugnote['field' + i + 'format'] === 'list'){
                            let plugValue = parseJson(plugnote['field' + i]);
                            if (typeof plugValue === 'string') {
                                if (plugValue) {
                                    value = [value];
                                }
                            } else if (plugValue == null) {
                            } else {
                                value = plugValue.reduce((res, obj) => {
                                    return res.concat(obj.text || '');
                                }, []).join(', ');
                            }
                        }
                        else if(plugnote['field' + i + 'format'] === 'table'){
                            let plugValue = parseJson(plugnote['field' + i]);
                            if (typeof plugValue === 'string') {
                                if (plugValue) {
                                    value = [value];
                                }
                            } else if (plugValue == null) {
                            } else {
                                value = plugValue.reduce((res, obj) => {
                                    return res.concat(obj.text + ' : ' + obj.value || '');
                                }, []).join(', ');
                            }
                        }
                        else if(plugnote['field' + i + 'format'] === 'plugform'){
                            let plugValue = parseJson(plugnote['field' + i]);
                            value = plugValue.value;
                        }
                        else if(plugnote['field' + i + 'format'] === 'qrcode'){
                            if(typeof value !== 'undefined' && value.length > 0){
                                let qrCode = "";
                                let comment = "";
                                if(value.startsWith(';')){
                                    let fieldValue  = value.substring(1);
                                    qrCode = '';
                                    comment = fieldValue;
                                }else{
                                    let fieldValue = value.split(/([^,]*);(.*)/);
                                    let fieldFilteredValue =  fieldValue.filter( a => a);
                                    qrCode = fieldFilteredValue[0];
                                    comment = fieldFilteredValue[1];
                                }

                                if(qrCode !== undefined && qrCode.length > 0){
                                    if (value.startsWith('PN') || value.startsWith('PP') || value.startsWith('PF') || value.startsWith('pn') || value.startsWith('pp') || value.startsWith('pf')){
                                        value = '(' + qrCode.toUpperCase() + ')';
                                    }
                                }

                                if(comment !== undefined && comment.length > 0){
                                    value = comment;
                                }

                                if(qrCode !== undefined && comment !== undefined && qrCode.length > 0 && comment.length > 0){
                                    value = '(' + qrCode.toUpperCase() + ') ' + comment;
                                }
                            }

                        }
                        else if(plugnote['field' + i + 'format'] === 'password'){
                            value = '*'.repeat(6);

                        }
                        else if(plugnote['field' + i + 'format'] === 'phone'){
                            value = <a href={`tel:${value}`}>{value}</a>;
                        }
                        else if(plugnote['field' + i + 'format'] === 'mail'){
                            value = <a href={`mailto:${value}`}>{value}</a>
                        }
                        else if(plugnote['field' + i + 'format'] === 'url'){
                            value = <a href={value} rel='noreferrer' target="_blank">{value}</a>
                        }
                        else if(plugnote['field' + i + 'format'] === 'glocalis'){
                            let plugValue = parseJson(value);
                            let tmp = "";
                            if(typeof plugValue.address !== "undefined" && plugValue.address.length > 0){
                                tmp = tmp.concat(plugValue.address);
                            }

                            if(typeof plugValue.latitude !== "undefined"  &&  typeof plugValue.longitude !== "undefined"){
                                if(tmp.length > 0){
                                    tmp = tmp.concat(' ~ ');
                                }
                                tmp = tmp.concat('lat : ' + plugValue.latitude + ' , lng : ' + plugValue.longitude);
                            }

                            value = tmp;
                        }

                        fields.push(
                            <div className="flex_display_direction_column" key={i} style={{marginBottom: 5}}>
                                <div className="flex_display_direction_row" >
                                    <div className="dixWidth">
                                        {
                                            plugnote['field' + i + 'format'] === 'now' ?
                                                <div className={classes.fieldLeft}>
                                                    {'(' + i + ')'}
                                                    <div className={classes.fieldIcon} >
                                                        <img
                                                            className={classes.fieldImage}
                                                            src={Images[camelCase(plugnote['field' + i + 'format']) + "Icon"]}
                                                            alt={t('fields.format-choice-' + plugnote['field' + i + 'format'])}
                                                        />
                                                    </div>
                                                </div>
                                                :
                                                <div>
                                                    <span className={classes.fieldNb}>{'(' + i + ')'}</span>
                                                    <img
                                                        className={classes.fieldImageSvg}
                                                        src={Images[camelCase(plugnote['field' + i + 'format']) + "IconSvg"]}
                                                        alt={t('fields.format-choice-' + plugnote['field' + i + 'format'])}
                                                    />
                                                </div>
                                        }
                                    </div>

                                    <div className="trenteWidth">
                                        <span className='indent' key={'label' + i}>{plugnote['field' + i + 'label']}</span>

                                    </div>

                                    <div className="soixanteWidth">
                                        <span className='breakText' key={'value' + i}>{value}</span>
                                    </div>

                                </div>
                                <div className="sous-header_content lightgrey" style={{marginTop: 5}}>
                                    <div className="line flex_style" />
                                </div>
                            </div>
                        );
                    }
                }
            }

            setAnnexes(
                <div>
                    { plugnote !== null && plugnote.Annexes.length > 0 &&
                    plugnote.Annexes.map((annexe, index) => {
                        return (
                            <div className="flexbox-container" key={index + 1} onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                            }}>
                                <div className="flexbox-item-annexe  plugnote-item m2">
                                    <div className="flexbox-piece_jointe">
                                        <div className="flexbox-item-annexe_texte flex_grow_65" style={{margin:0}}>
                                            <div className=" grey">
                                                <div className="black">
                                                    {
                                                        annexe.name
                                                    }
                                                </div>
                                                <div className="grey">
                                                    {
                                                        annexe.comment
                                                    }
                                                </div>
                                            </div>

                                            <div className="lightgrey" style={{ marginTop: 'auto' }}>
                                                {annexe.type !== 'note' &&
                                                    <span id="icon-file_copy" className="material-icons md-25" onClick={(event) => downloadEmployeeData(event, annexe)}>
                                                        get_app
                                                    </span>
                                                }

                                                {
                                                    annexe.type === 'note' &&
                                                    <span id="icon-file_copy" className="material-icons md-25" onClick={(event) => handleNoteOpen(event, annexe)}>
                                                        create
                                                    </span>
                                                }

                                            </div>

                                        </div>
                                        <div className="flexbox-item_img flex_grow_30">
                                            {
                                                renderAnnexePreview(annexe)
                                            }

                                        </div>
                                        <div className="flex_grow_5">
                                            {
                                                annexe.type === 'note' ?
                                                    <Tooltip title={'Type: ' + annexe.type} aria-label="add" placement="top-start">
                                                        <span id="icon-infos" className=" infos-piece_jointe material-icons green md-30 float_right" >
                                                            info
                                                        </span>
                                                    </Tooltip>
                                                    :

                                                    <Tooltip title={'Type: ' + annexe.type + ' | Size: ' + (annexe.size) / 1000 + 'Kb'} aria-label="add" placement="top-start">
                                                        <span id="icon-infos" className=" infos-piece_jointe material-icons green md-30 float_right" >
                                                            info
                                                        </span>
                                                    </Tooltip>
                                            }
                                            {annexe.groupid !== 0 ? <span>{annexe.groupid}</span> : <span />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )})
                    }
                </div>
            )
        }
        setFields(fields);
    }, [plugnote, model]);

    return (
        <Modal
            key={key}
            keepMounted={false}
            open={props.open}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div style={modalStyle} className={classes.popup}>
                <div className = "scrollbar-hidden popup_wrapper flexSpacebetween" >
                    <div className="popup_wrapper_content">
                        <div className="sous-header_modal_content">
                            <div className="text_align">
                                <span className="titres">
                                    <span className="align-items-center font_size_24">
                                        {plugnote !== null ? "[" + plugnote.modclef + "] " + plugnote.modname + " | " + environment.envIndex + "-" + plugnote.userindex  : ""}
                                    </span>
                                     <br/>
                                </span>
                            </div>
                            <div onClick={handleCloseModal}>
                                <span id="icon-close" className="material-icons md-50 lightgrey">
                                    close
                                </span>
                            </div>

                        </div>
                        <div className="sous-header_content lightgrey">
                            <div className="line flex_style">
                            </div>
                        </div>

                        <div className="popup_wrapper_content">
                            {
                                plugnote !== null &&
                                    <div>
                                        {fields}
                                        <div>
                                            {annexes}
                                        </div>
                                    </div>

                            }
                        </div>
                    </div>
                </div>

                {
                    openModalPreview && images.length > 0 &&
                    <Lightbox
                        reactModalStyle={openModalPreview ? modalStyles : modalStylesClosed}
                        mainSrc={images[selectedPreviewAnnexe]}
                        nextSrc={images[(selectedPreviewAnnexe + 1) % images.length]}
                        prevSrc={images[(selectedPreviewAnnexe + images.length - 1) % images.length]}
                        onCloseRequest={(event) => handlePreviewAnnexeClose()}
                        onMovePrevRequest={() =>
                            setSelectedPreviewAnnexe((selectedPreviewAnnexe + images.length - 1) % images.length)
                        }
                        onMoveNextRequest={() =>
                            setSelectedPreviewAnnexe((selectedPreviewAnnexe + 1)  % images.length)
                        }
                    />
                }
                {
                    openNote &&
                    <ModalNoteComponent
                        selectedNote={selectedNote} open={openNote} disabled={locked}
                        close={handleNoteClose} handleSaveNote={null} handleUpdateNote={null}/>
                }
            </div>
        </Modal>
    )
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        plugnote : state.data.unique || null,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        attemptGetPlugnoteByUserIndexAndUserHash: (userindex,userhash) => dispatch(getDataUniqueUserindexRequest(userindex, userhash)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalViewFicheComponent)
