import {call, fork, put, select, takeEvery} from 'redux-saga/effects';
import * as actions from '../actions/plugcode';
import * as modelActions from '../actions/model';
import * as dataActions from '../actions/data';
import * as api from '../api/plugcodeApi';
import * as modelApi from '../api/modelApi';
import * as dataApi from '../api/dataApi';
import {history} from '../hooks/history'
import {trackPromise} from "react-promise-tracker";
import {setAnnexes, setModel, setPlugnote} from "../utils/Common";

const myGenericTrackedWrapper = (fn, args1, args2, args3, args4, args5) => trackPromise(fn(args1,args2, args3, args4, args5));
const myGenericTrackedWrapper2 = (fn, args1, args2, args3, args4, args5,args6) => trackPromise(fn(args1,args2, args3, args4, args5,args6));

function forwardTo(location) {
    history.push(location);
}

/**
 * Permet de créer un modèle
 * @param api
 * @param action
 */
function * postGeneratePlugformcode (action) {

    /**
     * Quand on génére un pfcode, on doit d'abord initialiser le model à public, à cause de l'historique du projet.
     */

    let payload = {
        uh : action.userhash,
        mh : action.modelhash,
    };

    let payloadAccess = {
        is_public: '1'
    }

    if (!action.isPublic) {

        try {
            const responseAccess = yield call(myGenericTrackedWrapper,modelApi.updateAccess,action.modelhash, payloadAccess);
            if (!responseAccess.data || !responseAccess.data.result || responseAccess.data.result !== 'ok') {
                yield put(modelActions.postModelAccessFailure());
            }
        }catch (e) {
            yield put(modelActions.postModelAccessError());
        }
    }

    try {
        const response = yield call(myGenericTrackedWrapper,api.postGeneratePlugcodePlugform, payload);
        // success?
        if (response.statusText == "OK") {
            const uhm = yield select((state) => state.user.profil.hash);
            let responseModel = yield call(myGenericTrackedWrapper,modelApi.getV2ModelUnique,action.modelhash,action.userhash,uhm);
            if (responseModel.data.result === 'ok' && responseModel.data.data && responseModel.data.data.id) {
                const currentPlugform = responseModel.data.data;
                const currentLightPlugform = responseModel.data.light;
                yield put(actions.postGeneratePlugformcodeSuccess(currentPlugform));
                yield put(modelActions.putModelUniqueSuccess(currentLightPlugform));
            }
        } else {
            yield put(actions.postGeneratePlugformcodeFailure())
        }
    }catch(e) {
        yield put(actions.postGeneratePlugformcodeError());
    }
}

function * putGeneratePlugformcode (action) {

    try {
        const responseAccess = yield call(myGenericTrackedWrapper,modelApi.updateAccess,action.modelhash, action.accessPayload);
        if (!responseAccess.data || !responseAccess.data.result || responseAccess.data.result !== 'ok') {
            yield put(modelActions.postModelAccessFailure());
        }
    }catch (e) {
        yield put(modelActions.postModelAccessError());
    }

    try {
        const response = yield call(myGenericTrackedWrapper,api.updatePlugcodePlugform, action.payload);
        // success?
        if (response.statusText == "OK") {
            const uhm = yield select((state) => state.user.profil.hash);
            let responseModel = yield call(myGenericTrackedWrapper,modelApi.getV2ModelUnique,action.modelhash,action.userhash,uhm);
            if (responseModel.data.result === 'ok' && responseModel.data.data && responseModel.data.data.id) {
                const currentPlugform = responseModel.data.data;
                const currentLightPlugform = responseModel.data.light;
                //yield put(actions.putGeneratePlugformcodeSuccess(currentPlugform));
                yield put(modelActions.putModelUniqueSuccess(currentLightPlugform));
            }
        }
        else {
            yield put(actions.putGeneratePlugformcodeFailure())
        }
    }catch(e) {
        yield put(actions.putGeneratePlugformcodeError());
    }
}

function * deleteGeneratePlugcormcode (action) {
    try {
        const response = yield call(myGenericTrackedWrapper,api.deleteGeneratePlugcodePlugform, action.modelhash, action.modelpfc);

        if (response.statusText == "OK" && response.data.result === 'ok') {
            const uhm = yield select((state) => state.user.profil.hash);
            let responseModel = yield call(myGenericTrackedWrapper,modelApi.getV2ModelUnique,action.modelhash,action.userhash,uhm);
            if (responseModel.data.result === 'ok' && responseModel.data.data && responseModel.data.data.id) {
                const currentPlugform = responseModel.data.data;
                const currentLightPlugform = responseModel.data.light;
                yield put(actions.deleteGeneratePlugformcodeSuccess(currentPlugform));
                yield put(modelActions.putModelUniqueSuccess(currentLightPlugform));
            }
        }
        else {
            yield put(actions.deleteGeneratePlugformcodeFailure());
        }
    }catch (e) {
        yield put(actions.deleteGeneratePlugformcodeError());

    }
}

/**
 * Récupération d'une Plugnote via un PlugCode ou le scan d'un PlugCode
 * @param api
 * @param actions
 */
export function * getPlugnoteViaCode (action) {
    try {
        const response = yield call(myGenericTrackedWrapper,api.getPlugnoteWithCodeLogin, action.plugcode, action.userhash);
        if (response.statusText == "OK" && typeof response.data.result === 'undefined') {
            setModel(response.data.model)
            setPlugnote(response.data.data);
            setAnnexes(response.data.data.Annexes);
            yield put(actions.getPlugnoteViaCodeSuccess(response.data));
            if(!action.exist){
                yield call(forwardTo, '/plugcode-consult');
            }
            else{
                yield call(forwardTo, '/plugcode-consult-gallery');
            }
        } else {
            yield put(actions.getPlugnoteViaCodeFailure())
        }
    }catch(e) {
        yield put(actions.getPlugnoteViaCodeError());
    }
}

/**
 * Récupération d'une Plugnote via un PlugCode ou le scan d'un PlugCode
 * @param api
 * @param actions
 */
export function * getOldPlugnoteViaCode (action) {
    try {
        const response = yield call(myGenericTrackedWrapper,api.getPlugnoteWithCodeLogin, action.plugcode, action.userhash);
        if (response.statusText == "OK" && typeof response.data.result === 'undefined') {
            yield put(actions.getPlugnoteViaCodeSuccess(response.data));
            if(!action.exist){
                yield call(forwardTo, '/old-plugcode-consult');
            }else{
                yield call(forwardTo, '/old-plugcode-consult-gallery');
            }
        } else {
            yield put(actions.getPlugnoteViaCodeFailure())
        }
    }catch(e) {
        yield put(actions.getPlugnoteViaCodeError());
    }
}

/**
 * Récupération d'un PlugForm via un PlugCode ou le scan d'un PlugCode
 * @param api
 * @param actions
 */
export function * getPlugformViaCode (action) {
    try {
        const response = yield call(myGenericTrackedWrapper,api.getPlugformWithCodeLogin, action.plugcode, action.userhash);
        // success?
        if (response.statusText == "OK" && response.data && response.data.data  && response.data.result === 'ok') {
            let payload = {
                data : response.data.data,
                proprio : !!response.data.proprio ? response.data.proprio : undefined
            }
            yield put(actions.getPlugformViaCodeSuccess(payload));
            if(!action.exist){
                yield call(forwardTo, '/plugcode-complete');
            }else{
                yield call(forwardTo, '/plugcode-complete-gallery');
            }
        } else {
            yield put(actions.getPlugformViaCodeFailure())
        }
    }catch(e) {
        yield put(actions.getPlugformViaCodeError());
    }

}

/**
 * Récupération d'un PlugForm via un PlugCode ou le scan d'un PlugCode
 * @param api
 * @param actions
 */
export function * getOldPlugformViaCode (action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.getPlugformWithCodeLogin, action.plugcode, action.userhash);
        // success?
        if (response.statusText == "OK" && response.data && response.data.data  && response.data.result === 'ok') {
            let payload = {
                data : response.data.data,
                proprio : !!response.data.proprio ? response.data.proprio : undefined
            }
            yield put(actions.getPlugformViaCodeSuccess(payload));
            if(!action.exist){
                yield call(forwardTo, '/old-plugcode-complete');
            }else{
                yield call(forwardTo, '/old-plugcode-complete-gallery');
            }
        } else {
            yield put(actions.getPlugformViaCodeFailure())
        }
    }catch(e) {
        yield put(actions.getPlugformViaCodeError());
    }

}

/**
 * Récupération d'une Plugnote via un PlugCode ou le scan d'un PlugCode
 * @param api
 * @param actions
 */
export function * getPlugnoteViaCodeOffline (action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.getPlugnoteWithCodeLogoff, action.plugcode);
        if (response.statusText == "OK" && typeof response.data.result === 'undefined') {
            yield put(actions.getPlugnoteViaCodeOfflineSuccess(response.data));
        } else {
            yield put(actions.getPlugnoteViaCodeOfflineFailure())
        }
    }catch(e) {
        yield put(actions.getPlugnoteViaCodeOfflineError());
    }
}

/**
 * Récupération d'un PlugForm via un PlugCode ou le scan d'un PlugCode
 * @param api
 * @param actions
 */
export function * getPlugformViaCodeOffline (action) {

    try {
        const response = yield call(myGenericTrackedWrapper2,api.getPlugformWithCodeAndFolderLogoff, action.plugcode, action.folder, action.fiche,action.owner);
        // success?
        if (response.statusText == "OK" && response.data && response.data.data  && response.data.result === 'ok') {
            let payload = {
                data : response.data.data,
                link : typeof response.data.link !== "undefined" ? response.data.link : null,
                plug : typeof response.data.plug !== "undefined" ? response.data.plug : null,
                proprio : !!response.data.proprio ? response.data.proprio : undefined
            }
            yield put(actions.getPlugformViaCodeOfflineSuccess(payload));

        } else {
            yield put(actions.getPlugformViaCodeOfflineFailure())
        }
    }catch(e) {
        yield put(actions.getPlugformViaCodeOfflineError());
    }

}

export function * postClassifyInItsPlugform (action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.postClassifyInItsPlugform, action.userhash,action.plugnote.pncode,action.datahash);
        // success?
        if (response.data && response.data.result && response.data.result === 'ok') {
            yield put(actions.postClassifyInItsPlugformSuccess());
            yield call(forwardTo, '/models');
        } else {
            yield put(actions.postClassifyInItsPlugformFailure())
        }
    }catch(e) {
        yield put(actions.postClassifyInItsPlugformError());
    }

}


export function * postClassifyInAnotherPlugform (action) {
    try {

        let payload = {
            main_data: {},
            annexes: [],
            editLabel: action.editLabel
        }

        // Ajout des différents champs à créer dans la nouvelle Plugnote

        action.fields.forEach(fieldContainer => {
            payload.main_data[`field${fieldContainer.id}`] = fieldContainer.format.indexOf('select') > -1 || fieldContainer.format === 'list' || Array.isArray(fieldContainer.value) ? JSON.stringify(fieldContainer.value) : fieldContainer.value;
            payload.main_data[`field${fieldContainer.id}label`] = fieldContainer.label;
            payload.main_data[`field${fieldContainer.id}format`] = fieldContainer.format.indexOf('select') > -1 ? 'select' : fieldContainer.format;
        })

        // Ajout des annexes sélectionnées dans la nouvelle Plugnotes

        if(action.annexes.length > 0){
            action.annexes.forEach(annexe => {
                payload.annexes.push({
                    hash: annexe.hash,
                    datahash: action.plugnote.hash,
                    userhash: action.userhash
                })
            });
        }

        const response = yield call(myGenericTrackedWrapper,api.postClassifyInAnotherPlugform, action.userhash,action.plugnote.pncode,action.modelhash,payload);
        // success?
        if (response.data && response.data.result && response.data.result === 'ok') {
            yield put(actions.postClassifyInAnotherPlugformSuccess());
            yield call(forwardTo, '/models');
        } else {
            yield put(actions.postClassifyInAnotherPlugformFailure())
        }
    }catch(e) {
        yield put(actions.postClassifyInAnotherPlugformError());
    }
}

export function * postClassifyInAnotherPlugformMove (action) {
    try {

        let payload = {
            main_data: {},
            annexes: [],
            editLabel: action.editLabel,
            move : action.move,
        }

        // Ajout des différents champs à créer dans la nouvelle Plugnote

        action.fields.forEach(fieldContainer => {
            payload.main_data[`field${fieldContainer.id}`] = fieldContainer.format.indexOf('select') > -1 || fieldContainer.format === 'list' || Array.isArray(fieldContainer.value) ? JSON.stringify(fieldContainer.value) : fieldContainer.value;
            payload.main_data[`field${fieldContainer.id}label`] = fieldContainer.label;
            payload.main_data[`field${fieldContainer.id}format`] = fieldContainer.format.indexOf('select') > -1 ? 'select' : fieldContainer.format;
        })

        // Ajout des annexes sélectionnées dans la nouvelle Plugnotes

        if(action.annexes.length > 0){
            action.annexes.forEach(annexe => {
                payload.annexes.push({
                    hash: annexe.hash,
                    datahash: action.plugnote.hash,
                    userhash: action.userhash
                })
            });
        }

        const response = yield call(myGenericTrackedWrapper,api.postClassifyInAnotherPlugformFromPlugList, action.userhash,action.plugnote.hash,action.modelhash,payload);
        // success?
        if (response.data && response.data.result && response.data.result === 'ok') {
            yield put(actions.postClassifyInAnotherPlugformSuccess());
            yield call(forwardTo, '/models');
        } else {
            yield put(actions.postClassifyInAnotherPlugformFailure())
        }
    }catch(e) {
        yield put(actions.postClassifyInAnotherPlugformError());
    }
}

function * postGeneratePlugnotecode (action) {

    try {

        const payload = {
            ...action.payload,
            uh: action.userhash,
            public: action.isPublic
        }

        const response = yield call(myGenericTrackedWrapper,api.postGeneratePlugcodePlugnote, payload);
        // success?
        if (response.statusText == "OK") {
            let responseData = yield call(myGenericTrackedWrapper,dataApi.getDataUnique,action.payload.dh);
            if (response.statusText == "OK" && responseData.data && responseData.data.id) {
                const currentPlugnote = responseData.data;
                setPlugnote(currentPlugnote)
                yield put(actions.postGeneratePlugnotecodeSuccess(currentPlugnote));
                yield put(dataActions.putDataUniqueSuccess(currentPlugnote));
            }
        } else {
            yield put(actions.postGeneratePlugnotecodeFailure())
        }
    }catch(e) {
        yield put(actions.postGeneratePlugnotecodeError());
    }
}

function * deleteGeneratePlugnotecode (action) {
    try {
        const response = yield call(myGenericTrackedWrapper,api.deleteGeneratePlugcodePlugnote, action.datahash, action.datapnc);

        if (response.statusText == "OK" && response.data.result === 'ok') {
            let responseData = yield call(myGenericTrackedWrapper,dataApi.getDataUnique,action.datahash);
            if (response.statusText == "OK" && responseData.data && responseData.data.id) {
                const currentPlugnote = responseData.data;
                yield put(actions.postGeneratePlugnotecodeSuccess(currentPlugnote));
                yield put(dataActions.putDataUniqueSuccess(currentPlugnote));
            }
        }
        else {
            yield put(actions.deleteGeneratePlugnotecodeFailure());
        }
    }catch (e) {
        yield put(actions.deleteGeneratePlugnotecodeError());

    }
}

function * putGeneratePlugnotecode (action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.updatePlugcodePlugnote, action.payload);
        // success?
        if (response.statusText == "OK") {
            let responseData = yield call(myGenericTrackedWrapper,dataApi.getDataUnique,action.plugnote.hash);
            if (response.statusText == "OK" && responseData.data && responseData.data.id) {
                const currentPlugnote = responseData.data;
                yield put(actions.putGeneratePlugnotecodeSuccess(currentPlugnote));
                yield put(dataActions.putDataUniqueSuccess(currentPlugnote));
            }
        }
        else {
            yield put(actions.putGeneratePlugnotecodeFailure())
        }
    }catch(e) {
        yield put(actions.putGeneratePlugnotecodeError());
    }
}

function * putGeneratePsPlugnotecode (action) {


    try {

        let payload = {
            dh :action.datahash,
            pnc : action.pnhash,
            uh : action.userhash,
            listUsers : action.listUsers,
            comment : action.comment,
            public : action.public
        }

        const response = yield call(myGenericTrackedWrapper,api.updatePsPlugcodePlugnote, payload);
        // success?
        if (response.statusText == "OK") {
            let responseData = yield call(myGenericTrackedWrapper,dataApi.getDataUnique,action.plugnote.hash);
            if (response.statusText == "OK" && responseData.data && responseData.data.id) {
                const currentPlugnote = responseData.data;
                yield put(dataActions.putDataUniqueSuccess(currentPlugnote));
                yield put(actions.putGeneratePlugnotecodeSuccess(currentPlugnote));
            }
        }
        else {
            yield put(actions.putGeneratePlugnotecodeFailure())
        }
    }catch(e) {
        yield put(actions.putGeneratePlugnotecodeError());
    }
}


function * postMultipleGeneratePlugnotecode (action) {

    try {

        const payload = {
            mh: action.modelhash,
            uh: action.userhash,
            public: "1"
        }

        const response = yield call(myGenericTrackedWrapper,api.postGenerateMultiplePlugcodePlugnote, payload);
        // success?
        if (response.statusText == "OK" && response.data.result === 'ok') {
            yield put(actions.postModelGenerateMultiplePlugcodeSuccess())
        } else {
            yield put(actions.postModelGenerateMultiplePlugcodeFailure())
        }
    }catch(e) {
        yield put(actions.postModelGenerateMultiplePlugcodeError());
    }
}


function * getCorporateUsersGroups (action) {


    try {
        const response = yield call(myGenericTrackedWrapper,api.getCorporateUsersGroups, action.group);
        // success?
        if (response.statusText == "OK" && response.data.result) {
            yield put(actions.getCorporateUsersGroupsSuccess(response.data.group,response.data.datas, response.data.subgroup, response.data.listusersgroup));
        }
        else {
            yield put(actions.getCorporateUsersGroupsFailure())
        }
    }catch(e) {
        yield put(actions.getCorporateUsersGroupsError());
    }
}

/**
 * Vérification si le mail existe
 * @param api
 * @param actions
 */
export function * postCheckEmail (action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.postCheckEmail,{ email: action.email });
        if (response.data.result === 'ok') {
            yield put(actions.postCheckEmailSuccess(action.email, response.data.data))
        } else {
            yield put(actions.postCheckEmailFailure())
        }
    }catch(e) {
        yield put(actions.postCheckEmailError());
    }
}

/**
 * Vérification si le mail existe
 * @param api
 * @param actions
 */
export function * postUpdatePlugcodeUser (action) {

    try {
        const responseMailExist = yield call(myGenericTrackedWrapper,api.postCheckEmail,{ email: action.email });
        if (responseMailExist.data.result === 'ok') {
            yield put(actions.postCheckEmailSuccess(action.email, responseMailExist.data.data))
            try {
                action.payload.uh = responseMailExist.data.data;
                const response = yield call(myGenericTrackedWrapper,api.postUpdatePlugcodeUser,action.payload);
                if (response.data.result === 'ok') {
                    let responseData = yield call(myGenericTrackedWrapper,dataApi.getDataUnique,action.payload.dh);
                    if (response.statusText == "OK" && responseData.data && responseData.data.id) {
                        const currentPlugnote = responseData.data;
                        yield put(actions.postAddPlugcodePlugnoteUserSuccess(currentPlugnote))
                        yield put(dataActions.putDataUniqueSuccess(currentPlugnote));
                    }else{
                        yield put(actions.postAddPlugcodePlugnoteUserFailure())
                    }
                } else {
                    yield put(actions.postAddPlugcodePlugnoteUserFailure())
                }
            }catch(e) {
                yield put(actions.postAddPlugcodePlugnoteUserError());
            }
        } else {
            yield put(actions.postCheckEmailFailure())
        }
    }catch(e) {
        yield put(actions.postCheckEmailError());
    }
}

/**
 * Vérification si le mail existe
 * @param api
 * @param actions
 */
export function * delDeletePlugcodeUser (action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.delDeletePlugcodeUser,action.payload);
        if (response.data.result === 'ok') {
            let responseData = yield call(myGenericTrackedWrapper,dataApi.getDataUnique,action.payload.dh);
            if (response.statusText == "OK" && responseData.data && responseData.data.id) {
                const currentPlugnote = responseData.data;
                yield put(actions.delDeletePlugcodePlugnoteUserSuccess(currentPlugnote))
                yield put(dataActions.putDataUniqueSuccess(currentPlugnote));
            }else{
                yield put(actions.delDeletePlugcodePlugnoteUserFailure())
            }
        } else {
            yield put(actions.delDeletePlugcodePlugnoteUserFailure())
        }
    }catch(e) {
        yield put(actions.delDeletePlugcodePlugnoteUserError())
    }
}

function* fetchPlugformCode(action) {
    try {
        const response = yield call(myGenericTrackedWrapper,modelApi.getModelUnique, action.modelhash, action.userhash, action.usermainhash);
        if(response.data.result === 'ok'){
            yield put(actions.getPlugformcodeSuccess(response.data.data));
        }else{
            yield put(actions.getPlugformcodeFailure());
        }
    }catch(e) {
        yield put(actions.getPlugformcodeError());
    }
}

function* watchFetchPlugcode() {
    // create watcher of fetchData function
    yield takeEvery(actions.Types.POST_GENERATE_PLUGFORMCODE_REQUEST, postGeneratePlugformcode);
    yield takeEvery(actions.Types.POST_MODEL_GENERATE_MULTIPLE_PLUGCODE_REQUEST, postMultipleGeneratePlugnotecode);
    yield takeEvery(actions.Types.POST_GENERATE_PLUGNOTECODE_REQUEST, postGeneratePlugnotecode);
    yield takeEvery(actions.Types.UPDATE_GENERATE_PLUGFORMCODE_REQUEST, putGeneratePlugformcode);
    yield takeEvery(actions.Types.UPDATE_GENERATE_PLUGNOTECODE_REQUEST, putGeneratePlugnotecode);
    yield takeEvery(actions.Types.UPDATE_GENERATE_PS_PLUGNOTECODE_REQUEST, putGeneratePsPlugnotecode);
    yield takeEvery(actions.Types.DELETE_GENERATE_PLUGFORMCODE_REQUEST, deleteGeneratePlugcormcode);
    yield takeEvery(actions.Types.DELETE_GENERATE_PLUGNOTECODE_REQUEST, deleteGeneratePlugnotecode);
    yield takeEvery(actions.Types.GET_PLUGNOTE_VIA_CODE_REQUEST, getPlugnoteViaCode);
    yield takeEvery(actions.Types.GET_PLUGFORM_VIA_CODE_REQUEST, getPlugformViaCode);
    yield takeEvery(actions.Types.GET_OLD_PLUGNOTE_VIA_CODE_REQUEST, getOldPlugnoteViaCode);
    yield takeEvery(actions.Types.GET_OLD_PLUGFORM_VIA_CODE_REQUEST, getOldPlugformViaCode);
    yield takeEvery(actions.Types.GET_PLUGNOTE_VIA_CODE_OFFLINE_REQUEST, getPlugnoteViaCodeOffline);
    yield takeEvery(actions.Types.GET_PLUGFORM_VIA_CODE_OFFLINE_REQUEST, getPlugformViaCodeOffline);
    yield takeEvery(actions.Types.POST_CLASSIFY_IN_ITS_PLUGFORM_REQUEST, postClassifyInItsPlugform);
    yield takeEvery(actions.Types.POST_CLASSIFY_IN_ANOTHER_PLUGFORM_REQUEST, postClassifyInAnotherPlugform);
    yield takeEvery(actions.Types.POST_CLASSIFY_IN_ANOTHER_PLUGFORM_MOVE_REQUEST, postClassifyInAnotherPlugformMove);
    yield takeEvery(actions.Types.POST_CHECK_EMAIL_REQUEST, postCheckEmail);
    yield takeEvery(actions.Types.POST_ADD_PLUGCODE_PLUGNOTE_USER_REQUEST, postUpdatePlugcodeUser);
    yield takeEvery(actions.Types.DEL_DELETE_PLUGCODE_PLUGNOTE_USER_REQUEST, delDeletePlugcodeUser);
    yield takeEvery(actions.Types.GET_CORPORATE_USERS_GROUPS_REQUEST, getCorporateUsersGroups);
    yield takeEvery(actions.Types.GET_PLUGFORMCODE_REQUEST, fetchPlugformCode);

}

const PlugcodeSagas = [
    fork(watchFetchPlugcode)
];

export default PlugcodeSagas;
