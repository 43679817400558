import {Types} from '../actions/template';

// create initial state for reducers
const INIT_STATE = {
    fetchingTemplate : false,
    list_templates : [],
    list_group : [],
    list_models : [],
    list_categorie : [],
    model_by_code : null,
    open_model_code : false,
    open_list_models : false,
    open_list_group : false,
    open_list_categorie : false,
};

// reducer function to transform state
export default function template(state = INIT_STATE, action) {
    switch(action.type) {
        case Types.RESET_ALL_DATA : return INIT_STATE;
        case Types.RESET_TEMPLATE_HISTORY_REQUEST : return INIT_STATE;
        case Types.RESET_TEMPLATE_OPEN_LIST_MODELS_REQUEST :
            return {
                ...state,
                open_list_models: false,
            };
        case Types.RESET_TEMPLATE_OPEN_LIST_CATEGORIE_REQUEST :
            return {
                ...state,
                open_list_categorie: false,

            };
        case Types.RESET_TEMPLATE_MODEL_CODE_REQUEST :
            return {
                ...state,
                open_model_code: false,

            };
        case Types.RESET_TEMPLATE_OPEN_LIST_GROUP_REQUEST :
            return {
                ...state,
                open_list_group: false,
            };
        case Types.GET_TEMPLATE_ALL_REQUEST: {
            return {
                ...INIT_STATE,
                list_templates : [],
                fetchingTemplate: true,
            }
        }
        case Types.GET_TEMPLATE_CODE_CATEGORIE_REQUEST: {
            return {
                ...INIT_STATE,
                list_categorie : [],
                fetchingTemplate: true,
            }
        }
        case Types.GET_TEMPLATE_CODE_GROUP_REQUEST: {
            return {
                ...INIT_STATE,
                list_group : [],
                fetchingTemplate: true,
            }
        }
        case Types.GET_TEMPLATE_MODEL_CODE_REQUEST: {
            return {
                ...INIT_STATE,
                model_by_code : null,
                fetchingTemplate: true,
            }
        }
        case Types.GET_TEMPLATE_CODE_MODEL_REQUEST: {
            return {
                ...INIT_STATE,
                list_models : [],
                fetchingTemplate: true,
            }
        }
        case Types.GET_TEMPLATE_ALL_SUCCESS: {
            return {
                list_templates: action.payload.data,
                list_group: [],
                list_models: state.list_models,
                list_categorie: state.list_categorie,
                fetchingTemplate : false,
            }
        }
        case Types.GET_TEMPLATE_CODE_GROUP_SUCCESS: {
            return {
                ...INIT_STATE,
                list_group: action.payload.data,
                open_list_group: true
            }
        }
        case Types.GET_TEMPLATE_CODE_MODEL_SUCCESS: {
            return {
                ...INIT_STATE,
                list_models : action.payload.data,
                open_list_models: true
            }
        }
        case Types.GET_TEMPLATE_MODEL_CODE_SUCCESS: {
            return {
                ...INIT_STATE,
                model_by_code : action.payload.data,
                open_model_code: true
            }
        }
        case Types.GET_TEMPLATE_CODE_CATEGORIE_SUCCESS: {
            return {
                ...INIT_STATE,
                list_categorie: action.payload.data,
                open_list_categorie: true
            }
        }

        case Types.GET_TEMPLATE_CODE_CATEGORIE_ERROR:
        case Types.GET_TEMPLATE_MODEL_CODE_ERROR:
        case Types.GET_TEMPLATE_MODEL_CODE_FAILURE:
        case Types.GET_TEMPLATE_CODE_CATEGORIE_FAILURE:
        case Types.GET_TEMPLATE_CODE_GROUP_ERROR:
        case Types.GET_TEMPLATE_CODE_GROUP_FAILURE:
        case Types.GET_TEMPLATE_CODE_MODEL_ERROR:
        case Types.GET_TEMPLATE_CODE_MODEL_FAILURE:{
            return INIT_STATE
        }
        default: return state;
    }
};
