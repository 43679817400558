// actions types is object with the
// key: GET_DATA_REQUEST -> value: describes the action
export const Types = {
    RESET_ALL_DATA: 'reset_all_data',
    REDUX_GENERATE_MAIL_IN_RESET: 'redux_generate_mail_in_reset',


    GET_MAIL_IN_REQUEST: 'get_mail_in_request',
    GET_MAIL_IN_SUCCESS: 'get_mail_in_success',
    GET_MAIL_IN_FAILURE: 'get_mail_in_failure',
    GET_MAIL_IN_ERROR: 'get_mail_in_error',

    GET_MAILS_IN_REQUEST: 'get_mails_in_request',
    GET_MAILS_IN_SUCCESS: 'get_mails_in_success',
    GET_MAILS_IN_FAILURE: 'get_mails_in_failure',
    GET_MAILS_IN_ERROR: 'get_mails_in_error',

    GET_MAILS_IN_END_REQUEST: 'get_mails_in_end_request',
    GET_MAILS_IN_END_SUCCESS: 'get_mails_in_end_success',
    GET_MAILS_IN_END_FAILURE: 'get_mails_in_end_failure',
    GET_MAILS_IN_END_ERROR: 'get_mails_in_end_error',

    GET_MAILS_IN_PAGINATE_REQUEST: 'get_mails_in_paginate_request',
    GET_MAILS_IN_PAGINATE_SUCCESS: 'get_mails_in_paginate_success',
    GET_MAILS_IN_PAGINATE_FAILURE: 'get_mails_in_paginate_failure',
    GET_MAILS_IN_PAGINATE_ERROR: 'get_mails_in_paginate_error',
};

/*
Token
 */
// function that returns an object literal
export const resetAllData = () => ({
    type: Types.RESET_ALL_DATA,
});

export const reduxGenerateMailInReset = () => ({
    type: Types.REDUX_GENERATE_MAIL_IN_RESET,
});


/*
Token
 */
// function that returns an object literal
export const getMailInRequest = (mid,userhash) => ({
    type: Types.GET_MAIL_IN_REQUEST,
    mid : mid,
    userhash : userhash
});

// function that returns an object literal
export const getMailInSuccess = (data) => ({
    type: Types.GET_MAIL_IN_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getMailInFailure = () => ({
    type: Types.GET_MAIL_IN_FAILURE,
});

// function that returns an object literal
export const getMailInError = () => ({
    type: Types.GET_MAIL_IN_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const getMailsInRequest = (userhash) => ({
    type: Types.GET_MAILS_IN_REQUEST,
    userhash : userhash
});

// function that returns an object literal
export const getMailsInSuccess = (data) => ({
    type: Types.GET_MAILS_IN_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getMailsInFailure = () => ({
    type: Types.GET_MAILS_IN_FAILURE,
});

// function that returns an object literal
export const getMailsInError = () => ({
    type: Types.GET_MAILS_IN_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const getMailsInPaginateRequest = (userhash, page) => ({
    type: Types.GET_MAILS_IN_PAGINATE_REQUEST,
    userhash : userhash,
    page : page
});

// function that returns an object literal
export const getMailsInPaginateSuccess = (data) => ({
    type: Types.GET_MAILS_IN_PAGINATE_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getMailsInPaginateFailure = () => ({
    type: Types.GET_MAILS_IN_PAGINATE_FAILURE,
});

// function that returns an object literal
export const getMailsInPaginateError = () => ({
    type: Types.GET_MAILS_IN_PAGINATE_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const getMailsInEndRequest = () => ({
    type: Types.GET_MAILS_IN_END_REQUEST,
});

// function that returns an object literal
export const getMailsInEndSuccess = (start, end) => ({
    type: Types.GET_MAILS_IN_END_SUCCESS,
    payload: { start },
    payload2: { end }
});

// function that returns an object literal
export const getMailsInEndFailure = () => ({
    type: Types.GET_MAILS_IN_END_FAILURE,
});

// function that returns an object literal
export const getMailsInEndError = () => ({
    type: Types.GET_MAILS_IN_END_ERROR,
});

