// actions types is object with the
// key: GET_DATA_REQUEST -> value: describes the action
import {toast} from "react-toastify";
import {Translation} from "react-i18next";
import React from "react";

export const Types = {

    RESET_ALL_DATA: 'reset_all_data',

    GET_PARAMS_REQUEST: 'get_params_request',
    GET_PARAMS_SUCCESS: 'get_params_success',
    GET_PARAMS_FAILURE: 'get_params_failure',
    GET_PARAMS_ERROR: 'get_params_error',


};

/*
    Login
 */

// function that returns an object literal
export const getParamsRequest = (userhash) => ({
    type: Types.GET_PARAMS_REQUEST,
    userhash : userhash,
});

// function that returns an object literal
export const getParamsSuccess = (data) => ({
    type: Types.GET_PARAMS_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getParamsFailure = () => ({
    type: Types.GET_PARAMS_FAILURE,
});

// function that returns an object literal
export const getParamsError = () => ({
    type: Types.GET_PARAMS_ERROR,
});


export const resetAllData = () => ({
    type: Types.RESET_ALL_DATA,
});

const ErrorToastMessage = ({ message }) => (
    <div>
        <Translation ns="common">
            {
                (t, { i18n }) => <h6>{t(message)}</h6>
            }
        </Translation>
    </div>

)
