// actions types is object with the
// key: GET_DATA_REQUEST -> value: describes the action

export const Types = {
    RESET_ALL_DATA: 'reset_all_data',

    REDUX_GENERATE_DATA_PASSWORD_RESET: 'redux_generate_data_password_reset',
    REDUX_GET_DATA_BY_CLEF_USER_INDEX_RESET: 'redux_get_data_by_clef_user_index_reset',

    GET_DATAS_REQUEST: 'get_datas_request',
    GET_DATAS_SUCCESS: 'get_datas_success',
    GET_DATAS_FAILURE: 'get_datas_failure',
    GET_DATAS_ERROR: 'get_datas_error',

    GET_DATAS_LIGHT_REQUEST: 'get_datas_light_request',
    GET_DATAS_LIGHT_SUCCESS: 'get_datas_light_success',
    GET_DATAS_LIGHT_FAILURE: 'get_datas_light_failure',
    GET_DATAS_LIGHT_ERROR: 'get_datas_light_error',

    GET_DATAS_SHARED_LIGHT_REQUEST: 'get_datas_shared_light_request',
    GET_DATAS_SHARED_LIGHT_SUCCESS: 'get_datas_shared_light_success',
    GET_DATAS_SHARED_LIGHT_FAILURE: 'get_datas_shared_light_failure',
    GET_DATAS_SHARED_LIGHT_ERROR: 'get_datas_shared_light_error',

    GET_DATAS_END_REQUEST: 'get_datas_end_request',
    GET_DATAS_END_SUCCESS: 'get_datas_end_success',
    GET_DATAS_END_FAILURE: 'get_datas_end_failure',
    GET_DATAS_END_ERROR: 'get_datas_end_error',

    GET_DATAS_PAGINATE_REQUEST: 'get_datas_paginate_request',
    GET_DATAS_PAGINATE_SUCCESS: 'get_datas_paginate_success',
    GET_DATAS_PAGINATE_FAILURE: 'get_datas_paginate_failure',
    GET_DATAS_PAGINATE_ERROR: 'get_datas_paginate_error',

    GET_DATAS_PAGINATE_LIGHT_REQUEST: 'get_datas_paginate_light_request',
    GET_DATAS_PAGINATE_LIGHT_SUCCESS: 'get_datas_paginate_light_success',
    GET_DATAS_PAGINATE_LIGHT_FAILURE: 'get_datas_paginate_light_failure',
    GET_DATAS_PAGINATE_LIGHT_ERROR: 'get_datas_paginate_light_error',

    GET_DATAS_SHARED_PAGINATE_LIGHT_REQUEST: 'get_datas_shared_paginate_light_request',
    GET_DATAS_SHARED_PAGINATE_LIGHT_SUCCESS: 'get_datas_shared_paginate_light_success',
    GET_DATAS_SHARED_PAGINATE_LIGHT_FAILURE: 'get_datas_shared_paginate_light_failure',
    GET_DATAS_SHARED_PAGINATE_LIGHT_ERROR: 'get_datas_shared_paginate_light_error',

    GET_DATAS_FILTER_REQUEST: 'get_datas_filter_request',
    GET_DATAS_FILTER_SUCCESS: 'get_datas_filter_success',
    GET_DATAS_FILTER_FAILURE: 'get_datas_filter_failure',
    GET_DATAS_FILTER_ERROR: 'get_datas_filter_error',

    GET_DATAS_FILTER_LIGHT_REQUEST: 'get_datas_filter_light_request',
    GET_DATAS_FILTER_LIGHT_SUCCESS: 'get_datas_filter_light_success',
    GET_DATAS_FILTER_LIGHT_FAILURE: 'get_datas_filter_light_failure',
    GET_DATAS_FILTER_LIGHT_ERROR: 'get_datas_filter_light_error',

    GET_DATA_BY_CLEF_USER_INDEX_REQUEST: 'get_data_by_clef_user_index_request',
    GET_DATA_BY_CLEF_USER_INDEX_SUCCESS: 'get_data_by_clef_user_index_success',
    GET_DATA_BY_CLEF_USER_INDEX_FAILURE: 'get_data_by_clef_user_index_failure',
    GET_DATA_BY_CLEF_USER_INDEX_ERROR: 'get_data_by_clef_user_index_error',

    GET_DATAS_BY_CLEF_PAGINATE_REQUEST: 'get_datas_by_clef_paginate_request',
    GET_DATAS_BY_CLEF_PAGINATE_SUCCESS: 'get_datas_by_clef_paginate_success',
    GET_DATAS_BY_CLEF_PAGINATE_FAILURE: 'get_datas_by_clef_paginate_failure',
    GET_DATAS_BY_CLEF_PAGINATE_ERROR: 'get_datas_by_clef_paginate_error',


    GET_DATAS_BY_CLEF_END_REQUEST: 'get_datas_by_clef_end_request',
    GET_DATAS_BY_CLEF_END_SUCCESS: 'get_datas_by_clef_end_success',
    GET_DATAS_BY_CLEF_END_FAILURE: 'get_datas_by_clef_end_failure',
    GET_DATAS_BY_CLEF_END_ERROR: 'get_datas_by_clef_end_error',

    GET_DATA_UNIQUE_REQUEST: 'get_data_unique_request',
    GET_DATA_UNIQUE_SUCCESS: 'get_data_unique_success',
    GET_DATA_UNIQUE_FAILURE: 'get_data_unique_failure',
    GET_DATA_UNIQUE_ERROR: 'get_data_unique_error',
    GET_DATA_UNIQUE_RESET: 'get_data_unique_reset',

    GET_DATA_UNIQUE_USERINDEX_REQUEST: 'get_data_unique_userindex_request',
    GET_DATA_UNIQUE_USERINDEX_SUCCESS: 'get_data_unique_userindex_success',
    GET_DATA_UNIQUE_USERINDEX_FAILURE: 'get_data_unique_userindex_failure',
    GET_DATA_UNIQUE_USERINDEX_ERROR: 'get_data_unique_userindex_error',

    POST_CREATE_DATA_REQUEST: 'post_create_data_request',
    POST_CREATE_DATA_SUCCESS: 'post_create_data_success',
    POST_CREATE_DATA_FAILURE: 'post_create_data_failure',
    POST_CREATE_DATA_ERROR: 'post_create_data_error',

    PUT_UPDATE_DATA_REQUEST: 'put_update_data_request',
    PUT_UPDATE_DATA_SUCCESS: 'put_update_data_success',
    PUT_UPDATE_DATA_FAILURE: 'put_update_data_failure',
    PUT_UPDATE_DATA_ERROR: 'put_update_data_error',

    PUT_UPDATE_DATA_FIELD_REQUEST: 'put_update_data_field_request',
    PUT_UPDATE_DATA_FIELD_SUCCESS: 'put_update_data_field_success',
    PUT_UPDATE_DATA_FIELD_FAILURE: 'put_update_data_field_failure',
    PUT_UPDATE_DATA_FIELD_ERROR: 'put_update_data_field_error',

    PUT_UPDATE_DATA_FIELD_MANUAL_REQUEST: 'put_update_data_field_manual_request',
    PUT_UPDATE_DATA_FIELD_MANUAL_SUCCESS: 'put_update_data_field_manual_success',
    PUT_UPDATE_DATA_FIELD_MANUAL_FAILURE: 'put_update_data_field_manual_failure',
    PUT_UPDATE_DATA_FIELD_MANUAL_ERROR: 'put_update_data_field_manual_error',

    POST_CREATE_SHARED_DATA_REQUEST: 'post_create_shared_data_request',
    POST_CREATE_SHARED_DATA_SUCCESS: 'post_create_shared_data_success',
    POST_CREATE_SHARED_DATA_FAILURE: 'post_create_shared_data_failure',
    POST_CREATE_SHARED_DATA_ERROR: 'post_create_shared_data_error',

    PUT_UPDATE_SHARED_DATA_REQUEST: 'put_update_shared_data_request',
    PUT_UPDATE_SHARED_DATA_SUCCESS: 'put_update_shared_data_success',
    PUT_UPDATE_SHARED_DATA_FAILURE: 'put_update_shared_data_failure',
    PUT_UPDATE_SHARED_DATA_ERROR: 'put_update_shared_data_error',

    PUT_UPDATE_DATA_FAVORIS_REQUEST: 'put_update_data_favoris_request',
    PUT_UPDATE_DATA_FAVORIS_SUCCESS: 'put_update_data_favoris_success',
    PUT_UPDATE_DATA_FAVORIS_FAILURE: 'put_update_data_favoris_failure',
    PUT_UPDATE_DATA_FAVORIS_ERROR: 'put_update_data_favoris_error',

    PUT_UPDATE_DATA_LOCK_REQUEST: 'put_update_data_lock_request',
    PUT_UPDATE_DATA_LOCK_SUCCESS: 'put_update_data_lock_success',
    PUT_UPDATE_DATA_LOCK_FAILURE: 'put_update_data_lock_failure',
    PUT_UPDATE_DATA_LOCK_ERROR: 'put_update_data_lockerror',

    PUT_UPDATE_DATA_ARCHIVE_REQUEST: 'put_update_data_archive_request',
    PUT_UPDATE_DATA_ARCHIVE_SUCCESS: 'put_update_data_archive_success',
    PUT_UPDATE_DATA_ARCHIVE_FAILURE: 'put_update_data_archive_failure',
    PUT_UPDATE_DATA_ARCHIVE_ERROR: 'put_update_data_archive_error',

    PUT_UPDATE_DATAS_ARCHIVE_REQUEST: 'put_update_datas_archive_request',
    PUT_UPDATE_DATAS_ARCHIVE_SUCCESS: 'put_update_datas_archive_success',
    PUT_UPDATE_DATAS_ARCHIVE_FAILURE: 'put_update_datas_archive_failure',
    PUT_UPDATE_DATAS_ARCHIVE_ERROR: 'put_update_datas_archive_error',

    PUT_UPDATE_DATAS_UNARCHIVE_REQUEST: 'put_update_datas_unarchive_request',
    PUT_UPDATE_DATAS_UNARCHIVE_SUCCESS: 'put_update_datas_unarchive_success',
    PUT_UPDATE_DATAS_UNARCHIVE_FAILURE: 'put_update_datas_unarchive_failure',
    PUT_UPDATE_DATAS_UNARCHIVE_ERROR: 'put_update_datas_unarchive_error',

    DEL_DELETE_DATA_REQUEST: 'del_delete_data_request',
    DEL_DELETE_DATA_SUCCESS: 'del_delete_data_success',
    DEL_DELETE_DATA_FAILURE: 'del_delete_data_failure',
    DEL_DELETE_DATA_ERROR: 'del_delete_data_error',

    DEL_DELETE_DATAS_REQUEST: 'del_delete_datas_request',
    DEL_DELETE_DATAS_SUCCESS: 'del_delete_datas_success',
    DEL_DELETE_DATAS_FAILURE: 'del_delete_datas_failure',
    DEL_DELETE_DATAS_ERROR: 'del_delete_datas_error',

    GET_DECRYPT_PASSWORD_REQUEST: 'get_decrypt_password_request',
    GET_DECRYPT_PASSWORD_SUCCESS: 'get_decrypt_password_success',
    GET_DECRYPT_PASSWORD_FAILURE: 'get_decrypt_password_failure',
    GET_DECRYPT_PASSWORD_ERROR: 'get_decrypt_password_error',

    PUT_DATA_UNIQUE_REQUEST: 'put_data_unique_request',
    PUT_DATA_UNIQUE_SUCCESS: 'put_data_unique_success',
    PUT_DATA_UNIQUE_FAILURE: 'put_data_unique_failure',
    PUT_DATA_UNIQUE_ERROR: 'put_data_unique_error',


    REDUX_SET_DATA_FILTER_REQUEST : 'redux_set_data_filter_request',
    REDUX_DEL_ALL_DATA_FILTER_REQUEST : 'redux_del_all_data_filter_request',
    REDUX_DEL_DATA_FILTER_REQUEST : 'redux_del_data_filter_request',

    REDUX_SET_GLOBAL_DATA_FILTER_REQUEST : 'redux_set_global_data_filter_request',
    REDUX_DEL_GLOBAL_DATA_FILTER_REQUEST : 'redux_del_global_data_filter_request',

    REDUX_SET_GLOBAL_API_DATA_FILTER_REQUEST : 'redux_set_global_api_data_filter_request',
    REDUX_DEL_GLOBAL_API_DATA_FILTER_REQUEST : 'redux_del_global_api_data_filter_request',


    POST_CREATE_DATA_WITHOUT_REDIRECT_REQUEST: 'post_create_data_without_redirect_request',
    POST_CREATE_DATA_WITHOUT_REDIRECT_SUCCESS: 'post_create_data_without_redirect_success',
    POST_CREATE_DATA_WITHOUT_REDIRECT_FAILURE: 'post_create_data_without_redirect_failure',
    POST_CREATE_DATA_WITHOUT_REDIRECT_ERROR: 'post_create_data_without_redirect_error',

    PUT_UPDATE_DATA_WITHOUT_REDIRECT_REQUEST: 'put_update_data_without_redirect_request',
    PUT_UPDATE_DATA_WITHOUT_REDIRECT_SUCCESS: 'put_update_data_without_redirect_success',
    PUT_UPDATE_DATA_WITHOUT_REDIRECT_FAILURE: 'put_update_data_without_redirect_failure',
    PUT_UPDATE_DATA_WITHOUT_REDIRECT_ERROR: 'put_update_data_without_redirect_error',

    PUT_UPDATE_DATA_WITHOUT_REDIRECT_MODAL_REQUEST: 'put_update_data_without_redirect_modal_request',
    PUT_UPDATE_DATA_WITHOUT_REDIRECT_MODAL_SUCCESS: 'put_update_data_without_redirect_modal_success',
    PUT_UPDATE_DATA_WITHOUT_REDIRECT_MODAL_FAILURE: 'put_update_data_without_redirect_modal_failure',
    PUT_UPDATE_DATA_WITHOUT_REDIRECT_MODAL_ERROR: 'put_update_data_without_redirect_modal_error',

    POST_CREATE_SHARED_DATA_WITHOUT_REDIRECT_REQUEST: 'post_create_shared_data_without_redirect_request',
    POST_CREATE_SHARED_DATA_WITHOUT_REDIRECT_SUCCESS: 'post_create_shared_data_without_redirect_success',
    POST_CREATE_SHARED_DATA_WITHOUT_REDIRECT_FAILURE: 'post_create_shared_data_without_redirect_failure',
    POST_CREATE_SHARED_DATA_WITHOUT_REDIRECT_ERROR: 'post_create_shared_data_without_redirect_error',

    PUT_UPDATE_SHARED_DATA_WITHOUT_REDIRECT_REQUEST: 'put_update_shared_data_without_redirect_request',
    PUT_UPDATE_SHARED_DATA_WITHOUT_REDIRECT_SUCCESS: 'put_update_shared_data_without_redirect_success',
    PUT_UPDATE_SHARED_DATA_WITHOUT_REDIRECT_FAILURE: 'put_update_shared_data_without_redirect_failure',
    PUT_UPDATE_SHARED_DATA_WITHOUT_REDIRECT_ERROR: 'put_update_shared_data_without_redirect_error',

    PUT_UPDATE_SHARED_DATA_WITHOUT_REDIRECT_MODAL_REQUEST: 'put_update_shared_data_without_redirect_modal_request',
    PUT_UPDATE_SHARED_DATA_WITHOUT_REDIRECT_MODAL_SUCCESS: 'put_update_shared_data_without_redirect_modal_success',
    PUT_UPDATE_SHARED_DATA_WITHOUT_REDIRECT_MODAL_FAILURE: 'put_update_shared_data_without_redirect_modal_failure',
    PUT_UPDATE_SHARED_DATA_WITHOUT_REDIRECT_MODAL_ERROR: 'put_update_shared_data_without_redirect_modal_error',

    GET_DATAS_BY_CODE_FOLDER_REQUEST: 'get_datas_by_code_folder_request',
    GET_DATAS_BY_CODE_FOLDER_SUCCESS: 'get_datas_by_code_folder_success',
    GET_DATAS_BY_CODE_FOLDER_FAILURE: 'get_datas_by_code_folder_failure',
    GET_DATAS_BY_CODE_FOLDER_ERROR: 'get_datas_by_code_folder_error',


};

export const reduxSetDataFilterRequest = (payload, key) => ({
    type: Types.REDUX_SET_DATA_FILTER_REQUEST,
    payload : payload,
    key : key
});

export const reduxDelDataFilterRequest = (payload, key) => ({
    type: Types.REDUX_DEL_DATA_FILTER_REQUEST,
    payload : payload,
    key : key
});


export const reduxDelAllDataFilterRequest = () => ({
    type: Types.REDUX_DEL_ALL_DATA_FILTER_REQUEST,
});

export const reduxSetGlobalDataFilterRequest = (payload) => ({
    type: Types.REDUX_SET_GLOBAL_DATA_FILTER_REQUEST,
    payload : payload,
});

export const reduxDelGlobalDataFilterRequest = (payload) => ({
    type: Types.REDUX_DEL_GLOBAL_DATA_FILTER_REQUEST,
    payload : payload,
});


export const reduxSetGlobalApiDataFilterRequest = (payload) => ({
    type: Types.REDUX_SET_GLOBAL_API_DATA_FILTER_REQUEST,
    payload : payload,
});

export const reduxDelGlobalApiDataFilterRequest = (payload) => ({
    type: Types.REDUX_DEL_GLOBAL_API_DATA_FILTER_REQUEST,
    payload : payload,
});

/*
Token
 */
// function that returns an object literal
export const getDatasRequest = (modelhash,userhash) => ({
    type: Types.GET_DATAS_REQUEST,
    modelhash : modelhash,
    userhash : userhash
});

// function that returns an object literal
export const getDatasSuccess = (data) => ({
    type: Types.GET_DATAS_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getDatasFailure = () => ({
    type: Types.GET_DATAS_FAILURE,
});

// function that returns an object literal
export const getDatasError = () => ({
    type: Types.GET_DATAS_ERROR,
});


/*
Token
 */
// function that returns an object literal
export const getDatasLightRequest = (modelhash,userhash,mode) => ({
    type: Types.GET_DATAS_LIGHT_REQUEST,
    modelhash : modelhash,
    userhash : userhash,
    mode : mode,
});

// function that returns an object literal
export const getDatasLightSuccess = (data) => ({
    type: Types.GET_DATAS_LIGHT_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getDatasLightFailure = () => ({
    type: Types.GET_DATAS_LIGHT_FAILURE,
});

// function that returns an object literal
export const getDatasLightError = () => ({
    type: Types.GET_DATAS_LIGHT_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const getDatasSharedLightRequest = (modelhash,userhash,mode) => ({
    type: Types.GET_DATAS_SHARED_LIGHT_REQUEST,
    modelhash : modelhash,
    userhash : userhash,
    mode : mode,
});

// function that returns an object literal
export const getDatasSharedLightSuccess = (data) => ({
    type: Types.GET_DATAS_SHARED_LIGHT_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getDatasSharedLightFailure = () => ({
    type: Types.GET_DATAS_SHARED_LIGHT_FAILURE,
});

// function that returns an object literal
export const getDatasSharedLightError = () => ({
    type: Types.GET_DATAS_SHARED_LIGHT_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const getDatasPaginateRequest = (modelhash,userhash, page) => ({
    type: Types.GET_DATAS_PAGINATE_REQUEST,
    modelhash : modelhash,
    userhash : userhash,
    page : page
});

// function that returns an object literal
export const getDatasPaginateSuccess = (data) => ({
    type: Types.GET_DATAS_PAGINATE_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getDatasPaginateFailure = () => ({
    type: Types.GET_DATAS_PAGINATE_FAILURE,
});

// function that returns an object literal
export const getDatasPaginateError = () => ({
    type: Types.GET_DATAS_PAGINATE_ERROR,
});


/*
Token
 */
// function that returns an object literal
export const getDatasPaginateLightRequest = (modelhash,userhash, page,mode) => ({
    type: Types.GET_DATAS_PAGINATE_LIGHT_REQUEST,
    modelhash : modelhash,
    userhash : userhash,
    page : page,
    mode : mode,
});

// function that returns an object literal
export const getDatasPaginateLightSuccess = (data) => ({
    type: Types.GET_DATAS_PAGINATE_LIGHT_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getDatasPaginateLightFailure = () => ({
    type: Types.GET_DATAS_PAGINATE_LIGHT_FAILURE,
});

// function that returns an object literal
export const getDatasPaginateLightError = () => ({
    type: Types.GET_DATAS_PAGINATE_LIGHT_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const getDatasSharedPaginateLightRequest = (modelhash,userhash, page,mode) => ({
    type: Types.GET_DATAS_SHARED_PAGINATE_LIGHT_REQUEST,
    modelhash : modelhash,
    userhash : userhash,
    page : page,
    mode : mode
});

// function that returns an object literal
export const getDatasSharedPaginateLightSuccess = (data) => ({
    type: Types.GET_DATAS_SHARED_PAGINATE_LIGHT_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getDatasSharedPaginateLightFailure = () => ({
    type: Types.GET_DATAS_SHARED_PAGINATE_LIGHT_FAILURE,
});

// function that returns an object literal
export const getDatasSharedPaginateLightError = () => ({
    type: Types.GET_DATAS_SHARED_PAGINATE_LIGHT_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const getDatasFilterRequest = (modelhash,userhash, filter) => ({
    type: Types.GET_DATAS_FILTER_REQUEST,
    modelhash : modelhash,
    userhash : userhash,
    filter : filter
});

// function that returns an object literal
export const getDatasFilterSuccess = (data) => ({
    type: Types.GET_DATAS_FILTER_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getDatasFilterFailure = () => ({
    type: Types.GET_DATAS_FILTER_FAILURE,
});

// function that returns an object literal
export const getDatasFilterError = () => ({
    type: Types.GET_DATAS_FILTER_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const getDatasFilterLightRequest = (modelhash,userhash, filter) => ({
    type: Types.GET_DATAS_FILTER_LIGHT_REQUEST,
    modelhash : modelhash,
    userhash : userhash,
    filter : filter
});

// function that returns an object literal
export const getDatasFilterLightSuccess = (data) => ({
    type: Types.GET_DATAS_FILTER_LIGHT_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getDatasFilterLightFailure = () => ({
    type: Types.GET_DATAS_FILTER_LIGHT_FAILURE,
});

// function that returns an object literal
export const getDatasFilterLightError = () => ({
    type: Types.GET_DATAS_FILTER_LIGHT_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const getDataByClefUserIndexRequest = (clef,userhash, userindex, fieldId) => ({
    type: Types.GET_DATA_BY_CLEF_USER_INDEX_REQUEST,
    clef : clef,
    userhash : userhash,
    userindex : userindex,
    fieldId : fieldId
});


// function that returns an object literal
export const getDataByClefUserIndexSuccess = (data, data2, data3) => ({
    type: Types.GET_DATA_BY_CLEF_USER_INDEX_SUCCESS,
    payload: { data },
    payload2 : data2,
    payload3 : data3
 });

// function that returns an object literal
export const getDataByClefUserIndexFailure = () => ({
    type: Types.GET_DATA_BY_CLEF_USER_INDEX_FAILURE,
});

// function that returns an object literal
export const getDataByClefUserIndexError = () => ({
    type: Types.GET_DATA_BY_CLEF_USER_INDEX_ERROR,
});


/*
Token
 */
// function that returns an object literal
export const getDatasByClefPaginateRequest = (clef,userhash, page,filter, view) => ({
    type: Types.GET_DATAS_BY_CLEF_PAGINATE_REQUEST,
    clef : clef,
    userhash : userhash,
    page : page,
    filter : filter,
    view : view,
});


// function that returns an object literal
export const getDatasByClefPaginateSuccess = (data, data2) => ({
    type: Types.GET_DATAS_BY_CLEF_PAGINATE_SUCCESS,
    payload: { data },
    payload2 : data2,
});

// function that returns an object literal
export const getDatasByClefPaginateFailure = () => ({
    type: Types.GET_DATAS_BY_CLEF_PAGINATE_FAILURE,
});

// function that returns an object literal
export const getDatasByClefPaginateError = () => ({
    type: Types.GET_DATAS_BY_CLEF_PAGINATE_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const getDatasEndRequest = () => ({
    type: Types.GET_DATAS_END_REQUEST,
});

// function that returns an object literal
export const getDatasEndSuccess = (start, end) => ({
    type: Types.GET_DATAS_END_SUCCESS,
    payload: { start },
    payload2: { end }
});

// function that returns an object literal
export const getDatasEndFailure = () => ({
    type: Types.GET_DATAS_END_FAILURE,
});

// function that returns an object literal
export const getDatasEndError = () => ({
    type: Types.GET_DATAS_END_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const getDatasByClefEndRequest = () => ({
    type: Types.GET_DATAS_BY_CLEF_END_REQUEST,
});

// function that returns an object literal
export const getDatasByClefEndSuccess = (start, end) => ({
    type: Types.GET_DATAS_BY_CLEF_END_SUCCESS,
    payload: { start },
    payload2: { end }
});

// function that returns an object literal
export const getDatasByClefEndFailure = () => ({
    type: Types.GET_DATAS_BY_CLEF_END_FAILURE,
});

// function that returns an object literal
export const getDatasByClefEndError = () => ({
    type: Types.GET_DATAS_BY_CLEF_END_ERROR,
});

// function that returns an object literal
export const getDataUniqueRequest = (datahash,userhash) => ({
    type: Types.GET_DATA_UNIQUE_REQUEST,
    datahash : datahash,
    userhash : userhash
});

// function that returns an object literal
export const getDataUniqueSuccess = (data) => ({
    type: Types.GET_DATA_UNIQUE_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getDataUniqueFailure = () => ({
    type: Types.GET_DATA_UNIQUE_FAILURE,
});

// function that returns an object literal
export const getDataUniqueError = () => ({
    type: Types.GET_DATA_UNIQUE_ERROR,
});

// function that returns an object literal
export const getDataUniqueReset = () => ({
    type: Types.GET_DATA_UNIQUE_RESET,
});

// function that returns an object literal
export const getDataUniqueUserindexRequest = (userindex,userhash) => ({
    type: Types.GET_DATA_UNIQUE_USERINDEX_REQUEST,
    userindex : userindex,
    userhash : userhash
});

// function that returns an object literal
export const getDataUniqueUserindexSuccess = (data) => ({
    type: Types.GET_DATA_UNIQUE_USERINDEX_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getDataUniqueUserindexFailure = () => ({
    type: Types.GET_DATA_UNIQUE_USERINDEX_FAILURE,
});

// function that returns an object literal
export const getDataUniqueUserindexError = () => ({
    type: Types.GET_DATA_UNIQUE_USERINDEX_ERROR,
});

// function that returns an object literal
export const postCreateDataRequest = (modelhash, userhash, formData) => ({
    type: Types.POST_CREATE_DATA_REQUEST,
    modelhash : modelhash,
    userhash : userhash,
    formData : formData
});

// function that returns an object literal
export const postCreateDataSuccess = (data) => ({
    type: Types.POST_CREATE_DATA_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const postCreateDataFailure = () => ({
    type: Types.POST_CREATE_DATA_FAILURE,
});

// function that returns an object literal
export const postCreateDataError = () => ({
    type: Types.POST_CREATE_DATA_ERROR,
});

// function that returns an object literal
export const postCreateSharedDataRequest = (modelhash, userhash, formData) => ({
    type: Types.POST_CREATE_SHARED_DATA_REQUEST,
    modelhash : modelhash,
    userhash : userhash,
    formData : formData
});

// function that returns an object literal
export const postCreateSharedDataSuccess = (data) => ({
    type: Types.POST_CREATE_SHARED_DATA_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const postCreateSharedDataFailure = () => ({
    type: Types.POST_CREATE_SHARED_DATA_FAILURE,
});

// function that returns an object literal
export const postCreateSharedDataError = () => ({
    type: Types.POST_CREATE_SHARED_DATA_ERROR,
});

// function that returns an object literal
export const postCreateDataWithoutRedirectRequest = (modelhash, userhash, formData) => ({
    type: Types.POST_CREATE_DATA_WITHOUT_REDIRECT_REQUEST,
    modelhash : modelhash,
    userhash : userhash,
    formData : formData
});

// function that returns an object literal
export const postCreateDataWithoutRedirectSuccess = (data) => ({
    type: Types.POST_CREATE_DATA_WITHOUT_REDIRECT_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const postCreateDataWithoutRedirectFailure = () => ({
    type: Types.POST_CREATE_DATA_WITHOUT_REDIRECT_FAILURE,
});

// function that returns an object literal
export const postCreateDataWithoutRedirectError = () => ({
    type: Types.POST_CREATE_DATA_WITHOUT_REDIRECT_ERROR,
});

// function that returns an object literal
export const postCreateSharedDataWithoutRedirectRequest = (modelhash, userhash, formData) => ({
    type: Types.POST_CREATE_SHARED_DATA_WITHOUT_REDIRECT_REQUEST,
    modelhash : modelhash,
    userhash : userhash,
    formData : formData
});

// function that returns an object literal
export const postCreateSharedDataWithoutRedirectSuccess = (data) => ({
    type: Types.POST_CREATE_SHARED_DATA_WITHOUT_REDIRECT_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const postCreateSharedDataWithoutRedirectFailure = () => ({
    type: Types.POST_CREATE_SHARED_DATA_WITHOUT_REDIRECT_FAILURE,
});

// function that returns an object literal
export const postCreateSharedDataWithoutRedirectError = () => ({
    type: Types.POST_CREATE_SHARED_DATA_WITHOUT_REDIRECT_ERROR,
});

// function that returns an object literal
export const putUpdateDataWithoutRedirectRequest = (modelhash, userhash, formData) => ({
    type: Types.PUT_UPDATE_DATA_WITHOUT_REDIRECT_REQUEST,
    modelhash : modelhash,
    userhash : userhash,
    formData : formData
});

// function that returns an object literal
export const putUpdateDataWithoutRedirectSuccess = (data) => ({
    type: Types.PUT_UPDATE_DATA_WITHOUT_REDIRECT_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const putUpdateDataWithoutRedirectFailure = () => ({
    type: Types.PUT_UPDATE_DATA_WITHOUT_REDIRECT_FAILURE,
});

// function that returns an object literal
export const putUpdateDataWithoutRedirectError = () => ({
    type: Types.PUT_UPDATE_DATA_WITHOUT_REDIRECT_ERROR,
});


// function that returns an object literal
export const putUpdateDataWithoutRedirectModalRequest = (modelhash, userhash, formData) => ({
    type: Types.PUT_UPDATE_DATA_WITHOUT_REDIRECT_MODAL_REQUEST,
    modelhash : modelhash,
    userhash : userhash,
    formData : formData
});



// function that returns an object literal
export const putUpdateDataWithoutRedirectModalSuccess = (data) => ({
    type: Types.PUT_UPDATE_DATA_WITHOUT_REDIRECT_MODAL_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const putUpdateDataWithoutRedirectModalFailure = () => ({
    type: Types.PUT_UPDATE_DATA_WITHOUT_REDIRECT_MODAL_FAILURE,
});

// function that returns an object literal
export const putUpdateDataWithoutRedirectModalError = () => ({
    type: Types.PUT_UPDATE_DATA_WITHOUT_REDIRECT_MODAL_ERROR,
});

// function that returns an object literal
export const putUpdateDataRequest = (modelhash, userhash, formData, forward) => ({
    type: Types.PUT_UPDATE_DATA_REQUEST,
    modelhash : modelhash,
    userhash : userhash,
    formData : formData,
    forward : forward
});

// function that returns an object literal
export const putUpdateDataSuccess = (data) => ({
    type: Types.PUT_UPDATE_DATA_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const putUpdateDataFailure = () => ({
    type: Types.PUT_UPDATE_DATA_FAILURE,
});

// function that returns an object literal
export const putUpdateDataError = () => ({
    type: Types.PUT_UPDATE_DATA_ERROR,
});

// function that returns an object literal
export const putUpdateDataFieldRequest = (modelhash, datahash, userhash, formData) => ({
    type: Types.PUT_UPDATE_DATA_FIELD_REQUEST,
    modelhash : modelhash,
    datahash : datahash,
    userhash : userhash,
    formData : formData
});

// function that returns an object literal
export const putUpdateDataFieldSuccess = (data) => ({
    type: Types.PUT_UPDATE_DATA_FIELD_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const putUpdateDataFieldFailure = () => ({
    type: Types.PUT_UPDATE_DATA_FIELD_FAILURE,
});

// function that returns an object literal
export const putUpdateDataFieldError = () => ({
    type: Types.PUT_UPDATE_DATA_FIELD_ERROR,
});

// function that returns an object literal
export const putUpdateDataFieldManualRequest = (modelhash, datahash, userhash, formData) => ({
    type: Types.PUT_UPDATE_DATA_FIELD_MANUAL_REQUEST,
    modelhash : modelhash,
    datahash : datahash,
    userhash : userhash,
    formData : formData
});

// function that returns an object literal
export const putUpdateDataFieldManualSuccess = (data) => ({
    type: Types.PUT_UPDATE_DATA_FIELD_MANUAL_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const putUpdateDataFieldManualFailure = () => ({
    type: Types.PUT_UPDATE_DATA_FIELD_MANUAL_FAILURE,
});

// function that returns an object literal
export const putUpdateDataFieldManualError = () => ({
    type: Types.PUT_UPDATE_DATA_FIELD_MANUAL_ERROR,
});

// function that returns an object literal
export const putUpdateSharedDataRequest = (modelhash, userhash, formData) => ({
    type: Types.PUT_UPDATE_SHARED_DATA_REQUEST,
    modelhash : modelhash,
    userhash : userhash,
    formData : formData
});

// function that returns an object literal
export const putUpdateSharedDataSuccess = (data) => ({
    type: Types.PUT_UPDATE_SHARED_DATA_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const putUpdateSharedDataFailure = () => ({
    type: Types.PUT_UPDATE_SHARED_DATA_FAILURE,
});

// function that returns an object literal
export const putUpdateSharedDataError = () => ({
    type: Types.PUT_UPDATE_SHARED_DATA_ERROR,
});

// function that returns an object literal
export const putUpdateSharedDataWithoutRedirectRequest = (modelhash, userhash, formData) => ({
    type: Types.PUT_UPDATE_SHARED_DATA_WITHOUT_REDIRECT_REQUEST,
    modelhash : modelhash,
    userhash : userhash,
    formData : formData
});

// function that returns an object literal
export const putUpdateSharedDataWithoutRedirectSuccess = (data) => ({
    type: Types.PUT_UPDATE_SHARED_DATA_WITHOUT_REDIRECT_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const putUpdateSharedDataWithoutRedirectFailure = () => ({
    type: Types.PUT_UPDATE_SHARED_DATA_WITHOUT_REDIRECT_FAILURE,
});

// function that returns an object literal
export const putUpdateSharedDataWithoutRedirectError = () => ({
    type: Types.PUT_UPDATE_SHARED_DATA_WITHOUT_REDIRECT_ERROR,
});

// function that returns an object literal
export const putUpdateSharedDataWithoutRedirectModalRequest = (modelhash, userhash, formData) => ({
    type: Types.PUT_UPDATE_SHARED_DATA_WITHOUT_REDIRECT_MODAL_REQUEST,
    modelhash : modelhash,
    userhash : userhash,
    formData : formData
});

// function that returns an object literal
export const putUpdateSharedDataWithoutRedirectModalSuccess = (data) => ({
    type: Types.PUT_UPDATE_SHARED_DATA_WITHOUT_REDIRECT_MODAL_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const putUpdateSharedDataWithoutRedirectModalFailure = () => ({
    type: Types.PUT_UPDATE_SHARED_DATA_WITHOUT_REDIRECT_MODAL_FAILURE,
});

// function that returns an object literal
export const putUpdateSharedDataWithoutRedirectModalError = () => ({
    type: Types.PUT_UPDATE_SHARED_DATA_WITHOUT_REDIRECT_MODAL_ERROR,
});

// function that returns an object literal
export const putUpdateDataFavorisRequest = (datahash, favorite, userhash) => ({
    //plugnote.hash, !plugnote.favorite, environment.userhash
    type: Types.PUT_UPDATE_DATA_FAVORIS_REQUEST,
    datahash : datahash,
    favorite : favorite,
    userhash : userhash,
});

// function that returns an object literal
export const putUpdateDataFavorisSuccess = (data) => ({
    type: Types.PUT_UPDATE_DATA_FAVORIS_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const putUpdateDataFavorisFailure = () => ({
    type: Types.PUT_UPDATE_DATA_FAVORIS_FAILURE,
});

// function that returns an object literal
export const putUpdateDataFavorisError = () => ({
    type: Types.PUT_UPDATE_DATA_FAVORIS_ERROR,
});

// function that returns an object literal
export const putUpdateDataLockRequest = (datahash, isarchived, locked, userhash) => ({
    type: Types.PUT_UPDATE_DATA_LOCK_REQUEST,
    datahash : datahash,
    isarchived : isarchived,
    locked : locked,
    userhash : userhash,
});

// function that returns an object literal
export const putUpdateDataLockSuccess = (data) => ({
    type: Types.PUT_UPDATE_DATA_LOCK_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const putUpdateDataLockFailure = () => ({
    type: Types.PUT_UPDATE_DATA_LOCK_FAILURE,
});

// function that returns an object literal
export const putUpdateDataLockError = () => ({
    type: Types.PUT_UPDATE_DATA_LOCK_ERROR,
});

// function that returns an object literal
export const putUpdateDataArchiveRequest = (datahash, isarchived, locked, userhash) => ({
    type: Types.PUT_UPDATE_DATA_ARCHIVE_REQUEST,
    datahash : datahash,
    isarchived : isarchived,
    locked : locked,
    userhash : userhash,
});

// function that returns an object literal
export const putUpdateDataArchiveSuccess = (data) => ({
    type: Types.PUT_UPDATE_DATA_ARCHIVE_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const putUpdateDataArchiveFailure = () => ({
    type: Types.PUT_UPDATE_DATA_ARCHIVE_FAILURE,
});

// function that returns an object literal
export const putUpdateDataArchiveError = () => ({
    type: Types.PUT_UPDATE_DATA_ARCHIVE_ERROR,
});

// function that returns an object literal
export const putUpdateDatasArchiveRequest = (payloadhash, userhash) => ({
    type: Types.PUT_UPDATE_DATAS_ARCHIVE_REQUEST,
    payloadhash : payloadhash,
    userhash : userhash,
});

// function that returns an object literal
export const putUpdateDatasArchiveSuccess = (data) => ({
    type: Types.PUT_UPDATE_DATAS_ARCHIVE_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const putUpdateDatasArchiveFailure = () => ({
    type: Types.PUT_UPDATE_DATAS_ARCHIVE_FAILURE,
});

// function that returns an object literal
export const putUpdateDatasArchiveError = () => ({
    type: Types.PUT_UPDATE_DATAS_ARCHIVE_ERROR,
});

// function that returns an object literal
export const putUpdateDatasUnArchiveRequest = (payloadhash, userhash) => ({
    type: Types.PUT_UPDATE_DATAS_UNARCHIVE_REQUEST,
    payloadhash : payloadhash,
    userhash : userhash,
});

// function that returns an object literal
export const putUpdateDatasUnArchiveSuccess = (data) => ({
    type: Types.PUT_UPDATE_DATAS_UNARCHIVE_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const putUpdateDatasUnArchiveFailure = () => ({
    type: Types.PUT_UPDATE_DATAS_UNARCHIVE_FAILURE,
});

// function that returns an object literal
export const putUpdateDatasUnArchiveError = () => ({
    type: Types.PUT_UPDATE_DATAS_UNARCHIVE_ERROR,
});

// function that returns an object literal
export const delDeleteDataRequest = (datahash, userhash,back) => ({
    type: Types.DEL_DELETE_DATA_REQUEST,
    datahash : datahash,
    userhash : userhash,
    back : back
});

// function that returns an object literal
export const delDeleteDataSuccess = (data) => ({
    type: Types.DEL_DELETE_DATA_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const delDeleteDataFailure = () => ({
    type: Types.DEL_DELETE_DATA_FAILURE,
});

// function that returns an object literal
export const delDeleteDataError = () => ({
    type: Types.DEL_DELETE_DATA_ERROR,
});

// function that returns an object literal
export const delDeleteDatasRequest = (payloadhash, userhash) => ({
    type: Types.DEL_DELETE_DATAS_REQUEST,
    payloadhash : payloadhash,
    userhash : userhash,
});

// function that returns an object literal
export const delDeleteDatasSuccess = (data) => ({
    type: Types.DEL_DELETE_DATAS_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const delDeleteDatasFailure = () => ({
    type: Types.DEL_DELETE_DATAS_FAILURE,
});

// function that returns an object literal
export const delDeleteDatasError = () => ({
    type: Types.DEL_DELETE_DATAS_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const getDecryptPasswordRequest = (datahash,fieldId,userhash) => ({
    type: Types.GET_DECRYPT_PASSWORD_REQUEST,
    datahash : datahash,
    fieldId : fieldId,
    userhash : userhash
});

// function that returns an object literal
export const getDecryptPasswordSuccess = (data) => ({
    type: Types.GET_DECRYPT_PASSWORD_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getDecryptPasswordFailure = () => ({
    type: Types.GET_DECRYPT_PASSWORD_FAILURE,
});

// function that returns an object literal
export const getDecryptPasswordError = () => ({
    type: Types.GET_DECRYPT_PASSWORD_ERROR,
});


// function that returns an object literal
export const putDataUniqueRequest = (data, userhash) => ({
    type: Types.PUT_DATA_UNIQUE_REQUEST,
    payload : data,
    userhash : userhash
});

/*export function putDataUniqueSuccess(data) {
    return function(dispatch) {
        dispatch({
            type: Types.PUT_DATA_UNIQUE_SUCCESS,
            payload : data
        });
        toast.success("MY SUCCESS");
    };
}*/

export const putDataUniqueSuccess = (data, userhash) => ({
    type: Types.PUT_DATA_UNIQUE_SUCCESS,
    payload : data
});

// function that returns an object literal
export const putDataUniqueFailure = () => ({
    type: Types.PUT_DATA_UNIQUE_FAILURE,
});

// function that returns an object literal
export const putDataUniqueError = () => ({
    type: Types.PUT_DATA_UNIQUE_ERROR,
});

// function that returns an object literal
export const getDatasByCodeFolderRequest = (plugcode, folder, fiche) => ({
    type: Types.GET_DATAS_BY_CODE_FOLDER_REQUEST,
    plugcode : plugcode,
    folder : folder,
    fiche : fiche,
});

export function getDatasByCodeFolderSuccess(data, data2) {
    return function(dispatch) {
        dispatch({
            type: Types.GET_DATAS_BY_CODE_FOLDER_SUCCESS,
            payload : data,
            payload2 : data2,
        });
    };
}

// function that returns an object literal
export const getDatasByCodeFolderFailure = () => ({
    type: Types.GET_DATAS_BY_CODE_FOLDER_FAILURE,
});

// function that returns an object literal
export const getDatasByCodeFolderError = () => ({
    type: Types.GET_DATAS_BY_CODE_FOLDER_ERROR,
});

export const resetAllData = () => ({
    type: Types.RESET_ALL_DATA,
});

export const reduxGenerateDataPasswordReset = () => ({
    type: Types.REDUX_GENERATE_DATA_PASSWORD_RESET,

});

export const reduxGetDataByClefUserIndexReset = () => ({
    type: Types.REDUX_GET_DATA_BY_CLEF_USER_INDEX_RESET,
});
