import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Close, Search} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import {Input} from '@material-ui/core';
import {searchTypeData} from "../../searchs/search";
import _ from 'lodash'
import {
    delAllSearchParamsRequest,
    delSearchKeyParamsRequest,
    postSearchModelRequest,
    postSearchPlugRequest,
    setSearchPlugParamsRequest
} from "../../actions/search";
import {getCurrentEnvironnement} from "../../utils/Common";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";


const useStyles = makeStyles((theme) => ({
    root: {
        margin : 0,
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        backgroundColor: '#F1F1F1',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    input: {
        marginLeft : 5
    },
    buttonSimple:{
        maxWidth : "max-content",
        backgroundColor: '#58C3C1',
        fontSize : '10px !important',
        textTransform : 'inherit !important',
        fontWeight : "lighter",
        color: 'white',
        "&:hover":{
            backgroundColor: '#58C3C1'
        },
        "&:disabled":{
            backgroundColor: '#58C3C1'
        }
    }
}));

/**
 * Permet de récupérer le donnée par défaut
 * @param {boolean} resetData
 * @returns {{}}
 */
const getDefaultData = (resetData, props, indice) => {
    let {selectedType, requestSearchParams} = props;
    let data = {};

    let contenuType = {...searchTypeData.data[indice]};
    let optionGroupList = [...searchTypeData.optionGroupList];

    optionGroupList.map((groupItem) => {
        let option = contenuType[groupItem.name];

        if (option != undefined) {
            // Type de recherche "archives"
            if (contenuType.keyName == "archives") {
                let defaultValue = {}; // Valeurs défaut
                let valueFromProps = {}; // Valeurs déja sélectionnés

                if (requestSearchParams[selectedType.keyName]) {
                    for (let key in requestSearchParams[contenuType.keyName]) {
                        if (requestSearchParams[contenuType.keyName][key] && key.match(/Plug/)) { // key sous form: "onlyPlugNoteArch", "withPlugNoteArch", etc...
                            valueFromProps[key.slice(0, key.match(/Plug/).index)] = true;
                            valueFromProps[key.slice(key.match(/Plug/).index, key.length)] = true;
                        }
                    }
                }

                option.map((optionValue) => {
                    if (optionValue.defaultValue) {
                        defaultValue[optionValue.keyName] = optionValue.defaultValue;
                    }
                });

                // Prendre les valeurs par défaut Si c'est quand le bouton "vider" ou si aucun options sélectionnés
                if (!resetData && !_.isEmpty(valueFromProps)) {
                    data = {...data, ...valueFromProps};
                } else {
                    data = {...data, ...defaultValue};
                }

            }

            else if (groupItem.dataType == "boolean") {

                if (groupItem.fieldType == "switchKeyword1" || groupItem.fieldType == "switchKeyword2") {

                    option.map((optionValue) => {
                        if (!!resetData &&
                            requestSearchParams[selectedType.keyName] &&
                            requestSearchParams[selectedType.keyName][optionValue.keyName]) {

                            if (requestSearchParams[selectedType.keyName][optionValue.keyName] == optionValue.value) {
                                data[optionValue.keyName] = optionValue.value;
                            }

                        } else {
                            let defaultValue = {};
                            let valueFromProps = {};

                            if (optionValue.defaultValue) {
                                defaultValue[optionValue.keyName] = optionValue.value;
                            }
                            if (requestSearchParams[selectedType.keyName] && !!requestSearchParams[selectedType.keyName][optionValue.keyName] && typeof requestSearchParams[selectedType.keyName][optionValue.keyName] != "function") {
                                valueFromProps[optionValue.keyName] = requestSearchParams[selectedType.keyName][optionValue.keyName];
                            }
                            if (!_.isEmpty(valueFromProps)) {
                                data = {...data, ...valueFromProps};
                            } else {
                                data = {...data, ...defaultValue};
                            }
                        }

                        // Si c'est dans le cas de type "Divers", quans on choisit certains options, il y a un textInput
                        if (optionValue.showTextInput && requestSearchParams[selectedType.keyName] && requestSearchParams[selectedType.keyName].value) {
                            data.value = requestSearchParams[selectedType.keyName].value;
                        }
                    });

                } else {
                    let defaultValue = {};
                    let valueFromProps = {};
                    option.map((optionValue) => {
                        if (optionValue.defaultValue) {
                            defaultValue[optionValue.keyName] = optionValue.defaultValue;
                        }
                        if (requestSearchParams['content'] && !!requestSearchParams['content'][optionValue.keyName] && typeof requestSearchParams['content'][optionValue.keyName] != "function") {
                            valueFromProps[optionValue.keyName] = requestSearchParams['content'][optionValue.keyName];
                        }
                    });
                    if (!resetData && !_.isEmpty(valueFromProps)) {
                        data = {...data, ...valueFromProps};
                    } else {
                        data = {...data, ...defaultValue};
                    }
                }

            }
            else {
                data[option.keyName] =
                    !resetData && requestSearchParams['content'] && requestSearchParams['content'][option.keyName] ?
                        requestSearchParams['content'][option.keyName] : option.defaultValue;
            }
        }
    });

    return data;
};

const getDefaultDataNumber = (resetData, props,indice) => {
    let {selectedType, requestSearchParams} = props;
    let data = {};

    let contenuType = {...searchTypeData.data[indice]};
    let optionGroupList = [...searchTypeData.optionGroupList];

    optionGroupList.map((groupItem) => {
        let option = contenuType[groupItem.name];

        if (option != undefined) {
            // Type de recherche "archives"
            if (contenuType.keyName == "archives") {
                let defaultValue = {}; // Valeurs défaut
                let valueFromProps = {}; // Valeurs déja sélectionnés

                if (requestSearchParams[selectedType.keyName]) {
                    for (let key in requestSearchParams[contenuType.keyName]) {
                        if (requestSearchParams[contenuType.keyName][key] && key.match(/Plug/)) { // key sous form: "onlyPlugNoteArch", "withPlugNoteArch", etc...
                            valueFromProps[key.slice(0, key.match(/Plug/).index)] = true;
                            valueFromProps[key.slice(key.match(/Plug/).index, key.length)] = true;
                        }
                    }
                }

                option.map((optionValue) => {
                    if (optionValue.defaultValue) {
                        defaultValue[optionValue.keyName] = optionValue.defaultValue;
                    }
                });

                // Prendre les valeurs par défaut Si c'est quand le bouton "vider" ou si aucun options sélectionnés
                if (!resetData && !_.isEmpty(valueFromProps)) {
                    data = {...data, ...valueFromProps};
                } else {
                    data = {...data, ...defaultValue};
                }

            }

            else if (groupItem.dataType == "boolean") {

                if (groupItem.fieldType == "switchKeyword1" || groupItem.fieldType == "switchKeyword2") {

                    option.map((optionValue) => {
                        if (!!resetData &&
                            requestSearchParams[selectedType.keyName] &&
                            requestSearchParams[selectedType.keyName][optionValue.keyName]) {

                            if (requestSearchParams[selectedType.keyName][optionValue.keyName] == optionValue.value) {
                                data[optionValue.keyName] = optionValue.value;
                            }

                        } else {
                            let defaultValue = {};
                            let valueFromProps = {};

                            if (optionValue.defaultValue) {
                                defaultValue[optionValue.keyName] = optionValue.value;
                            }
                            if (requestSearchParams[selectedType.keyName] && !!requestSearchParams[selectedType.keyName][optionValue.keyName] && typeof requestSearchParams[selectedType.keyName][optionValue.keyName] != "function") {
                                valueFromProps[optionValue.keyName] = requestSearchParams[selectedType.keyName][optionValue.keyName];
                            }
                            if (!_.isEmpty(valueFromProps)) {
                                data = {...data, ...valueFromProps};
                            } else {
                                data = {...data, ...defaultValue};
                            }
                        }

                        // Si c'est dans le cas de type "Divers", quans on choisit certains options, il y a un textInput
                        if (optionValue.showTextInput && requestSearchParams[selectedType.keyName] && requestSearchParams[selectedType.keyName].value) {
                            data.value = requestSearchParams[selectedType.keyName].value;
                        }
                    });

                } else {
                    let defaultValue = {};
                    let valueFromProps = {};
                    option.map((optionValue) => {
                        if (optionValue.defaultValue) {
                            defaultValue[optionValue.keyName] = optionValue.defaultValue;
                        }
                        if (requestSearchParams['content'] && !!requestSearchParams['content'][optionValue.keyName] && typeof requestSearchParams['content'][optionValue.keyName] != "function") {
                            valueFromProps[optionValue.keyName] = requestSearchParams['content'][optionValue.keyName];
                        }
                    });
                    if (!resetData && !_.isEmpty(valueFromProps)) {
                        data = {...data, ...valueFromProps};
                    } else {
                        data = {...data, ...defaultValue};
                    }
                }

            } else {
                data[option.keyName] =
                    !resetData && requestSearchParams['content'] && requestSearchParams['content'][option.keyName] ?
                        requestSearchParams['content'][option.keyName] : option.defaultValue;
            }
        }
    });

    return data;
};

const MenuLeftSearchComponent = (props) => {

    const classes = useStyles();

    const [t, i18n] = useTranslation('common');

    const [selectedOptionsData, setSelectedOptionsData] = useState(getDefaultData(false, props, 0));
    const [search, setSearch] = useState("");
    const [activeSearch, setActiveSearch] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [selected, setSelected] = useState("label");
    const [enableSearch, setEnableSearch] = useState(false);

    const [typeIdSearch, setTypeIdSearch] = useState(1);
    const [typeLabelSearch, setTypeLabelSearch] = useState(t('models.search-model-one'));
    const [typeLabelSearchTooltip, setTypeLabelSearchTooltip] = useState(t('models.search-model-one-tooltip'));

    const environment = getCurrentEnvironnement();

    const handleChangeTextInput = (event, value) => {
        setSearch(event.target.value);
    };

    const setDefaultState = (props) => {
        let defaultData = getDefaultData(false, props);
        setSelectedOptionsData(defaultData);
    }

    const setDefaultStateNumber = (props) => {
        let defaultData = getDefaultDataNumber(false, props);
        setSelectedOptionsData(defaultData);

    }

    const _handlePlugsSearch = (keyName,searchTypeData) => {

        setDefaultState(props);

        setTimeout(() => {
            let data = {};
            let paramName = keyName;
            let contenuType = {...searchTypeData};
            let groupName = 'optionGroup1';

            let selectedOptionIndex = 0;
            let selectedModelOptionIndex = 1;
            selectedOptionsData[contenuType[groupName][selectedModelOptionIndex].keyName] = false;
            selectedOptionsData[contenuType[groupName][selectedOptionIndex].keyName] = true;

            if(activeSearch){
                setActiveSearch(false);
            }

            const checkDoublon = (textList, text) => {
                if(!!textList){
                    if (textList.findIndex((item) => {
                        return item.value == text
                    }) == -1) {
                        return false;
                    } else {
                        return true;
                    }
                }else{
                    return false;
                }
            };

            let keyword = {};

            if (!checkDoublon(selectedOptionsData.keyword.list, search)) {

                if(typeof selectedOptionsData.keyword.list !== "undefined"){
                    if(selectedOptionsData.keyword.list.length === 0){
                        selectedOptionsData.keyword.list.push({value : search});
                    }else{
                        selectedOptionsData.keyword.list[0] = {value : search}
                    }
                }else{
                    selectedOptionsData.keyword.list = [];
                    selectedOptionsData.keyword.list[0] = {value : search}
                }

                keyword.list = selectedOptionsData.keyword.list;

                setSelectedOptionsData({
                    ...selectedOptionsData,
                    keyword
                });

                data = {
                    ...selectedOptionsData,
                    keyword
                };

                props.setSearchParams(paramName, data,false);
            }
            else{
                keyword.list = selectedOptionsData.keyword.list;

                data = {
                    ...selectedOptionsData,
                    keyword
                };

                //this.props.setSearchParams(paramName, data,false);
                //this.props.setRequestSearchParams(paramName, data);
                props.setSearchParams(paramName, data,false);
            }

            if (paramName == "content") {
                if (!selectedOptionsData.keyword.list.length) {
                    return;
                }
            }
            else if (!!["pnnumber", "plugkey", "plugcode"].find(item => item == paramName)) {
                if (!selectedOptionsData.keyword.length) {
                    return;
                }
            }
            else if (paramName == "dates") {
                if (!selectedOptionsData.date.length) {
                    return;
                }
            }

            setSearchActive(true);


            props.attemptSearchPlug(environment.userhash);
        },10)
    }

    const _handleModelsSearch = (keyName) => {

        setDefaultState(props);

        setTimeout(() => {

            let data = {};
            let paramName = keyName;
            /*
            let contenuType = {...searchTypeData.data[7]};
            let groupName = 'optionGroup1';

            let selectedOptionIndex = 1;
            let selectedPlugOptionIndex = 0;
            selectedOptionsData[contenuType[groupName][selectedPlugOptionIndex].keyName] = false;
            selectedOptionsData[contenuType[groupName][selectedOptionIndex].keyName] = true;*/

            if(activeSearch){
                setActiveSearch(false);
            }

            const checkDoublon = (textList, text) => {
                if(!!textList){
                    if (textList.findIndex((item) => {
                        return item.value == text
                    }) == -1) {
                        return false;
                    } else {
                        return true;
                    }
                }else{
                    return false;
                }
            };

            let keyword = {};

            if (!checkDoublon(selectedOptionsData.keyword.list, search)) {

                if(typeof selectedOptionsData.keyword.list !== "undefined"){
                    if(selectedOptionsData.keyword.list.length === 0){
                        selectedOptionsData.keyword.list.push({value : search});
                    }else{
                        selectedOptionsData.keyword.list[0] = {value : search}
                    }
                }else{
                    selectedOptionsData.keyword.list = [];
                    selectedOptionsData.keyword.list[0] = {value : search}
                }


                keyword.list = selectedOptionsData.keyword.list;

                setSelectedOptionsData({
                    ...selectedOptionsData,
                    keyword
                });

                data = {
                    ...selectedOptionsData,
                    keyword
                };

                props.setSearchParams(paramName, data,false);

            }
            else{
                keyword.list = selectedOptionsData.keyword.list;

                data = {
                    ...selectedOptionsData,
                    keyword
                };

                props.setSearchParams(paramName, data,false);
            }

            if (paramName == "content") {
                if (!selectedOptionsData.keyword.list.length) {
                    return;
                }
            } else if(paramName == "label"){
                if (!selectedOptionsData.keyword.list.length) {
                    return;
                }
            } else if (!!["pnnumber", "plugkey", "plugcode"].find(item => item == paramName)) {
                if (!selectedOptionsData.keyword.length) {
                    return;
                }
            } else if (paramName == "dates") {
                if (!selectedOptionsData.date.length) {
                    return;
                }
            }

            setSearchActive(true);

            props.attemptSearchModel(environment.userhash);
        }, 10)

    }

    const _handlePlugKey = (keyName,searchTypeData) => {
        setDefaultStateNumber(props);
        setTimeout(() => {
            let data = {};
            let paramName = keyName;
            let contenuType = {...searchTypeData};
            let groupName = 'optionGroup1';

            let selectedOptionIndex = 1;
            selectedOptionsData[contenuType[groupName][selectedOptionIndex].keyName] = true;
            delete(selectedOptionsData.keyword.list);

            if(activeSearch){
                setActiveSearch(false);
            }

            selectedOptionsData.keyword = search;

            let keyword = selectedOptionsData.keyword;

            data = {
                ...selectedOptionsData,
                keyword
            };

            props.setSearchParams(paramName, data,false);

            if (paramName == "content") {
                if (!selectedOptionsData.keyword.list.length) {
                    return;
                }
            } else if (!!["pnnumber", "plugkey", "plugcode"].find(item => item == paramName)) {
                if (!selectedOptionsData.keyword.length) {
                    return;
                }
            } else if (paramName == "dates") {
                if (!selectedOptionsData.date.length) {
                    return;
                }
            }

            setSearchActive(true);

            props.attemptSearchModel(environment.userhash);
        }, 10)
    }

    const _handlePlugSearch = (keyName,searchTypeData) => {

        setDefaultStateNumber(props);
        setTimeout(() => {
            let data = {};
            let paramName = keyName;
            let contenuType = {...searchTypeData};
            let groupName = 'optionGroup1';

            let selectedOptionIndex = 0;
            selectedOptionsData[contenuType[groupName][selectedOptionIndex].keyName] = true;
            delete(selectedOptionsData.keyword.list);

            if(activeSearch){
                setActiveSearch(false);
            }

            selectedOptionsData.keyword = search;

            let keyword = selectedOptionsData.keyword;

            data = {
                ...selectedOptionsData,
                keyword
            };

            props.setSearchParams(paramName, data,false);

            if (paramName == "content") {
                if (!selectedOptionsData.keyword.list.length) {
                    return;
                }
            } else if (!!["pnnumber", "plugkey", "plugcode"].find(item => item == paramName)) {
                if (!selectedOptionsData.keyword.length) {
                    return;
                }
            } else if (paramName == "dates") {
                if (!selectedOptionsData.date.length) {
                    return;
                }
            }

            setSearchActive(true);

            props.attemptSearchPlug(environment.userhash);
        }, 10)
    }


    const _handleSearch = () => {


        if(search.length > 0){

            switch (selected) {
                case "content":
                    _handlePlugsSearch(searchTypeData.data[0].keyName,searchTypeData.data[0]);
                    break;
                case "pnnumber":
                    _handlePlugSearch(searchTypeData.data[5].keyName,searchTypeData.data[5]);
                    break;
                case "plugkey":
                    _handlePlugKey(searchTypeData.data[6].keyName,searchTypeData.data[6]);
                    break;
                case "label":
                    _handleModelsSearch(searchTypeData.data[7].keyName);
                    break;
            }
        }
    };

    const handleResetPlugSearch = () => {
        setSearch("");
        setSearchActive(false);
        props.attemptResetSearch();
    };

    const handleTypeSearch = () => {
        let id = typeIdSearch;
        id++;

        let newId = id === 5 ? 1 : id;
        setTypeIdSearch(newId);

        switch (newId.toString()) {
            case "1":
                setTypeLabelSearch(t('models.search-model-one'));
                setTypeLabelSearchTooltip(t('models.search-model-one-tooltip'));
                setSelected("label");
                setSelectedOptionsData(getDefaultData(false, props, 7));
                //setSearchActive(false);
                //setEnableSearch(true);
                break;
            case "2":
                setTypeLabelSearch(t('models.search-model-two'));
                setTypeLabelSearchTooltip(t('models.search-model-two-tooltip'));
                setSelected("content");
                setSelectedOptionsData(getDefaultData(false, props, 0));
                //setSearchActive(false);
                //setEnableSearch(true);
                break;
            case "3":
                setTypeLabelSearch(t('models.search-model-tree'));
                setTypeLabelSearchTooltip(t('models.search-model-tree-tooltip'));
                setSelected("pnnumber");
                setSelectedOptionsData(getDefaultDataNumber(false, props, 5));
                //setSearchActive(false);
                //setEnableSearch(true);
                break;
            case "4":
                setTypeLabelSearch(t('models.search-model-four'));
                setTypeLabelSearchTooltip(t('models.search-model-four-tooltip'));
                setSelected("plugkey");
                setSelectedOptionsData(getDefaultDataNumber(false, props, 6));

                break;
        }
    }

    const handleEnableSearch = () => {
        setSearchActive(false);
        setEnableSearch(true);
    }

    useEffect(() => {
        if(props.reset){
            setSearch("");
            setSearchActive(false);
        }
    }, [props.reset]);
    useEffect(() => {
            setSearchActive(props.isSearchActive);
    }, [props.isSearchActive]);

    useEffect(() => {

        if(props.isSearchActive){
            setSearchActive(true)
        }

        if(typeof props.searchParams.content !== "undefined"){

            setSelected("content");

            setSelectedOptionsData(getDefaultData(false, props, 0));

            let searchValue = "";

            if(typeof props.searchParams.content !== "undefined"){
                searchValue = props.searchParams.content.keyword.list[0].value;
            }

            setSearch(searchValue);

        }
        else if(typeof props.searchParams.label !== "undefined"){

            setSelected("label");

            setSelectedOptionsData(getDefaultData(false, props, 7));

            let searchValue = "";

            if(typeof props.searchParams.label !== "undefined"){
                searchValue = props.searchParams.label.keyword.list[0].value;
            }

            setSearch(searchValue);

        }
        else if(typeof props.searchParams.plugkey !== "undefined"){

            setSelected("plugkey");

            setSelectedOptionsData(getDefaultDataNumber(false, props, 6));

            let searchValue = "";

            if(typeof props.searchParams.plugkey !== "undefined"){
                searchValue = props.searchParams.plugkey.keyword;
            }

            setSearch(searchValue);

        }
        else if(typeof props.searchParams.pnnumber !== "undefined"){

            setSelected("pnnumber");

            setSelectedOptionsData(getDefaultDataNumber(false, props, 5));

            let searchValue = "";

            if(typeof props.searchParams.pnnumber !== "undefined"){
                searchValue = props.searchParams.pnnumber.keyword;
            }

            setSearch(searchValue);

        }
    }, [props.searchParams]);

    useEffect(() => {

        if(enableSearch){
            _handleSearch();
            setEnableSearch(false);
        }

    }, [enableSearch]);

    return (
        <div style={{width : '95%'}}>
            <Input
                id="standard-full-width"
                label=""
                style={{ margin: 8 }}
                placeholder={props.placeholder}
                fullWidth={true}
                margin="none"
                disableUnderline={true}
                onKeyPress={(e) => { if (e.key === 'Enter') { handleEnableSearch()}}}
                startAdornment={
                    <Tooltip title={typeLabelSearchTooltip} aria-label="add" placement="top-start">
                        <Button
                            className={classes.buttonSimple}
                            type="button"
                            fullWidth={true}
                            id={"switch"}
                            name="label"
                            onClick={(event) => {
                                handleTypeSearch()
                            }}
                        >
                            {typeLabelSearch}
                        </Button>
                    </Tooltip>
                }
                endAdornment={ searchActive ?
                    <InputAdornment position="end" onClick={handleResetPlugSearch} style={{cursor : 'pointer'}}>
                        <Close />
                    </InputAdornment>
                    :
                    <InputAdornment position="start" onClick={handleEnableSearch}  style={{cursor : 'pointer' , color : 'black'}}>
                        <Search />
                    </InputAdornment>
                }
                inputProps={{
                    className: classes.input,
                }}
                className={classes.textField}
                onChange={handleChangeTextInput}
                value={search}
            />

{/*            {
                props.open &&
                <div style={{
                    margin: 8,
                    display: 'flex',
                }}>
                    <div className="flex_display_direction_row">

                        <Tooltip title={typeLabelSearch} aria-label="add" placement="top-start">
                            <Button
                                className='green'
                                type="button"
                                id={"switch"}
                                name="label"
                                onClick={(event) => {
                                    setSelected("label");
                                    setSelectedOptionsData(getDefaultData(false, props, 7));
                                    setSearchActive(false);
                                    setEnableSearch(true);
                                }}
                            >
                                {typeLabelSearch}
                            </Button>
                        </Tooltip>
                        <Tooltip title={t('models.search-model-one')} aria-label="add" placement="top-start">
                            <Button
                                className={selected === "label" ? 'green' : 'lightgrey'}
                                type="button"
                                id={"label" + '-' + 1}
                                name="label"
                                onClick={(event) => {
                                    setSelected("label");
                                    setSelectedOptionsData(getDefaultData(false, props, 7));
                                    setSearchActive(false);
                                    setEnableSearch(true);
                                }}
                            >
                                <span className={`material-icons md-25`}>
                                    folder
                                </span>
                                <span className={`material-icons md-25`}>
                                    subject
                                </span>
                            </Button>
                        </Tooltip>

                        <Tooltip title={t('models.search-model-two')} aria-label="add" placement="top-start">
                            <Button
                                className={selected === "content" ? 'green' : 'lightgrey'}
                                type="button"
                                id={"content" + '-' + 2}
                                name="content"
                                onClick={(event) => {
                                    setSelected("content");
                                    setSelectedOptionsData(getDefaultData(false, props, 0));
                                    setSearchActive(false);
                                    setEnableSearch(true);
                                }}
                            >
                                <span className={`material-icons md-25 `}>
                                    select_all
                                </span>
                                <span className={`material-icons md-25`}>
                                    subject
                                </span>
                            </Button>
                        </Tooltip>

                        <Tooltip title={t('models.search-model-tree')} aria-label="add" placement="top-start">
                            <Button
                                className={selected === "pnnumber" ? 'green' : 'lightgrey'}
                                type="button"
                                id={"pnnumber" + '-' + 3}
                                name="pnnumber"
                                onClick={(event) => {
                                    setSelected("pnnumber");
                                    setSelectedOptionsData(getDefaultDataNumber(false, props, 5));
                                    setSearchActive(false);
                                    setEnableSearch(true);
                                }}
                            >
                                <span className={`material-icons md-25`}>
                                    description
                                </span>
                                <span className={`material-icons md-25`}>
                                    filter_1
                                </span>
                            </Button>
                        </Tooltip>

                        <Tooltip title={t('models.search-model-four')} aria-label="add" placement="top-start">
                            <Button
                                className={selected === "plugkey" ? 'green' : 'lightgrey'}
                                type="button"
                                id={"plugkey" + '-' + 4}
                                name="plugkey"
                                onClick={(event) => {
                                    setSelected("plugkey");
                                    setSelectedOptionsData(getDefaultDataNumber(false, props, 6));
                                    setSearchActive(false);
                                    setEnableSearch(true);
                                }}
                            >
                                <span className={`material-icons md-25`}>
                                    folder
                                </span>
                                <span className={`material-icons md-25`}>
                                    filter_1
                                </span>
                            </Button>
                        </Tooltip>
                    </div>
                </div>
            }*/}
        </div>
    )
};


// redux providing state takeover
const mapStateToProps = (state,ownProps) => {
    let isSearchResult = false;
    if(!!state.search.searchResult){
        isSearchResult = (state.search.searchResult.length > 0 && state.search.isSearchResult)
    }
    return {
        isSearchResult : isSearchResult,
        isSearchVisible : ownProps.isSearchVisible || false,
        fetching: state.search.fetching,
        selectedTypeList: state.search.selectedTypeList || [],
        searchParams: state.search.searchParams || {},
        requestSearchParams: state.search.searchParams || {},
        searchResult: state.search.searchResult || [],
        error: state.search.error,
        isSearchActive: state.search.isSearchActive,
    }
}

const mapDispatchToProps = (dispatch) => ({
    setSearchParams: (paramName, data,advancedSearch) => dispatch(setSearchPlugParamsRequest(paramName, data,advancedSearch)),
    deleteSearchParams: (paramName, userhash) => dispatch(delSearchKeyParamsRequest(paramName,userhash)),
    attemptSearchPlug: (userhash) => dispatch(postSearchPlugRequest(userhash)),
    attemptSearchModel: (userhash) => dispatch(postSearchModelRequest(userhash)),
    attemptResetSearch : () => dispatch(delAllSearchParamsRequest())
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuLeftSearchComponent);
