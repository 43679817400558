import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {connect} from "react-redux";
import {getCurrentEnvironnement, getModel, getModelToUpdate, getPlugnote} from "../../../utils/Common";
import TextField from "@material-ui/core/TextField";
import '@contentful/forma-36-react-components/dist/styles.css';
import Input from "@material-ui/core/Input";
import {getModelsSharedRequest, getModelUniqueByClefRequest, getModelUniqueByClefReset} from "../../../actions/model";
import _, {isString} from "lodash";
import Immutable from "seamless-immutable";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import {formatTextTypes, isAlmostCheckCompatible, isAlmostCompatible, isCompatible} from "../../../utils/Compatibility";
import {confirmAlert} from "react-confirm-alert";
import i18n from "i18next";
import {isJsonString} from "../../../utils/utils";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import ModalPrintPluglinkComponent from "../../modals/pluglink/ModalPrintPluglinkComponent";
import colors from "../../../assets/themes/Colors";

function getModalStyle() {
    const top = 0;
    return {
        top: `${top}`,
        margin:'auto',
        overflow: 'auto',
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    poPup: {
        position: 'absolute',
        backgroundColor: 'white',
        width: '95%',
        maxHeight: '100%',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    marginLeft:{
        marginLeft : '10px'
    },
    MuiInputBaseInput:{
        width : '50%'
    },
    printBtn: {
        display: 'flex',
        alignItems: 'flex-start',
        fontSize: 30,
        height: 40,
        color: colors.mainButton
    },
    closeBtn: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 30,
        margin: 8
    },
    titres: {
        fontSize: 20,
        width: '-webkit-fill-available',
        textAlignLast: 'center'
    },
    headerContainer: {
        alignItems: 'flex-end',
        borderBottom: '1px solid',
        borderColor: 'lightgrey !important',
        marginBottom: 20,
    },
    subHeaderContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    subHeader: {
        display: 'flex',
        flexDirection: 'row',
        width: '45%',
        marginTop: '-15px',
    },
    subHeader2: {
        display: 'flex',
        flexDirection: 'row',
        width: '45%'
    },
    inputField: {
        width: 250
    },
    paramContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxHeight: 25,
        '@media (max-width: 1024px)': {
            maxHeight: 50,
            fontSize: '12px !important'
        }
    },
    param1Container: {
        width: '45%',
        display: 'flex',
        justifyContent: 'space-between',
        color: colors.orangeLight,
        '@media (max-width: 1024px)': {
            fontSize: '12px !important'
        }
    },
    param2Container: {
        width: '45%',
        display: 'flex',
        justifyContent: 'space-between',
        color: colors.plugnotesGreen,
        '@media (max-width: 1024px)': {
            fontSize: '12px !important'
        }
    },
    selectContainer: {
        margin: '0px 20px',
        flexGrow: 1
    }
}));

const ModalFormatPluglinkConstructorComponent = (props) => {

    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = useState(getModalStyle);
    const [t, i18n] = useTranslation('common');
    const [clefCode, setClefCode] = useState(typeof props.field.options.code !== "undefined" ? props.field.options.code : "");
    const [pfCode, setPfCode] = useState(typeof props.field.options.pfcode !== "undefined" ? props.field.options.pfcode : "");

    let setDragSelectedFields;

    if(typeof props.field.options.fields !== "undefined"){
        setDragSelectedFields = props.field.options.fields.map((item, index) => {
            return {"from" : item.from.toString() , "to" : item.to.toString() , "dragid" : 'item-' + (index + 1)}
        })
    }
    else{
        setDragSelectedFields =  [{"from" : "" , "to" : "" , "dragid" : 'item-' + 1}];
    }

    const [selectedFields, setSelectedFields] = useState(setDragSelectedFields);
    const [key, setKey] = useState(0);
    const [fields, setFields] = useState([]);
    const [propsFields, setPropsFields] = useState([]);
    const [openModalPrint, setOpenModalPrint] = useState(false)

    const model = getModelToUpdate();

    const environment = getCurrentEnvironnement();

    const handleCloseModal = () => {
        props.close();
    };

    const handleGetModel = () => {
        props.attemptGetModelUnique(pfCode, environment.userhash);
    }

    const handlePrintModel = () => {
        setOpenModalPrint(true)
    }

    useEffect(() => {
        if(typeof props.modelUniqueRefresh !== "undefined" && props.modelUniqueRefresh !== null){
            const labels = Object.keys(props.modelUniqueRefresh).filter((key) => {
                const regex = /field[0-9]+label/gi;
                return regex.test(key);
            });

            let champs = labels.map((item) => {
                return props.modelUniqueRefresh[item];
            });

            let listFields = [];

            champs.forEach((field, index) => {
                if (field) {
                    const fieldIndex = index + 1;
                    let format =  props.modelUniqueRefresh[`field${fieldIndex}format`];
                    let options = isJsonString(props.modelUniqueRefresh[`field${fieldIndex}formatoptions`]) ? JSON.parse(props.modelUniqueRefresh[`field${fieldIndex}formatoptions`]) : {};
                    let title = props.modelUniqueRefresh[`field${fieldIndex}label`];
                    if(format === "table"){
                        switch (options.multiselect) {
                            case true :
                                if (options.editable) {
                                    format = "free-multi-table";
                                } else {
                                    format = "multi-table";
                                }
                                break;
                            default:
                                if (options.editable) {
                                    format = "free-single-table";
                                } else {
                                    format = "single-table";
                                }
                        }
                    }

                    if(format === "select"){
                        switch (options.multiselect) {
                            case true :
                                if (options.editable) {
                                    format = "free-multi-select";
                                } else {
                                    format = "multi-select";
                                }
                                break;
                            default:
                                if (options.editable) {
                                    format = "free-single-select";
                                } else {
                                    format = "single-select";
                                }
                        }
                    }

                    listFields.push({
                        id: fieldIndex,
                        title: title,
                        format: format,
                    });
                }
            });
            setFields(listFields);
            setClefCode(props.modelUniqueRefresh.clef);
        }
    }, [props.modelUniqueRefresh]);

    const handleAddTo = () => {
        let tmp = selectedFields;
        tmp.push({"from" : "", "to" : "",dragid : 'item-' + (selectedFields.length + 1)})
        setSelectedFields([...tmp]);
        //props.handleDataFields(tmp);
    }

    const handleAddToFrom = (value , index) => {
        let tmp = selectedFields;
        if(typeof tmp[index] !== "undefined"){
            if(tmp[index].to === ""){
                tmp[index].from = value.toString();
                setSelectedFields([...tmp]);
                props.handleDataFields(tmp);
            }
            else{
                let compatible = isCompatible(fields.find((item) => item.id.toString() === tmp[index].to), propsFields.find((item =>item.id.toString() === value.toString())));
                if(compatible){
                    tmp[index].from = value.toString();
                    setSelectedFields([...tmp]);
                    props.handleDataFields(tmp);
                }
                else{
                    confirmAlert({
                        title: i18n.t('common:global.plugnotes'),
                        message: i18n.t('common:formats.pluglink-configurator-not-compatible-format'),
                        buttons: [
                            {
                                label: i18n.t('common:global.ok'),
                            },
                            {
                                label: i18n.t('common:global.cancel')
                            },
                        ]
                    });
                }
            }
        }
    }

    const handleAddToTo = (value, index) => {
        let tmp = selectedFields;
        if(typeof tmp[index] !== "undefined"){
            if(tmp[index].from === ""){
                tmp[index].to = value.toString();
                setSelectedFields([...tmp]);
                props.handleDataFields(tmp);
            }
            else{
                let compatible = isCompatible(propsFields.find((item) => item.id.toString() === tmp[index].from), fields.find((item =>item.id.toString() === value.toString())));
                if(compatible){
                    tmp[index].to = value.toString();
                    setSelectedFields([...tmp]);
                    props.handleDataFields(tmp);
                }
                else{
                    confirmAlert({
                        title: i18n.t('common:global.plugnotes'),
                        message: i18n.t('common:formats.pluglink-configurator-not-compatible-format'),
                        buttons: [
                            {
                                label: i18n.t('common:global.ok'),
                            },
                            {
                                label: i18n.t('common:global.cancel')
                            },
                        ]
                    });
                }
            }
        }
    }

    const handleClickDelete = (i) => {
        let tmp = selectedFields.filter((item, index) => {
            if(i!== index){
                return item;
            }
        })
        setSelectedFields([...tmp]);
        props.handleDataFields(tmp);
    }

    const handleChangeCode = (value) => {
        setPfCode(value);
        props.handleDataPfCode(value)
        if(value === ""){
            setFields([]);
        }
    }

    useEffect(() => {
        if(props.open === true){
            let target = _.cloneDeep(props.fields)
            let fields = target.map((field, index) => {
                if(field.format === "table"){
                    switch (field.options.multiselect) {
                        case true :
                            if (field.options.editable) {
                                field.format = "free-multi-table";
                            } else {
                                field.format = "multi-table";
                            }
                            break;
                        default:
                            if (field.options.editable) {
                                field.format = "free-single-table";
                            } else {
                                field.format = "single-table";
                            }
                    }
                }

                if(field.format === "select"){
                    switch (field.options.multiselect) {
                        case true :
                            if (field.options.editable) {
                                field.format = "free-multi-select";
                            } else {
                                field.format = "multi-select";
                            }
                            break;
                        default:
                            if (field.options.editable) {
                                field.format = "free-single-select";
                            } else {
                                field.format = "single-select";
                            }
                    }
                }
                return field;
            });

            setPropsFields(fields);
            props.attemptGetModelUniqueReset();
            setFields([]);
            if(typeof props.field.options.pfcode !== "undefined" && props.field.options.pfcode !== ""){
                props.attemptGetModelUnique(props.field.options.pfcode, environment.userhash);
            }
        }
        else{
            props.attemptGetModelUniqueReset();
            setFields([]);
        }
    }, [props.open])

    useEffect(() => {
        props.handleDataCode(clefCode)
    }, [clefCode])

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        //padding: grid * 2,
        //margin: `0 0 ${grid}px 0`,

        // change background colour if dragging
        background: isDragging ? "#E54F62" : "white",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const items = reorder(
            selectedFields,
            result.source.index,
            result.destination.index
        );
        setSelectedFields(items);
        props.handleDataFields(items)
    }

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "lightblue" : "white",
        //padding: grid,
    });

    return (<div>
        <Modal
            key={key}
            keepMounted={false}
            open={props.open}
            onClose={handleCloseModal}
            // aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div style={modalStyle} className={classes.poPup}>
                <div className = "scrollbar-hidden popup_wrapper flexSpacebetween" style={{padding: '0px 5px'}}>
                    {/* <div className="popup_wrapper_content"> */}
                    <div className={`sous-header_modal_content ${classes.headerContainer}`}>
                            <span className={classes.titres}>
                                F28 : {t('fields.format-choice-'+ props.field.format.toLowerCase())}
                                <br/>
                            </span>
                        <span id="icon-listForm" style={{marginLeft: 5}} className={`material-icons ${classes.printBtn}`} onClick={(event) => handlePrintModel()}>
                                print
                            </span>
                        <div onClick={handleCloseModal} className={classes.closeBtn}>
                                <span id="icon-close" className="material-icons md-50 lightgrey">
                                    close
                                </span>
                        </div>
                    </div>
                    {/* <div className="sous-header_content lightgrey">
                            <div className="line flex_style">
                            </div>
                            <br/>
                            <br/>
                        </div> */}
                    <div className={classes.subHeaderContainer}>
                        <div className={classes.subHeader}>
                            {/* <span>1)</span> */}
                            <TextField
                                id="standard-full-width-clef"
                                label={t('common:fields.format-title-pluglink-1')}
                                placeholder={t('fields.format-choice-placeholder-'+props.field.format.toLowerCase()+'-pfcode')}
                                // fullWidth={true}
                                margin="none"
                                // disableUnderline={true}
                                inputlabelprops={{
                                    shrink: true,
                                }}
                                inputProps={
                                    {
                                        fieldkey: props.field.pfcode,
                                        className: classes.inputField,
                                        maxLength: 40
                                    }
                                }
                                InputProps={{
                                    endAdornment:
                                        (pfCode.length === 15 ?
                                            <React.Fragment>
                                                <span id="icon-listForm" className={`material-icons md-25`} onClick={(event) => handleGetModel()}>
                                                    cached
                                                </span>
                                            </React.Fragment> : null)
                                }}
                                disabled={false}
                                className={classes.inputField}
                                onChange={(event) => handleChangeCode(event.target.value)}
                                value={pfCode}
                            />
                        </div>
                        <div className={classes.subHeader2}>
                            <span>{t('common:fields.format-title-pluglink-2')}</span>
                        </div>
                    </div>
                    <div className="sous-header_content ">
                        <div className="display_flex flex_display_direction_column div_width_100">
                            <div className="display_flex flex_display_direction_row div_width_100">
                                <div className="div_width_45">
                                        <span>{t('common:fields.format-title-pluglink-4')}
                                            {typeof model !== "undefined" && model !== null ?
                                                <span> {model.clef} - {model.label} </span>
                                                :
                                                <span> {t('common:fields.format-title-pluglink-5')} </span>
                                            }
                                        </span>
                                </div>
                                <div className="div_width_10_centered">
                                    <span/>
                                </div>
                                <div className="div_width_45">
                                        <span>{t('common:fields.format-title-pluglink-3')}
                                            {typeof props.modelUniqueRefresh !== "undefined" && props.modelUniqueRefresh !== null &&
                                                <span> {props.modelUniqueRefresh.clef} - {props.modelUniqueRefresh.label} </span>
                                            }
                                        </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sous-header_content ">
                        <div className="display_flex flex_display_direction_row div_width_100">
                            <div className="div_width_45" style={{color: colors.orangeLight}}>
                                {propsFields.map((item, index) => {
                                    return(
                                        <div key={index} className="flex_display_direction_row flexSpacebetween" onClick={(event) => handleAddToFrom(item)}>
                                            <span style={{fontSize : 13, textAlign : 'left' , marginRight : '0px', maxHeight: 24}}>{item.id} - {item.label} </span>
                                            <span style={{fontSize : 12, textAlign : 'right' , marginRight : '10px'}}> ({formatTextTypes[item.format.toUpperCase()]} - {t('fields.format-choice-'+item.format.toLowerCase())})</span>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="div_width_10_centered">
                                <span/>
                            </div>
                            <div className="div_width_45" style={{color: colors.plugnotesGreen}}>
                                {fields.map((item, index) => {
                                    return(
                                        <div key={index} className="flex_display_direction_row flexSpacebetween" onClick={(event) => handleAddToTo(item)}>
                                            <span  style={{fontSize : 13, textAlign : 'left' , marginRight : '0px', maxHeight: 24}}>{item.id} - {item.title} </span>
                                            <span style={{fontSize : 12, textAlign : 'right', marginRight : '10px'}}> ({formatTextTypes[item.format.toUpperCase()]} - {t('fields.format-choice-'+item.format.toLowerCase())})</span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="sous-header_content lightgrey">
                        <div className="line flex_style" />
                        <br/>
                    </div>

                    <div className="sous-header_content ">
                        <div className="display_flex flex_display_direction_column div_width_100">
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}
                                        >
                                            {typeof selectedFields !== "undefined" && selectedFields.length > 0 && selectedFields.map((item, index) => {
                                                let fieldLeft = fields.find((right) => right.id.toString() === item.to);
                                                let fieldRight = propsFields.find((left) => left.id.toString() === item.from);

                                                return (
                                                    <Draggable key={item.dragid} draggableId={item.dragid} index={index}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style
                                                                )}
                                                            >
                                                                <div key={index} className={classes.paramContainer}>
                                                                    <div className={classes.param1Container}>
                                                                        {t('fields.format-choice-copy-content-right')}
                                                                        <span className={classes.selectContainer}>
                                                                            <Select
                                                                                disableUnderline={true}
                                                                                fullWidth={false}
                                                                                labelId="simple-select-left"
                                                                                id="simple-select-left"
                                                                                SelectDisplayProps={{style: {paddingTop: 8, paddingBottom: 8, color : '#020202'}}}
                                                                                style={{minWidth: '50%', flexGrow: 1}}
                                                                                value={typeof fieldRight !== "undefined" ? fieldRight.id : "0"}
                                                                                onChange={(event) => handleAddToFrom(event.target.value, index)}
                                                                            >
                                                                             <MenuItem selected disabled value="0">
                                                                                    <em>0 - { t('common:global.select') }</em>
                                                                                  </MenuItem>
                                                                                {propsFields.map((option, optionIndex) => <MenuItem key={optionIndex} value={option.id}>
                                                                                    <span> {option.id} - {option.label}</span>
                                                                                </MenuItem>)
                                                                                }
                                                                            </Select>
                                                                        </span>
                                                                        { t('common:fields.format-title-pluglink-6') }
                                                                    </div>
                                                                    <div className={classes.param2Container}>
                                                                        {t('fields.format-choice-complete-content-left')}
                                                                        <span className={classes.selectContainer}>
                                                                            <Select
                                                                                disableUnderline={true}
                                                                                fullWidth={false}
                                                                                labelId="simple-select-right"
                                                                                id="simple-select-right"
                                                                                SelectDisplayProps={{style: {paddingTop: 8, paddingBottom: 8, color : '#020202'}}}
                                                                                style={{minWidth: '50%'}}
                                                                                value={typeof fieldLeft !== "undefined" ? fieldLeft.id : "0"}
                                                                                onChange={(event) => handleAddToTo(event.target.value, index)}
                                                                            >
                                                                                 <MenuItem selected disabled value="0">
                                                                                    <em>0 - { t('common:global.select') }</em>
                                                                                  </MenuItem>
                                                                                {fields.map((option, optionIndex) => <MenuItem value={option.id} key={optionIndex}>
                                                                                    <span> {option.id} - {option.title} </span>
                                                                                </MenuItem>)
                                                                                }
                                                                            </Select>
                                                                        </span>
                                                                        <div >
                                                                            <Tooltip title={t('common:plugnote.delete')} aria-label="add" placement="top-start">
                                                                                <span id="icon-supprimer"
                                                                                      className="material-icons md-30 padding_0_10"
                                                                                      onClick={(event) => handleClickDelete(index)}>
                                                                                    delete
                                                                                </span>
                                                                            </Tooltip>

                                                                            <Tooltip  title={t('common:helper.move-field')} aria-label="add" placement="top-start">
                                                                                <span id="icon-more" className="material-icons md-small">
                                                                                    drag_handle
                                                                                </span>
                                                                            </Tooltip>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>)}
                                                    </Draggable>
                                                )
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>

                    </div>

                    <div className="sous-header_content ">
                        <div className="display_flex flex_display_direction_column div_width_100">
                            <div className="display_flex flex_display_direction_row div_width_100">
                                <Tooltip  title={t('fields.format-choice-placeholder-'+props.field.format.toLowerCase()+'-add')} aria-label="add" placement="top-start">
                                        <span id="icon-more" className={`material-icons md-50`} onClick={(event) => handleAddTo()}>
                                            add
                                        </span>
                                </Tooltip>
                            </div>
                        </div>
                    </div>

                    <div className="sous-header_content ">
                        <div className="display_flex flex_display_direction_column div_width_100">
                            <div className="display_flex flex_display_direction_row div_width_100">
                                    <span style={{fontSize : '12px', fontStyle: 'italic'}}>
                                        * <span style={{fontSize : '12px', fontStyle: 'italic'}}>{ t('common:fields.format-title-pluglink-7') }</span>
                                    </span>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </div>
            </div>
        </Modal>
        {openModalPrint && (
            <ModalPrintPluglinkComponent
                open={openModalPrint}
                close={() => setOpenModalPrint(false)}
                field={props.field}
                fromModel={model}
                toModel={props.modelUniqueRefresh}
                fromFields={propsFields}
                toFields={fields}
                user={environment}
            />
        )}
    </div>)
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        modelUniqueRefresh : state.model.modelUniqueRefresh || null
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        attemptGetModelUnique: (code, userhash) => dispatch(getModelUniqueByClefRequest(code,userhash)),
        attemptGetModelUniqueReset: () => dispatch(getModelUniqueByClefReset()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalFormatPluglinkConstructorComponent)
