import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {KeyboardDatePicker} from "@material-ui/pickers";
import { Tooltip } from "@material-ui/core";
import colors from "../../assets/themes/Colors";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    datePickerIcon: {
        "& .MuiIconButton-root": {
            padding: 0
        }
    },
    timeContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    timeDelButton: {
        height:'22px',
        width: '12px',
        color: colors.main,
        alignItems: 'center',
        marginRight: '3px'
    },
    timeNowButton: {
        height:'21px',
        width: '21px',
        color: colors.main,
        alignItems: 'center',
        border: '2.5px solid',
        borderColor: colors.main,
        borderRadius: '22px',
        fontSize: "18px",
        fontWeight: '900',
    },
    endButton: {    
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        alignSelf: 'center'
    },
}));


const FormatBirthdayComponent = (props) => {
    const classes = useStyles();

    let currentValue = null;
    if(props.field.value !== null && props.field.value.length > 0){
        let currentTime = new Date();
        let split = props.field.value.split('-');
        currentValue = new Date(currentTime.getFullYear(), split[1] - 1, split[0], null, null);
    }

    const [selectedDate, handleDateChange] = useState(currentValue);
    const [disabled, setDisabled] = useState(typeof props.disabled === 'undefined' ? false: props.disabled);

    const deleteDate = () => {
        handleDateChange(null)
        props.handleEditDateTime(null, '', props.field, props.index);
    }

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    return (
    <div className={classes.timeContainer}>
        <KeyboardDatePicker
            autoComplete='off'
            InputAdornmentProps={{ position: 'start' }}
            disabled={disabled}
            className={`${classes.datePickerIcon} ` + classes.fullWidth}
            variant="inline"
            format="dd-MM"
            placeholder="dd-MM"
            fullWidth={true}
            id={'date-picker-inline-'+props.field.id}
            KeyboardButtonProps={{
                'aria-label': 'change date',
            }}
            value={selectedDate}
            onChange={(date, value) => {
                handleDateChange(date);
                props.handleEditDateTime(date, value, props.field, props.index);
            }}
            InputProps={{ disableUnderline: true }}
        />
        {!disabled && (selectedDate === "" || selectedDate !== null) ?
            <Tooltip title="Delete" placement="top" className={classes.endButton} >
                <span className={`${classes.timeDelButton} material-icons`} onClick={() => deleteDate()}>highlight_off</span>
            </Tooltip> 
            
        : null}
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatBirthdayComponent);





