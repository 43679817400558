import {api} from "./index";


export let
    postCreateExternalPlugnotePFCode,
    postCreateInternalPlugnotePFCode,
    postCreateInternalPlugnote,
    postCreateInternalPlugnoteBase64,
    postSendMails;

postCreateExternalPlugnotePFCode = (payload) => api.post('datas/createexternaldatafromqrcodes.json', payload, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});

postCreateInternalPlugnotePFCode = (payload) => api.post('datas/createinternaldatafromqrcodes.json', payload, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});

postCreateInternalPlugnote = (payload) => api.post('datas/createinternaldatas.json', payload, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});

postCreateInternalPlugnoteBase64 = (payload) => api.post('datas/createinternalbase64datas.json', payload);

postSendMails = (payload) => api.post('utils/sendmails.json', payload, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});
