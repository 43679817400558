// create initial state for reducers
import {Types} from "../actions/mailbox";

const INIT_STATE = {
        end : null,
        start : 1,
        list : [],
        mail : null,
        fetching : false
};


// reducer function to transform state
export default function mailbox(state = INIT_STATE, action) {
    switch(action.type) {
        case Types.RESET_ALL_DATA : return INIT_STATE;

        case Types.GET_MAIL_IN_REQUEST: {
            return {
                ...state,
                mail : null,
                fetching: true,
            }
        }

        case Types.GET_MAIL_IN_SUCCESS: {
            return {
                ...state,
                mail : action.payload.data,
                fetching: false,
            }
        }

        case Types.GET_MAILS_IN_REQUEST: {
            return {
                ...state,
                list : [],
                end : null,
                fetching: true,
            }
        }

        case Types.GET_MAILS_IN_PAGINATE_REQUEST: {
            return {
                ...state,
                fetching: true,
            }
        }

        case Types.GET_MAILS_IN_PAGINATE_SUCCESS: {
            return {
                ...state,
                list: state.list.concat(action.payload.data),
                fetching: true,
            }
        }

        case Types.GET_MAILS_IN_SUCCESS: {
            return {
                ...state,
                list: action.payload.data,
                fetching : false,
            }
        }
        case Types.GET_MAILS_IN_END_SUCCESS: {
            return {
                ...state,
                start : action.payload.start,
                end: action.payload2.end,
                fetching : false,
            }
        }

        case Types.REDUX_GENERATE_MAIL_IN_RESET: {
            return {
                ...state,
                mail : null,
            }
        }


        default: return state;
    }
};