import React, {useCallback, useEffect, useRef, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import colors from "../../assets/themes/Colors";
import Modal from '@material-ui/core/Modal';
import SignatureCanvas from 'react-signature-canvas'
import Data9002Printable from "../printable/Data9002Printable";
import Data9099Printable from "../printable/Data9099Printable";
import Data2001Printable from "../printable/Data2001Printable";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    mainContainer: {
        display: 'flex',
        gap: 5,
        flexDirection: 'column'
    },
    formatInputFieldInline: {
        backgroundColor: colors.greyLighter,
        border: "solid #E3E3E3 1px",
        borderRadius: "4px",
        padding: "3px 10px",
    },
    signature: {
        justifyContent : 'center',
        alignItems : 'center',
        backgroundColor: colors.greyLighter,
        border: "solid #E3E3E3 1px",
        borderRadius: "4px",
        padding: "3px 10px",
    },
    signatureBis: {
        backgroundColor: colors.white,
        // height: '150px',
        justifyContent : 'center',
        alignItems : 'center',
        border: "solid #E3E3E3 1px",
        borderRadius: "4px",
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%'
    },
    input_add_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    fieldRight:{
        flexGrow: 1,
    },
    mailContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    button:{
        margin : '5px',
        /* border: '1px red solid', */
        display: 'inline-block',
        color: "white",
        backgroundColor: "#58c3c1",
        padding: "3px 15px",
        borderRadius: "3px",
        cursor: "pointer",
    },
    div:{
        /* border: '1px red solid', */
        display: 'flex',
        justifyContent: 'flex-end',
        margin: "5px",
        padding: "10px",
    },
    signButton:{
        /* border: '1px red solid', */
        display: 'inline-block',
        color: "white",
        backgroundColor: "#58c3c1",
        padding: "3px 15px",
        borderRadius: "3px",
        cursor: "pointer",
    },
    signCanvas: {
        height: '150px!important',
        width: '100%'
    }
}));

const useModalStyles = makeStyles((theme) => ({
    popup: {
        position: 'absolute',
        padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        boxSizing: 'border-box',
        borderRadius: 10,
        maxHeight: '90vh',
        width: '65vw',
        scrollbarWidth: 'none!important',
        "&::-webkit-scrollbar": {
            display: 'none'
        },
        overflow: 'hidden',
        '@media (max-width: 640px)': {
            width: '100vw'
        }
    },
    closeBtn: {
        alignSelf: 'flex-end'
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    modalHeader: {
        display: 'flex',
        flexDirection: 'column',
        position: 'sticky',
        top: 0,
        zIndex: 9999,
        width: '100%'
    },
    headerTitle:{
        textAlign: 'center',
        transform: 'translateY(-30px)',
        fontSize: 28,
        alignSelf: 'center',
    },
    marginLeft:{
        marginLeft : '10px'
    },
    icon:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : 'black'
    },
    fieldImageSvg: {
        width: 25,
        height: 25,
    },
    fieldImage: {
        height: 10,
        width: 10
    },
    modalBody: {
        display: 'flex',
        flexDirection: 'column',
    },
    errorNote: {
        backgroundColor: colors.greyDark,
    },
    "&::-webkit-scrollbar": {
        display: 'none'
    }
}));

const SignComponent = (props) => {
    let userIndex = typeof props.plugnote !== "undefined" && props.plugnote !== null ?  props.plugnote.userindex : 0;
    let dataNumber = userIndex.toString().padStart(6, 0);
    let model = props.model;
    let modelClef = model.clef

    const fileName = 'PN-' + dataNumber + '-' + modelClef

    const { t, i18n } = useTranslation(['common', 'models']);
    const classes = useModalStyles();
    const useClasses = useStyles();

    const [text, setText] = useState("");
    const [createPdf, setCreatePdf] = useState("");

    const [signature, setSignature] = useState(null);

    const sigCanvas = useRef({})
    const sigPad = useRef({})

    const handleEditValue = (value) => {
        setText(value);
    }

    const handleConfirm = () => {
        let options = {year: "numeric", month: "numeric", day: "numeric", hour: 'numeric', minute: '2-digit' ,hour12: false};
        let now = new Date();
        let tmp =  i18n.t('common:global.signed', { signataire: text, date : now.toLocaleString('fr-FR', options) });
        props.handleEditValue(tmp, props.field, props.index)
    }

    const handleClearSign = () => {
        sigPad.current.clear();
        setSignature(null);
        setCreatePdf("");
    }

    const handleConfirmSign = () => {
        handleConfirm();

        let template = props?.field?.options?.template;

        let signature = sigCanvas.current.toDataURL('image/png', 0.1);
        switch (template) {
            case '9002':
                createPDF9002(signature);
                break;
            case '9099':
                createPDF9099(signature);
                break;
            case '2001':
                createPDF2001(signature);
                break;
            default:
                break;
        }
    }

    const createPDF9002 = (signature) => {
        setSignature(signature);
        setCreatePdf("9002");
    }

    const createPDF9099 = (signature) => {
        setSignature(signature);
        setCreatePdf("9099");
    }

    const createPDF2001 = (signature) => {
        setSignature(signature);
        setCreatePdf("2001");
    }

    const measuredRef = useCallback(node => {
        const resizeCanvas = (signaturePad, canvas) => {
            canvas.width = canvas.parentElement.clientWidth // width of the .canvasWrapper
            canvas.height = canvas.parentElement.clientWidth / 3
            signaturePad.clear()
        }
        if (node !== null) {
            sigCanvas.current = node.getCanvas()
            sigPad.current = node.getSignaturePad()
            resizeCanvas(node.getSignaturePad(), node.getCanvas())
        }
    }, [3])

    return (
        <Modal
            keepMounted={false}
            open={props.open}
            onClose={props.close}
            className={classes.modal}
            BackdropProps={{style: {backgroundColor: colors.blackAlpha}}}
        >
            <div className={classes.popup}>
                <div className={classes.modalHeader}>
                    <span
                        id="icon-close"
                        className={ `${classes.closeBtn} material-icons md-50 lightgrey`}
                        onClick={props.close}>
                        close
                    </span>
                    <div className={classes.headerTitle}>
                        {"Signature"}
                        <br/>
                    </div>
                </div>
                <div className={classes.modalBody}>
                    <div className={`scrollbar-hidden ${useClasses.mainContainer}`} >
                        <div className={useClasses.formatInputFieldInline}>
                            <TextField
                                onChange={(event) => handleEditValue(event.target.value)}
                                id={"standard-full-width-"+props.field.id}
                                label={t('common:global.signed-by')}
                                type="text"
                                helperText=""
                                fullWidth={true}
                                InputLabelProps={{
                                    shrink: true,

                                }}
                                className={`${useClasses.fieldRight} testeBorder`}
                                value={text}
                                disabled={false}
                                InputProps={{
                                    disableUnderline: true,
                                }}
                            />
                        </div>
                        {text.length > 0  &&
                        <div className={useClasses.signature}>
                            <div className={useClasses.signatureBis}>
                                <div id={"canvas-signature-"+ props.field.id}>
                                    <SignatureCanvas
                                        // canvasProps={{className: `sigCanvas`}}
                                        canvasProps={{ width: "calc(100vw - 60px)", height: 200, className: 'sig-canvas' }}
                                        penColor='black'
                                        ref={measuredRef}
                                        />
                                </div>
                            </div>
                            <div className={useClasses.div}>
                                <div className={useClasses.button} onClick={(event) => handleClearSign()}>
                                    <span id="icon-listForm" className={`material-icons md-small white`} style={{color: colors.main}} >clear</span>
                                </div>
                                <div className={useClasses.button} onClick={(event) => handleConfirmSign()}>
                                    <span id="icon-listForm" className={`material-icons md-small white`} style={{color: colors.main}}>check</span>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
                {
                    props.annexes!== null && props.fields !== null && props.user !== null && model !== null  && createPdf === '9002'&& signature !== null &&
                    <Data9002Printable plugnote={props.plugnote} annexes={props.annexes} fields={props.fields} user={props.user} model={model} filename={fileName} index={props.field.id} signature={signature} close={props.close}  onChangeHandlerDrop={props.onChangeHandlerDrop}/>
                }

                {
                    props.annexes!== null && props.fields !== null && props.user !== null && model !== null  && createPdf === '9099'&& signature !== null &&
                    <Data9099Printable plugnote={props.plugnote} annexes={props.annexes} fields={props.fields} user={props.user} model={model} filename={fileName} index={props.field.id} signature={signature} close={props.close}  onChangeHandlerDrop={props.onChangeHandlerDrop}/>
                }

                {
                    props.annexes!== null && props.fields !== null && props.user !== null && model !== null  && createPdf === '2001'&& signature !== null &&
                    <Data2001Printable plugnote={props.plugnote} annexes={props.annexes} fields={props.fields} user={props.user} model={model} filename={fileName} index={props.field.id} signature={signature} close={props.close}  onChangeHandlerDrop={props.onChangeHandlerDrop} t={t}/>
                }

            </div>
        </Modal>
    )
}

const FormatSignComponent = (props) => {
    const classes = useStyles();
    const [disabled, setDisabled] = useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    const [text, setText] = useState(props.field.value);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    useEffect(() => {
        setText(!!props.field.value ? props.field.value : "");
    },[props.field.value])

    const handleEditValue = (value, field, index) => {
        setText(value);
        props.handleEditValue(value, field, index)
    }

    const handleOpenSignComponent = () => {
        if (!disabled) setOpen(!open)
    }

    return (
        <div className={classes.mailContainer}>
            <TextField
                className={`${classes.fieldRight} testeBorder`}
                contentEditable={false}
                disabled={true}
                helperText=""
                id={'signatory-input-' + props.field.id}
                InputLabelProps={{shrink: true}}
                InputProps={{
                    disableUnderline: true,
                    endAdornment:
                        <React.Fragment>
                            {!disabled &&
                            <span className={classes.signButton} onClick={(event) => handleOpenSignComponent()}>
                                <span id="icon-listForm" className={`material-icons md-small white`} style={{color: colors.main}} >
                                    draw
                                </span>
                            </span>}
                        </React.Fragment>}}
                onClick={(event) => handleOpenSignComponent()}
                type="text"
                value={text}
            />
            {open &&
            <SignComponent open={open} close={() => setOpen(false)} {...props} handleEditValue={handleEditValue}/>
            }
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        user : state?.user || null,
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatSignComponent);





