import {call, fork, put, takeEvery} from 'redux-saga/effects';
import * as actions from '../actions/template';
import * as api from '../api/templateApi';
import {trackPromise} from "react-promise-tracker";

const myGenericTrackedWrapper = (fn, args1, args2, args3) => trackPromise(fn(args1,args2, args3));


/**
 * Permet de récupérer toutes les langues, toutes les catégories et tous les modèles de template
 * @param api
 * @param action
 */
function * getAllTemplates (action) {

}

/**
 * Permet de récupérer la liste des catégories pour les templates de modèles en fonction de la langue et de la catégorie
 * @param api
 * @param action
 */
function * getTemplateCodeGroup (action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.getTemplateGroup, action.codeGroup, action.userhash);

        if(response.statusText == "OK" && response.data.result === 'ok'){
            yield put(actions.getTemplateCodeGroupSuccess(response.data.group));
        }else{
            yield put(actions.getTemplateCodeGroupFailure());
        }
    }catch (e) {
        yield put(actions.getTemplateCodeGroupError());

    }

}

/**
 * Permet de récupérer la liste des catégories pour les templates de modèles en fonction de la langue et de la catégorie
 * @param api
 * @param action
 */
function * getTemplateCodeModel (action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.getTemplateModelGroup, action.codeModel, action.userhash);

        if(response.statusText == "OK" && response.data.result === 'ok'){
            yield put(actions.getTemplateCodeModelSuccess(response.data.models));
        }else{
            yield put(actions.getTemplateCodeModelFailure());
        }
    }catch (e) {
        yield put(actions.getTemplateCodeModelError());

    }

}

/**
 * Permet de récupérer la liste des catégories pour les templates de modèles en fonction de la langue et de la catégorie
 * @param api
 * @param action
 */
function * getTemplateModelCode (action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.getTemplateModelByCode, action.code, action.userhash, action.langue);

        if(response.statusText == "OK" && response.data.result ===  true){
            yield put(actions.getTemplateModelCodeSuccess(response.data.data));
        }else{
            yield put(actions.getTemplateModelCodeFailure());
        }
    }catch (e) {
        yield put(actions.getTemplateModelCodeError());

    }

}


/**
 * Permet de récupérer le model par code à générer
 * @param api
 * @param action
 */


function * getTemplateCodeCategorie (action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.getTemplateCategoryModel, action.codeCategorie, action.langue);

        if(response.statusText == "OK" && response.data.result === 'ok'){
            yield put(actions.getTemplateCodeCategorieSuccess(response.data.group));
        }else{
            yield put(actions.getTemplateCodeCategorieFailure());
        }
    }catch (e) {
        yield put(actions.getTemplateCodeCategorieError());

    }

}

function* watchFetchTemplate() {
    // create watcher of fetchData function
    yield takeEvery(actions.Types.GET_TEMPLATE_ALL_REQUEST, getAllTemplates);
    yield takeEvery(actions.Types.GET_TEMPLATE_CODE_MODEL_REQUEST, getTemplateCodeModel);
    yield takeEvery(actions.Types.GET_TEMPLATE_MODEL_CODE_REQUEST, getTemplateModelCode);
    yield takeEvery(actions.Types.GET_TEMPLATE_CODE_GROUP_REQUEST, getTemplateCodeGroup);
    yield takeEvery(actions.Types.GET_TEMPLATE_CODE_CATEGORIE_REQUEST, getTemplateCodeCategorie);
}

const TemplateSagas = [
    fork(watchFetchTemplate)
];

export default TemplateSagas;
