import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";

import {useTranslation} from "react-i18next";
import PlacesAutocomplete, {geocodeByAddress} from 'react-places-autocomplete';
import {GoogleApiWrapper} from "google-maps-react";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    localContainer:{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        gap: 15,

    },
    localComponent:{
        padding: '3px 0',
        flexGrow: 1,
    },
    locationSearchInput: {
        border: 'none',
        borderRadius: '5px',
        height: 'auto',
        //backgroundColor: '#f1f1f1',
        fontSize: '16px',
        width: '100%',
      },
    localButton:{
        display: 'inline-block',
    color: "white",
    backgroundColor: "#58c3c1",
    padding: "3px 15px",
    borderRadius: "3px",
    cursor: "pointer",
    },
}));


const FormatLocalisationComponent = (props) => {
    const classes = useStyles();

    const { t, i18n } = useTranslation(['common', 'models']);
    const [address, setAddress] = useState(typeof props.field.value !== "undefined" && props.field.value.length > 0 ? props.field.value : "");
    const [disabled, setDisabled] = useState(typeof props.disabled === 'undefined' ? false: props.disabled);

    const [fullAddress, setFullAddress] = useState("http://maps.google.com/maps?daddr="+address);

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    useEffect(() => {
        setAddress(!!props.field.value ? props.field.value : "");
    },[props.field.value])


    const handleSelect = address => {
        geocodeByAddress(address)
            .then(results => {
                setAddress(results[0].formatted_address);
                props.handleChangeLocalisation(results[0].formatted_address, props.field, props.index)
                setFullAddress("http://maps.google.com/maps?daddr=" + address)
            })
            .catch(error => console.error('Error', error));
    };

    const  handleChange = address => {
        setAddress(address);
        if(address == ""){
            props.handleChangeLocalisation("", props.field, props.index)
        }
    };

    return (
        <div className={classes.localContainer}>
            <PlacesAutocomplete
                clearable={true}
                value={address}
                onChange={handleChange}
                onSelect={handleSelect}
                key={props.index}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div className={classes.localComponent}>
                        <input
                            {...getInputProps({
                                disabled: disabled,
                                placeholder: t('fields.format-choice-localis'),
                                onFocus: (e) => e.target.placeholder = '',
                                onBlur: (e) => e.target.placeholder = t('fields.format-choice-localis'),
                                className: classes.locationSearchInput,
                            })}
                        />
                        <div className={classes.localSugestions}>
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion, index) => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                    ? { backgroundColor: '#51C3C1', color: '#fff', cursor: 'pointer' }
                                    : { backgroundColor: '#fafafa', cursor: 'pointer' };
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                        key={index}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
            { typeof address !== 'undefined' &&  address !== '' &&
                    <a href={fullAddress} target="_blank"  className={classes.localButton} rel="noreferrer"><span className="material-icons md-small white">room</span></a>
            }
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper({apiKey:'AIzaSyDhcQZNiwclLgi3Q0uiIrTHyjLXZwnbxCw'})(FormatLocalisationComponent));





