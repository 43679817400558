// actions types is object with the
// key: GET_DATA_REQUEST -> value: describes the action
import {toast} from "react-toastify";
import {Translation} from "react-i18next";
import React from "react";

export const Types = {
    RESET_ALL_DATA: 'reset_all_data',

    SET_SEARCH_MODEL_PARAMS_REQUEST: 'set_search_model_params_request',
    SET_SEARCH_PLUG_PARAMS_REQUEST: 'set_search_plug_params_request',

    DEL_SEARCH_KEY_PARAMS_REQUEST: 'del_search_key_params_request',

    DEL_ALL_SEARCH_PARAMS_REQUEST: 'del_all_search_params_request',

    POST_SEARCH_MODEL_REQUEST: 'post_search_model_request',
    POST_SEARCH_MODEL_SUCCESS: 'post_search_model_success',
    POST_SEARCH_MODEL_FAILURE: 'post_search_model_failure',
    POST_SEARCH_MODEL_ERROR: 'post_search_model_error',

    POST_SEARCH_PLUG_REQUEST: 'post_search_plug_request',
    POST_SEARCH_PLUG_SUCCESS: 'post_search_plug_success',
    POST_SEARCH_PLUG_FAILURE: 'post_search_plug_failure',
    POST_SEARCH_PLUG_ERROR: 'post_search_plug_error',

    POST_SEARCH_REQUEST: 'post_search_request',
    POST_SEARCH_SUCCESS: 'post_search_success',
    POST_SEARCH_FAILURE: 'post_search_failure',
    POST_SEARCH_ERROR: 'post_search_error',
};


/*
Token
 */
// function that returns an object literal
export const setSearchModelParamsRequest = (paramName, data,advancedSearch) => ({
    type: Types.SET_SEARCH_MODEL_PARAMS_REQUEST,
    paramName : paramName,
    data : data,
    advancedSearch :advancedSearch
});

// function that returns an object literal
export const delSearchKeyParamsRequest = (paramName,userhash) => ({
    type: Types.DEL_SEARCH_KEY_PARAMS_REQUEST,
    paramName : paramName,
    userhash : userhash
});

// function that returns an object literal
export const delAllSearchParamsRequest = () => ({
    type: Types.DEL_ALL_SEARCH_PARAMS_REQUEST,
});



/*
Token
 */
// function that returns an object literal
export const setSearchPlugParamsRequest = (paramName, data,advancedSearch) => ({
    type: Types.SET_SEARCH_PLUG_PARAMS_REQUEST,
    paramName : paramName,
    data : data,
    advancedSearch :advancedSearch
});


/*
Token
 */
// function that returns an object literal
export const postSearchModelRequest = (userhash) => ({
    type: Types.POST_SEARCH_MODEL_REQUEST,
    userhash : userhash
});

// function that returns an object literal
export const postSearchModelSuccess = (data) => ({
    type: Types.POST_SEARCH_MODEL_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const postSearchModelFailure = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_SEARCH_MODEL_FAILURE,
        });
        toast.warn(<Translation ns="common">
            {
                (t, { i18n }) => <p>{t('models.search-model-no-result')}</p>
            }
        </Translation>);
    };
};

// function that returns an object literal
export const postSearchModelError = () => ({
    type: Types.POST_SEARCH_MODEL_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const postSearchPlugRequest = (userhash) => ({
    type: Types.POST_SEARCH_PLUG_REQUEST,
    userhash : userhash
});

// function that returns an object literal
export const postSearchPlugSuccess = (data) => ({
    type: Types.POST_SEARCH_PLUG_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const postSearchPlugFailure = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_SEARCH_PLUG_FAILURE,
        });
        toast.warn("Combinaison de recherche sans résultat");
    };
};

// function that returns an object literal
export const postSearchPlugError = () => ({
    type: Types.POST_SEARCH_PLUG_ERROR,
});


export const postSearchRequest = (userhash) => ({
    type: Types.POST_SEARCH_REQUEST,
    userhash : userhash
});
// function that returns an object literal
export const postSearchSuccess = (data) => ({
    type: Types.POST_SEARCH_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const postSearchFailure = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_SEARCH_FAILURE,
        });
        toast.warn("Combinaison de recherche sans résultat");
    };
};

// function that returns an object literal
export const postSearchError = () => ({
    type: Types.POST_SEARCH_ERROR,
});


export const resetAllData = () => ({
    type: Types.RESET_ALL_DATA,
});


