import {call, fork, put, takeEvery} from 'redux-saga/effects';
import * as actions from '../actions/repertory';
import * as api from '../api/repertoryApi';
import {trackPromise} from "react-promise-tracker";

const myGenericTrackedWrapper = (fn, args1, args2, args3) => trackPromise(fn(args1,args2, args3));


export function * getListContacts (action) {

    try {
        const response = yield call(api.getListContacts, action.userhash);
        // success?
        if (response.statusText == "OK" && response.data.result === true) {
            yield put(actions.getListContactsSuccess(response.data.data));
        } else {
            yield put(actions.getListContactsFailure())
        }
    }catch(e) {
        yield put(actions.getListContactsError());
    }
}


function* watchFetchRepertory() {
    // create watcher of fetchData function
    yield takeEvery(actions.Types.GET_LIST_CONTACTS_REQUEST, getListContacts);
}

const RepertorySagas = [
    fork(watchFetchRepertory)
];

export default RepertorySagas;