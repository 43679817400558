import React, {useEffect} from "react"
import {useTranslation} from "react-i18next";
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {connect} from "react-redux";
import '@contentful/forma-36-react-components/dist/styles.css';
import Input from "@material-ui/core/Input";

function getModalStyle() {
    const top = 5;

    return {
        top: `${top}%`,
        margin:'auto',
        overflow: 'auto',
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    popup: {
        position: 'absolute',
        //width: '50%',
        //height : '50%',
        //backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        //boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        minWidth: '50%',
        maxWidth : '100%',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    marginLeft:{
        marginLeft : '10px'
    }
}));

const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: [],
};

const ModalEmailComponent = (props) => {

    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [t, i18n] = useTranslation('common');

    const [key, setKey] = React.useState(0);

    const handleCloseModal = () => {
        props.close();
    };

    useEffect(() => {

    }, [props.open]);

    return (
        <Modal
            key={key}
            keepMounted={false}
            open={props.open}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div style={modalStyle} className={classes.popup}>
                <div className = "scrollbar-hidden popup_wrapper flexSpacebetween" >
                    <div className="">
                        <span>{t('common:external.insert-email-pf-code-complete')}</span>
                        <br/>
                        <br/>
                        <Input
                            onChange={(event) => props.handleChangeEmail(event.target.value)}
                            id="standard-full-width-field"
                            disableUnderline={true}
                            placeholder={t('common:external.email')}
                            label="Label"
                            fullWidth={true}
                            margin="none"
                            inputlabelprops={{
                                shrink: true,
                            }}
                            className="input"
                        />
                        <br/>
                        {
                            props.emailValid &&  <button className="button" onClick={props.handleSaveCreatePlugnoteConfirm} >{t('common:multiple.send')}</button>
                        }
                    </div>
                </div>
            </div>
        </Modal>
    )
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalEmailComponent)
