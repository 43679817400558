import {call, fork, put, takeEvery} from 'redux-saga/effects';
import * as actions from '../actions/filter';
import * as api from '../api/filterApi';
import {trackPromise} from "react-promise-tracker";

const myGenericTrackedWrapper = (fn, args1, args2, args3) => trackPromise(fn(args1,args2, args3));


export function * postListFiltersByModel (action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.postListFiltersByModel,action.payload);
        if(response.status === 200 && response.data.result){
            yield put(actions.postSendListFilterSuccess());
        }else{
            yield put(actions.postSendListFilterFailure());
        }
    }catch(e) {
        yield put(actions.postSendListFilterError());
    }
}

export function * getListFiltersByModel (action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.getListFiltersByModel,action.payload.modelhash, action.payload.userhash);
        if(response.status === 200 && response.data.result){
            yield put(actions.getListFilterSuccess(response.data.data));
        }else{
            yield put(actions.getListFilterFailure());
        }
    }catch(e) {
        yield put(actions.getListFilterError());
    }
}

export function * deleteFilter (action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.deleteFilter,action.payload.id);
        if(response.status === 200 && response.data.result){
            yield put(actions.deleteFilterSuccess());
        }else{
            yield put(actions.deleteFilterFailure());
        }
    }catch(e) {
        yield put(actions.deleteFilterError());
    }
}


function* watchFetchUtils() {
    // create watcher of fetchData function
    yield takeEvery(actions.Types.POST_SEND_LIST_FILTER_REQUEST, postListFiltersByModel);
    yield takeEvery(actions.Types.GET_LIST_FILTER_REQUEST, getListFiltersByModel);
    yield takeEvery(actions.Types.DELETE_FILTER_REQUEST, deleteFilter);
}

const FilterSagas = [
    fork(watchFetchUtils)
];

export default FilterSagas;
