import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Close, Search} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import {Input} from '@material-ui/core';
import {getCurrentEnvironnement} from "../../utils/Common";
import {reduxDelGlobalDataFilterRequest, reduxSetGlobalDataFilterRequest} from "../../actions/data";


const useStyles = makeStyles((theme) => ({
    root: {
        margin : 0,
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F1F1F1',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    }
}));


const MenuLeftPlugSearchComponent = (props) => {
    const classes = useStyles();
    const [t, i18n] = useTranslation('common');
    const [search, setSearch] = useState(props.globalDataFilter !== null ? props.globalDataFilter : "");
    const [enableSearch, setEnableSearch] = useState(props.globalDataFilter !== null);

    const environment = getCurrentEnvironnement();

    const handleChangeTextInput = (event, value) => {
        setSearch(event.target.value);
    };

    const handleResetPlugSearch = () => {
        setSearch("");
        props.attemptDelDataFilter()
        props.close()
    }

    useEffect(() => {
        if(search !== ""){
            setEnableSearch(true);
            props.attemptSetDataFilter(search);
        }else{
            setEnableSearch(false);
            props.attemptDelDataFilter()
        }
    }, [search])

    return (
        <div style={{width : '95%'}}>
            <Input
                id="standard-full-width"
                label=""
                autoFocus
                autoComplete="off"
                style={{ margin: 8 }}
                placeholder={props.placeholder}
                fullWidth={true}
                margin="none"
                disableUnderline={true}
                startAdornment={
                    enableSearch ?
                        <InputAdornment position="start"  style={{color : '#58C3C1'}}>
                            <Search />
                        </InputAdornment>
                        :
                    <InputAdornment position="start"  style={{color : 'black'}}>
                        <Search />
                    </InputAdornment>
                }
                endAdornment={ enableSearch &&
                    <InputAdornment position="end" onClick={handleResetPlugSearch} style={{cursor : 'pointer'}}>
                        <Close />
                    </InputAdornment>
                }
                inputlabelprops={{
                    className: classes.input,
                }}
                className={classes.textField}
                onChange={handleChangeTextInput}
                value={search}
                onBlur={() => {
                    if(search !== null && search === "") props.close();
                    else if(search === null) props.close();
                }}
            />
        </div>
    )
};


// redux providing state takeover
const mapStateToProps = (state) => {

    return {
        selectedDataFilter: state.data.globalDataFilter  || {},
    }
}

const mapDispatchToProps = (dispatch) => ({
    attemptSetDataFilter: (dataFilter) => dispatch(reduxSetGlobalDataFilterRequest(dataFilter)),
    attemptDelDataFilter: () => dispatch(reduxDelGlobalDataFilterRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuLeftPlugSearchComponent);
