export const MailExportWithDataTemplateFR = ({ }) => {
    return `Bonjour,\n
En ouvrant le fichier ci-joint dans votre tableur (Excel,..), vous pouvez consulter et éditer vos Plugnotes puis les réimporter dans le dossier mentionné ci-dessus.\n
Veuillez respecter les instructions suivantes :\n
\n
    1. Lisez et respectez les instructions spécifiques à chaque format reprises dans l’entête des colonnes,\n
    2. Ne modifiez ou n’effacez pas les lignes d’entêtes,\n
    3. Ne modifiez pas le nom, ni le format du fichier lors de sa sauvegarde,\n
    4. Vous pouvez supprimer des lignes du fichier, mais pas en ajouter, \n
    5. Sauvegardez ou transférez le fichier complété sur un Cloud accessible via Plugnotes (fichiers de votre téléphone, bureau de votre ordinateur…),\n
    6. Réimportez le fichier completé via l’option 2 des outils du plugform d’origine.\n
\n    
*Avertissement* - Vu le caractère irréversible de cet import, il est fortement conseillé de faire au préalable une copie du fichier d’origine pour pouvoir le réimporter le cas échéant et annuler ainsi vos modifications.\n    
On reste à votre disposition en cas de questions.\n
Bon travail !\n
L’équipe de Plugnotes`
};