import React from "react"
import {useTranslation} from "react-i18next";
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {connect} from "react-redux";
import {getCurrentEnvironnement, getModel} from "../../utils/Common";
import TextField from "@material-ui/core/TextField";
import '@contentful/forma-36-react-components/dist/styles.css';
import {Input} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import InputLabel from "@material-ui/core/InputLabel";
import {confirmAlert} from "react-confirm-alert";
import {postExportDataFromModelCsvRequest, postExportDataTableMultiFromModelCsvRequest} from "../../actions/eximport";
import ModalSearchInRepertoriesComponent from "./ModalSearchInRepertoriesComponent";

function getModalStyle() {
    const top = 5;

    return {
        top: `${top}%`,
        margin:'auto',
        overflow: 'auto',
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    popup: {
        position: 'absolute',
        //width: '50%',
        //height : '50%',
        //backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        //boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        width: '820px',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    marginLeft:{
        marginLeft : '10px'
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F3F3F3',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    }
}));

const getCurrentDate=()=>{

    let date = new Date().getDate();
    let month = new Date().getMonth() + 1;
    let year = new Date().getFullYear();

    //Alert.alert(date + '-' + month + '-' + year);
    // You can turn it in to your desired format
    return date + '/' + month + '/' + year;//format: dd-mm-yyyy;
}

const checkDoublon = (list, text) => {
    return list.findIndex((item) => item === text) > -1
};

const ModalSimpleExportTableMultipleCsvComponent = (props) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation(['common']);

    let languageCode = i18n.language.substr(0, 2).toLowerCase();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);

    const model = getModel();
    const environment = getCurrentEnvironnement();

    const modelName = model.label + ' (' +  model.clef + ')';
    const titleModelName = '[' + model.clef + '] ' +  model.label;
    const env = '(' + environment.envIndex + ' - ' + environment.name + ')';
    const profilName = props.mainProfil.firstname + ' ' + props.mainProfil.lastname;

    const [body, setBody] = React.useState(languageCode === 'fr' ? 'En annexe, l’export des Plugnotes sélectionnées dans le dossier  '+ modelName + ' - ' + env  + '\n\n' + profilName: 'In the appendix, the export of Plugnotes selected in the folder '+ modelName + ' - ' + env + '\n\n' + profilName);
    const [bodyShared, setBodyShared] = React.useState(languageCode === 'fr' ? 'En annexe, l’export des Plugnotes sélectionnées dans le dossier partagé  '+ modelName  + '\n\n' + profilName: 'In the appendix, the export of Plugnotes selected in the folder '+ modelName + ' - ' + '\n\n' + profilName);
    const [sujet, setSujet] = React.useState(languageCode === 'fr' ? 'PN - ' + modelName + ' - Sélection du ' + getCurrentDate() : 'PN - ' + modelName + ' - Selection of ' + getCurrentDate());
    const [expediteur, setExpediteur] = React.useState("");
    const [mailNotification, setMailNotification] = React.useState([props.mainProfil.email]);
    const [mailNotificationCc, setMailNotificationCc] = React.useState([]);
    const [mail, setMail] = React.useState("");
    const [mailCc, setMailCc] = React.useState("");
    const [method, setMethod] = React.useState('');
    const [openRepertory, setOpenRepertory] = React.useState(false);


    const handleChange = (value) => {
        switch (method){
            case 'setMailNotification':
                value.map((item, index) => {
                    handleAddNotificationValue(item.email);
                })
                break;
            case 'setMailNotificationCc':
                value.map((item, index) => {
                    handleAddNotificationCcValue(item.email);
                })
                break;
        }
        setMethod('');
    }

    const handleCloseSubModal = () => {
        setOpenRepertory(false);
    }

    const handleCloseModal = () => {
        props.close();
    };

    const handleSendMail = () => {

    }

    /**
     * Ajout d'une vérif pour le type 'email'
     */
    const isEmail = (value) => {
        return /(.)+@(.)+/.test(value)
    }

    /**
     * Permet d'ajouter un mail pour le notifier
     * @private
     */
    const handleAddNotification = () => {
        let email = mail;
        let tmpMailNotification = mailNotification;

        if(isEmail(email)){
            if (!checkDoublon(tmpMailNotification, email)) {
                mailNotification.push(email);
            }

            setMailNotification(mailNotification);
            setMail("");
        }else{
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('global.wrong-email'),
                buttons: [
                    {
                        label: t('global.ok'),
                    },
                ]
            });
        }
    };

    /**
     * Permet d'ajouter un mail pour le notifier
     * @private
     */
    const handleAddNotificationCc = () => {
        let emailCC = mailCc;
        let tmpMailNotificationCc = mailNotificationCc;

        if(isEmail(emailCC)){
            if (!checkDoublon(tmpMailNotificationCc, emailCC)) {
                mailNotificationCc.push(emailCC);
            }

            setMailNotificationCc(mailNotificationCc);
            setMailCc("");
        }else{
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('global.wrong-email'),
                buttons: [
                    {
                        label: t('global.ok'),
                    },
                ]
            });
        }
    }

    /**
     * Permet d'ajouter un mail pour le notifier
     * @private
     */
    const handleAddNotificationValue = (value) => {
        let email = value;
        let tmpMailNotification = mailNotification;

        if(isEmail(email)){
            if (!checkDoublon(tmpMailNotification, email)) {
                mailNotification.push(email);
            }

            setMailNotification(mailNotification);
            setMail("");
        }else{
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('global.wrong-email'),
                buttons: [
                    {
                        label: t('global.ok'),
                    },
                ]
            });
        }
    };

    /**
     * Permet d'ajouter un mail pour le notifier
     * @private
     */
    const handleAddNotificationCcValue = (value) => {
        let emailCC = value;
        let tmpMailNotificationCc = mailNotificationCc;

        if(isEmail(emailCC)){
            if (!checkDoublon(tmpMailNotificationCc, emailCC)) {
                mailNotificationCc.push(emailCC);
            }

            setMailNotificationCc(mailNotificationCc);
            setMailCc("");
        }else{
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('global.wrong-email'),
                buttons: [
                    {
                        label: t('global.ok'),
                    },
                ]
            });
        }
    }

    /**
     * Permet de supprimer un mail pour la notification
     * @private
     */
    const handleDeleteNotification = (mail, mailIndex) => {
        confirmAlert({
            title: t('global.plugnotes'),
            message: t('global.confirm-delete'),
            buttons: [
                {
                    label: t('global.yes'),
                    onClick : () => setMailNotification(mailNotification.filter((item , index) => {
                        return item !== mail
                    }))
                },
                {
                    label: t('global.no'),
                },
            ]
        });

    };

    /**
     * Permet de supprimer un mail pour la notification
     * @private
     */
    const handleDeleteNotificationCc = (mail, index) => {
        confirmAlert({
            title: t('global.plugnotes'),
            message: t('global.confirm-delete'),
            buttons: [
                {
                    label: t('global.yes'),
                    onClick : () =>         setMailNotificationCc(mailNotificationCc.filter((item , index) => {
                        return item !== mail
                    }))
                },
                {
                    label: t('global.no'),
                },
            ]
        });

    };

    const handlePressValidate = () => {

        if(mailNotification.length > 0){
            let message = t('export.plug-list-export-csv');
            message += ' ' + t('export.plug-list-export-csv-2');
            confirmAlert({
                title: t('global.plugnotes'),
                message: message,
                buttons: [
                    {
                        label: t('global.ok'),
                        onClick: () => {
                            let payload = props.payload;
                            payload.mailNotification = mailNotification;
                            payload.mailNotificationCc = mailNotificationCc.length > 0 ? mailNotificationCc : null;
                            payload.subject = sujet.length > 0 ?  sujet : null ;
                            if(!props.modelShared){
                                payload.body = body.length > 0 ? body : null;

                            }else{
                                payload.body = bodyShared.length > 0 ? bodyShared : null;
                            }
                            props.close();
                            props.attemptExportCsv(payload,props.modelShared,environment.userhash);
                        }
                    },
                    {
                        label: t('global.cancel'),
                    }
                ]
            });
        }else{
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('global.missing-email'),
                buttons: [
                    {
                        label: t('global.ok'),
                    },
                ]
            });
        }
    };

    return (
        <Modal
            keepMounted={false}
            open={props.open}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div key={props.countable} style={modalStyle} className={classes.popup}>
                <div className = "scrollbar-hidden popup_wrapper flexSpacebetween" >
                    <div className="popup_wrapper_content">
                        <div className="sous-header_modal_content">
                            <div>
                                <span className="titres">
                                    <span>Exporter les données affichées au format excel {titleModelName} </span>
                                     <br/>
                                </span>
                            </div>
                            <div onClick={handleCloseModal}>
                                    <span id="icon-close" className="material-icons md-50 lightgrey">
                                    close
                                    </span>
                            </div>

                        </div>
                        <div className="sous-header_content lightgrey">
                            <div className="line flex_style">
                            </div>
                        </div>
                    </div>
                    <div className="popup_wrapper_content">
                        <span className="unbreakText unbreakText_width unbreakText_margin_left">
                            <InputLabel shrink>Destinataire(s)</InputLabel>
                        </span>
                        <Input
                            id="standard-full-width"
                            label=""
                            style={{ margin: 8 }}
                            //placeholder={props.placeholder}
                            fullWidth={true}
                            margin="none"
                            disableUnderline={true}
                            endAdornment={
                                <div style={{display : 'flex' , flexDirection : 'row'}}>
                                    <span style={{cursor : "pointer" , color : 'black', marginRight : '5px'}} onClick={(event) => {setOpenRepertory(true); setMethod('setMailNotification')}}>
                                        <span id="icon-listForm" className={`material-icons md-25 `}>
                                            search
                                        </span>
                                    </span>
                                    <span onClick={handleAddNotification} style={{cursor : "pointer" , color : 'black', marginRight : '5px'}}>
                                        <Add />
                                    </span>
                                    {/*                                    <InputAdornment position="end" onClick={handleAddNotification} style={{cursor : 'pointer'}}>
                                        <Add />
                                    </InputAdornment>*/}
                                </div>
                            }
                            inputlabelprops={{
                                className: classes.input,
                            }}
                            className={classes.textField}
                            onChange={(event) => setMail(event.target.value)}
                            onKeyPress={(e) => { if (e.key === 'Enter') { handleAddNotification()}}}
                            value={mail}
                        />
                        {
                            mailNotification.map((mail, index) => {
                                return(
                                    <div key={index} onClick={(event) => handleDeleteNotification(mail,index)}>
                                        <div>
                                            {mail}
                                            <span id="icon-close" className="material-icons md-25 lightgrey">
                                                delete
                                            </span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <span className="unbreakText unbreakText_width unbreakText_margin_left">
                            <InputLabel shrink>Destinataire(s) Cc</InputLabel>
                        </span>
                        <Input
                            id="standard-full-width"
                            label=""
                            style={{ margin: 8 }}
                            //placeholder={props.placeholder}
                            fullWidth={true}
                            margin="none"
                            disableUnderline={true}
                            endAdornment={
                                <div style={{display : 'flex' , flexDirection : 'row'}}>
                                    <span style={{cursor : "pointer" , color : 'black', marginRight : '5px'}} onClick={(event) => {setOpenRepertory(true); setMethod('setMailNotificationCc')}}>
                                        <span id="icon-listForm" className={`material-icons md-25 `}>
                                            search
                                        </span>
                                    </span>
                                    <span onClick={handleAddNotificationCc} style={{cursor : "pointer" , color : 'black', marginRight : '5px'}}>
                                        <Add />
                                    </span>
                                    {/*                                    <InputAdornment position="end" onClick={handleAddNotificationCc} style={{cursor : 'pointer'}}>
                                        <Add />
                                    </InputAdornment>*/}
                                </div>

                            }
                            inputlabelprops={{
                                className: classes.input,
                            }}
                            className={classes.textField}
                            onChange={(event) => setMailCc(event.target.value)}
                            onKeyPress={(e) => { if (e.key === 'Enter') { handleAddNotificationCc()}}}
                            value={mailCc}
                        />

                        {
                            mailNotificationCc.map((mail, index) => {
                                return(
                                    <div key={index} onClick={(event) => handleDeleteNotificationCc(mail,index)}>
                                        <div>
                                            {mail}
                                            <span id="icon-close" className="material-icons md-25 lightgrey">
                                                delete
                                            </span>
                                        </div>
                                    </div>
                                )
                            })
                        }

                        <span className="unbreakText unbreakText_width unbreakText_margin_left">
                            <InputLabel shrink>Sujet</InputLabel>
                        </span>

                        <Input
                            id="standard-full-width"
                            label=""
                            style={{ margin: 8 }}
                            //placeholder={props.placeholder}
                            fullWidth={true}
                            margin="none"
                            disableUnderline={true}
                            inputlabelprops={{
                                className: classes.input,
                            }}
                            className={classes.textField}
                            onChange={(event) => setSujet(event.target.value)}
                            value={sujet}
                        />

                        <span className="unbreakText unbreakText_width unbreakText_margin_left">
                            <InputLabel shrink>Message</InputLabel>
                        </span>

                        <TextField
                            id="outlined-multiline-static"
                            //label="Commentaire d'annexe"
                            multiline
                            minRows={10}
                            defaultValue={props.modelShared ? body : bodyShared}
                            variant="outlined"
                            fullWidth={true}
                            inputlabelprops={{
                                className: classes.input,
                            }}
                            //className={classes.textField}
                            onChange={(event) => setBody(event.target.value)}
                        />

                    </div>
                    <button className="button" onClick={handlePressValidate}>Envoyer</button>
                </div>
                <ModalSearchInRepertoriesComponent open={openRepertory} multiple={true} onChange={handleChange} close={handleCloseSubModal}/>
            </div>
        </Modal>
    )
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        mainProfil : state.user.profil
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        attemptExportCsv: (payload, shared, userhash) => dispatch(postExportDataTableMultiFromModelCsvRequest(payload, shared,userhash)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalSimpleExportTableMultipleCsvComponent)
