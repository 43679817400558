import {Types} from '../actions/model';

// create initial state for reducers
const INIT_STATE = {
    model : null,
    fetching : false,
    modelUniqueRefresh : null,
    categories: [],
    datas: [],
    list: [],
    shareds: [],
    favorites: 0,
    imported: 0,
    quota: true,
    end : null,
    start : 1,
};

// reducer function to transform state
export default function model(state = INIT_STATE, action) {
    switch(action.type) {
        case Types.RESET_ALL_DATA : return INIT_STATE;
        case Types.GET_MODELS_REQUEST: {
            return {
                ...state,
                datas : state.datas,
                shareds: state.shareds,
                fetching: true,
            }
        }

        case Types.GET_MODELS_LIGHT_REQUEST: {
            return {
                ...state,
                list : state.list,
                fetching: true,
            }
        }

        case Types.GET_MODEL_UNIQUE_REQUEST: {
            return {
                ...state,
                model : null,
                fetching: true,
            }
        }

        case Types.GET_MODEL_UNIQUE_SUCCESS: {
            return {
                ...state,
                model : action.payload,
                fetching: false,
            }
        }

        case Types.GET_MODEL_UNIQUE_FAILURE: {
            return {
                ...state,
                model : null,
                fetching: false,
            }
        }

        case Types.GET_MODEL_UNIQUE_ERROR: {
            return {
                ...state,
                model : null,
                fetching: false,
            }
        }

        case Types.GET_MODEL_UNIQUE_RESET: {
            return {
                ...state,
                model : null,
                fetching: false,
            }
        }

        case Types.GET_MODEL_SHARED_UNIQUE_REQUEST: {
            return {
                ...state,
                model : null,
                fetching: true,
            }
        }

        case Types.GET_MODEL_SHARED_UNIQUE_SUCCESS: {
            return {
                ...state,
                model : action.payload,
                fetching: false,
            }
        }

        case Types.GET_MODEL_SHARED_UNIQUE_FAILURE: {
            return {
                ...state,
                model : null,
                fetching: false,
            }
        }

        case Types.GET_MODEL_SHARED_UNIQUE_ERROR: {
            return {
                ...state,
                model : null,
                fetching: false,
            }
        }

        case Types.GET_MODEL_SHARED_UNIQUE_RESET: {
            return {
                ...state,
                model : null,
                fetching: false,
            }
        }

        case Types.GET_MODELS_LIGHT_COUNTABLE_PLUGNOTE_REQUEST: {
            return {
                ...state,
                list : state.list,
                fetching: true,
            }
        }

        case Types.GET_MODELS_CATEGORIES_REQUEST: {
            return {
                ...state,
                fetching: true,
            }
        }

        case Types.GET_MODELS_PAGINATE_REQUEST: {
            return {
                ...state,
                fetching: true,
            }
        }

        case Types.GET_MODEL_UNIQUE_BY_CLEF_REQUEST: {
            return {
                ...state,
                modelUniqueRefresh : null,
                fetching: true,
            }
        }

        case Types.GET_MODEL_UNIQUE_BY_CLEF_RESET: {
            return {
                ...state,
                modelUniqueRefresh : null,
                fetching: false,
            }
        }

        case Types.GET_MODEL_UNIQUE_BY_CLEF_SUCCESS: {
            return {
                ...state,
                modelUniqueRefresh: action.payload.data,
                fetching: false,
            }
        }

        case Types.GET_MODELS_SHARED_REQUEST: {
            return {
                ...state,
                datas : state.datas,
                shareds: state.shareds,
                fetching: true,
            }
        }
        case Types.GET_MODELS_SHARED_PAGINATE_REQUEST: {
            return {
                ...state,
                fetching: true,
            }
        }

        case Types.GET_MODELS_SUCCESS: {
            return {
                ...state,
                shareds: state.shareds,
                datas: action.payload.data.data,
                //categories: action.payload.data.categories,
                //favorites: action.payload.data.favorites,
                //imported: action.payload.data.imported,
                fetching : false
            }
        }

        case Types.GET_MODELS_LIGHT_SUCCESS: {

            return {
                ...state,
                list: action.payload.data.data,
                fetching : false
            }
        }

        case Types.GET_MODELS_LIGHT_COUNTABLE_PLUGNOTE_SUCCESS: {

            return {
                ...state,
                list: action.payload.data.data,
                fetching : false
            }

        }

        case Types.PUT_MODEL_DELETE_SHARE_SUCCESS:{
            let list = state.list.map((model, index) => {
                if (action.payload.id === model.id) {
                    model.numshared = model.numshared - 1;
                    return {
                        ...model,
                    }
                } else {
                    return model
                }
            });

            return {
                ...state,
                list : list
            }
        }

        case Types.PUT_MODEL_POST_SHARE_SUCCESS:{
            let list = state.list.map((model, index) => {
                if (action.payload.id === model.id) {
                    model.numshared = model.numshared + 1;
                    return {
                        ...model,
                    }
                } else {
                    return model
                }
            });

            return {
                ...state,
                list : list
            }
        }

        case Types.GET_MODELS_CATEGORIES_SUCCESS: {
            return {
                ...state,
                categories: action.payload.data.data,
                fetching : false
            }
        }

        case Types.GET_MODELS_PAGINATE_SUCCESS: {

            let list = state.datas.concat(action.payload.data.data);

            let sortedList = list.sort(function(a, b) {
                return a.label.toLowerCase().localeCompare(b.label.toLowerCase())
            }).filter(function(a, b) {
                return a.hash.localeCompare(b.hash)
            });

            return {
                ...state,
                datas: sortedList,
                fetching : false
            }
        }

        case Types.GET_MODELS_END_SUCCESS: {
            return {
                ...state,
                start : action.payload.start,
                end: action.payload2.end,
            }
        }

        case Types.GET_MODELS_SHARED_SUCCESS: {
            return {
                ...state,
                shareds : action.payload.data.data
            }
        }

        case Types.GET_MODELS_SHARED_PAGINATE_SUCCESS: {
            return {
                ...state,
                shareds : state.shared.concat(action.payload.data.data)
            }
        }

        case Types.GET_MODELS_SHARED_END_SUCCESS: {
            return {
                ...state,
                start : action.payload.start,
                end: action.payload2.end,
            }
        }

/*        case Types.POST_MODEL_SUCCESS : {

            let list = state.datas.concat(action.payload);
            let sortedList = list.sort(function(a, b) {
                return a.label.localeCompare(b.label)
            });

            return {
                ...state,
                list: sortedList,
                fetching : false
            }
        }*/

        case Types.POST_CREATE_MODEL_BIBLIO_SUCCESS: {
            let list = state.datas.concat(action.payload);
            let sortedList = list.sort(function(a, b) {
                return a.label.localeCompare(b.label)
            });

            return {
                ...state,
                list: sortedList,
                fetching : false
            }
        }


        case Types.POST_CREATE_MODEL_BIBLIO_V2_SUCCESS: {
            let list = state.list.concat(action.payload);
            let sortedList = list.sort(function(a, b) {
                return a.label.localeCompare(b.label)
            });

            return {
                ...state,
                list: sortedList,
                fetching : false
            }
        }


        case Types.POST_MODEL_DUPLICATE_SUCCESS : {

            let list = state.list.concat(action.payload);
            let sortedList = list.sort(function(a, b) {
                return a.label.localeCompare(b.label)
            });

            return {
                ...state,
                list: sortedList,
                fetching : false
            }
        }

/*        case Types.PUT_MODEL_SUCCESS : {

            let modelUpdated = action.payload;

            const list = state.list.map((model) => {
                if (model.hash === modelUpdated.hash) {
                    return {
                        ...modelUpdated,
                    }
                } else {
                    return model
                }
            })

            return {
                ...state,
                list: list,
                fetching : false
            }
        }*/

/*        case Types.PUT_REPLACE_MODEL_SUCCESS : {

            let modelUpdated = action.payload;

            const list = state.list.map((model) => {
                if (model.hash === modelUpdated.hash) {
                    return {
                        ...modelUpdated,
                    }
                } else {
                    return model
                }
            })

            return {
                ...state,
                list: list,
                fetching : false
            }
        }*/

        case Types.PUT_MODEL_UNIQUE_SUCCESS : {
            let modelUpdated = action.payload;

            const list = state.list.map((model) => {
                if (model.hash === modelUpdated.hash) {
                    return {
                        ...modelUpdated,
                    }
                } else {
                    return model
                }
            })

            return {
                ...state,
                list: list,
                fetching : false
            }
        }
        case Types.DEL_MODEL_UNIQUE_SUCCESS : {
            let modelDeleted = action.payload;

            const list = state.list.filter((model) => {
                if (model.hash !== modelDeleted) {
                    return {
                        ...model,
                    }
                }
            })

            return {
                ...state,
                list: list,
                fetching : false
            }
        }

        case Types.DEL_SHARE_MODEL_SHARE_SUCCESS: {

            let list = state.list.filter((item, index) => {
                if(item.ms_hash  === "undefined"){
                    return item;
                }else if(item.ms_hash !== action.payload){
                    return item;
                }
            });

            list.filter(Boolean);

            return {
                ...state,
                list : list,
            }
        }

        case Types.POST_MODEL_ZAPIER_SUCCESS:{
            let modelUpdated = action.payload;

            const list = state.list.map((model) => {
                if (model.hash === modelUpdated.hash) {
                    return {
                        ...modelUpdated,
                    }
                } else {
                    return model
                }
            })

            return {
                ...state,
                list: list,
                fetching : false
            }
        }

        default: return state;
    }
};
