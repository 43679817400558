import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import {setModelToUpdate} from "../../../utils/Common";
import {useTranslation} from "react-i18next";

export default function SubModelMenuLeftPortalComponent(props) {

    const { t, i18n } = useTranslation(['common', 'models', 'global']);

    const [show, setShow] = React.useState(false);

    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    }, [show])

    return (
        <Tooltip title={t('common:models.edit')} aria-label="add" placement="top">
                <span className="p3 cursor">
                    <div>
                            <Box p={1}>
                                <div className="flex_display_direction_column">
                                    <div className="p3 cursor material-icons-hover" onClick={() => {
                                        setModelToUpdate(props.model);
                                        props.history.push({
                                            pathname: '/update-model',
                                            state: { back: '/pluglist' }
                                        });
                                    }}>
                                        <span className="material-icons md-25 grey padding_0_20" >chevron_right</span>
                                        <span>{ t('models.edit-simple-1')}</span><br/>
                                    </div>
                                    <div className="p3 cursor material-icons-hover" onClick={() => {
                                        setModelToUpdate(props.model);
                                        props.history.push({
                                            pathname: '/update-model',
                                            state: { back: '/pluglist' }
                                        });
                                    }}>
                                        <span className="material-icons  md-25 grey padding_0_20" >chevron_right</span>
                                        <span>{ t('models.edit-simple-2')}</span><br/>
                                    </div>
                                    {props.plugnotes.length > 0 &&
                                    <div className="p3 cursor material-icons-hover" onClick={(event) => props.handleSelectModelEdit(props.model)}>
                                        <span className="material-icons md-25 grey padding_0_20">chevron_right</span>
                                        <span>{t('models.edit-expert-1')}</span><br/>
                                    </div>
                                    }
                                    {props.plugnotes.length > 0 &&
                                    <div className="p3 cursor material-icons-hover" onClick={(event) => props.handleSelectModelEdit(props.model)}>
                                        <span className="material-icons md-25 grey padding_0_20">chevron_right</span>
                                        <span>{t('models.edit-expert-2')}</span><br/>
                                    </div>
                                    }
                                    {props.plugnotes.length > 0 &&
                                    <div className="p3 cursor material-icons-hover" onClick={(event) => props.handleSelectModelEdit(props.model)}>
                                        <span className="material-icons md-25 grey padding_0_20" >chevron_right</span>
                                        <span>{ t('models.edit-expert-3')}</span><br/>
                                    </div>
                                    }
                                </div>
                            </Box>
                        </div>
                </span>
        </Tooltip>
    );
}
