import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {
    getMailInRequest,
    getMailsInPaginateRequest,
    getMailsInRequest,
    reduxGenerateMailInReset
} from "../../actions/mailbox";
import CssBaseline from "@material-ui/core/CssBaseline";
import {Content, Header} from "rsuite";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import Images from "../../assets/themes/Images";
import ModalViewMailComponent from "../../components/modals/ModalViewMailComponent";
import {reduxGenerateAnnexeReset} from "../../actions/annexe";


const drawerWidth = 320;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor : 'white',
    },
    iconRefresh:{
        float : 'right'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        padding: '15px 0px 0px 30px'
    },
    paperAnchorDockedLeft : {
        boxShadow: '0px 0px 6px 4px rgba(136, 136, 136, 0.12)',
        border : 0,
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: '57px'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    openClose: {
        bottom: 0,
        position: 'absolute',
        width : '100%'
    },
    openCloseRelative: {
        bottom: 0,
        position: 'relative',
        width : '100%'
    },
    openCloseButton : {
        float : 'right'
    },
    content: {
        //width : `calc(100% - ${drawerWidth}px)`,
        width : '100%',
        backgroundColor : 'white',
        padding: '64px 20px',
    },
    contentModelGrid : {
        //flexGrow: 1,
        //padding: theme.spacing(3),
        // display: 'flex',
        backgroundColor : 'white',
        flexWrap : 'wrap',
        justifyContent: 'space-evenly',
        gridColumnGap: '20px',
        columnGap: '20px',
        maxHeight : 'calc(80vh - 100px)',
    },
    contentModelList : {
        //flexGrow: 1,
        //padding: theme.spacing(3),
        backgroundColor : 'white',
        flexWrap : 'wrap',
        justifyContent: 'space-evenly',
        columnGap: '20px',
        maxHeight : 'calc(80vh - 100px)',
    },
    icon:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : '#DADADA'
    },
    iconActive:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : '#58C3C1'
    },
    gridHeader : {
        padding: '10px 20px !important',
        width: '100%',
    },
    selectEnvironnement : {
    },
    iconSelect : {
        color : '#58C3C1 !important'
    },
    marginLeftAuto :{
        marginLeft : 'auto'
    },
    pointer:{
        cursor: 'pointer'
    },
}));

const ViewMails = ({mails, hash, startPage, limitPage , attemptGetMailsInPaginate, handleViewEmail}) => {
    const classes = useStyles();

    const [page, setPage] = useState(typeof startPage === 'undefined' ? 1 : startPage);
    const [end, setEnd] = useState(typeof limitPage === 'undefined' ? 1 : limitPage);
    const [hasMore, setHasMore] = useState(startPage < limitPage);
    let annexeImageSource = Images.linksRedIcon;

    useEffect(() => {
        setPage(startPage);
        setEnd(limitPage);
        if(startPage < limitPage){
            setHasMore(true);
        }
    }, [startPage, limitPage]);

    const fetchDatas = () => {
        let nextPage = page + 1;
        setPage(nextPage);
        if(nextPage < limitPage){
            setHasMore(true);
        }else{
            setHasMore(false);
        }

        attemptGetMailsInPaginate(hash, nextPage);

    };

        return (
            <InfiniteScroll
                className="scrollbar-hidden"
                dataLength={mails.length} //This is important field to render the next data
                next={fetchDatas}
                hasMore={hasMore}
                scrollThreshold={0.7}
                loader={<div/>}
            >
                <div className={classes.contentModelGrid}>
                    {mails.map((mail, index) => {
                        return (
                            <div className={`flexbox-container-email ${classes.pointer}`} key={index} onClick={(event) => handleViewEmail(mail.mid)}>
                                <div className="flexbox-item-email ">
                                    <div className="flexbox-item_texte">

                                        <div className="references green">
                                            [{mail.mid}]
                                        </div>

                                        <div className="titres_small">
                                            {mail.DecodedSubject}
                                        </div>

                                        <div className="infos-supplementaires grey">

                                            <div>
                                                <span className="references">
                                                    {mail.NbOfAttachs}
                                                </span>
                                                <span className="md-small">
                                                    <img src={annexeImageSource} alt={mail.NbOfAttachs} className="size_10"/>
                                                </span>
                                            </div>
                                            <div>
                                                <span className="references">
                                                    {mail.FromName}
                                                </span>
                                            </div>
                                            <div>
                                                <span className="references">
                                                    {mail.Date}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flexbox-item_button grey">


                                    </div>

                                </div>

                            </div>
                    )})}
                </div>
            </InfiniteScroll>
        )
};


function InboxMail(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation(['common', 'models', 'global']);

    const [hash, setHash] = React.useState(props.mainuser);
    const [isOpenMail, setIsOpenMail] = React.useState(false);
    const [mid, setMid] = React.useState(null);


    useEffect(() => {
        props.attemptGetMailsIn(props.mainuser);
    }, [hash]);

    useEffect(() => {
        if(mid !== null){
            setIsOpenMail(true);
        }
    }, [mid]);

    const handleViewEmail = (mid) => {
        props.attemptResetMailIn();
        setMid(mid);
    }

    const handleModalMailClose = () => {
        props.attemptResetMailIn();
        setIsOpenMail(false);
        setMid(null);
    };




    return(
        <div className={classes.root}>
            <CssBaseline />
            <main className={classes.content}>
                <Header>
                    <div id="btnContainer">
                    </div>
                </Header>
                <Content className={classes.contentModelList}>
                    <ViewMails
                        mails={props.mails}
                        hash={props.mainuser}
                        startPage={props.start}
                        limitPage={props.end}
                        attemptGetMailsInPaginate={props.attemptGetMailsInPaginate}
                        handleViewEmail={handleViewEmail}
                    />
                </Content>
            </main>
            {
                isOpenMail && mid !== null &&
                <ModalViewMailComponent key={mid} index={mid} open={isOpenMail} close={handleModalMailClose} mid={mid}/>
            }
        </div>
    );
}

const mapStateToProps = (state) => {

    return {
        end : state.mailbox.end,
        start : state.mailbox.start,
        mails : state.mailbox.list,
        fetching : state.mailbox.fetching,
        mainuser : state.user.profil.hash
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        attemptGetMailsIn: (userhash) => dispatch(getMailsInRequest(userhash)),
        attemptGetMailIn: (mid,userhash) => dispatch(getMailInRequest(mid,userhash)),
        attemptGetMailsInPaginate: (userhash, page) => dispatch(getMailsInPaginateRequest(userhash,page)),
        attemptResetMailIn: () => dispatch(reduxGenerateMailInReset()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InboxMail)
