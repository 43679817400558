import React, {useEffect} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import PhoneInput from "react-phone-number-input";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%'
    },
    input_add_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    fieldRight:{
        width : '100%',
    },
    phoneContainer:{
        /* border: '1px blue solid', */
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    phoneComponent:{
        border: 'none',
        display: 'flex',
        gap: 10,
        width: '100%',
    },
    phoneButton:{
    display: 'inline-block',
    color: "white",
    backgroundColor: "#58c3c1",
    padding: "3px 15px",
    borderRadius: "3px",
    cursor: "pointer",
    },
}));

const FormatPhoneComponent = (props) => {

    const classes = useStyles();

    const { t, i18n } = useTranslation(['common', 'models']);
    const [disabled, setDisabled] = React.useState(typeof props.disabled === 'undefined' ? false: props.disabled);

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    const handleEditPhoneValue = (value, field, index) => {
        props.handleEditPhoneValue(value, field, index)
    }

    return (
        <div className={classes.phoneContainer}>
                <PhoneInput
                    className={`${classes.phoneComponent} phoneComponent`}
                    defaultCountry="BE"
                    international
                    value={typeof props.field.value !== 'undefined' ? props.field.value.toString() : ''}
                    placeholder={t('fields.format-choice-placeholder-'+props.field.visuelFormat.toLowerCase())}
                    onClick={(e) => e.target.placeholder='' }
                    onBlur={(e) => e.target.placeholder=t('fields.format-choice-placeholder-'+props.field.visuelFormat.toLowerCase())}
                    onChange={(value) => handleEditPhoneValue(value, props.field, props.index)}
                    disabled={disabled}
                />
            { typeof props.field.value !== 'undefined' &&  props.field.value !== '' &&
                <div>
                        <a href={`tel:${props.field.value.toString()}`} className={classes.phoneButton}><span className="material-icons md-small white">phone_in_talk</span></a>
                </div>
            }
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatPhoneComponent);





