import React from "react"
import {useTranslation} from "react-i18next";
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {connect} from "react-redux";
import {getCurrentEnvironnement} from "../../utils/Common";
import '@contentful/forma-36-react-components/dist/styles.css';
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import {postCreateEnvironmentRequest} from "../../actions/user";

function getModalStyle() {
    const top = 5;

    return {
        top: `${top}%`,
        margin:'auto',
        overflow: 'auto',
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    popup: {
        position: 'absolute',
        //width: '50%',
        //height : '50%',
        //backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        //boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        width: '820px',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    marginLeft:{
        marginLeft : '10px'
    },
    root: {
        flexGrow: 1,
    },
    MuiInputBaseInput:{
        padding : 0
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    field : {
        width : '85%',
        display: 'flex',
    },
    inputField:{
        padding : '0px 0px 7px',
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F3F3F3',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    }
}));

const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: [],
};

const ModalCreateUniversComponent = (props) => {

    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [t, i18n] = useTranslation('common');

    const [open, setOpen] = React.useState(true);
    const [universName, setUniversName] = React.useState("");
    const [publicName, setPublicName] = React.useState("");


    const environment = getCurrentEnvironnement();

    const handleCloseModal = () => {
        props.close();
    };

    const handleCreateUnivers = () => {
        let payload =  {
            "profilname": universName,
            "username": publicName,
        };

        props.close();
        props.attemptCreateEnvironment(payload, props.profil.hash);
    };


    return (
        <Modal
            keepMounted={false}
            open={props.open}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div style={modalStyle} className={classes.popup}>
                <div className = "scrollbar-hidden popup_wrapper flexSpacebetween" >
                    <div className="popup_wrapper_content">
                        <div className="sous-header_modal_content">
                            <div>
                                <span className="titres">
                                    {
                                        t('models.create-univers')
                                    }
                                     <br/>
                                </span>
                            </div>
                            <div onClick={handleCloseModal}>
                                    <span id="icon-close" className="material-icons md-50 lightgrey">
                                    close
                                    </span>
                            </div>

                        </div>
                        <div className="sous-header_content lightgrey">
                            <div className="line flex_style">
                            </div>
                        </div>
                    </div>
                    <div className="popup_wrapper_content">
                        <InputLabel shrink>{t('models.name-univers')}</InputLabel>
                        <Input
                            id="standard-full-width"
                            label="Label"
                            fullWidth={true}
                            margin="none"
                            inputlabelprops={{
                                className: classes.input,
                                shrink: true,
                            }}
                            className={classes.textField}
                            onChange={(event) => setUniversName(event.target.value)}
                            defaultValue={universName}
                            disableUnderline={true}
                        />
                        <InputLabel shrink>{t('models.plublic-share-univers')}</InputLabel>
                        <Input
                            id="standard-full-width"
                            label="Label"
                            fullWidth={true}
                            margin="none"
                            inputlabelprops={{
                                className: classes.input,
                                shrink: true,

                            }}
                            className={classes.textField}
                            onChange={(event) => setPublicName(event.target.value)}
                            defaultValue={publicName}
                            disableUnderline={true}
                        />
                    </div>

                    <button className="button" onClick={handleCreateUnivers} disabled={universName.length === 0 && publicName.length === 0}>Créer</button>

                </div>
            </div>
        </Modal>
    )
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        profil : state.user.profil,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        attemptCreateEnvironment: (payload, userhash) => dispatch(postCreateEnvironmentRequest(payload, userhash)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalCreateUniversComponent)
