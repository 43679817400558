import {call, fork, put, select, takeEvery} from 'redux-saga/effects';
import * as actions from '../actions/model';
import * as modelActions from '../actions/model';
import * as actionsUser from '../actions/user';
import * as api from '../api/modelApi';
import * as apiShare from '../api/shareApi';
import {history} from '../hooks/history'
import {trackPromise} from "react-promise-tracker";
import {confirmAlert} from "react-confirm-alert";
import i18n from './../translations/i18n';
import {setLoadModel, setModel, setModelToUpdate} from "../utils/Common";

const myGenericTrackedWrapper = (fn, ...args) => trackPromise(fn(...args));
//const myGenericTrackedWrapperMultiple = (fn, args1, args2, args3) => trackPromise(fn(args1,args2, args3));


function forwardTo(location) {
    history.push(location);
}

function* fetchModels(action) {
    try {
        const response = yield call(myGenericTrackedWrapper,api.getModels, action.userhash, action.language);
        if(response.data.result){
            yield put(actions.getModelsEndSuccess(1, response.data.end));
            yield put(actions.getModelsSuccess(response.data));

        }else{
            yield put(actions.getModelsFailure());
        }
    }catch(e) {
        yield put(actions.getModelsError());
    }
}

function* fetchModel(action) {
    try {
        const response = yield call(myGenericTrackedWrapper,api.getModelUnique, action.modelhash, action.userhash, action.usermainhash);
        if(response.data.result){
            setModel(response.data.data);
            yield put(actions.getModelUniqueSuccess(response.data.data));

        }else{
            yield put(actions.getModelUniqueFailure());
        }
    }catch(e) {
        yield put(actions.getModelUniqueError());
    }
}
//
function* fetchModelShared(action) {
    try {
        const response = yield call(myGenericTrackedWrapper,api.getModelSharedUnique, action.modelhash, action.userhash, action.usermainhash);
        if(response.data.result){
            setModel(response.data.data);
            yield put(actions.getModelUniqueSuccess(response.data.data));

        }else{
            yield put(actions.getModelUniqueFailure());
        }
    }catch(e) {
        yield put(actions.getModelUniqueError());
    }
}
//

function* fetchLightModels(action) {
    try {
        const response = yield call(myGenericTrackedWrapper,api.getModelsLight, action.userhash);
        if(response.data.result){
            yield put(actions.getModelsLightSuccess(response.data));

        }else{
            yield put(actions.getModelsLightFailure());
        }
    }catch(e) {
        yield put(actions.getModelsLightError());
    }
}

function* fetchLightModelsCountablePlugnote(action) {
    try {
        const response = yield call(myGenericTrackedWrapper,api.getModelsLightCountablePlugnote, action.userhash);
        if(response.data.result){
            yield put(actions.getModelsLightCountablePlugnoteSuccess(response.data));

        }else{
            yield put(actions.getModelsLightCountablePlugnoteFailure());
        }
    }catch(e) {
        yield put(actions.getModelsLightCountablePlugnoteError());
    }
}

function* fetchModelsCategories(action) {
    try {
        const response = yield call(myGenericTrackedWrapper,api.getModelsCategories, action.language);
        if(response.data.result){
            yield put(actions.getModelsCategoriesSuccess(response.data));
        }else{
            yield put(actions.getModelsCategoriesFailure());
        }
    }catch(e) {
        yield put(actions.getModelsCategoriesError());
    }
}

function* fetchModelByClef(action) {
    try {

        const uhm = yield select((state) => state.user.profil.hash);

        const response = yield call(myGenericTrackedWrapper,api.getModelByClefUnique, action.code, action.userhash, uhm);

        if(response.data.result){
            yield put(actions.getModelUniqueByClefSuccess(response.data));

        }else{
            yield put(actions.getModelUniqueByClefFailure());
        }
    }catch(e) {
        yield put(actions.getModelUniqueByClefError());
    }
}

function* fetchModelByClefAndOwner(action) {
    try {

        const uhm = yield select((state) => state.user.profil.hash);

        const response = yield call(myGenericTrackedWrapper,api.getModelByClefAndOwnerUnique, action.clef, action.owner, uhm);

        if(response.data.result){
            yield put(actions.getModelUniqueByClefAndOwnerSuccess(response.data));

        }else{
            yield put(actions.getModelUniqueByClefAndOwnerFailure());
        }
    }catch(e) {
        yield put(actions.getModelUniqueByClefAndOwnerError());
    }
}

function* fetchPaginateModels(action) {
    try {
        const response = yield call(myGenericTrackedWrapper,api.getModelsLight, action.userhash, action.language, action.page);
        if(response.data.result){
            yield put(actions.getModelsEndSuccess(action.page, response.data.end));
            yield put(actions.getModelsPaginateSuccess(response.data));
        }else{
            yield put(actions.getModelsPaginateFailure());
        }
    }catch(e) {
        alert(e.message);
        yield put(actions.getModelsPaginateError());
    }
}

function* fetchModelsShared(action) {
    try {
        const response = yield call(myGenericTrackedWrapper,api.getModelsShared, action.userhash, action.usermainhash , action.language);
        if(response.data.result){
            yield put(actions.getModelsSharedEndSuccess(1,response.data.end));
            yield put(actions.getModelsSharedSuccess(response.data));
        }else{
            yield put(actions.getModelsSharedFailure());
        }
    }catch(e) {
        yield put(actions.getModelsSharedError());
    }
}

function* fetchPaginateModelsShared(action) {
    try {
        const response = yield call(myGenericTrackedWrapper,api.getModelsShared, action.userhash, action.usermainhash , action.language, action.page);
        if(response.data.result){
            yield put(actions.getModelsSharedEndSuccess(action.page, response.data.end));
            yield put(actions.getModelsSharedPaginateSuccess(response.data));
        }else{
            yield put(actions.getModelsSharedPaginateFailure());
        }
    }catch(e) {
        yield put(actions.getModelsSharedPaginateError());
    }
}

/**
 * Permet de créer un modèle
 * @param api
 * @param action
 */
function * postModel (action) {
    try {
        const response = yield call(myGenericTrackedWrapper,api.postV2Model, action.userhash, action.payload);
        // success?
        if (response.statusText == "OK" && response.data.result === 'ok') {
            yield call(forwardTo, '/models');
            yield put(actions.postModelSuccess(response.data.data));
        } else {
            yield put(actions.postModelFailure())
        }
    }catch(e) {
        yield put(actions.postModelError());
    }
}

/**
 *
 * @param api
 * @param action
 */
function * updateModel (action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.updateModel, action.userhash, action.payload);
        // success?
        if (response.statusText == "OK" && response.data.result === 'ok') {
            yield put(actions.putModelSuccess(response.data.data));

            if(action.back.length > 0){
                yield call(forwardTo, action.back);
            }else{
                yield call(forwardTo, '/models');
            }
        } else {
            yield put(actions.putModelFailure())
        }
    }catch(e) {
        yield put(actions.putModelError());
    }
}

/**
 *
 * @param api
 * @param action
 */
function * replaceModel (action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.replaceModel, action.userhash, action.payload);
        // success?
        if (response.data.result === 'ok') {
            setLoadModel(true);
            yield put(actions.putReplaceModelSuccess(response.data.data));
            yield call(forwardTo, '/models');
        } else {
            yield put(actions.putReplaceModelFailure())
        }
    }catch(e) {
        yield put(actions.putReplaceModelError());
    }
}

/**
 *
 * @param api
 * @param action
 */
function * duplicateModel (action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.duplicateModel, action.userhash, action.model);
        // success?
        if (response.data.result === 'ok') {
            yield put(actions.postModelDuplicateSuccess(response.data.light));
        } else {
            yield put(actions.postModelDuplicateFailure())
        }
    }catch(e) {
        yield put(actions.postModelDuplicateError());
    }
}

/**
 *
 * @param api
 * @param action
 */
function * postModelSettingZapier (action) {
    try {

        let payload = {
            is_zapier: action.payload
        }

        const response = yield call(myGenericTrackedWrapper,api.updateZapier, action.modelhash, payload);
        // success?
        if (response.statusText == "OK" && response.data.result === 'ok') {
            yield put(actions.postModelZapierSuccess(response.data.data));
        } else {
            yield put(actions.postModelZapierFailure())
        }
    }catch(e) {
        yield put(actions.postModelZapierError());
    }
}

/**
 *
 * @param api
 * @param action
 */
function * deleteModel (action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.deleteModel, action.modelhash, action.userhash);
        // success?
        if (response.data.result == 'ok' && response.statusText == "OK") {
            yield put(actions.delModelUniqueSuccess(action.modelhash));
            if(typeof action.back !== "undefined" && action.back){
                yield call(forwardTo, '/models');
            }
        } else {
            yield put(actions.delModelUniqueFailure())
        }
    }catch(e) {
        yield put(actions.delModelUniqueError());
    }
}

/**
 * Permet de créer des modèles directement de la gallerie de template de modèles.
 * @param api
 * @param action
 */
function * postModelFromTemplateGroup (action) {

    if(action.unique){
        try {
            let response = yield call(api.postModel, action.userhash, action.model);
            // success?
            if (response.statusText == "OK") {
                //yield put(actions.postModelFromTemplateGroupSuccess(action.modelhash))
                yield call(forwardTo, '/models');
            } else {
                yield put(actions.postModelFromTemplateGroupFailure())
            }
        }catch(e) {
            yield put(actions.postModelFromTemplateGroupError());
        }
    }else{
        try {
            const response = yield call(api.postModels, action.userhash, action.models);
            // success?
            if (response.statusText == "OK") {
                yield call(forwardTo, '/models');
            } else {
                yield put(actions.postModelFromTemplateGroupFailure())
            }
        }catch(e) {
            yield put(actions.postModelFromTemplateGroupError());
        }
    }
}

function * postCreateModelBiblio (action) {

    try {
        let response = yield call(api.postModelsByBibloCats, action.userhash, action.payload, action.language);
        // success?
        if (response.statusText == "OK" && response.data.result == 'ok') {
            yield put(actions.postCreateModelBiblioSuccess(response.data.data))
            yield call(forwardTo, '/models');
        } else {
            yield put(actions.postCreateModelBiblioFailure())
        }
    }catch(e) {
        yield put(actions.postCreateModelBiblioError());
    }
}

function * postCreateModelBiblioV2 (action) {

    try {
        let response = yield call(myGenericTrackedWrapper,api.postModelsByBibloCats, action.userhash, action.payload, action.language);
        // success?
        if (response.statusText == "OK" && response.data.result == 'ok') {
            yield put(actions.postCreateModelBiblioV2Success(response.data.light))
            setModelToUpdate(response.data.light)
            yield call(forwardTo, '/update-model');
        } else {
            yield put(actions.postCreateModelBiblioV2Failure())
        }
    }catch(e) {
        yield put(actions.postCreateModelBiblioV2Error());
    }
}


export function* postExportFullFileDatas(action) {

    try {

        const promise = new Promise((resolve, reject) => {
            confirmAlert({
                title: i18n.t('common:global.confirm'),
                message: i18n.t('common:models.plug-form-popup-message-export-files-datas-full'),
                buttons: [
                    {
                        label: i18n.t('common:global.ok'),
                        onClick: () => resolve(true)
                    },
                    {
                        label: i18n.t('common:global.cancel'),
                        onClick: () => resolve(false)
                    }


            ]
            });
        });

        const respUser = yield call(() => promise);

        if (respUser) {

            try {
                const response = yield call(api.exportFullFilesDatas, action.payloadhash, action.usermainhash, action.modelhash);
                // success?
                if (response.statusText == "OK") {
                    confirmAlert({
                        title: i18n.t('common:global.plugnotes'),
                        message: i18n.t('common:models.plug-form-popup-message-export-files-datas-email'),
                        buttons: [
                            {
                                label: i18n.t('common:global.ok'),
                                onClick: () => actions.postExportFullFileDatasSuccess()
                            }
                        ]
                    });
                } else {
                    yield put(actions.postExportFullFileDatasFailure())
                }
            }catch(e) {
                yield put(actions.postExportFullFileDatasError());
            }

        }
    }catch(e) {
        yield put(actions.postExportFullFileDatasError());
    }

}

export function * acceptInviteModelShare (action){

    try {
        const callNotFarmated = yield call(myGenericTrackedWrapper,api.confirmModelShared, action.modelsharedhash, action.accept);
        const response = JSON.parse(callNotFarmated.data);

        if((response.result === "ok")){

            yield put(actions.acceptShareModelShareSuccess());
            yield put(actionsUser.cleanListToAccept(action.modelsharedhash));

            const uhm = yield select((state) => state.user.profil.hash);

            if(uhm === action.userhash){
                try {
                    const response = yield call(myGenericTrackedWrapper,api.getModelsLight, action.userhash);
                    if(response.data.result){
                        yield put(actions.getModelsLightSuccess(response.data));

                    }else{
                        yield put(actions.getModelsLightFailure());
                    }
                }
                catch(e) {
                    yield put(actions.getModelsLightError());
                }
            }
        }else{
            yield put(actions.acceptShareModelShareFailure());

        }
    }catch(e) {
        yield put(actions.acceptShareModelShareError());
    }
}

export function * deleteShareModelShare (action) {

    try {
        const response = yield call(myGenericTrackedWrapper,apiShare.deleteModelShares, action.modelsharedhash, action.model);

        if((response.data.result === "ok" || response.data.result_duplicate === "ok")){

            yield put(modelActions.delShareModelShareSuccess(action.modelsharedhash));

        }else{
            yield put(modelActions.delShareModelShareFailure());

        }
    }catch(e) {
        yield put(modelActions.delShareModelShareError());
    }
}


function* watchFetchModel() {
    // create watcher of fetchData function
    yield takeEvery(actions.Types.GET_MODELS_REQUEST, fetchModels);
    yield takeEvery(actions.Types.GET_MODEL_UNIQUE_REQUEST, fetchModel);
    yield takeEvery(actions.Types.GET_MODEL_SHARED_UNIQUE_REQUEST, fetchModelShared);
    yield takeEvery(actions.Types.GET_MODELS_LIGHT_REQUEST, fetchLightModels);
    yield takeEvery(actions.Types.GET_MODELS_LIGHT_COUNTABLE_PLUGNOTE_REQUEST, fetchLightModelsCountablePlugnote);
    yield takeEvery(actions.Types.GET_MODELS_CATEGORIES_REQUEST, fetchModelsCategories);
    yield takeEvery(actions.Types.GET_MODEL_UNIQUE_BY_CLEF_REQUEST, fetchModelByClef);
    yield takeEvery(actions.Types.GET_MODEL_UNIQUE_BY_CLEF_AND_OWNER_REQUEST, fetchModelByClefAndOwner);
    yield takeEvery(actions.Types.POST_MODEL_ZAPIER_REQUEST, postModelSettingZapier);
    yield takeEvery(actions.Types.GET_MODELS_PAGINATE_REQUEST, fetchPaginateModels);
    yield takeEvery(actions.Types.GET_MODELS_SHARED_REQUEST, fetchModelsShared);
    yield takeEvery(actions.Types.ACCEPT_SHARE_MODEL_SHARE_REQUEST, acceptInviteModelShare);
    yield takeEvery(actions.Types.GET_MODELS_SHARED_PAGINATE_REQUEST, fetchPaginateModelsShared);
    yield takeEvery(actions.Types.POST_MODEL_REQUEST, postModel);
    yield takeEvery(actions.Types.POST_MODEL_DUPLICATE_REQUEST, duplicateModel);
    yield takeEvery(actions.Types.PUT_MODEL_REQUEST, updateModel);
    yield takeEvery(actions.Types.PUT_REPLACE_MODEL_REQUEST, replaceModel);
    yield takeEvery(actions.Types.DEL_MODEL_UNIQUE_REQUEST, deleteModel);
    yield takeEvery(actions.Types.POST_MODEL_FROM_TEMPLATE_GROUP_REQUEST, postModelFromTemplateGroup);
    yield takeEvery(actions.Types.POST_CREATE_MODEL_BIBLIO_REQUEST, postCreateModelBiblio);
    yield takeEvery(actions.Types.POST_CREATE_MODEL_BIBLIO_V2_REQUEST, postCreateModelBiblioV2);
    yield takeEvery(actions.Types.POST_EXPORT_FULL_FILE_DATAS_REQUEST, postExportFullFileDatas);
    yield takeEvery(actions.Types.DEL_SHARE_MODEL_SHARE_REQUEST, deleteShareModelShare);
}

const ModelSagas = [
    fork(watchFetchModel)
];

export default ModelSagas;
