import {api} from "./index";

export let
    postSimpleSendMail,
    postSimpleSendMailV3;


postSimpleSendMail = (payload) => api.post('/utils/simples/sendmails.json', payload);
postSimpleSendMailV3 = (payload) => api.post('/utils/v3s/sendmails.json', payload);

