import React, { useEffect, useState } from 'react';
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {Document, Image, Page, PDFDownloadLink, StyleSheet, Text, View} from '@react-pdf/renderer';
import Images from "../../assets/themes/Images";
import {getCurrentEnvironnement, } from "../../utils/Common";
import {formatTextTypes} from "../../utils/Compatibility";
import colors from '../../assets/themes/Colors';

const environment = getCurrentEnvironnement();

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#E4E4E4',
        margin : 50
    },
    section: {
        margin: '3px 10px',
        flexGrow: 0,
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100%'
    },
    viewer: {
        paddingTop: 58,
        paddingBottom: 58,
        width: '100%'
    },
    paragraph: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        flexGrow: '1' /* Let it fill the entire space horizontally */
    },
    viewFieldsLeft: {
        display: "flex",
        flexWrap: "nowrap",
        flex: '1' /* Let it fill the entire space horizontally */,
        justifyContent: 'flex-start',
        flexDirection: "row",
        width: '100%'
    },
    text: {
        width: "auto",
        fontWeight : 500,
        fontSize: 11,
    },
    textInfos: {
        width: "auto",
        fontWeight : 100,
        fontSize: 8,
    },
    textUnder: {
        width: "auto",
        fontWeight : 500,
        fontSize: 11,
        textDecoration: 'underline',
    },
    textThin: {
        width: "auto",
        fontWeight : 300,
        fontSize: 11,
    },
    textThinSmall:{
        width: "auto",
        fontWeight : 200,
        fontSize: 10,
    },
    textLink: {
        width: "auto",
        color : "green",
        fontWeight : 300,
        fontSize: 11,
    },
    icon: {
        width: "30",
        height: "30",
    },
    title:{
        marginBottom : 10,
        paddingBottom: 10
    },
    header: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: 60,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    headerText: {
        textAlign: 'center',
        color: 'grey',
        fontSize: 12,
    },
    logo: {
        width: 'auto',
        height: '50px',
        // position: 'absolute',
        left: 0
    },
    fieldListContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    optionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%'
    },
    fieldInfoContainer: {
        width: '90%',
        height: '100%',
        display: 'flex',
        flexGrow: 1,
        alignItems: 'flex-start'
    },
    fieldText: {
        width: "auto",
        // fontWeight : 400,
        fontSize: 9,
    },
    descText: {
        color: colors.grey,
        fontSize: 8
    }
});
const useStyles = makeStyles((theme) => ({
    pointer:{
        cursor: 'pointer'
    }
}));

const MyDoc = (props) => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    let time = today.toLocaleTimeString()
    today = mm + '/' + dd + '/' + yyyy + ' - ' + time;
    let _props = props.props
    let user = typeof _props.user !== "undefined" ? _props.user : null;
    console.log('MyDoc', props);
    return(
    <Document>
        <Page size="A4" orientation="landscape" style={styles.viewer} wrap>
            <View style={styles.header} fixed>
                <Image style={styles.logo} src={Images.logo}/>
                <View style={styles.section}>
                    <Text style={styles.textThin}>{_props.fromModel.label}</Text>
                    <Text style={styles.text}>Field n°{_props.field.id}: {_props.field.label}</Text>
                </View>
                <View style={styles.section}>
                    <View  style={styles.paragraph}>
                        { user !== null && <Text style={styles.textThin}>{user.name} - {today}</Text>}
                        { user === null && <Text style={styles.textThin}>{today}</Text>}
                    </View>
                    <View  style={styles.paragraph}>
                        { environment !== null && environment.envIndex !== null  &&
                        <Text style={styles.textThin}>[{environment.envIndex} - {_props.fromModel.clef}]</Text>
                        }
                    </View>
                    {/* <View style={styles.paragraph}>
                        <Text style={styles.textThin}></Text>
                    </View> */}
                </View>
            </View>
            <View style={styles.fieldListContainer}>
                <View style={styles.section}>
                    <View style={styles.title}>
                        <Text style={styles.textUnder}>COPIED FROM: {_props.fromModel?.clef} - {_props.fromModel?.label}</Text>
                    </View>
                    {_props.fromFields.map((field, index) => {
                        // console.log('mappage from', field);
                        if(index > 0)
                        return(
                            <View key={index} style={styles.fieldInfoContainer}>
                                <View style={styles.viewFieldsLeft}>
                                    <View style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                        <Text style={styles.descText}>{(index)} - {field.formatLabel}</Text>
                                        <Text style={styles.fieldText}>{field.title} {formatTextTypes[field.format.toUpperCase()]}</Text>
                                    </View>
                                </View>
                            </View>
                        )})}
                </View>
                <View style={styles.section}>
                    <View style={styles.title}>
                        <Text style={styles.textUnder}>INTO:  {_props.toModel?.clef} - {_props.toModel?.label}</Text>
                    </View>
                    {_props.toFields.map((field, index) => {
                        if(index > 0)
                        return(
                            <View key={index} style={styles.fieldInfoContainer}>
                                <View style={styles.viewFieldsLeft}>
                                    <View style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}>
                                        <Text style={styles.descText}>{(index)} - {field.formatLabel}</Text>
                                        <Text style={styles.fieldText}>{field.title} {formatTextTypes[field.format.toUpperCase()]}</Text>
                                    </View>
                                </View>
                            </View>
                        )})}
                </View>
            </View>
            <View style={styles.optionsContainer}>
                <View style={styles.section}>
                    <View style={styles.title}>
                        <Text style={styles.textUnder}>Pre-FILLED FIELDS IN Plugform N°: {props.props.options.pfcode}: </Text>
                    </View>
                    <View style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5}}>
                        <View style={styles.viewFieldsLeft}>
                            <View style={{width : '100%'}}>
                                <Text style={styles.fieldText}>Copy content of field </Text>
                            </View>
                        </View>
                        <View style={styles.viewFieldsLeft}>
                            <View style={{width : '100%'}}>
                                <Text style={styles.fieldText}>into field </Text>
                            </View>
                        </View>
                    </View>
                    {_props.options.fields.map((option, index) => {
                        let fIndex = parseInt(option.from)
                        let tIndex = parseInt(option.to)
                        return(
                            <View key={index} style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5}}>
                                <View style={styles.viewFieldsLeft}>
                                    <View style={{width : '90%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between',}}>
                                        <Text style={styles.descText}>({_props.fromFields[fIndex].formatLabel})</Text>
                                        <Text style={styles.fieldText}>{fIndex} - {_props.fromFields[fIndex].title} </Text>
                                    </View>
                                </View>
                                <View style={styles.viewFieldsLeft}>
                                    <View style={{width : '80%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between',}}>
                                        <Text style={styles.fieldText}>{tIndex} - {_props.toFields[tIndex].title}</Text>
                                        <Text style={styles.descText}>({_props.toFields[tIndex].formatLabel})</Text>
                                    </View>
                                </View>
                            </View>
                        )})}
                </View>
            </View>
        </Page>
    </Document>
    )
}

function Data0000Printable(props) {
    const { t, } = useTranslation(['common', 'models']);
    const classes = useStyles();

    let pdfTitle = `F28:  ${t('common:fields.format-choice-pluglink')}`
    console.log("print0000", props)

    /*    const [instance, updateInstance] = usePDF({ document: MyDoc });

        if (instance.loading) return <div style={{display : 'flex'}}> <span className="material-icons md-25 "> sync_alt </span><div className={classes.pointer}>{t('common:global.print-sync') }</div></div>;

        if (instance.error) return <div>Something went wrong: {instance.error}</div>;

        console.log(instance.url);

        return (
            <a href={instance.url} download="test.pdf" style={{textDecoration: 'none', color : 'black'}}>
                <div style={{display : 'flex'}}> <span className="material-icons md-25 "> picture_as_pdf </span><div className={classes.pointer}>{t('common:global.print') }</div></div>
            </a>
        );*/

    function handleShowPdf (url) {
        if(url !== '' && url !== null) {
            props.setPath(url)
        }
    }
    

    return (
        <PDFDownloadLink document={<MyDoc props={props} pdfTitle={pdfTitle} options={props.options}/>} className={classes.pointer} fileName={props.filename+'.pdf'} style={{textDecoration : 'none', color : 'black'}}>
            {({ blob, url, loading, error }) =>
                {
                // setLoading(loading)
                // props.setPath(url)
                loading ? 
                <div style={{display : 'flex'}}> 
                    <span className="material-icons md-25 "> sync_alt </span>
                    <div className={classes.pointer}>{t('common:global.print-sync') }</div>
                </div> 
                :
                <div style={{display : 'flex'}} onClick={() => handleShowPdf(url)}> 
                    <span className="material-icons md-25 "> picture_as_pdf </span>
                    <div className={classes.pointer}>{t('common:global.print') }</div>
                </div>
                && props.setPath(url)
                }
            }
        </PDFDownloadLink>
    );
}

export default Data0000Printable;