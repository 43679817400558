import React, { useEffect, useState } from "react"
//import styles from "./styles.module.less"
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import { HighlightOff, Search } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

import colors from "../../assets/themes/Colors";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%',
        /* border: '1px solid red', */
    },
    input_add_item:{
        flexDirection: 'row',
        display: 'flex',
        border: "solid #E3E3E3 1px",
        borderRadius: "6px",
        width: "100%",
        padding: "5px 0 5px 10px",
    },
    input_view_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex',
    },
    margin_left_5:{
        marginLeft : '5px',
    },
    textField: {
        fontWeight: '500',
        borderRadius: '5px',
        padding: '0 10px',
        backgroundColor: colors.greyLighter,
        fontSize: '14px',
        flexGrow: 1,
        border: 'none',
    },
    keywordContainer: {
        width : '100%',
    },
    keywordAdd: {
        width : '35%',
        display: 'flex',
        alignItems: 'center',
        gap: 10,
    },
    keywordAddButton: {
        width : 20,
        height : 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid',
        borderColor: colors.main,
        color: colors.main,
        borderRadius: 50,
        fontSize: 16,
    },
    keywordItemContainer: {
        flexDirection: 'row',
        width: '100%',
        display: 'flex',
        borderRadius: "6px",
        flexWrap: 'wrap',
        gap: 5,
        alignItems: 'flex-start',
    },
    keywordItem: {
        flexDirection: 'row',
        height: '43px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: "6px",
        backgroundColor: colors.selectedAlpha,
        color: colors.white,
        padding: "0 10px",
        gap: 5,
    },
    keywordInput: {
        //border: '1px solid red',
        //textAlign: 'center',
        padding: 5,
        margin: 0,
        color: colors.white,
        // fontWeight: '900'
    },
    keywordDelButton: {
        width : 18,
        height : 18,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: colors.white,
        borderRadius: 50,
        fontSize: 28,
    },
    multipleSearchButton: {
        color: colors.main,
    },
    multipleCleanButton: {
        // width : 22,
        // height : 22,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: colors.main,
        color: colors.main,
        borderRadius: 50,
    },
}));


const FormatKeywordComponent = (props) => {

    const classes = useStyles();

    const [selected, setSelected] = useState(props.field.value.length > 0 ? props.field.value : []);
    const [newValue, setNewValue] = useState("");
    const [disabled, setDisabled] = React.useState(typeof props.disabled === 'undefined' ? false: props.disabled);

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    //filter values
    const [value, setValue] = useState('')

    const handleAddValue = (value) => {
        if(!selected.includes(value)){
            setSelected([...selected, value.trim()]);
        }
        props.handleChangeKeyword(value, selected.length - 1,props.field,props.index);
        setNewValue('');
    }

    const handleRemoveValue = (value) => {
        if(selected.includes(value)){
            setSelected(selected.filter(item => item !== value));
        }
        props.handleChangeKeyword(value, selected.length - 1,props.field,props.index);
    }

    const { t, } = useTranslation(['common']);

    return (
        <div className={classes.keywordContainer}>
            {/* filter field */}
            {!disabled && props.field.value.length > 10 ? 
            <div className={classes.multipleItemPresetOpen}>
                <Input
                    id={`standard-full-width-search-${props.field.id}`}
                    label=""
                    margin="none"
                    fullWidth={true}
                    disableUnderline={true}
                    startAdornment={
                        <InputAdornment position="start">
                            <Search className={classes.multipleSearchButton}/>
                        </InputAdornment>}
                    endAdornment={ value.length > 0 &&
                        <InputAdornment position="end" onClick={(event) => setValue("")} style={{cursor : 'pointer'}}>
                            <HighlightOff className={classes.multipleCleanButton} />
                        </InputAdornment>
                    }
                    placeholder="filter list"
                    className={classes.textField}
                    onChange={(event) => setValue(event.target.value)}
                    value={value}
                />
            </div> : null}
            <div className={props.field.value === 0 ? null : classes.keywordItemContainer}>
                {!disabled &&
                <div className={classes.keywordAdd}>
                    <div className={classes.input_add_item}>
                        <Input
                            disabled={disabled}
                            autoComplete='off'
                            id={'new-item-input-' + props.field.id}
                            placeholder={t('common:multiple.add-item')}
                            fullWidth={false}
                            inputlabelprops={{
                                shrink: true,
                            }}
                            value={newValue}
                            disableUnderline={true}
                            onBlur={(e) => { if (e.target.value !== '') { handleAddValue(newValue, props.field, props.index)}}}
                            onKeyPress={(e) => { if (e.key === 'Enter') { handleAddValue(newValue, props.field, props.index)}}}
                            onChange={(event) => setNewValue(event.target.value)}
                        />
                    </div>
                </div>
                }
                {
                    props.field.value.filter(item => {
                            if (!value) return true
                            if (item.toLowerCase().includes(value.toLowerCase())) {
                                return true
                            }
                            else return false
                        }).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).map((value,indexation) => {
                        return (
                            <div key={indexation} className={classes.keywordItem}>
                                <div className={classes.keywordInput}>{value}</div>
                                {
                                    !disabled &&
                                    <div onClick={handleRemoveValue.bind(this,value, props.field, props.index)}>
                                            <span className={`${classes.keywordDelButton} material-icons`}>
                                                highlight_off
                                            </span>
                                    </div>
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatKeywordComponent);





