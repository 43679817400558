import React, {useState, useEffect } from "react"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import { isJsonStringObject } from "../../utils/utils";
import TextField from "@material-ui/core/TextField";
import {deleteFilterRequest, getListFilterRequest, getListFilterReset, postSendListFilterRequest} from "../../actions/filter";
import {getCurrentEnvironnement} from "../../utils/Common";
import CircularProgress from "@material-ui/core/CircularProgress";
import colors from "../../assets/themes/Colors";
import { Checkbox, Tooltip } from "@material-ui/core";
import { Close, Search } from "@material-ui/icons";
import { confirmAlert } from "react-confirm-alert";
import { parseFilterParameters, parseModel } from "../../utils/Fields";


const useStyles = makeStyles((theme) => ({
    popup: {
        position: "absolute",
        maxHeight: '75vh',
        zIndex: 99,
        top: '-20px',
        right: -30,
        backgroundColor: "white",
        maxWidth: "80vw",
        width: "70vw",
        overflow: 'auto',
        boxSizing: "border-box",
        boxShadow: '0px 0px 20px 20px rgb(0 0 0 / 14%), 0px 11px 15px rgb(0 0 0 / 20%)',
        borderRadius: "5px",
        "@media (max-width: 640px)": {
            position: 'fixed',
            width: '100vw',
            maxWidth: '100vw',
            maxHeight: '100vh',
            left: 0,
            right: 0,
            top: 20,
            bottom: 20,
            zIndex: 9999,
        }
    },
    thinPopup: {
        position: "absolute",
        maxHeight: "calc(100% - 250px)",
        zIndex: 99,
        top: '200px',
        right: -30,
        backgroundColor: "white",
        maxWidth: "calc(100% - 540px)",
        width: "70vw",
        overflow: 'auto',
        boxSizing: "border-box",
        boxShadow: '0px 0px 20px 20px rgb(0 0 0 / 14%), 0px 11px 15px rgb(0 0 0 / 20%)',
        borderRadius: "5px",
        "@media (max-width: 1000px)": {
            left: 0,
            right: 0,
            zIndex: 9999,
            width: '80vw',
            maxWidth: '90vw',
            top: 340,
            maxHeight: '40vh'
        },
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "90%",
        margin: "auto",
    },
    marginLeft: {
        marginLeft: "10px",
    },
    buttonSaveComment: {
        width: "91%",
        padding: '8px 20px',
        color: 'white',
        backgroundColor: '#58c3c1',
        border: 'none',
        borderRadius: '3px',
        margin: '5px auto',
        cursor: 'pointer',
    },
    filterListContainer: {
        // display: 'flex',
        // flexDirection: 'column',
        padding: '10px',
        flexDirection: 'row',
        width: '100%',
        display: 'flex',
        borderRadius: "6px",
        flexWrap: 'wrap',
        gap: 5,
        alignItems: 'stretch',
    },
    newSelectionContainer: {
        margin: '15px 10px',
        '@media (min-width: 640px)': {
            width: '50%'
        }
    },
    selectionContainer: {
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid',
        borderRadius: 4,
        borderColor: colors.grey,
        padding: 5,
        marginBottom: 5,
        cursor: 'pointer',
        position: 'relative'
    },
    selectionDetails: {
        color: colors.grey,
        fontSize: 13
    },
    selectionAuthor: {
        position: 'absolute',
        right: 3,
        bottom: '-7px',
        fontSize: 10,
        color: colors.frost
    },
    delContainer: {
        fontSize: 9,
        // width: 50,
        // color: colors.grey
    },
    searchField: {
        display: 'flex',
        gap: 5,
        alignItems: 'center',
        padding: '0 10px 0 0',
        color: 'black',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: 1000,
        }),
        justifyContent: 'space-between'
    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    headerField: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        position: 'relative',
        paddingLeft: 10,
        paddingTop: 10
    },
    topHeaderField: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        position: 'relative',
        paddingLeft: 10,
        paddingTop: 10
    },
    checkContainer: {
        cursor: 'pointer',
        bottom: 0,
        // width: '120%',
        fontSize: 12,
        color: colors.darkSilver
    },
    checkbox: {
        color: colors.mainButton
    },
    textField: {
        fontWeight: '500',
        padding: '0 10px',
        backgroundColor: colors.greyLighter,
        fontSize: '14px',
        flexGrow: 1,
        border: '1px solid',
        borderRadius: 4,
        borderColor: colors.grey,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: 1000,
        }),
    },
    searchButton: {
        cursor: 'pointer',
    },
    btnContainer: {
        display: 'flex',
        position: 'relative',
        justifyContent: 'space-between',
        zIndex: 50
    },
    actionBtn: {
        // minWidth: "160px",
        height: 41,
        padding: "4px 20px",
        margin: '3px 5px',
        borderRadius: "3px",
        cursor: "pointer",
        color: colors.white,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.mainButton,
        textAlign: 'center',
        '@media (max-width: 1300px)': {
            margin: 0,
            height: 'auto'
        }
    },
}));
const FilterSelectionList = (props) => {
    const { t, i18n } = useTranslation(['common', 'models', 'global', 'plugnote']);
    const classes = useStyles();
    const environment = getCurrentEnvironnement();
    const [modelhash, setModelhash] = useState(props.modelHash);
    const [userhash, setUserhash] = useState(environment.userhash);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState("");
    const [fields, setFields] = useState(null)
    const [list, setList] = useState([])
    const [editMode, setEditMode] = useState(false)
    const [toEdit, setToEdit] = useState('')
    const [newName, setNewName] = useState("")
    const [search, setSearch] = useState("")
    const [openSearch, setOpenSearch] = useState(false)
    const [onlyMine, setOnlyMine] = useState(true);
    const [hasOwn, setHasOwn] = useState(false) // vérifie si l'utilisateur possède des sélections

    const handleLoad = (value) => {
        props.attemptResetListFilterSaveByModel();
        if(isJsonStringObject(value.filters)){
            let filters = JSON.parse(value.filters);
            props.setFilterSet(filters);
        }
        else {
            props.setFilterSet(value)
        }
        props.setRetrieved(false)
        handleClose(value)
    }

    const handleClose = (value) => {
        editMode?
            confirmAlert({
                title: i18n.t('common:global.plugnotes'),
                message: i18n.t('common:filters.unsaved-selection'),
                buttons: [
                    {label: i18n.t('common:global.ok'),
                    onClick: () =>  {props.close(value);},
                    },
                    {label: i18n.t('common:global.cancel')
                    },
                ]
            }) :
        props.close(value);
    }

    const handleDelete = (object) => {
        confirmAlert({
            title: t('global.confirm'),
            message: t('filters.confirm-delete'),
            buttons: [
                {
                    label: t('global.yes'),
                    onClick: () =>  {
                        setValue("");
                        const index = props.list.indexOf(object)
                        if (index > -1) {
                            props.list.splice(index, 1);
                        }
                        let optionsTmp = options.filter((element, index) => {
                            return element.id !== object.id;
                        });
                        setOptions(optionsTmp);
                        props.attemptDeleteFilter({id : object.id});
                    }
                },
                {
                    label: t('global.cancel'),
                }
            ]
        });
    }

    const checkIsOwn = (filter) => {
        if(filter?.authorName !== undefined && environment?.userhash !== undefined) return filter?.authorName.hash === environment.userhash
        else return true
    }

    useEffect(() => {
        if (fields === null) setFields(parseModel(props.model))
    },[])

    useEffect(() => {
        // props.attemptResetListFilterSaveByModel();
        let active = true;
        if (!loading) {
            return undefined;
        }
        const fetchFilters = async () => {
            // console.log('fetching...');
            let payload = {
                modelhash : modelhash,
                userhash : userhash
            }
            if(!props.retrieved) props.attemptGetListFilterSaveByModel(payload);
            setLoading(false)
            props.setRetrieved(true)
        }

        if(!props.retrieved) {
            fetchFilters()
                .catch(console.error);
        }
        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {
        if(typeof props.list !== "undefined" && props.list.length > 0){
            setOptions(props.list);
            setLoading(false);
            props.setRetrieved(true)
        }
        else {
            setOptions([]);
            setLoading(false);
            props.setRetrieved(true)
        }
        let selectionList = []
        let isOwn = false
        props.list.map((filter) => {
            let title = filter.label
            let desc = filter.description
            let options = JSON.parse(filter.filters);
            let parameters = parseFilterParameters(filter, options)
            let set = {...options}
            set["label"] = title
            set["params"] = parameters
            set["description"] = desc
            selectionList.push(set)
            if(checkIsOwn(filter)) isOwn = true
        })
        setHasOwn(isOwn)
        if(!isOwn) setOnlyMine(false)
        setList(selectionList)
    }, [props.list])

    const excludeSearch = (item) => {
        let found = false
        if(search !== "") {
            let _search = search.toLowerCase()
            let label = item.label.toLowerCase()
            let filters = item.filters.toLowerCase()
            let description = item.description.toLowerCase()
            if (label.includes(_search)) {
                found = true}
            if (filters.includes(_search)) {
                found = true}
            if (description.includes(_search)) {
                found = true}
            return found
        }
        return true
    }

    const handleModification = (filter, option) => {
        let label = newName !== '' ? newName : filter.label
        let payload = {
            clef : props.clef,
            description : 'description',
            filters : option,
            label : label,
            modelhash : modelhash,
            text : label,
            userhash : userhash,
        }
        props.attemptDeleteFilter({id: filter.id})
        setHasOwn(true)
        props.attempListFilterSaveByModel(payload);
        setEditMode(false)
        props.close();
        setTimeout(() => props.openSelectionList(), 200)
    }

    const handleOpenEditMode = (filter) => {
        setEditMode(true);
        setNewName(filter.label)
        setToEdit(filter.id)
        document.getElementById(filter.label + '-' + filter.clef).focus()
    }

    return (
            <div className={classes.thinPopup}>
                <div className="scrollbar-hidden popup_wrapper flexSpacebetween">
                    <div
                    // className="popup_wrapper_content"
                    >
                        <div className="sous-header_modal_content">
                            <div className={classes.headerContainer}>
                                <div className={classes.topHeaderField}>
                                    <div className={classes.searchField} onClick={(e) => {e.stopPropagation(); e.preventDefault() }}>
                                        <span className="titres">
                                        {t("common:filters.title-saved") } <br />
                                        </span>
                                    </div>
                                    <div
                                        onClick={() => {
                                            props.deleteFilters()
                                            props.handleOpenFilterPanel();
                                        }}
                                        className={classes.actionBtn}>
                                        <span >{t("common:filters.new-selection")}</span>
                                    </div>
                                    <div className={classes.searchField} onClick={(e) => {e.stopPropagation(); e.preventDefault() }}>
                                    </div>
                                </div>
                                <div className={classes.headerField}>
                                    {/* checkbox field */}
                                    {
                                        hasOwn?
                                        <div className={classes.checkContainer}>
                                            <span onClick={(e) => {e.preventDefault(); e.stopPropagation(); setOnlyMine(!onlyMine)}}>See only my selections</span>
                                            <Checkbox
                                                className={classes.checkbox}
                                                type="checkbox"
                                                checked={onlyMine}
                                                onClick={(e) => {e.preventDefault(); e.stopPropagation(); setOnlyMine(!onlyMine)}}
                                                onChange={(e) => {}}
                                                sx={{
                                                    color: colors.mainButton,
                                                    '&.Mui-checked': {
                                                    color: colors.mainButton,
                                                    },
                                                }}
                                                />
                                        </div>
                                        : null
                                    }
                                    {/* filter field */}
                                        {openSearch ?
                                        <TextField
                                            id={`search-field`}
                                            label=""
                                            margin="none"
                                            autoFocus
                                            fullWidth={false}
                                            InputProps={{
                                                disableUnderline: true,
                                                endAdornment: (search.length > 0 ?
                                                <Close className={classes.searchButton} onClick={() => setSearch('')}/>
                                                : <Search className={classes.searchButton} onClick={() => setOpenSearch(false)}/>),
                                            }}
                                            className={classes.textField}
                                            onChange={(event) => setSearch(event.target.value)}
                                            onBlur={() => {if (search === "") setOpenSearch(false)}}
                                            value={search}
                                        />
                                        :
                                        <Tooltip title={t('filters.search')} placement="top">
                                            <span
                                                id="icon-save-selection"
                                                className="material-icons md-30"
                                                onClick={(e) => {e.preventDefault(); e.stopPropagation(); setOpenSearch(true)}}>
                                                search
                                            </span>
                                        </Tooltip>
                                        }
                                </div>
                            </div>

                            <div onClick={() => handleClose(null)}>
                                <span
                                    id="icon-close"
                                    className="material-icons md-50 lightgrey"
                                >
                                  close
                                </span>
                            </div>
                        </div>
                        <div className="sous-header_content lightgrey">
                            <div className="line flex_style"></div>
                        </div>

                        <div className={classes.filterListContainer}>
                        {
                            !loading && props.list.length > 0 ?
                            props.list
                            .sort((a, b) => {return a.label.toLowerCase().localeCompare(b.label.toLowerCase()); })
                            .filter((item) => excludeSearch(item))
                            .filter((item) => {if(onlyMine){
                                if(item?.authorName !== undefined && environment?.userhash !== undefined) return item?.authorName.hash === environment.userhash
                                else return true
                            }
                            else return true
                            })
                            .map((filter) => {
                                let options = JSON.parse(filter.filters);
                                let parameters = parseFilterParameters(fields, options)
                                return (
                                    <div
                                        className={classes.selectionContainer}
                                        onClick={(e) => {
                                            e.preventDefault(); e.stopPropagation();
                                            if(!editMode) handleLoad(filter)}}
                                        key={filter.label + '-' + filter.clef}>
                                        <TextField
                                            id={filter.label + '-' + filter.clef}
                                            variant="standard"
                                            inputProps={{cursor: 'pointer',}}
                                            InputProps={{
                                                disableUnderline: editMode? filter.id !== toEdit :true,
                                                cursor: 'pointer',
                                                startAdornment: (
                                                    <React.Fragment>
                                                        {(filter?.authorName !== undefined) && filter.authorName?.hash === environment.userhash ?
                                                        <Tooltip title={editMode? t('filters.update-selection') : t('filters.edit-selection')}>
                                                        <div className={classes.delContainer}>
                                                            <span id="icon-delete-playlist" className="material-icons md-small"
                                                                onClick={(e) => {
                                                                    e.preventDefault(); e.stopPropagation();
                                                                    if(editMode) handleModification(filter, options, value); else handleOpenEditMode(filter)}}>
                                                                {editMode? (toEdit === filter.id? 'check': null) : 'edit'}
                                                            </span>
                                                        </div></Tooltip>
                                                        : null}
                                                    </React.Fragment>
                                                ),
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {loading ? (
                                                            <CircularProgress
                                                                color="inherit"
                                                                size={20}
                                                            />
                                                        ) :
                                                        filter?.authorName !== undefined && filter.authorName.hash === environment.userhash ?
                                                        <div className={classes.delContainer}>
                                                            <span id="icon-delete-playlist" className="material-icons md-small" onClick={(e) => {e.preventDefault(); e.stopPropagation(); handleDelete(filter)}}>
                                                                delete
                                                            </span>
                                                        </div>
                                                        : null}
                                                    </React.Fragment>
                                                ),
                                            }}
                                            value={editMode && toEdit === filter.id ?
                                                newName
                                                :
                                                ((typeof filter !== "undefined" && filter !== "") || (filter.length > 0)?
                                                // filter.label + ' ('+ filter.description + ')'
                                                // :
                                                filter.label + ' '
                                                : "")}
                                            onChange={(e) => {if (editMode) setNewName(e.target.value)}}
                                            onKeyDown={(e)=> {if(e.key === "Enter") handleModification(filter, options, value);}}
                                        />
                                        <span className={classes.selectionDetails}>({filter.id}) {parameters}</span>
                                        <span className={classes.selectionAuthor}>{filter.authorName !== null && filter.authorName !== undefined? `${filter.authorName.firstname} ${filter.authorName.lastname}`: 'Unknown author'}</span>
                                    </div>
                                );
                            })
                        : <span className={classes.selectionDetails}>{t('filters.empty-selections')}</span>
                        }
                        </div>
                    </div>
                </div>
            </div>
    );
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        mainHash : state?.user?.profil?.hash || null,
        list: state?.filter?.list || [],
    }
};

const mapDispatchToProps = (dispatch) => ({
    attemptGetListFilterSaveByModel: (payload) => dispatch(getListFilterRequest(payload)),
    attemptResetListFilterSaveByModel: () => dispatch(getListFilterReset()),
    attemptDeleteFilter: (payload) => dispatch(deleteFilterRequest(payload)),
    attempListFilterSaveByModel: (payload) => dispatch(postSendListFilterRequest(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterSelectionList);
