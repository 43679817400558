import {call, fork, put, select, takeEvery} from 'redux-saga/effects';
import * as actions from '../actions/search';
import * as api from '../api/searchApi';
import {trackPromise} from "react-promise-tracker";
import _ from "lodash";


const myGenericTrackedWrapper = (fn, ...args) => trackPromise(fn(...args));
const myGenericTrackedWrapperMultiple = (fn, args1, args2, args3, args4) => trackPromise(fn(args1,args2, args3, args4));


export function * searchModel(action) {

    const userhash = action.userhash;
    const searchParams = yield select((state) => state.search.searchParams);
    let newSearchParams = JSON.stringify(searchParams);
    newSearchParams = newSearchParams.replace(/("sans":)/g, '"without":');
    newSearchParams = JSON.parse(newSearchParams);
    newSearchParams.advancedSearch = false;

    try {
        const response = yield call(myGenericTrackedWrapperMultiple,api.postSearchPlug, userhash, newSearchParams);
        if(response.statusText === 'OK' && response.data.result === 'true' && response.data.data.length > 0){
            yield put(actions.postSearchModelSuccess(response.data.data));
        }else{
            yield put(actions.postSearchModelFailure());
        }
    }catch (e) {
        yield put(actions.postSearchModelError());

    }
}

export function * searchPlug(action) {
    const userhash = action.userhash;
    const searchParams = yield select((state) => state.search.searchParams);
    let newSearchParams = JSON.stringify(searchParams);
    newSearchParams = newSearchParams.replace(/("sans":)/g, '"without":');
    newSearchParams = JSON.parse(newSearchParams);
    newSearchParams.advancedSearch = false;

    try {
        const response = yield call(myGenericTrackedWrapperMultiple,api.postSearchPlug, userhash, newSearchParams);
        if(response.statusText === 'OK' && response.data.result === 'true' && response.data.data.length > 0){
            yield put(actions.postSearchPlugSuccess(response.data.data));
        }else{
            yield put(actions.postSearchPlugFailure());
        }
    }catch (e) {
        yield put(actions.postSearchPlugError());

    }
}

export function * checkSearch(action){
    const userhash = action.userhash;
    const searchParams = yield select((state) => state.search.searchParams);

    if(_.isEmpty(searchParams)){
        yield put(actions.delAllSearchParamsRequest());
    }else{
        let newSearchParams = JSON.stringify(searchParams);
        newSearchParams = newSearchParams.replace(/("sans":)/g, '"without":');
        newSearchParams = JSON.parse(newSearchParams);
        newSearchParams.advancedSearch = false;

        try {
            const response = yield call(myGenericTrackedWrapperMultiple,api.postSearchPlug, userhash, newSearchParams);
            if(response.statusText === 'OK' && response.data.result === 'true' && response.data.data.length > 0){
                yield put(actions.postSearchSuccess(response.data.data));
            }else{
                yield put(actions.postSearchFailure());
            }
        }catch (e) {
            yield put(actions.postSearchError());

        }
    }
}

function* watchFetchField() {
    // create watcher of fetchData function
    yield takeEvery(actions.Types.POST_SEARCH_MODEL_REQUEST, searchModel);
    yield takeEvery(actions.Types.POST_SEARCH_PLUG_REQUEST, searchPlug);
    yield takeEvery(actions.Types.DEL_SEARCH_KEY_PARAMS_REQUEST, checkSearch);
}

const SearchSagas = [
    fork(watchFetchField)
];

export default SearchSagas;





