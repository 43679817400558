/**
 * Liste des types de recherche
 * @type {[*]}
 */
import Images from "../assets/themes/Images";

export const searchTypeList = [
    {
        id: 1,
        keyName: "content",
        name: 'search.plug-search-content-type-title',
        icon: Images.contentTypeSearchIcon
    },
    {
        id: 2,
        keyName: "plugcodes",
        name: 'search.plug-search-plugcodes-type-title',
        icon: Images.plugCodesIcon
    },
    {
        id: 3,
        keyName: "dates",
        name: 'search.plug-search-date-type-title',
        icon: Images.dateTypeSearchIcon
    },
    {
        id: 4,
        keyName: "archives",
        name: 'search.plug-search-archives-type-title',
        icon: Images.archivesSearchIcon
    },
    {
        id: 5,
        keyName: "divers",
        name: 'search.plug-search-divers-type-title',
        icon: Images.diversSearchIcon
    },
    {
        id: 6,
        keyName: "pnnumber",
        name: 'search.plug-search-pn-number-type-title',
        icon: Images.pnNumberSearchIcon
    },
    {
        id: 7,
        keyName: "plugkey",
        name: 'search.plug-search-plugkey-type-title',
        icon: Images.plugkeySearchIcon
    }
];

/**
 * Liste des options
 * @type {[*]}
 */
export const searchTypeData = {
    optionGroupList: [
        // le bouton pour switche les valeur (type boulean)
        {name: "optionGroup1", fieldType: "switch1", dataType: "boolean", type: "list"},
        // le bouton pour switche les valeur (type keyword, pour le type "Date" option Date de création par ex)
        {name: "optionGroup2", fieldType: "switchKeyword1", dataType: "boolean", type: "list"},
        // le bouton pour switche les valeur (type boulean)
        {name: "optionGroup3", fieldType: "switch1", dataType: "boolean", type: "list"},
        // le bouton pour switche pour les options "Ou", "Et" qui apparraisent à côté la liste des keywords
        {name: "optionGroup7", fieldType: "switch2", dataType: "boolean", type: "list"}, // options for keyword
        // le textInput pour entrer les keyword du type de recherche "Contenu"
        {name: "optionGroup4", fieldType: "textInput1", dataType: "text", type: "object"}, // multi keyword
        // le textInput pour entrer le keyword ( pour le type de recherche "Num PN", "plugkey"
        {name: "optionGroup5", fieldType: "textInput2", dataType: "textList", type: "object"}, // unique keyword
        // Pour la date
        {name: "optionGroup6", fieldType: "dateInput", dataType: "date", type: "object"},
        // le bouton pour switche les valeur pour le type de recherche "Divers"
        {name: "optionGroup8", fieldType: "switchKeyword2", dataType: "boolean", type: "list"}
    ],
    data: [
        {
            id: 1,
            keyName: "content",
            optionGroup1: [
                {
                    id: 1,
                    keyName: "plugform_plugnote_annexe",
                    label: "plug-search-all-option-label",
                    defaultValue: false,
                    visible : false
                },
                {
                    id: 2,
                    keyName: "plugform",
                    label: "plug-search-plugform-option-label",
                    defaultValue: true,
                    visible : false
                },
                {
                    id: 3,
                    keyName: "plugnote",
                    label: "plug-search-plugnote-option-label-espace",
                    defaultValue: false,
                    visible : false
                },
                {
                    id: 4,
                    keyName: "annexe",
                    label: "plug-annexe-search-option-label",
                    defaultValue: false,
                    visible : false
                }

            ],
            optionGroup3: [
                {
                    id: 1,
                    keyName: "with",
                    label: "plug-search-text-with-option-label",
                    defaultValue: true,
                    visible :false
                },
                {
                    id: 2,
                    keyName: "begin",
                    label: "plug-search-text-begin-option-label",
                    defaultValue: false,
                    visible :false
                },
                {
                    id: 3,
                    keyName: "equal",
                    label: "plug-search-text-equal-option-label",
                    defaultValue: false,
                    visible :false
                }
            ],
            optionGroup4: {
                keyName: "keyword",
                placeHolder: 'plug-search-search-field-placeholder',
                defaultRightIcon: "search",
                defaultValue: {list: []},
                visible : true
            },
            optionGroup7: [
                {
                    id: 1,
                    keyName: "or",
                    label: "plug-search-text-or-option-label",
                    defaultValue: true,
                    visible :false
                },
                {
                    id: 2,
                    keyName: "and",
                    label: "plug-search-text-and-option-label",
                    defaultValue: false,
                    visible :false
                }
            ]
        },
        {
            id: 2,
            keyName: "plugcode",
            optionGroup1: [
                {
                    id: 1,
                    keyName: "progress",
                    label: "plug-search-plugcode-univers-in-prog",
                    defaultValue: true,
                    visible : true
                },
                {
                    id: 2,
                    keyName: "all",
                    label: "plug-search-plugcode-all-univers",
                    defaultValue: false,
                    visible : true
                },
            ],
            optionGroup5: {
                keyName: "keyword",
                placeHolder: 'plug-search-search-field-placeholder',
                defaultRightIcon: "search",
                defaultValue: "",
                visible : true
            },
        },
        {
            id: 3,
            keyName: "dates",
            optionGroup2: [
                {
                    id: 1,
                    keyName: "keyword",
                    label: "plug-search-date-plug-option-label",
                    defaultValue: true,
                    value: "fiches",
                    visible : true
                },
                {
                    id: 2,
                    keyName: "keyword",
                    label: "plug-search-date-creation-pn-option-label",
                    defaultValue: false,
                    value: "creationPN",
                    visible : true
                },
                {
                    id: 3,
                    keyName: "keyword",
                    label: "plug-search-date-modification-pn-option-label",
                    defaultValue: false,
                    value: "modificationPN",
                    visible : true
                },
                {
                    id: 4,
                    keyName: "keyword",
                    label: "plug-search-date-expiration-pn-option-label",
                    defaultValue: false,
                    value: "expirationPN",
                    visible : true
                },
                {
                    id: 5,
                    keyName: "keyword",
                    label: "plug-search-date-creation-an-option-label",
                    defaultValue: false,
                    value: "creationAN",
                    visible : true
                },
                {
                    id: 7,
                    keyName: "keyword",
                    label: "plug-search-date-expiration-an-option-label",
                    defaultValue: false,
                    value: "expirationAN",
                    visible : true
                }
            ],
            optionGroup3: [
                {
                    id: 1,
                    keyName: "from",
                    label: "plug-search-date-from-option-label",
                    defaultValue: true,
                    visible : true
                },
                {
                    id: 2,
                    keyName: "equal",
                    label: "plug-search-date-equal-option-label",
                    defaultValue: false,
                    visible : true
                },
                {
                    id: 3,
                    keyName: "to",
                    label: "plug-search-date-until-option-label",
                    defaultValue: false,
                    visible : true
                }
            ],
            optionGroup6: {
                id: 1,
                keyName: "date",
                defaultValue: "",
                visible : true
            }
        },
        {
            id: 4,
            keyName: "archives",
            optionGroup1: [
                {
                    id: 1,
                    keyName: "PlugFormArch",
                    label: "plug-search-archives-plugform-option-label",
                    defaultValue: true,
                    visible : true
                },
                {
                    id: 2,
                    keyName: "PlugNoteArch",
                    label: "plug-search-archives-plugnote-option-label",
                    defaultValue: false,
                    visible : true
                }
            ],
            optionGroup3: [
                {
                    id: 1,
                    keyName: "only",
                    label: "plug-search-archives-only-option-label",
                    defaultValue: true,
                    visible : true
                },
                {
                    id: 2,
                    keyName: "with",
                    label: "plug-search-archives-with-option-label",
                    defaultValue: false,
                    visible : true
                },
                {
                    id: 3,
                    keyName: "without",
                    label: "plug-search-archives-without-option-label",
                    defaultValue: false,
                    visible : true
                }
            ]
        },
        {
            id: 5,
            keyName: "divers",
            optionGroup1: [
                {
                    id: 1,
                    keyName: "only",
                    label: "plug-search-divers-only-option-label",
                    defaultValue: true,
                    visible : true
                },
                {
                    id: 2,
                    keyName: "sans",
                    label: "plug-search-divers-without-option-label",
                    defaultValue: false,
                    visible : true
                }
            ],
            optionGroup8: [
                {
                    id: 1,
                    keyName: "keyword",
                    label: "plug-search-divers-tiers-option-label",
                    defaultValue: true,
                    value: "tiers",
                    showTextInput: true,
                    visible : true
                },
                {
                    id: 2,
                    keyName: "keyword",
                    label: "plug-search-divers-annexes-option-label",
                    defaultValue: false,
                    value: "annexes",
                    showTextInput: false,
                    visible : true
                },
                {
                    id: 3,
                    keyName: "keyword",
                    label: "plug-search-divers-public-option-label",
                    defaultValue: false,
                    value: "public",
                    showTextInput: false,
                    visible : true
                },
                {
                    id: 4,
                    keyName: "keyword",
                    label: "plug-search-divers-semi-option-label",
                    defaultValue: false,
                    value: "semi",
                    showTextInput: false,
                    visible : true
                },
                {
                    id: 5,
                    keyName: "keyword",
                    label: "plug-search-divers-private-option-label",
                    defaultValue: false,
                    value: "private",
                    showTextInput: false,
                    visible : true
                },
                {
                    id: 6,
                    keyName: "keyword",
                    label: "plug-search-divers-shared-option-label",
                    defaultValue: false,
                    value: "shared",
                    showTextInput: true,
                    visible : true
                }
            ]
        },
        {
            id: 6,
            keyName: "pnnumber",
            optionGroup1: [
                {
                    id: 1,
                    keyName: "equal",
                    label: "plug-search-pnnumber-equal-option-label",
                    defaultValue: true,
                    visible : true
                },
                {
                    id: 2,
                    keyName: "from",
                    label: "plug-search-pnnumber-from-option-label",
                    defaultValue: false,
                    visible : true
                },
                {
                    id: 3,
                    keyName: "to",
                    label: "plug-search-pnnumber-to-option-label",
                    defaultValue: false,
                    visible : true
                }
            ],
            optionGroup5: {
                keyName: "keyword",
                placeHolder: 'plug-search-search-field-placeholder',
                defaultRightIcon: "search",
                defaultValue: "",
                visible : true
            },
        },
        {
            id: 7,
            keyName: "plugkey",
            optionGroup1: [
                {
                    id: 1,
                    keyName: "begin",
                    label: "plug-search-plugkey-begin-option-label",
                    defaultValue: true,
                    visible : true
                },
                {
                    id: 2,
                    keyName: "equal",
                    label: "plug-search-plugkey-equal-option-label",
                    defaultValue: false,
                    visible : true
                },
                {
                    id: 3,
                    keyName: "to",
                    label: "plug-search-plugkey-to-option-label",
                    defaultValue: false,
                    visible : true
                },
                {
                    id: 4,
                    keyName: "from",
                    label: "plug-search-plugkey-from-option-label",
                    defaultValue: false,
                    visible : true
                }
            ],
            optionGroup5: {
                keyName: "keyword",
                placeHolder: 'plug-search-search-field-placeholder',
                defaultRightIcon: "search",
                defaultValue: "",
                visible : true
            },
        },
        {
            id: 8,
            keyName: "label",
            optionGroup3: [
                {
                    id: 1,
                    keyName: "with",
                    label: "plug-search-text-with-option-label",
                    defaultValue: true,
                    visible :false
                },
                {
                    id: 2,
                    keyName: "begin",
                    label: "plug-search-text-begin-option-label",
                    defaultValue: false,
                    visible :false
                },
                {
                    id: 3,
                    keyName: "equal",
                    label: "plug-search-text-equal-option-label",
                    defaultValue: false,
                    visible :false
                }
            ],
            optionGroup4: {
                keyName: "keyword",
                placeHolder: 'plug-search-search-field-placeholder',
                defaultRightIcon: "search",
                defaultValue: {list: []},
                visible : true
            },
            optionGroup7: [
                {
                    id: 1,
                    keyName: "or",
                    label: "plug-search-text-or-option-label",
                    defaultValue: true,
                    visible :false
                },
                {
                    id: 2,
                    keyName: "and",
                    label: "plug-search-text-and-option-label",
                    defaultValue: false,
                    visible :false
                }
            ]
        }
    ]
};