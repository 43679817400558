import React, { useState } from 'react';
import Tooltip from "@material-ui/core/Tooltip";
import {makeStyles, } from '@material-ui/core/styles';
import {getCurrentEnvironnement} from "../../../utils/Common";
import {useTranslation} from "react-i18next";
import TextField from "@material-ui/core/TextField";
import {putPlugcodeGalleryRequest} from "../../../actions/gallery";
import {connect} from "react-redux";
import {confirmAlert} from "react-confirm-alert";
import colors from '../../../assets/themes/Colors';

const useStyles = makeStyles((theme) => ({
    actionCtn: {
        display :  'flex',
        justifyContent : 'end',
        paddingBottom: 8,
    },
    mainCtn: {
        display : 'inline-flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width : '100%',
        height: '100%',
        position: 'relative'
    },
    commentCtn: {
        padding: '5px',
        display: 'flex',
        flexDirection: 'column',
        gap: 5
    },
    ownerCmntCtn: {
        border: '1px solid',
        borderColor: colors.main,
        borderRadius: 4,
    },
    ownerComment: {
        fontSize: 14,
        color: colors.main,
        border: '1px solid',
        borderColor: colors.main,
        borderRadius: 4,
        padding : '0px 10px',
    },
    personalComment: {
        fontSize: 14,
        // color: colors.main,
        border: '1px solid',
        borderColor: colors.grey,
        borderRadius: 4,
        padding : '0px 10px',
    },
    inputField: {
        position: 'relative',
        width: 308,
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: colors.grey,
                border: '1px solid'
            }
        }
    },
    inputBtn: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        position: 'absolute',
        top: 2,
        right: -2,
        background: colors.white,
        zIndex: 1

    }
}))
function SubPlugcodePortalComponent(props) {
    const classes = useStyles()
    const { t, } = useTranslation(['common', 'models', 'global']);
    let timer

    const _handleSubmitComment = (event) => {
        event.preventDefault();
        event.stopPropagation();
        timer = setTimeout(() => {
            let gallery = {
                plugcode : props.item.refcode,
                comment : comment,
                galleryhash : props.item.hash,
                userhash : props.uh,
                mainuserhash : props.umh
            }
            props.attemptUpdateGalleryRequest(gallery, props.item.mainuserhash);
            setShow(false)
        }, 500)
        // confirmAlert({
        //     title: t('global.confirm'),
        //     message: t('global.save'),
        //     buttons: [
        //         {
        //             label: t('global.yes'),
        //             onClick: () =>
        //             {
        //                 props.attemptUpdateGalleryRequest(gallery, props.item.mainuserhash);
        //                 setShow(false);
        //             }
        //         },
        //         {
        //             label: t('global.cancel'),
        //         }
        //     ]
        // });
    }

    const _handleDeleteComment = (event) => {
        clearTimeout(timer);
        event.preventDefault();
        event.stopPropagation();
        let gallery = {
            plugcode : props.item.refcode,
            comment : '',
            galleryhash : props.item.hash,
            userhash : props.uh,
            mainuserhash : props.umh
        }
        confirmAlert({
            title: t('global.confirm'),
            message: t('global.delete'),
            buttons: [{
                label: t('global.yes'),
                onClick: () =>{
                    props.attemptUpdateGalleryRequest(gallery, props.item.mainuserhash);
                    setShow(false);
                }},
                {label: t('global.cancel')}
            ]
        })
    }

    const [show, setShow] = useState(false);
    const [comment, setComment] = useState(props.item.commentperso);
    const handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setShow(!show);
    };

    // console.log('sub pc', props);
    return (
    <div className={classes.mainCtn} onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
        <div className={classes.commentCtn}>
            {typeof props.item.commentproprio !== 'undefined' && props.item?.commentproprio.length > 0 &&
                <div className={classes.ownerComment}>
                    {props.item.commentproprio}
                </div>
            }
            {show?
            <TextField
                autoFocus
                className={classes.inputField}
                id="outlined-multiline-static"
                InputProps={{
                    endAdornment:
                    <span className={classes.inputBtn}>
                        <Tooltip title={t('common:global.validate')} placement='top'>
                            <span id="icon-add-playlist" className={`material-icons green`} onClick={(event) => _handleSubmitComment(event)}>
                                check_circle
                            </span>
                        </Tooltip>
                        <Tooltip title={t('common:global.delete')} placement='top'>
                            <span id="icon-add-playlist" className={`material-icons red`} onClick={(event) => _handleDeleteComment(event)}>
                                cancel
                            </span>
                        </Tooltip>
                    </span>
                }}
                multiline
                minRows={4}
                rowsMax={Infinity}
                defaultValue={comment}
                variant="outlined"
                fullWidth={true}
                onBlur={(event) => _handleSubmitComment(event)}
                onChange={(event) => setComment(event.target.value)}
            />
            :
            typeof props.item.commentperso !== 'undefined' && props.item?.commentperso.length > 0 ?  
            <div className={classes.personalComment} onClick={handleClick}>
                <Tooltip title={t('common:actions.comment-edit')} aria-label="add" placement="top">
                    <span className={"material-icons md-25 "} style={{marginLeft: -8}}>
                        edit
                    </span>
                </Tooltip>
                {props.item.commentperso}
            </div>
            :
            <span className="cursor titres black font_size_10" onClick={handleClick}>
                <Tooltip title={t('common:models.add-comment')} aria-label="add" placement="top">
                    <span className={"material-icons md-20 "} >
                        edit
                    </span>
                </Tooltip>
                <span className="cursor titres black font_size_10" >+</span>
            </span>}
        </div>
    </div>
    );
}

// redux providing state takeover
const mapStateToProps = (state) => {
    const environment = getCurrentEnvironnement();

    return {
        uh : environment.userhash,
        umh : state.user.profil.hash,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        attemptUpdateGalleryRequest: (gallery, userhash) => dispatch(putPlugcodeGalleryRequest(gallery, userhash)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubPlugcodePortalComponent)
