import React, {useEffect} from "react";
import PropTypes from "prop-types";
import Chip from "@material-ui/core/Chip";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Downshift from "downshift";
import {confirmAlert} from "react-confirm-alert";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    chip: {
        margin: theme.spacing(0.5, 0.25),
    },
    MuiInputBaseInput:{
        display: 'block',
    }
}));

const isEmail = (value) => {
    return value.match(new RegExp( /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) != null;
    //return /(.)+@(.)+/.test(value)
}

const checkDoublon = (list, text) => {
    return list.findIndex((item) => item === text) > -1
};

const checkDoublonObject = (list, text) => {
    return list.findIndex((item) => item.email === text) > -1
};



export default function TagsInputObjectMails({ ...props }) {

    const [t, i18n] = useTranslation('common');

    const classes = useStyles();
    const { selectedTags, placeholder, tags, controls, handleAdd, handleRemove, ...other } = props;
    const [inputValue, setInputValue] = React.useState("");
    const [selectedItem, setSelectedItem] = React.useState([]);
    useEffect(() => {
        setSelectedItem(tags);
    }, [tags]);
    useEffect(() => {
        selectedTags(selectedItem);
    }, [selectedItem, selectedTags]);

    function handleKeyDown(event) {
        if (event.key === "Enter") {

            if (checkDoublon(tags,event.target.value.trim())) {
                setInputValue("");
                return;
            }

            if(typeof controls !== "undefined"){
                if(checkDoublonObject(controls,event.target.value.trim())){
                    setInputValue("");
                    return;
                }
            }

            if (!event.target.value.replace(/\s/g, "").length) return;
            if (!isEmail(event.target.value)) return;

            setInputValue("");
            handleAdd(event.target.value.trim());
        }
    }

    const handleDelete = item => () => {

        confirmAlert({
            title: t('global.plugnotes'),
            message: t('global.confirm-delete'),
            buttons: [
                {
                    label: t('global.ok'),
                    onClick: () => {
                        handleRemove(item);
                    }
                },
                {
                    label: t('global.cancel'),
                },
            ]
        });
    };

    function handleInputChange(event) {
        setInputValue(event.target.value);
    }
    return (
        <React.Fragment>
            <Downshift
                id="downshift-multiple"
                inputValue={inputValue}
                selectedItem={selectedItem}
            >
                {({ getInputProps }) => {
                    const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
                        onKeyDown: handleKeyDown,
                        placeholder
                    });
                    return (
                        <div>
                            <TextField
                                multiline={false}
                                InputProps={{
                                    disableUnderline: true,
                                    className: classes.MuiInputBaseInput
                                    ,
                                    startAdornment: selectedItem.map((item,index) => (
                                        <Chip
                                            key={index}
                                            tabIndex={-1}
                                            label={item.email}
                                            className={classes.chip}
                                            onDelete={handleDelete(item.email)}
                                        />
                                    )),
                                    onBlur,
                                    onChange: event => {
                                        handleInputChange(event);
                                        onChange(event);
                                    },
                                    onFocus
                                }}
                                {...other}
                                {...inputProps}
                            />
                        </div>
                    );
                }}
            </Downshift>
        </React.Fragment>
    );
}
TagsInputObjectMails.defaultProps = {
    tags: []
};
TagsInputObjectMails.propTypes = {
    selectedTags: PropTypes.func.isRequired,
    tags: PropTypes.arrayOf(PropTypes.object)
};
