import React from "react"
import {useTranslation} from "react-i18next";
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {connect} from "react-redux";
import {getCurrentEnvironnement, getModalModel, getModalPlugnote, getModel, getPlugnote} from "../../utils/Common";
import TextField from "@material-ui/core/TextField";
import '@contentful/forma-36-react-components/dist/styles.css';
import {Input} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import {postAnnexesSendMailModalRequest, postAnnexesSendMailRequest} from "../../actions/annexe";
import ModalSearchInRepertoriesComponent from "./ModalSearchInRepertoriesComponent";

function getModalStyle() {
    const top = 5;

    return {
        top: `${top}%`,
        margin:'auto',
        overflow: 'auto',
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    popup: {
        position: 'absolute',
        //width: '50%',
        //height : '50%',
        //backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        //boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        width: '820px',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    marginLeft:{
        marginLeft : '10px'
    },    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F3F3F3',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    input: {
        color: '#F3F3F3',
        fontFamily : 'Arial, FontAwesome'
    }
}));


const ModalSendMailAnnexeComponent = (props) => {

    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [t, i18n] = useTranslation('common');

    const model = getModel();
    const plugnote = getPlugnote();
    const [disabled, setDisabled] = React.useState(false);
    const [destinataire, setDestinataire] = React.useState("");
    const [cc, setCc] = React.useState("");
    const [cci, setCci] = React.useState("");

    const [viewCc, setViewCc] = React.useState(false);
    const [viewCci, setViewCci] = React.useState(false);
    const [openRepertory, setOpenRepertory] = React.useState(false);
    const [method, setMethod] = React.useState('');

    const [sujet, setSujet] = React.useState("");
    const [body, setBody] = React.useState("");


    const [open, setOpen] = React.useState(true);
    const [modal, setModal] = React.useState(typeof props.modal !== "undefined" ? props.modal : false);

    const environment = getCurrentEnvironnement();

    const handleCloseModal = () => {
        props.close();
    };

    const handleCloseSubModal = () => {
        setOpenRepertory(false);
    }

    const handleSendMail = () => {

        let payload = {
            cc: cc || '',
            cci: cci || '',
            to: destinataire || '',
            subject: sujet || '',
            body: body || '',
        };

        if (modal) {
            if (getModalPlugnote() === null) {
                let dataForHash = buildNewAnnexe(props.fields);
                props.attemptAnnexesSendMailModal(payload, [], environment.userhash, getModalModel().hash, getModalPlugnote() !== null ? getModalPlugnote().hash : null, getModalPlugnote() !== null ? getModalPlugnote().userindex : null, dataForHash, true);
            } else {
                props.attemptAnnexesSendMailModal(payload, [], environment.userhash, getModalModel().hash, getModalPlugnote() !== null ? getModalPlugnote().hash : null, getModalPlugnote() !== null ? getModalPlugnote().userindex : null, null, false);
            }
        } else{
            if (getPlugnote() === null) {
                let dataForHash = buildNewAnnexe(props.fields);
                props.attemptAnnexesSendMail(payload, [], environment.userhash, getModel().hash, getPlugnote() !== null ? getPlugnote().hash : null, getPlugnote() !== null ? getPlugnote().userindex : null, dataForHash, true);
            } else {
                props.attemptAnnexesSendMail(payload, [], environment.userhash, getModel().hash, getPlugnote() !== null ? getPlugnote().hash : null, getPlugnote() !== null ? getPlugnote().userindex : null, null, false);
            }
    }

        props.close();

    }

    const buildNewAnnexe = (fields) => {
        let newData = {};

        const modelForHash = getModel();

        fields.map((field) => {
            if (field.format === "multi-select" || field.format === "free-multi-select"
                || field.format === "single-select" || field.format === "free-single-select") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else if (Array.isArray(field.value)) {
                    newData["field" + field.id] = JSON.stringify(field.value);
                } else {
                    newData["field" + field.id] = JSON.stringify([field.value]);
                }
            }else if(field.format === "list"){
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else if (Array.isArray(field.value)) {
                    let correctValue = [];
                    field.value.map((item, index) => {
                        correctValue.push({"text" : item})
                    });
                    newData["field" + field.id] = JSON.stringify(correctValue);
                } else {
                    let transformValue = [field.value];
                    let correctValue = [];
                    transformValue.map((item, index) => {
                        correctValue.push({"text" : item})
                    });
                    newData["field" + field.id] = correctValue;
                }
            } else {
                newData["field" + field.id] = field.value;
            }

            if (field.format === "multi-select" || field.format === "free-multi-select"
                || field.format === "single-select" || field.format === "free-single-select") {
                newData["field" + field.id + "format"] = "select";
            } else {
                newData["field" + field.id + "format"] = field.format;
            }

            if (modelForHash && field.format === "indice") {
                let editable = JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.editable || false;
                if(!editable && field.value === "" || (editable && field.value === "")){
                    newData["field" + field.id] = (JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.racine || "") +  (JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.indice || 0);
                }
            }

            if(field.format && field.format === 'agenda' || field.format && field.format === 'birthday'){
                newData["field" + field.id + "options"] = field.event;
            }

            newData["field" + field.id + "label"] = field.title;
        });
        newData.Annexes = [];
        newData.lastversion_model = modelForHash ? modelForHash.version : null;
        newData.modid = modelForHash.id;
        newData.offline = modelForHash.offline;

        return newData;
    };


    const handleChange = (value) => {
        switch (method){
            case 'setDestinataire':
                setDestinataire(value);
                break;
            case 'setCc':
                setCc(value);
                break;
            case 'setCci':
                setCci(value);
                break;
        }
        setMethod('');
    }

    return (
        <Modal
            keepMounted={false}
            open={props.open}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div style={modalStyle} className={classes.popup}>
                <div className = "scrollbar-hidden popup_wrapper flexSpacebetween" >
                    <div className="popup_wrapper_content">
                        <div className="sous-header_modal_content">
                            <div>
                                <span className="titres">
                                        Titre
                                     <br/>
                                </span>
                            </div>
                            <div onClick={handleCloseModal}>
                                    <span id="icon-close" className="material-icons md-50 lightgrey">
                                    close
                                    </span>
                            </div>

                        </div>
                        <div className="sous-header_content lightgrey">
                            <div className="line flex_style">
                            </div>
                        </div>
                    </div>
                    <div className="popup_wrapper_content">

                        <div>
                            <ul className="inputWrapper">
                                <li>
                                    <Input
                                        id="standard-full-width"
                                        label=""
                                        placeholder="Email"
                                        fullWidth={true}
                                        margin="none"
                                        disableUnderline={true}
                                        endAdornment={
                                            <div style={{display : 'flex' , flexDirection : 'row'}}>
                                                    <span style={{cursor : "pointer" , color : 'black', marginRight : '5px'}} onClick={(event) => {setOpenRepertory(true); setMethod('setDestinataire')}}>
                                                        <span id="icon-listForm" className={`material-icons md-25 `}>
                                                            search
                                                        </span>
                                                    </span>
                                                    <span style={{cursor : "pointer" , color : 'black', marginRight : '5px'}} onClick={(event) => setViewCc(!viewCc)}>Cc </span>
                                                    <span style={{cursor : "pointer" , color : 'black', marginRight : '5px'}} onClick={(event) => setViewCci(!viewCci)}>Cci </span>
                                                {
                                                    destinataire.length > 0 &&
                                                    <span id="icon-close" className="material-icons md-25" style={{cursor : "pointer" , color : 'black'}} onClick={(event) => {setDestinataire("");
                                                    }}>
                                                        close
                                                    </span>
                                                }
                                            </div>
                                        }
                                        inputlabelprops={{
                                            className: classes.input,
                                        }}
                                        value={destinataire}
                                        className={classes.textField}
                                        onChange={(event) => setDestinataire(event.target.value)}
                                    />
                                    {/*<input className="input field input_font" type="text" placeholder="email" value={email} onChange={(event) => setEmail(event.target.value)}/>*/}
                                </li>
                                {
                                    viewCc &&
                                    <li>
                                        <Input
                                            id="standard-full-width"
                                            label=""
                                            placeholder="Cc"
                                            fullWidth={true}
                                            margin="none"
                                            disableUnderline={true}
                                            endAdornment={
                                                <div style={{display : 'flex' , flexDirection : 'row'}}>
                                                    <span style={{cursor : "pointer" , color : 'black', marginRight : '5px'}} onClick={(event) => {setOpenRepertory(true); setMethod('setCc')}}>
                                                        <span id="icon-listForm" className={`material-icons md-25 `}>
                                                            search
                                                        </span>
                                                    </span>
                                                    {
                                                        cc.length > 0 &&
                                                        <span id="icon-close" className="material-icons md-30"
                                                              style={{cursor: "pointer", color: 'black'}}
                                                              onClick={(event) => {
                                                                  setCc("");
                                                              }}>
                                                        close
                                                    </span>
                                                    }
                                                </div>
                                            }
                                            inputlabelprops={{
                                                className: classes.input,
                                            }}
                                            value={cc}
                                            className={classes.textField}
                                            onChange={(event) => setCc(event.target.value)}
                                        />
                                        {/*<input className="input field input_font" type="text" placeholder="email" value={email} onChange={(event) => setEmail(event.target.value)}/>*/}
                                    </li>
                                }

                                {
                                    viewCci &&
                                    <li>
                                        <Input
                                            id="standard-full-width"
                                            label=""
                                            placeholder="Cci"
                                            fullWidth={true}
                                            margin="none"
                                            disableUnderline={true}
                                            endAdornment={
                                                <div style={{display : 'flex' , flexDirection : 'row'}}>
                                                    <span style={{cursor : "pointer" , color : 'black', marginRight : '5px'}} onClick={(event) => {setOpenRepertory(true); setMethod('setCci')}}>
                                                        <span id="icon-listForm" className={`material-icons md-25 `}>
                                                            search
                                                        </span>
                                                    </span>
                                                    {
                                                cci.length > 0 &&
                                                <span id="icon-close" className="material-icons md-30" style={{cursor : "pointer" , color : 'black'}} onClick={(event) => {setCci("");
                                                }}>
                                                        close
                                                </span>
                                                    }
                                                </div>
                                            }
                                            inputlabelprops={{
                                                className: classes.input,
                                            }}
                                            value={cci}
                                            className={classes.textField}
                                            onChange={(event) => setCci(event.target.value)}
                                        />
                                        {/*<input className="input field input_font" type="text" placeholder="email" value={email} onChange={(event) => setEmail(event.target.value)}/>*/}
                                    </li>
                                }

                                <li>
                                    <span className="unbreakText unbreakText_width unbreakText_margin_left">
                                        <InputLabel shrink>Sujet</InputLabel>
                                    </span>
                                </li>

                                <li>
                                    <Input
                                        id="standard-full-width"
                                        label=""
                                        //placeholder={props.placeholder}
                                        fullWidth={true}
                                        margin="none"
                                        disableUnderline={true}
                                        inputlabelprops={{
                                            className: classes.input,
                                        }}
                                        className={classes.textField}
                                        onChange={(event) => setSujet(event.target.value)}
                                        value={sujet}
                                    />
                                </li>

                                <li>
                                    <span className="unbreakText unbreakText_width unbreakText_margin_left">
                                        <InputLabel shrink>Message</InputLabel>
                                    </span>
                                </li>

                                <li>
                                    <TextField
                                        id="outlined-multiline-static"
                                        //label="Commentaire d'annexe"
                                        multiline
                                        minRows={10}
                                        defaultValue={body}
                                        variant="outlined"
                                        fullWidth={true}
                                        inputlabelprops={{
                                            className: classes.input,
                                        }}
                                        //className={classes.textField}
                                        onChange={(event) => setBody(event.target.value)}
                                    />
                                </li>

                            </ul>
                        </div>

                    </div>

                    <div>
                        <button className="button" style={{width : '100%'}} onClick={handleSendMail} disabled={destinataire.length === 0 && body.length === 0 && sujet.length === 0}>{t('common:annexe.send-email')}</button>
                    </div>
                </div>
                <ModalSearchInRepertoriesComponent open={openRepertory} multiple={false} onChange={handleChange} close={handleCloseSubModal}/>
            </div>
        </Modal>
    )
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        attemptAnnexesSendMail : (sendMailData, attachments, userhash, modelhash, datahash, dataUserIndex ,formData, newData) => dispatch(postAnnexesSendMailRequest(sendMailData, attachments, userhash, modelhash, datahash, dataUserIndex , formData, newData)),
        attemptAnnexesSendMailModal : (sendMailData, attachments, userhash, modelhash, datahash, dataUserIndex ,formData, newData) => dispatch(postAnnexesSendMailModalRequest(sendMailData, attachments, userhash, modelhash, datahash, dataUserIndex , formData, newData)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalSendMailAnnexeComponent)
