import React, {useState} from "react"
//import styles from "./styles.module.less"
import {deleteHistoryPlugAction, setModel, setModelToUpdate} from "../../../utils/Common";
import Images from "../../../assets/themes/Images";
import * as moment from "moment";
import Container from "@material-ui/core/Container";
import {reduxGenerateAnnexeReset} from "../../../actions/annexe";
import {connect} from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import i18n from "i18next";


// handleModalModelShareOpen={handleModalModelShareOpen}
// handleModalModelShareClose={handleModalModelShareClose}
// handleModalModelPlugcodeOpen={handleModalModelPlugcodeOpen}
// handleModalModelPlugcodeClose={handleModalModelPlugcodeClose}



// ms_accepted: 1
// ms_active: 1
// ms_add_annexe: 0
// ms_add_fiche: 0
// ms_delete_annexe: 0
// ms_delete_fiche: 0
// ms_edit_annexe: 0
// ms_edit_fiche: 0
// ms_list_model_shared: null
// ms_read_annexe: 1
// ms_read_fiche: 1
// ms_share: 1

const FlipModelSharedComponent = (props) => {

    let value = props.value;

    const [isFlipped, changeFlip] = useState(false);
    const handleHoever = () => changeFlip(prevState => !prevState);

    const handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        changeFlip(prevState => !prevState);
    };

    /*    const handleClick = useCallback((event) => {
            event.preventDefault();
            event.stopPropagation();
            changeFlip(prevState => !prevState)
        })*/

    const handleUpdateModel = (event, model) => {
        event.preventDefault();
        event.stopPropagation();
        setModelToUpdate(model);

        props.history.push({
            pathname: '/update-model',
            state: { back: '/models' }
        });
    }

    const handleCreatePlugnote = (event, model) => {
        event.preventDefault();
        event.stopPropagation();
        props.attemptResetAnnexe();
        deleteHistoryPlugAction();
        setModel(model);
        props.history.push('/create-plugnote-shared');
    };

    const handleSubmitModelShare = (event, model) => {
        event.preventDefault();
        event.stopPropagation();
        props.handleSubmitModelShare(model)
    }

    const handleGeneratePlugFormCode = (event, model) => {
        event.preventDefault();
        event.stopPropagation();
        setModel(model);
        props.handleModalModelPlugcodeOpen();
    };

    const handleShareModel = (event, model) => {
        event.preventDefault();
        event.stopPropagation();
        setModel(model);
        props.handleModalModelShareOpen();
    };

    const handleDeleteModel = (event, model) => {
        event.preventDefault();
        event.stopPropagation();
        props.handleDeleteModel(model);
    };

    const getCodePFCode = (model) => {
        return model.codepfcode != null ? 'PF-' + moment(model.creationdate.date).format('YYMMDD') + '-' + model.codepfcode : ''
    }

    return (
        <div className="flexbox-item-shared">
            <div className="flexbox-item_img flexbox-item_img_parent">
                <img src={Images.logoIcon} alt="logo"/>
            </div>

            <div className="flexbox-item_texte">

                <div className="references green">
                    [{value.clef}]
                    <span className="p3 grey references_10">
                        {value.username}
                    </span>
                </div>

                <div className="titres_small_nowrap">
                    {value.label}
                </div>

                <div className="infos-supplementaires grey">

                    <div>
                                    <span className="references">
                                        {value.nbFichesNotArchived + value.nbFichesArchived}
                                    </span>
                        <span className="md-small">
                                    <img src ={Images.iconLogo} alt="plugnote" width="20"/>
                        </span>
                    </div>
                    <div>
                        {value.isPublic && (value.codepfcode != null && value.codepfcode !== "") ?
                            <Container>
                                        <span className="references">
                                            {
                                                getCodePFCode(value)
                                            }
                                        </span>
                                {value.pfcode_countopen > 0 &&
                                <span className="references"> ({value.pfcode_countopen}) </span>
                                }
                                <span className="material-icons md-small">
                                            qr_code
                                        </span>
                            </Container>
                            :
                            <span></span>
                        }
                    </div>
                    <div>
                        {
                            value.nbModelShared != 0 ?
                                <span className="material-icons md-small span_group_add">
                                        group_add
                                    </span>
                                :
                                <span></span>
                        }
                    </div>
                </div>
            </div>

            <div className="flexbox-item_button grey text_align">

                <Tooltip title={i18n.t('common:actions.delete-shared')} aria-label="add" placement="top-start">

                        <span className="material-icons md-25" onClick={(event) => { handleSubmitModelShare(event, value)
                        }}>
                            delete
                        </span>
                </Tooltip>

                {
                    value.ms_add_fiche > 0 &&
                        <span className="material-icons md-42 blue" onClick={(event) =>  handleCreatePlugnote(event, value)}>
                            add
                        </span>
                }

            </div>

        </div>
    );
};

// redux providing state takeover
const mapStateToProps = (state) => {
    return {
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        attemptResetAnnexe: () => dispatch(reduxGenerateAnnexeReset()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FlipModelSharedComponent)