import { useDrag, useDrop } from 'react-dnd';
import React, { useCallback } from "react";
import PropTypes from "prop-types";

function useCombinedRefs(...refs) { return useCallback(handle => { for (const ref of refs) { if (typeof ref === 'function') { ref(handle); } else if (ref !== null) { ref.current = handle; } } }, refs); }
const DraggableHeaderRenderer  = (props) => {

    const propTypes = {
        children: PropTypes.instanceOf(Object).isRequired,
    };

    const [{ isDragging }, drag] = useDrag({
        item: { key: props.column.key, type: 'COLUMN_DRAG' },
        collect: monitor => ({
            isDragging: !!monitor.isDragging()
        })
    });

    const [{ isOver }, drop] = useDrop({
        accept: 'COLUMN_DRAG',
        drop({ key, type }) {
            if (type === 'COLUMN_DRAG') {
                props.onColumnsReorder(key, props.column.key);
            }
        },
        collect: monitor => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop()
        })
    });

    let combineRef = useCombinedRefs(drag, drop);


    return (
        <div
            ref={combineRef}
            style={{
                opacity: isDragging ? 0.5 : 1,
                backgroundColor: isOver ? '#ececec' : 'inherit',
                cursor: 'move'
            }}
        >
            {props.children}
        </div>
    );
}

export default DraggableHeaderRenderer