import React, {useState} from 'react';
import {connect} from 'react-redux';

import {postNewPasswordRequest} from '../../actions/user';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useTranslation} from "react-i18next";

import {useForm} from 'react-hook-form'
import logo from '../../assets/images/logos/plugnotes_logo_color.png';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    logoContainer:{
        display: 'inline-block',
        float: 'left',
    },
    logo:{
        width: '35%',
    },
    formContainer: {
        padding: '20px',
        border: 'solid',
        borderRadius: '10px',
        margin: '20px',
        borderColor: 'darkgray',
        boxShadow: '5px 5px 5px grey',
    },
    notchedOutline : {
        borderColor: 'red',
    }
}));

function Finalize(props) {

    const { register, errors, handleSubmit, formState } = useForm({
        mode: "onChange"
    });

    const email = useFormInput(props.email);
    const codeSms = useFormInput('');
    const password = useFormInput('');
    const confirmPassword = useFormInput('');

    const [errorCodeSms, setErrorCodeSms] = React.useState(false);
    const [errorPassword, setErrorPassword] = React.useState(false);
    const [errorConfirmPassword, setErrorConfirmPassword] = React.useState(false);
    const [error, setError] = React.useState(false);


    // handle button click of login form
    const handleConfirm = () => {
        if(codeSms.value.length === 0) setErrorCodeSms(true); else setErrorCodeSms(false);
        if(password.value.length === 0) setErrorPassword(true); else setErrorPassword(false);
        if(confirmPassword.value.length === 0) setErrorConfirmPassword(true); else setErrorConfirmPassword(false);
        if(password.value !== confirmPassword.value) setError(true); else setError(false);

        if(!errorCodeSms && !error){
            //239553
            props.attemptConfirmPassword(email.value,codeSms.value,password.value);
        }
    };

    const handleLogin = () => {
        props.history.push('/');
    };

    const classes = useStyles();
    const { t, i18n } = useTranslation(['common', 'login']);

    return (
        <Grid>
            <div className="container_signin">
                <div className="logoSignin">
                    <img className="" src={logo} alt="Plugnotes" />
                </div>

                <div>

                    <div className="popup signin">
                        <div className="popup_wrapper flexSpacebetween">

                            <div className="popup_wrapper_content">
                                <div className="sous-header_content">
                                    <div>
                                        <span className="titres">
                                            { t('login.define-password')}
                                        </span>
                                    </div>
                                </div>
                                <div className="sous-header_content lightgrey">
                                    <div className="line flex_style">
                                    </div>
                                </div>
                            </div>
                            <div className="popup_wrapper_content">

                                <ul className="inputWrapper">
                                    <li>
                                        { props.email !== "" &&
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            disabled={true}
                                            name="email"
                                            label="Email"
                                            type="text"
                                            id="email"
                                            {...email}
                                            inputProps={{
                                                autoComplete: "off",
                                            }}
                                        />
                                        }
                                    </li>
                                    <li style={{display:"flex", flexDirection:"column"}}>
                                        <TextField
                                            error={errorCodeSms}
                                            helperText={errorCodeSms ? <span>codeSms is required</span> : ''}
                                            variant="outlined"
                                            required
                                            fullWidth
                                            name="codeSms"
                                            label={t('login.code-sms')}
                                            type="text"
                                            id="codeSms"
                                            {...codeSms}
                                            inputProps={{
                                                autoComplete: "off",
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <TextField
                                            error={errorPassword}
                                            variant="outlined"
                                            required
                                            fullWidth
                                            name="password"
                                            label={t('login.password')}
                                            type="password"
                                            id="password"
                                            {...password}
                                            inputProps={{
                                                autoComplete: "new-password",
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <TextField
                                            error={errorConfirmPassword || error}
                                            helperText={errorConfirmPassword || error ? <span>Password is required and equal</span> : ''}
                                            variant="outlined"
                                            required
                                            fullWidth
                                            name="confirmPassword"
                                            label={t('login.confirm-password')}
                                            type="password"
                                            id="confirmPassword"
                                            {...confirmPassword}
                                            inputProps={{
                                                autoComplete: "confirm-password",
                                            }}
                                        />
                                    </li>
                                    <li onClick={handleLogin}><span className="cliquables grey margin-left-auto"> {t('login.already-account')}</span></li>

                                </ul>

                            </div>
                            <div className="popup_wrapper_content align-items-center">

                                <div className="confirmation_pop-up">
                                    <button className="button_signin"
                                            onClick={handleConfirm}
                                            type="submit"
                                            disabled={props.fetching}
                                    >{props.fetching ? t('global.loading') :  t('login.define-my-password')}</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </Grid>

    );

}

const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);

    const handleChange = e => {
        setValue(e.target.value);
    }
    return {
        value,
        onChange: handleChange
    }
}


// redux providing state takeover
const mapStateToProps = (state, props) => {

    let email = "";
    if(typeof props.location.state !== "undefined"){
        if(typeof props.location.state.email !== "undefined"){
            email = props.location.state.email;
        }
    }

    return {
        email
    }
}

const mapDispatchToProps = (dispatch) => ({
    attemptConfirmPassword: (email, code, password) => dispatch(postNewPasswordRequest(email, code, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Finalize)