import React, {useEffect, useRef, useState} from "react";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Immutable from "seamless-immutable";
import _, {isString} from "lodash";
import moment from "moment";
import {getCurrentEnvironnement, getPlugnote, getToken,} from "../../utils/Common";

import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {toast} from "react-toastify";
import {postCreateDataRequest, putUpdateDataRequest,} from "../../actions/data";
import {saveAs} from "file-saver";
import ApplicationConfig from "../../config/ApplicationConfig";
import {trackPromise} from "react-promise-tracker";
import axios from "axios";
import {
  delDeleteAnnexeRequest,
  postAddNoteRequest,
  postAnnexesRequest,
  putEditNoteRequest,
} from "../../actions/annexe";
import {isJsonString, isJsonStringObject} from "../../utils/utils";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faFile,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faFileVideo,
  faFileWord,
  faStickyNote,
} from "@fortawesome/free-regular-svg-icons";
import {postPlugcodeGalleryRequest} from "../../actions/gallery";
import {postCreatePFCodePlugnotePublicRequest,} from "../../actions/external";
import {
  getPlugformViaCodeOfflineRequest,
  getPlugformViaCodeOfflineReset,
  getPlugnoteViaCodeOfflineRequest,
  getPlugnoteViaCodeOfflineReset,
} from "../../actions/plugcode";
import {confirmAlert} from "react-confirm-alert";
import MenuOfflineComponent from "../../components/menu/MenuOfflineComponent";
import Plugforms from "../../components/plugforms/Plugforms";
import ModalNoteComponent from "../../components/modals/ModalNoteComponent";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paperAnchorDockedLeft: {
    boxShadow: "0px 0px 6px 4px rgba(136, 136, 136, 0.12)",
    border: 0,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  fieldImageSvg: {
    width: 25,
    height: 25,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: "57px",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  openClose: {
    bottom: 0,
    position: "absolute",
    width: "100%",
  },
  openCloseButton: {
    float: "right",
  },
  content: {
    flexGrow: 1,
    padding: 0,
  },
  contentModel: {
    flexGrow: 1,
    padding: theme.spacing(2),
    overflow: "scroll",
    maxHeight: "calc(100vh - 100px)",
    //maxHeight: 'calc(100vh - (calc(100vh - 100%)) - 100px) '
  },
  icon: {
    width: 56,
    height: 56,
    lineHeight: "56px",
    textAlign: "center",
    color: "black",
  },
  iconActive: {
    width: 56,
    height: 56,
    lineHeight: "56px",
    textAlign: "center",
    color: "blue",
  },
  gridHeader: {
    padding: "10px 20px !important",
    width: "100%",
  },
  selectEnvironnement: {},
  fieldImage: {
    height: 10,
    width: 10,
  },
  fieldIconMandatory: {
    width: 20,
    height: 20,
    backgroundColor: "#57bbb8",
    borderRadius: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid red",
  },
  fieldIcon: {
    width: 20,
    height: 20,
    backgroundColor: "#57bbb8",
    borderRadius: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
  field: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  fieldRight: {
    width: "100%",
  },
  fieldLeft: {
    alignContent: "flex-start",
    display: "flex",
    alignItems: "center",
  },
  fieldRightLeft: {
    alignContent: "flex-end",
    //textAlign: 'center'
  },
  fullWidth: {
    width: "100%",
  },

  faIconSize: {
    fontSize: 20,
    color: "#51C3C1",
  },
  faIconView: {
    fontSize: 200,
    color: "#51C3C1",
  },
  modalFile: {
    border: "none",
    width: "100%",
    height: "100%",
  },
  confirmContainer: {
    border: "1px solid #F9F9F9",
    backgroundColor: "white",
    borderRadius: 15,
    padding: "15px",
  },
  confirmTitle: {
    color: "black",
    fontSize: "30px",
  },
  confirmText: {
    color: "#88949C",
  },
  confirmButtons: {
    marginTop: "30px",
    display: "flex",
    justifyContent: "space-between",
  },
  confirmButtonStyle: {
    width: "100px",
    padding: "4px 20px",
    borderRadius: "3px",
    cursor: "pointer",
    "@media (max-width: 1024px)": {
      /* width: "100%", */
    },
  },
  confirmAffirmative: {
    color: "white",
    backgroundColor: "#58c3c1",
    border: "none",
  },
  confirmNegative: {
    color: "#E3E3E3",
    backgroundColor: "transparent",
    border: "2px solid #E3E3E3",
    fontWeight: "600",
  },
  notRender: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 15,
  },
  notRenderText: {
    fontWeight: "bold",
    color: "#88949C",
  },
}));

const specialFields = [
  "plugform",
  "textarea",
  "list",
  "single-select",
  "free-single-select",
  "multi-select",
  "free-multi-select",
  "single-table",
  "free-single-table",
  "multi-table",
  "free-multi-table",
  "qrcode",
  "localis",
  "glocalis",
];
const zeroPad = (num, places) => String(num).padStart(places, "0");

function PlugExternalCreateFromCodeV2(props) {
  const [fields, setFields] = useState([]);
  const { code, folder, fiche } = props.match.params;
  // Since `line` is an optional parameter, it'll either be undefined or a string.

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [cancel, setCancel] = React.useState(false);

  const [viewInputSearchCode, setViewInputSearchCode] = useState(true);
  const [searchPlugcode, setSearchPlugcode] = useState(
      code.length > 0 ? code : ""
  );
  const [searchPlugcodeFiche, setSearchPlugcodeFiche] = useState(
      fiche.length > 0 ? fiche : ""
  );
  const [searchPlugcodeFolder, setSearchPlugcodeFolder] = useState(
      folder.length > 0 ? folder : ""
  );
  const [searchActive, setSearchActive] = useState(
      props.isSearchActive && typeof props.searchParams.content !== "undefined"
  );
  const [activeSearch, setActiveSearch] = useState(code.length > 0);

  const [reset, setReset] = useState(true);

  const [openModal, setOpenModal] = React.useState(false);
  const [openSave, setOpenSave] = React.useState(false);
  const [openNote, setOpenNote] = React.useState(false);
  const [isOpenFloatingMenu, setIsOpenFloatingMenu] = React.useState(false);

  const plugnote = props.plugnote;
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [selectedNote, setSelectedNote] = useState(null);
  const [openClassify, setOpenClassify] = useState(false);

  const [dataHasChanged, setDataHasChanged] = React.useState(false);

  const [createDateWithAnnexe, setCreateDateWithAnnexe] = React.useState(false);

  const [loading, setLoading] = useState(true);
  const [internalFetching, setInternalFetching] = useState(false);
  const [loaded, setLoaded] = useState(0);

  const [isOpenModalAnnexe, setIsOpenModalAnnexe] = React.useState(false);
  const [selectedAnnexe, setSelectedAnnexe] = useState(null);

  const [model, setModel] = useState(null);
  const [modelLinked, setModelLinked] = useState(null);
  const [modelForHash, setModelForHash] = useState(null);
  const [dataLinked, setDataLinked] = useState(null);
  const [dataForHash, setDataForHash] = useState(null);

  const [copy, setCopy] = useState(true);
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);

  const [isOpenModalListAnnexesVersion, setIsOpenModalListAnnexesVersion] =
      React.useState(false);

  const [isOpenModalListAnnexes, setIsOpenModalListAnnexes] =
      React.useState(false);

  const [plugnoteAnnexes, setPlugnotesAnnexes] = useState(
      typeof props.annexes !== "undefined" && props.annexes.length > 0
          ? props.annexes
          : []
  );

  const [openModalPreview, setOpenModalPreview] = React.useState(false);
  const [selectedPreviewAnnexe, setSelectedPreviewAnnexe] = useState(null);
  const [images, setImages] = useState([null]);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setIsMobile(window.innerWidth <= 900);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const inputFile = useRef(null);

  const environment = getCurrentEnvironnement();

  const handleModalAnnexeClose = () => {
    setSelectedAnnexe(null);
    setIsOpenModalAnnexe(false);
  };

  const handleModalAnnexeOpen = (annexe) => {
    setSelectedAnnexe(annexe);
    setIsOpenModalAnnexe(true);
  };

  const handleSaveFictifComment = (annexe, comment) => {
    let annexes = plugnoteAnnexes;
    annexes.map((item, index) => {
      if (item.id === annexe.id) {
        item.comment = comment;
      }

      return item;
    });
    setPlugnotesAnnexes(annexes);
    setDataHasChanged(true);
  };

  const handlePreviewAnnexe = (event, hash) => {
    event.preventDefault();
    event.stopPropagation();
    const images = [];
    plugnoteAnnexes.map((annexeToPreview, index) => {
      switch (annexeToPreview.type.toLowerCase()) {
        case "jpg":
        case "JPG":
        case "jpeg":
        case "png":
        case "PNG":
        case "gif":
          let url = getUrlPreviewAnnexe(annexeToPreview);
          images.push(url);
          if (hash === annexeToPreview.hash) {
            setSelectedPreviewAnnexe(images.length - 1);
          }
          break;
      }
    });

    setImages(images);
    setOpenModalPreview(true);
  };

  const handlePreviewPdfAnnexe = (event, url) => {
    event.preventDefault();
    event.stopPropagation();
    window.open(url, "_blank");
    //setOpenPdfPreview(true);
    //setSelectedPreviewPdfAnnexe(url);
  };

  const handleDeleteAnnexeSharedExternal = (annexe) => {
    let annexes = plugnoteAnnexes;
    let filteredAnnexes = annexes.filter((item, index) => {
      return item.id !== annexe.id;
    });
    filteredAnnexes.filter(Boolean);
    setPlugnotesAnnexes(filteredAnnexes);
    setDataHasChanged(true);
  };

  const handleDeleteAnnexe = (event, annexe, previousFile) => {
    event.preventDefault();
    event.stopPropagation();

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
            <div className={classes.confirmContainer}>
              <h1 className={classes.confirmTitle}>{t("global.confirm")}</h1>
              <p className={classes.confirmText}>
                {t("plugnote.confirm-delete-annexe")}
              </p>
              <div className={classes.confirmButtons}>
                <button
                    className={`${classes.confirmButtonStyle} ${classes.confirmNegative}`}
                    onClick={onClose}
                >
                  {t("global.cancel")}
                </button>
                <button
                    className={`${classes.confirmButtonStyle} ${classes.confirmAffirmative}`}
                    onClick={() => {
                      //props.attemptDeleteAnnexe(annexe.hash, environment.userhash);
                      handleDeleteAnnexeSharedExternal(annexe);
                      if(typeof previousFile !== "undefined" && previousFile !== null){
                        previousFile();
                      }
                      onClose();
                    }}
                >
                  {t("global.yes")}
                </button>
              </div>
            </div>
        );
      },
    });
  };

  const maxSelectFileDrop = (files) => {
    if (files.length > 1) {
      const msg = "Only 1 images can be uploaded at a time";
      toast.warn(msg);
      return false;
    }
    return true;
  };

  const checkFileSizeDrop = (files) => {
    let size = 100000000;
    let err = [];
    for (var x = 0; x < files.length; x++) {
      if (files[x].size > size) {
        err[x] = files[x].type + "is too large, please pick a smaller file\n";
      }
    }
    for (var z = 0; z < err.length; z++) {
      // if message not same old that mean has error
      // discard selected file
      toast.error(err[z]);
    }
    return true;
  };

  const onChangeHandlerDrop = (acceptedFiles) => {
    const files = acceptedFiles;

    if (maxSelectFileDrop(files) && checkFileSizeDrop(files)) {
      setLoaded(0);
      setSelectedFiles(files);
      setCreateDateWithAnnexe(true);
    }
  };

  const renderAnnexeIconPreview = (annexeToPreview) => {
    if (annexeToPreview !== null) {
      switch (annexeToPreview.type.toLowerCase()) {
        case "jpg":
        case "JPG":
        case "jpeg":
        case "png":
        case "PNG":
        case "gif":
          /* return <img src={getUrlPreviewAnnexe(annexeToPreview)} alt={annexeToPreview.name} onClick={(event) => handlePreviewAnnexe(event,annexeToPreview.hash)} className={classes.pointer}/>; */
          return (
              <FontAwesomeIcon
                  icon={faFileImage}
                  className={classes.faIconSize}
              />
          );
          break;

        case "mp4":
        case "mkv":
        case "avi":
        case "mov":
          return (
              <FontAwesomeIcon
                  icon={faFileVideo}
                  className={classes.faIconSize}
              />
          );
          break;

        case "pdf":
          return (
              <FontAwesomeIcon
                  icon={faFilePdf}
                  className={classes.faIconSize}
                  onClick={(event) =>
                      handlePreviewPdfAnnexe(
                          event,
                          getUrlPreviewAnnexe(annexeToPreview)
                      )
                  }
              />
          );
          break;

        case "zip":
        case "rar":
          return (
              <FontAwesomeIcon
                  icon={faFileArchive}
                  className={classes.faIconSize}
              />
          );
          break;

        case "mp3":
        case "aac":
          return (
              <FontAwesomeIcon
                  icon={faFileAudio}
                  className={classes.faIconSize}
              />
          );
          break;

        case "html":
          return (
              <FontAwesomeIcon icon={faFileCode} className={classes.faIconSize} />
          );
          break;

        case "txt":
        case "sheet":
        case "doc":
        case "docx":
          return (
              <FontAwesomeIcon icon={faFileWord} className={classes.faIconSize} />
          );
          break;

        case "xls":
        case "xlsx":
        case "csv":
          return (
              <FontAwesomeIcon
                  icon={faFileExcel}
                  className={classes.faIconSize}
              />
          );
          break;

        case "note":
          return (
              <FontAwesomeIcon
                  icon={faStickyNote}
                  className={classes.faIconSize}
              />
          );
          break;

        case "pages":
          return (
              <FontAwesomeIcon icon={faFileWord} className={classes.faIconSize} />
          );
          break;

        case "pptx":
        case "ppt":
          return (
              <FontAwesomeIcon
                  icon={faFilePowerpoint}
                  className={classes.faIconSize}
              />
          );
          break;
        default:
          return (
              <FontAwesomeIcon icon={faFile} className={classes.faIconSize} />
          );
          break;
      }
    }
  };

  const renderAnnexeImagePreview = (annexeToPreview) => {
    switch (annexeToPreview.type.toLowerCase()) {
      case "jpg":
      case "JPG":
      case "jpeg":
      case "png":
      case "PNG":
      case "gif":
        /* case 'jfif': */
        return (
            <img
                src={annexeToPreview.path}
                alt={annexeToPreview.name}
                onClick={(event) =>
                    handlePreviewAnnexe(event, annexeToPreview.hash)
                } /* className={classes.modalFile} */
            />
        );
        break;

      case "mp4":
      case "mkv":
      case "avi":
      case "mov":
        return (
            <video className={classes.modalFile} controls>
              <source
                  src={annexeToPreview.path}
                  type={`video/${annexeToPreview.type.toLowerCase()}`}
              />
            </video>
        );
        break;

      case "pdf":
        return (
            <iframe src={annexeToPreview.path} className={classes.modalFile} />
        );
        break;

      case "zip":
      case "rar":
        return (
            <div className={classes.notRender}>
              <FontAwesomeIcon
                  icon={faFileArchive}
                  className={classes.faIconView}
              />
              <p class={classes.notRenderText}>
                {t("multiple.file-not-previewed")}
              </p>
            </div>
        );
        break;

      case "mp3":
      case "aac":
        return (
            <audio className={classes.modalFile} controls>
              <source
                  src={annexeToPreview.path}
                  type={`audio/${annexeToPreview.type.toLowerCase()}`}
              />
            </audio>
        );
        break;

      case "html":
        return (
            <iframe src={annexeToPreview.path} className={classes.modalFile} />
        );
        break;

      case "txt":
        return (
            <iframe
                src={annexeToPreview.path}
                className={classes.modalFile}
                onClick={(event) =>
                    handlePreviewPdfAnnexe(
                        event,
                        getUrlPreviewAnnexe(annexeToPreview)
                    )
                }
            />
        );
        break;

      case "sheet":
      case "doc":
      case "docx":
        /* return <iframe src={`https://docs.google.com/gview?url=${getUrlPreviewAnnexe(annexeToPreview)}&embedded=true`} className={classes.modalFile} onClick={(event) => handlePreviewPdfAnnexe(event,getUrlPreviewAnnexe(annexeToPreview))} />; */
        return (
            <iframe
                src={`https://view.officeapps.live.com/op/embed.aspx?src=${annexeToPreview.path}`}
                className={classes.modalFile}
            />
        );
        break;

      case "xls":
      case "xlsx":
      case "csv":
        return (
            <iframe
                src={`https://view.officeapps.live.com/op/embed.aspx?src=${annexeToPreview.path}`}
                className={classes.modalFile}
            />
        );
        break;

      case "note":
        return (
            <div className={classes.notRender}>
              <FontAwesomeIcon
                  icon={faStickyNote}
                  className={classes.faIconSize}
              />
              <p class={classes.notRenderText}>
                {t("multiple.file-not-previewed")}
              </p>
            </div>
        );
        break;

      case "pages":
        return (
            <div className={classes.notRender}>
              <FontAwesomeIcon icon={faFileWord} className={classes.faIconSize} />
              <p class={classes.notRenderText}>
                {t("multiple.file-not-previewed")}
              </p>
            </div>
        );
        break;

      case "pptx":
      case "ppt":
        return (
            <iframe
                src={`https://view.officeapps.live.com/op/embed.aspx?src=${annexeToPreview.path}`}
                className={classes.modalFile}
            />
        );
        break;
      default:
        return (
            <div className={classes.notRender}>
              <FontAwesomeIcon icon={faFile} className={classes.faIconView} />
              <p class={classes.notRenderText}>
                {t("multiple.file-not-previewed")}
              </p>
            </div>
        );
        break;
    }
  };

  /**
   * Quand l'utilisateur veut éditer un champ
   * @param value
   * @param field
   * @param index
   * @private
   */

  const handleEditValue = (value, field, index) => {
    let listFields = fields;
    listFields[index].value = value;
    setFields([...listFields]);
    setDataHasChanged(true);
  };

  const handleSaveNote = (title, content) => {
    setSelectedNote(null);
    let annexes = plugnoteAnnexes;
    let newId =
        typeof annexes[annexes.length - 1] === "undefined"
            ? 1
            : annexes[annexes.length - 1].id + 1;
    annexes.push({
      id: newId,
      hash: undefined,
      type: "note",
      name: title,
      title: title,
      comment: "",
      Webdoc: {
        hash: undefined,
        content: content,
      },
    });
    setPlugnotesAnnexes(annexes);
    setDataHasChanged(true);
  };

  const handleUpdateNote = (title, content, annexehash, notehash) => {
    setSelectedNote(null);
    let annexes = plugnoteAnnexes;
    annexes.map((annexe, index) => {
      if (annexe.id === selectedNote.id && annexe.type === "note") {
        annexe.name = title;
        annexe.title = title;
        annexe.Webdoc.content = content;
      }
    });
    setPlugnotesAnnexes(annexes);
    setDataHasChanged(true);
  };

  const handleEditPhoneValue = (value, field, index) => {
    let listFields = fields;
    listFields[index].value = value;
    setFields([...listFields]);
    setDataHasChanged(true);
  };

  const handleEditDateTime = (date, value, field, index) => {
    let listFields = fields;
    listFields[index].value = value;

    listFields[index].visuelValue = date;

    setFields([...listFields]);
    setDataHasChanged(true);
  };

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  const handleClickCancelModal = () => {
    props.history.push("/pluglist");
    setOpenModal(false);
  };

  const handleClickContinueModal = () => {
    setOpenModal(false);
  };

  const _handleOpenModalListAnnexes = () => {
    setIsOpenModalListAnnexes(true);
  };

  const _handleCloseModalListAnnexes = () => {
    setIsOpenModalListAnnexes(false);
  };

  const handleModalListAnnexesVersionOpen = (event, annexehash) => {
    event.preventDefault();
    event.stopPropagation();
    props.attemptGetAnnexesVersion(annexehash);
    setIsOpenModalListAnnexesVersion(true);
  };
  /* const [fields, setFields] = useState([]);  */

  const [nameModel, setNameModel] = useState("");

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleNoteOpen = (event,annexe) => {
    event.preventDefault();
    event.stopPropagation();
    if(typeof annexe !== "undefined"){
      setSelectedNote(annexe);
    }else{
      setSelectedNote(null);
    }

    setOpenNote(true);

  };

  const handleNoteClose = () => {
    setOpenNote(false);
  };

  const handleCloseClassify = () => {
    setOpenClassify(false);
  };

  const handleExitCreatePlugnote = () => {
    props.history.push("/plugcodes");
  };

  const checkData = (fields) => {
    return fields.some((field) => {
      if (field.format !== null && typeof field.format !== "undefined") {
        if (!!field.mandatory && field.mandatory === 1) {
          if (field.format.includes("select")) {
            return field.value.length === 0;
          } else if (field.format === "list") {
            return (
                (!field.value ||
                    field.value === "[]" ||
                    field.value.length === 0) &&
                field.format != "indice"
            );
          } else if (field.format === "boolean") {
            return field.value === "";
          } else {
            return !field.value && field.format != "indice";
          }
        }
      }
    });
  };

  /**
   * Construction de la nouvelle fiche à partir des champs
   * @param fields
   */
  const buildNewAnnexe = (fields) => {
    let newData = {};

    const modelForHash = props.plugform;

    fields.map((field) => {
      if (
          field.format === "list" ||
          field.format === "multi-select" ||
          field.format === "free-multi-select" ||
          field.format === "single-select" ||
          field.format === "free-single-select" ||
          field.format === "multi-table" ||
          field.format === "free-multi-table" ||
          field.format === "single-table" ||
          field.format === "free-single-table"
      ) {
        if (field.value.length === 0) {
          newData["field" + field.id] = "";
        } else if (Array.isArray(field.value)) {
          newData["field" + field.id] = JSON.stringify(field.value);
        } else {
          newData["field" + field.id] = JSON.stringify([field.value]);
        }
      } else {
        newData["field" + field.id] = field.value;
      }

      if (
          field.format === "multi-select" ||
          field.format === "free-multi-select" ||
          field.format === "single-select" ||
          field.format === "free-single-select"
      ) {
        newData["field" + field.id + "format"] = "select";
      } else if (
          field.format === "multi-table" ||
          field.format === "free-multi-table" ||
          field.format === "single-table" ||
          field.format === "free-single-table"
      ) {
        newData["field" + field.id + "format"] = "table";
      } else {
        newData["field" + field.id + "format"] = field.format;
      }

      if (modelForHash && field.format === "indice") {
        let editable = JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.editable || false;
        if(!editable && field.value === "" || (editable && field.value === "")){
          newData["field" + field.id] = (JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.racine || "") +  (JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.indice || 0);
        }
      }

      if (
          (field.format && field.format === "agenda") ||
          (field.format && field.format === "birthday")
      ) {
        newData["field" + field.id + "options"] = field.event;
      }

      newData["field" + field.id + "label"] = field.title;
    });
    newData.Annexes = [];
    newData.lastversion_model = modelForHash ? modelForHash.version : null;
    newData.modid = modelForHash.id;
    newData.offline = modelForHash.offline;

    return newData;
  };

  /**
   * Construction de la mise à jour de la fiche à partir des champs
   * @param fields
   */
  const buildUpdateAnnexe = (fields) => {
    let newData = getPlugnote();

    const modelForHash = props.plugform;

    fields.map((field) => {
      if (
          field.format === "multi-select" ||
          field.format === "free-multi-select" ||
          field.format === "single-select" ||
          field.format === "free-single-select"
      ) {
        if (field.value.length === 0) {
          newData["field" + field.id] = "";
        } else if (Array.isArray(field.value)) {
          newData["field" + field.id] = JSON.stringify(field.value);
        } else {
          newData["field" + field.id] = JSON.stringify([field.value]);
        }
      } else if (
          field.format === "multi-table" ||
          field.format === "free-multi-table" ||
          field.format === "single-tabletable" ||
          field.format === "free-single-table"
      ) {
        if (field.value.length === 0) {
          newData["field" + field.id] = "";
        } else if (Array.isArray(field.value)) {
          newData["field" + field.id] = JSON.stringify(field.value);
        } else {
          newData["field" + field.id] = JSON.stringify([field.value]);
        }
      } else if (field.format === "plugform") {
        if (field.value.length === 0) {
          newData["field" + field.id] = "";
        } else {
          newData["field" + field.id] = JSON.stringify(field.value);
        }
      }
      else if (field.format === "qrcodeint") {
        if (field.value.length === 0) {
          newData["field" + field.id] = JSON.stringify({"code" : "", "model" : "", "data" : "", "comment" : ""});
        } else {
          newData["field" + field.id] = JSON.stringify(field.value);
        }
      }else if (field.format === "intdatamaj") {
        if (field.value.length === 0) {
          newData["field" + field.id] = JSON.stringify({"value" : "", "dataUpdated" : ""});
        } else {
          newData["field" + field.id] = JSON.stringify(field.value);
        }
      }
      else if (field.format === "list") {
        if (field.value.length === 0) {
          newData["field" + field.id] = "";
        } else if (Array.isArray(field.value)) {
          let correctValue = [];
          field.value.map((item, index) => {
            correctValue.push({ text: item });
          });
          newData["field" + field.id] = JSON.stringify(correctValue);
        } else {
          let transformValue = [field.value];
          let correctValue = [];
          transformValue.map((item, index) => {
            correctValue.push({ text: item });
          });
          newData["field" + field.id] = correctValue;
        }
      } else {
        newData["field" + field.id] = field.value;
      }

      if (
          field.format === "multi-select" ||
          field.format === "free-multi-select" ||
          field.format === "single-select" ||
          field.format === "free-single-select"
      ) {
        newData["field" + field.id + "format"] = "select";
      } else if (
          field.format === "multi-table" ||
          field.format === "free-multi-table" ||
          field.format === "single-table" ||
          field.format === "free-single-table"
      ) {
        newData["field" + field.id + "format"] = "table";
      } else {
        newData["field" + field.id + "format"] = field.format;
      }

      if (modelForHash && field.format === "indice") {
        let editable = JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.editable || false;
        if(!editable && field.value === "" || (editable && field.value === "")){
          newData["field" + field.id] = (JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.racine || "") +  (JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.indice || 0);
        }
      }

      if (
          (field.format && field.format === "agenda") ||
          (field.format && field.format === "birthday")
      ) {
        newData["field" + field.id + "options"] = field.event;
      }

      newData["field" + field.id + "label"] = field.title;
    });
    newData.Annexes = [];
    newData.lastversion_model = modelForHash ? modelForHash.version : null;
    newData.modid = modelForHash.id;
    newData.offline = modelForHash.offline;

    return newData;
  };

  const handleClickAlertWithCancel = () => {
    setCancel(true);
    setOpenModal(true);
  };

  const handleClickAlertWithoutCancel = () => {
    setCancel(false);
    setOpenModal(true);
  };

  const handleSaveCreatePlugnoteConfirm = () => {
    setOpenSave(false);

    if (dataHasChanged) {
      if (checkData(fields)) {
        if (selectedFiles !== null) {
          handleClickAlertWithCancel();
        } else {
          handleClickAlertWithoutCancel();
        }
      } else {
        const modelForHash = props.plugform;

        let dataForHash = buildNewAnnexe(fields);

        let payload = {
          dataForHash,
          email: email.replace(/\s/g, ""),
          copy: copy,
          plugname: props.plugform.label,
          plugkey: props.plugform.clef,
        };

        props.attemptCreatePFCodePlugnote(
            payload,
            plugnoteAnnexes,
            props.plugform,
            null
        );
      }
    } else {
      if (checkData(fields)) {
        if (selectedFiles !== null) {
          handleClickAlertWithCancel();
        } else {
          handleClickAlertWithoutCancel();
        }
      } else {
        toast.warn("Aucune donnée ajoutée");
      }
    }
  };

  const handleChangeEmail = (value) => {
    setEmail(value);
    if (isEmail(value)) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  };

  const handleChangeSendCopy = () => {
    setCopy(!copy);
  };

  /**
   * Ajout d'une vérif pour le type 'email'
   */
  const isEmail = (value) => {
    return /(.)+@(.)+/.test(value);
  };

  const handleSaveCreatePlugnote = () => {
    setOpenSave(true);
  };

  const handleCloseSave = () => {
    setOpenSave(false);
  };

  const handleChangeExtData = async (destinations) => {
    let listFields = [...fields];

    if (destinations.length > 0) {
      let updatedListFields =  listFields.map((field, index) => {
        if(!!field.id){
          if(typeof destinations.find((elm) => Number(field.id) === Number(elm.dest)) !== "undefined"){
            let destination = destinations.find((elm) => Number(field.id) === Number(elm.dest));
            return {...field, value : destination.value, visuelValue :  destination.value}
          }
        }
        return field;
      });

      setFields(updatedListFields);
      setDataHasChanged(true);
    }

  }

  const handleChangeTableValue = (value, field, index) => {
    let listFields = fields;
    listFields[index].value = value;
    setFields([...listFields]);
    setDataHasChanged(true);
  }

  const handleChangeTableRowsValue = (value, rows, field, index) => {
    let listFields = fields;
    listFields[index].value.value = value;
    listFields[index].value.list = rows;
    setFields([...listFields]);
    setDataHasChanged(true);
  }


  const handleChangeRadio = (value, indexation, field, index, multiselect, mandatory) => {
    let listFields = fields;

    if (mandatory) {
      if (listFields[index].value.includes(value)) {
        listFields[index].value = listFields[index].value.filter(
            (item) => item !== value
        );
      } else {
        if (!multiselect) {
          listFields[index].value = [];
          listFields[index].value.push(value);
        } else {
          listFields[index].value.push(value);
        }
      }
    } else {
      if (listFields[index].value.includes(value)) {
        listFields[index].value = listFields[index].value.filter(
            (item) => item !== value
        );
      } else {
        if (!multiselect) {
          listFields[index].value = [];
          listFields[index].value.push(value);
        } else {
          listFields[index].value.push(value);
        }
      }
    }
    setFields([...listFields]);
    setDataHasChanged(true);
  };

  const includesInJson = (search, value) => {
    return search.findIndex((item, index) => {
      return item.text === value;
    });
  };

  const handleChangeRadioTable = (
      value,
      indexation,
      field,
      index,
      multiselect,
      mandatory
  ) => {
    let listFields = fields;

    if (mandatory) {
      if (!multiselect) {
        listFields[index].value = [];
        listFields[index].value.push({ text: value, value: 1 });
      } else {
        listFields[index].value.push({ text: value, value: 1 });
      }
    } else {
      if (includesInJson(listFields[index].value, value) !== -1) {
        listFields[index].value = listFields[index].value.filter(
            (item) => item.text !== value
        );
      } else {
        if (!multiselect) {
          listFields[index].value = [];
          listFields[index].value.push({ text: value, value: 1 });
        } else {
          listFields[index].value.push({ text: value, value: 1 });
        }
      }
    }

    setFields([...listFields]);
    setDataHasChanged(true);
  };

  const handleChangeInputTable = (value, indexation, field, index, multiselect, mandatory) => {
    let listFields = fields;

    if (mandatory) {
      if (!multiselect) {
        listFields[index].value = [];
        listFields[index].value.push({ text: value.text, value: value.value });
      } else {
        if (includesInJson(listFields[index].value, value.text) === -1) {
          listFields[index].value.push({
            text: value.text,
            value: value.value,
          });
        } else {
          listFields[index].value[
              includesInJson(listFields[index].value, value.text)
              ].value = value.value;
        }
      }
    } else {
      if (!multiselect) {
        listFields[index].value = [];
        listFields[index].value.push({ text: value.text, value: value.value });
      } else {
        if (includesInJson(listFields[index].value, value.text) === -1) {
          listFields[index].value.push({
            text: value.text,
            value: value.value,
          });
        } else {
          listFields[index].value[
              includesInJson(listFields[index].value, value.text)
              ].value = value.value;
        }
      }
    }

    setFields([...listFields]);
    setDataHasChanged(true);
  };

  const handleUpdateInputTable = (
      value,
      indexation,
      field,
      index,
      multiselect,
      mandatory
  ) => {
    let listFields = fields;
    let tmpValue = listFields[index].value;

    listFields[index].value = tmpValue.filter((item) =>
        value.includes(item.text)
    );

    setFields([...listFields]);
    setDataHasChanged(true);
  };

  const handleDeleteInputTable = (value, indexation, field, index, multiselect, mandatory) => {
    let listFields = fields;
    let tmpValue = listFields[index].value;

    listFields[index].value = tmpValue.filter((item) => {
      return !item.text.includes(value)
    });

    setFields([...listFields]);
    setDataHasChanged(true);
  };

  const handleChangeKeyword = (value, indexation, field, index) => {
    let listFields = fields;
    if (listFields[index].value.includes(value)) {
      listFields[index].value = listFields[index].value.filter(
          (item) => item !== value
      );
    } else {
      listFields[index].value.push(value);
    }

    setFields([...listFields]);
    setDataHasChanged(true);
  };

  const handleChangeBoolean = (value, field, index) => {
    let listFields = fields;
    listFields[index].value = value;
    setFields([...listFields]);
    setDataHasChanged(true);
  };

  const handleChangePlugcode = (plugcode, comment, field, index) => {
    let listFields = fields;
    listFields[index].value = plugcode + ";" + comment;
    setFields([...listFields]);
    setDataHasChanged(true);
  };

  const handleChangePlugcodeInternal = (plugcode, comment, field, index, destinations) => {
    let listFields = [...fields];

    listFields[index].value = plugcode;

    if (destinations?.length > 0) {
      let updatedListFields =  listFields.map((field, index) => {
        if(!!field.id){
          if(typeof destinations.find((elm) => Number(field.id) === Number(elm.dest)) !== "undefined"){
            let destination = destinations.find((elm) => Number(field.id) === Number(elm.dest));
            switch (field.format) {
              case 'numeric':
                let tmpValue = destination.value.replaceAll(",", ".");
                return {...field, value : tmpValue, visuelValue :  tmpValue}
              default:
                return {...field, value : destination.value, visuelValue :  destination.value}
            }
          }
        }
        return field;
      });

      setFields(updatedListFields);
      setDataHasChanged(true);
    }else{
      setFields(listFields);
      setDataHasChanged(true);
    }
  }

  const handleChangePassword = (value, field, index) => {
    let listFields = fields;
    listFields[index].value = value;
    setFields([...listFields]);
    setDataHasChanged(true);
  };

  const handleChangeLocalisation = (value, field, index) => {
    let listFields = fields;
    listFields[index].value = value;
    setFields([...listFields]);
    setDataHasChanged(true);
  };

  const handleUploadClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const handleDownloadFile = (annexe) => {
    saveAs(
        ApplicationConfig.downloadUrl +
        environment.userhash +
        "/" +
        plugnote.hash +
        "/" +
        annexe.name +
        "." +
        annexe.type,
        annexe.name + "." + annexe.type
    );
  };

  //      .fetch('GET', baseURL + userHash + "/" + action.dataHash + "/" + encodeURIComponent(action.annexe.name) + "." + action.annexe.type)
  const downloadEmployeeData = (annexe) => {
    // Fetch the dynamically generated excel document from the server.
    trackPromise(
        axios
            .get(
                ApplicationConfig.apiUrl + "annexe.json?annexehash=" + annexe.hash,
                {
                  crossdomain: true,
                  headers: {
                    "X-AUTH-TOKEN": getToken(),
                  },
                }
            )
            .then((response) => {
              if (response.data.result) {
                const fileNameHeader = "x-suggested-filename";
                const suggestedFileName = response.headers[fileNameHeader];
                const effectiveFileName =
                    suggestedFileName === undefined
                        ? annexe.name + "." + annexe.type
                        : suggestedFileName;
                var a = document.createElement("a"); //Create <a>
                a.href = response.data.base64; //Image Base64 Goes here
                a.download = effectiveFileName; //File name Here
                a.click(); //Downloaded file
              }
            })
            .catch((response) => {
              console.error(
                  "Could not Download the Excel report from the backend.",
                  response
              );
            })
    );
  };

  /* const getUrlPreviewAnnexe = (annexe) => {
    return (
      ApplicationConfig.downloadUrl +
      environment.userhash +
      "/" +
      plugnote.hash +
      "/" +
      encodeURIComponent(annexe.name) +
      "." +
      annexe.type
    );
  }; */

  const getUrlPreviewAnnexe = (annexe) => {
    let annexes = plugnoteAnnexes;
    annexes.map((item, index) => {
      let source;
      if (item.id === annexe.id) {
        source = annexe.path;
        return source.toString();
      } else {
        source = item.path;
        return source.toString();
      }
    });
  };

  /* const renderAnnexePreview = (annexeToPreview) => {
    switch (annexeToPreview.type.toLowerCase()) {
      case "jpg":
      case "JPG":
      case "jpeg":
      case "png":
      case "PNG":
      case "gif":
        return (
          <FontAwesomeIcon
            icon={faFile}
            size="lg"
            style={{ fontSize: "50", marginRight: "10" }}
          />
        );
        break;

      case "mp4":
      case "mkv":
      case "avi":
      case "mov":
        return (
          <FontAwesomeIcon
            icon={faFileVideo}
            size="lg"
            style={{ fontSize: "50", marginRight: "10" }}
          />
        );
        break;

      case "pdf":
        return (
          <FontAwesomeIcon
            icon={faFilePdf}
            size="lg"
            style={{ fontSize: "50", marginRight: "10" }}
          />
        );
        break;

      case "zip":
      case "rar":
        return (
          <FontAwesomeIcon
            icon={faFileArchive}
            size="lg"
            style={{ fontSize: "50", marginRight: "10" }}
          />
        );
        break;

      case "mp3":
      case "aac":
        return (
          <FontAwesomeIcon
            icon={faFileAudio}
            size="lg"
            style={{ fontSize: "50", marginRight: "10" }}
          />
        );
        break;

      case "html":
        return (
          <FontAwesomeIcon
            icon={faFileCode}
            size="lg"
            style={{ fontSize: "50", marginRight: "10" }}
          />
        );
        break;

      case "txt":
      case "sheet":
      case "doc":
      case "docx":
        return (
          <FontAwesomeIcon
            icon={faFileWord}
            size="lg"
            style={{ fontSize: "50", marginRight: "10" }}
          />
        );
        break;

      case "xls":
      case "xlsx":
      case "csv":
        return (
          <FontAwesomeIcon
            icon={faFileExcel}
            size="lg"
            style={{ fontSize: "50", marginRight: "10" }}
          />
        );
        break;

      case "note":
        return (
          <FontAwesomeIcon
            icon={faStickyNote}
            size="lg"
            style={{ fontSize: "50", marginRight: "10" }}
          />
        );
        break;

      case "pages":
        return (
          <FontAwesomeIcon
            icon={faFileWord}
            size="lg"
            style={{ fontSize: "50", marginRight: "10" }}
          />
        );
        break;

      case "pptx":
      case "ppt":
        return (
          <FontAwesomeIcon
            icon={faFilePowerpoint}
            size="lg"
            style={{ fontSize: "50", marginRight: "10" }}
          />
        );
        break;
    }
  }; */

  const onError = (e) => {
    //console.log(e, 'error in file-viewer');
  };

  const checkMimeType = (event) => {
    //getting file object
    let files = event.target.files;
    //define message container
    let err = [];
    // list allow mime type
    // const types = ['image/png', 'image/jpeg', 'image/gif']
    // // loop access array
    // for(var x = 0; x<files.length; x++) {
    //     // compare file type find doesn't matach
    //     if (types.every(type => files[x].type !== type)) {
    //         // create error message and assign to container
    //         err[x] = files[x].type+' is not a supported format\n';
    //     }
    // };
    for (var z = 0; z < err.length; z++) {
      // if message not same old that mean has error
      // discard selected file
      toast.error(err[z]);
      event.target.value = null;
    }
    return true;
  };

  const maxSelectFile = (event) => {
    let files = event.target.files;
    if (files.length > 1) {
      const msg = "Only 1 images can be uploaded at a time";
      event.target.value = null;
      toast.warn(msg);
      return false;
    }
    return true;
  };

  const checkFileSize = (event) => {
    let files = event.target.files;
    let size = 1000000000;
    let err = [];
    for (var x = 0; x < files.length; x++) {
      if (files[x].size > size) {
        err[x] = files[x].type + "is too large, please pick a smaller file\n";
      }
    }
    for (var z = 0; z < err.length; z++) {
      // if message not same old that mean has error
      // discard selected file
      toast.error(err[z]);
      event.target.value = null;
    }
    return true;
  };

  const onChangeHandler = (event) => {
    const files = event.target.files;
    if (maxSelectFile(event) && checkMimeType(event) && checkFileSize(event)) {
      setLoaded(0);
      setSelectedFiles(files);
      setCreateDateWithAnnexe(true);
    }
  };

  const handleCreateNote = (event) => {
    handleNoteOpen(event);
  };

  const handleSavePlugform = () => {
    //sauvegarder du formulaire dans la gallerie
    setOpenClassify(true);
  };

  useEffect(() => {
    if (createDateWithAnnexe) {
      if (selectedFiles.length > 0) {
        onClickHandler();
        setCreateDateWithAnnexe(false);
      }
    }
  }, [createDateWithAnnexe]);

  const onClickHandler = () => {
    const data = plugnoteAnnexes;
    for (let x = 0; x < selectedFiles.length; x++) {
      let tmpFile = URL.createObjectURL(selectedFiles[x]);
      if (typeof tmpFile !== "undefined") {
        let fullName = selectedFiles[x].name;
        let name = fullName.split(".").slice(0, -1).join(".");
        let type = fullName.substring(fullName.lastIndexOf(".") + 1);
        let newId =
            typeof data[data.length - 1] === "undefined"
                ? 1
                : data[data.length - 1].id + 1;
        let tmpPayload = {
          id: newId,
          hash: undefined,
          type: type,
          path: tmpFile,
          name: name,
          comment: "",
          creationdate: undefined,
          expirationdate: undefined,
          groupid: undefined,
          size: selectedFiles[x].size,
          data: selectedFiles[x],
        };
        data.push(tmpPayload);
        setDataHasChanged(true);
      }
    }
    data.filter(Boolean);
    setPlugnotesAnnexes(data);
  };

  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
  }, [plugnoteAnnexes]);

  const { t, i18n } = useTranslation(["common", "models"]);

  useEffect(() => {
    if (
        typeof model !== "undefined" &&
        model !== null &&
        modelLinked === null
    ) {
      const labels = Object.keys(model).filter((key) => {
        const regex = /field[0-9]+label/gi;
        return regex.test(key);
      });

      let champs = labels.map((item) => {
        return model[item];
      });

      let listFields = [];

      champs.forEach((field, index) => {
        if (field) {
          const fieldIndex = index + 1;
          let value = "";
          let format = {};
          let visuelFormat = {};
          let show = false;
          let event = "";
          let encodable = false;
          let disabled = false;
          let visualDefault = false;

          let options = [];

          let originalOptions = [];

          if (modelForHash && modelForHash[`field${fieldIndex}formatoptions`]) {
            options =
                typeof modelForHash[`field${fieldIndex}formatoptions`] !==
                "string"
                    ? Object.create(modelForHash[`field${fieldIndex}formatoptions`])
                    : Object.create(
                    JSON.parse(modelForHash[`field${fieldIndex}formatoptions`])
                    );
            originalOptions =
                typeof modelForHash[`field${fieldIndex}formatoptions`] !==
                "string"
                    ? Object.create(modelForHash[`field${fieldIndex}formatoptions`])
                    : Object.create(
                    JSON.parse(modelForHash[`field${fieldIndex}formatoptions`])
                    );
          }

          // Récupération du format du champ, si le modèle existe le format vient du modèle sinon il vient de la fiche
          if (!_.isEmpty(modelForHash)) {
            format = modelForHash[`field${fieldIndex}format`];
            encodable = modelForHash[`field${fieldIndex}encodable`] === 1;
            visuelFormat = modelForHash[`field${fieldIndex}format`];
          }

          // Si c'est une préselection on initialise la valeur à un tableau vide
          if (options != null && (options.presel || format === "list")) {
            value = [];
          }

          // On vérifie si le champ est une préselection avec les options
          if (options != null && options.presel && format === "select") {
            if (Array.isArray(value) && Immutable.isImmutable(value)) {
              value = Immutable.asMutable(value);
            }

            if(options?.visibledefault){
              visualDefault = options?.visibledefault;
            }

            // On vérifie si la valeur est un tableau sous forme de chaine de caractères, dans ce cas on sérialize le tableau
            if (!Array.isArray(value)) {
              if (
                  value &&
                  value != null &&
                  value.length &&
                  /^[\],:{}\s]*$/.test(
                      value
                          .replace(/\\["\\\/bfnrtu]/g, "@")
                          .replace(
                              /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
                              "]"
                          )
                          .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
                  )
              ) {
                value = JSON.parse(value);
              } else {
                if (value && value != null) {
                  value = [value];
                } else {
                  value = [];
                }
              }
            }

            switch (options.multiselect) {
              case true:
                if (options.editable) {
                  format = "free-multi-select";
                } else {
                  format = "multi-select";
                }
                break;
              default:
                if (options.editable) {
                  format = "free-single-select";
                } else {
                  format = "single-select";
                }
            }

            options.presel =
                options && options.presel && isString(options.presel)
                    ? options.presel.split(";")
                    : options.presel;
          } else if (options != null && options.presel && format === "table") {
            if (Array.isArray(value) && Immutable.isImmutable(value)) {
              value = Immutable.asMutable(value);
            }

            if(options?.visibledefault){
              visualDefault = options?.visibledefault;
            }

            // On vérifie si la valeur est un tableau sous forme de chaine de caractères, dans ce cas on sérialize le tableau
            if (!Array.isArray(value)) {
              if (
                  value &&
                  value != null &&
                  value.length &&
                  /^[\],:{}\s]*$/.test(
                      value
                          .replace(/\\["\\\/bfnrtu]/g, "@")
                          .replace(
                              /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
                              "]"
                          )
                          .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
                  )
              ) {
                value = JSON.parse(value);
              } else {
                if (value && value != null) {
                  value = [value];
                } else {
                  value = [];
                }
              }
            }

            switch (options.multiselect) {
              case true:
                if (options.editable) {
                  format = "free-multi-table";
                } else {
                  format = "multi-table";
                }
                break;
              default:
                if (options.editable) {
                  format = "free-single-table";
                } else {
                  format = "single-table";
                }
            }

            options.presel =
                options && options.presel && isString(options.presel)
                    ? options.presel.split(";")
                    : options.presel;
          } else {
            if (format === "select") {
              format = "multi-select";
              options = [];

              if (
                  value &&
                  value != null &&
                  value.length &&
                  /^[\],:{}\s]*$/.test(
                      value
                          .replace(/\\["\\\/bfnrtu]/g, "@")
                          .replace(
                              /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
                              "]"
                          )
                          .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
                  )
              ) {
                value = JSON.parse(value);
              } else {
                if (value && value != null) {
                  value = [value];
                } else {
                  value = [];
                }
              }
            }

            if (format === "table") {
              format = "multi-table";
              options = [];

              if (
                  value &&
                  value != null &&
                  value.length &&
                  /^[\],:{}\s]*$/.test(
                      value
                          .replace(/\\["\\\/bfnrtu]/g, "@")
                          .replace(
                              /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
                              "]"
                          )
                          .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
                  )
              ) {
                value = JSON.parse(value);
              } else {
                if (value && value != null) {
                  value = [value];
                } else {
                  value = [];
                }
              }
            }
          }

          if (format === "now" && !value) {
            value = moment().format("YYYY-MM-DD HH:mm:ss");
            setDataHasChanged(true);
          }

          if (format === "tablemultin" && value.length === 0) {
            value = {"value" : "", "list" : []};
          }
          if (format === "document" && value.length === 0) {
            value = {"value" : "", "list" : []};
          }
          if (format === "smailvalidmulti" && value.length === 0) {
            value = {"value" : "", "list" : []};
          }

          let originalPresel = undefined;
          if (
              typeof originalOptions.presel !== "undefined" &&
              originalOptions.presel.length > 0
          ) {
            if (Array.isArray(originalOptions.presel)) {
              originalPresel = originalOptions.presel;
            } else {
              originalPresel = originalOptions.presel.split(";");
            }
          }

          listFields.push({
            id: fieldIndex,
            title: modelForHash[`field${fieldIndex}label`],
            titleTranslate: modelForHash[`field${fieldIndex}label`],
            value: value,
            visuelValue: value,
            encodable,
            format,
            visuelFormat,
            options,
            originalPresel,
            mandatory: modelForHash[`field${fieldIndex}mandatory`],
            show,
            event: event,
            disabled,
            visualDefault
          });
        }
      });

      setFields([...listFields]);
      setViewInputSearchCode(false);
    } else if (
        typeof model !== "undefined" &&
        model !== null &&
        modelLinked !== null &&
        typeof modelLinked !== "undefined"
    ) {
      const labels = Object.keys(model).filter((key) => {
        const regex = /field[0-9]+label/gi;
        return regex.test(key);
      });

      let champs = labels.map((item) => {
        return model[item];
      });

      let listFields = [modelLinked];

      champs.forEach((field, index) => {
        if (field) {
          const fieldIndex = index + 1;

          let fieldF28Format = Object.keys(modelLinked).find(function (key) {
            if (
                key.includes("format") &&
                modelLinked[key].includes("pluglink")
            ) {
              let optionsFormat = modelLinked[key.concat("options")];
              let includesFormatOptions =
                  optionsFormat.includes(searchPlugcode);
              if (includesFormatOptions) {
                return JSON.parse(optionsFormat);
              }
            }
          });

          let value = "";

          let format = {};
          let visuelFormat = {};
          let show = false;
          let event = "";
          let encodable = false;
          let disabled = false;
          let visualDefault = false;

          let options = [];

          let originalOptions = [];

          if (modelForHash && modelForHash[`field${fieldIndex}formatoptions`]) {
            options =
                typeof modelForHash[`field${fieldIndex}formatoptions`] !==
                "string"
                    ? Object.create(modelForHash[`field${fieldIndex}formatoptions`])
                    : Object.create(
                    JSON.parse(modelForHash[`field${fieldIndex}formatoptions`])
                    );
            originalOptions =
                typeof modelForHash[`field${fieldIndex}formatoptions`] !==
                "string"
                    ? Object.create(modelForHash[`field${fieldIndex}formatoptions`])
                    : Object.create(
                    JSON.parse(modelForHash[`field${fieldIndex}formatoptions`])
                    );
          }

          // Récupération du format du champ, si le modèle existe le format vient du modèle sinon il vient de la fiche
          if (!_.isEmpty(modelForHash)) {
            format = modelForHash[`field${fieldIndex}format`];
            encodable = modelForHash[`field${fieldIndex}encodable`] === 1;
            visuelFormat = modelForHash[`field${fieldIndex}format`];
          }

          // Si c'est une préselection on initialise la valeur à un tableau vide
          if (options != null && (options.presel || format === "list")) {
            value = [];
          }

          // On vérifie si le champ est une préselection avec les options
          if (options != null && options.presel && format === "select") {
            if (Array.isArray(value) && Immutable.isImmutable(value)) {
              value = Immutable.asMutable(value);
            }

            if(options?.visibledefault){
              visualDefault = options?.visibledefault;
            }

            // On vérifie si la valeur est un tableau sous forme de chaine de caractères, dans ce cas on sérialize le tableau
            if (!Array.isArray(value)) {
              if (
                  value &&
                  value != null &&
                  value.length &&
                  /^[\],:{}\s]*$/.test(
                      value
                          .replace(/\\["\\\/bfnrtu]/g, "@")
                          .replace(
                              /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
                              "]"
                          )
                          .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
                  )
              ) {
                value = JSON.parse(value);
              } else {
                if (value && value != null) {
                  value = [value];
                } else {
                  value = [];
                }
              }
            }

            switch (options.multiselect) {
              case true:
                if (options.editable) {
                  format = "free-multi-select";
                } else {
                  format = "multi-select";
                }
                break;
              default:
                if (options.editable) {
                  format = "free-single-select";
                } else {
                  format = "single-select";
                }
            }

            options.presel =
                options && options.presel && isString(options.presel)
                    ? options.presel.split(";")
                    : options.presel;
          } else if (options != null && options.presel && format === "table") {
            if (Array.isArray(value) && Immutable.isImmutable(value)) {
              value = Immutable.asMutable(value);
            }

            if(options?.visibledefault){
              visualDefault = options?.visibledefault;
            }

            // On vérifie si la valeur est un tableau sous forme de chaine de caractères, dans ce cas on sérialize le tableau
            if (!Array.isArray(value)) {
              if (
                  value &&
                  value != null &&
                  value.length &&
                  /^[\],:{}\s]*$/.test(
                      value
                          .replace(/\\["\\\/bfnrtu]/g, "@")
                          .replace(
                              /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
                              "]"
                          )
                          .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
                  )
              ) {
                value = JSON.parse(value);
              } else {
                if (value && value != null) {
                  value = [value];
                } else {
                  value = [];
                }
              }
            }

            switch (options.multiselect) {
              case true:
                if (options.editable) {
                  format = "free-multi-table";
                } else {
                  format = "multi-table";
                }
                break;
              default:
                if (options.editable) {
                  format = "free-single-table";
                } else {
                  format = "single-table";
                }
            }

            options.presel =
                options && options.presel && isString(options.presel)
                    ? options.presel.split(";")
                    : options.presel;
          } else {
            if (format === "select") {
              format = "multi-select";
              options = [];

              if (
                  value &&
                  value != null &&
                  value.length &&
                  /^[\],:{}\s]*$/.test(
                      value
                          .replace(/\\["\\\/bfnrtu]/g, "@")
                          .replace(
                              /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
                              "]"
                          )
                          .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
                  )
              ) {
                value = JSON.parse(value);
              } else {
                if (value && value != null) {
                  value = [value];
                } else {
                  value = [];
                }
              }
            }

            if (format === "table") {
              format = "multi-table";
              options = [];

              if (
                  value &&
                  value != null &&
                  value.length &&
                  /^[\],:{}\s]*$/.test(
                      value
                          .replace(/\\["\\\/bfnrtu]/g, "@")
                          .replace(
                              /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
                              "]"
                          )
                          .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
                  )
              ) {
                value = JSON.parse(value);
              } else {
                if (value && value != null) {
                  value = [value];
                } else {
                  value = [];
                }
              }
            }
          }

          if (format === "now" && !value) {
            value = moment().format("YYYY-MM-DD HH:mm:ss");
            setDataHasChanged(true);
          }

          let fieldF28 = [];

          if (typeof fieldF28Format !== "undefined") {
            if (isJsonString(modelLinked[fieldF28Format + "options"])) {
              let fieldF28Extract = JSON.parse(
                  modelLinked[fieldF28Format + "options"]
              );
              if (typeof fieldF28Extract.fields !== "undefined") {
                if (isJsonString(fieldF28Extract.fields)) {
                  fieldF28 = JSON.parse(fieldF28Extract.fields);
                } else {
                  fieldF28 = fieldF28Extract.fields;
                }
              }
            }
          }

          if (fieldF28.length > 0 && fieldF28.find((subItem) => subItem.to.toString() === fieldIndex.toString())) {
            fieldF28.map((item, index) => {
              if (fieldIndex.toString() === item.to.toString()) {
                if (format.includes("select")) {
                  if (Array.isArray(value) && Immutable.isImmutable(value)) {
                    value = Immutable.asMutable(value);
                  }
                  // On vérifie si la valeur est un tableau sous forme de chaine de caractères, dans ce cas on sérialize le tableau
                  if (!Array.isArray(dataLinked["field" + item.from.toString()])) {
                    if (dataLinked["field" + item.from.toString()] && dataLinked["field" + item.from.toString()] != null && dataLinked["field" + item.from.toString()].length && /^[\],:{}\s]*$/.test(dataLinked["field" + item.from.toString()].replace(/\\["\\\/bfnrtu]/g, "@").replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, "]").replace(/(?:^|:|,)(?:\s*\[)+/g, ""))) {
                      value = JSON.parse(
                          dataLinked["field" + item.from.toString()]
                      );
                    } else {
                      if (
                          dataLinked["field" + item.from.toString()] &&
                          dataLinked["field" + item.from.toString()] != null
                      ) {
                        value = [dataLinked["field" + item.from.toString()]];
                      } else {
                        value = [];
                      }
                    }
                  }
                  if(Array.isArray(value) && value.length > 0){
                    if(typeof options.presel === 'undefined'){
                      options.presel = value;
                    }else{
                      options.presel = options.presel.concat(value);
                    }
                  }
                }
                else if (format.includes("table") && !format.includes('tablemulti')) {
                  if (Array.isArray(value) && Immutable.isImmutable(value)) {
                    value = Immutable.asMutable(value);
                  }
                  // On vérifie si la valeur est un tableau sous forme de chaine de caractères, dans ce cas on sérialize le tableau
                  if (
                      !Array.isArray(dataLinked["field" + item.from.toString()])
                  ) {
                    if (
                        dataLinked["field" + item.from.toString()] &&
                        dataLinked["field" + item.from.toString()] != null &&
                        dataLinked["field" + item.from.toString()].length &&
                        /^[\],:{}\s]*$/.test(
                            dataLinked["field" + item.from.toString()]
                                .replace(/\\["\\\/bfnrtu]/g, "@")
                                .replace(
                                    /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
                                    "]"
                                )
                                .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
                        )
                    ) {
                      value = JSON.parse(
                          dataLinked["field" + item.from.toString()]
                      );
                    } else {
                      if (
                          dataLinked["field" + item.from.toString()] &&
                          dataLinked["field" + item.from.toString()] != null
                      ) {
                        value = [dataLinked["field" + item.from.toString()]];
                      } else {
                        value = [];
                      }
                    }
                  }

                  if(Array.isArray(value) && value.length > 0){
                    if(typeof options.presel === 'undefined'){
                      options.presel = value.map(e => e.text);
                    }else{
                      options.presel = options.presel.concat(value.map(e => e.text));
                    }
                  }
                }
                else if (format.includes("glocalis")) {
                  if (
                      typeof dataLinked["field" + item.from.toString()] !==
                      "undefined"
                  ) {
                    value = dataLinked["field" + item.from.toString()];
                  }
                }
                else if (format.includes("localis")){
                  if (typeof dataLinked['field' + item.from.toString()] !== "undefined") {
                    value = dataLinked['field' + item.from.toString()];
                  }
                }
                else if (format.includes("qrcodeint")){
                  let tmp = dataLinked['field' + item.from.toString()];
                  if (isJsonStringObject(tmp)) {
                    value = JSON.parse(tmp);
                  }
                }
                else if(format.includes("tablemulti") && !format.includes("tablemultin") ){
                  let tmp = dataLinked['field' + item.from.toString()];
                  if (isJsonStringObject(tmp)) {
                    let tmpValue = JSON.parse(tmp);
                    let data = [];
                    if(tmpValue.length > 0){
                      tmpValue.map((v,i) => {
                        let tmpObjectValues = Object.values(v);
                        let finalData = {}
                        if(options.length > 0){
                          options.map((option,optionIndex) => {
                            if (tmpObjectValues[optionIndex] !== undefined) {
                              finalData[option.id] = tmpObjectValues[optionIndex];
                            }
                          })
                          data.push(finalData);
                        }
                      });
                    }
                    value = data;
                  }
                }
                else if(format.includes("tablemultin")){
                  let tmp = dataLinked['field' + item.from.toString()];
                  if (isJsonStringObject(tmp)) {
                    value = JSON.parse(tmp);
                  }
                }
                else if(format.includes("document")){
                  let tmp = dataLinked['field' + item.from.toString()];
                  if (isJsonStringObject(tmp)) {
                    value = JSON.parse(tmp);
                  }
                }
                else if(format.includes("smailvalidmulti")){
                  let tmp = dataLinked['field' + item.from.toString()];
                  if (isJsonStringObject(tmp)) {
                    value = JSON.parse(tmp);
                  }
                }
                else if (format.includes("plugform")){
                  let tmp = dataLinked['field' + item.from.toString()];
                  if (isJsonStringObject(tmp)) {
                    value = JSON.parse(tmp);
                  }
                }
                else if(format.includes("numeric")){
                  value = dataLinked["field" + item.from.toString()];
                }
                else {
                  let tmp = dataLinked["field" + item.from.toString()];

                  if (!Array.isArray(tmp)) {
                    if (tmp && tmp != null && tmp.length && /^[\],:{}\s]*$/.test(tmp.replace(/\\["\\\/bfnrtu]/g, "@").replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, "]").replace(/(?:^|:|,)(?:\s*\[)+/g, ""))) {
                      if (isJsonStringObject(tmp)) {
                        let tmpParse = JSON.parse(tmp);
                        tmp = tmpParse.join(",");
                      }
                    }
                  }

                  if (value !== "" && tmp !== "") {
                    value = value + "\n " + tmp;
                  } else if (value === "" && tmp !== "") {
                    value = tmp;
                  }
                }
              }
            });

            if (value !== "" && value !== undefined) {
              setDataHasChanged(true);
              disabled = true;
            }
          }

          let originalPresel = undefined;
          if (
              typeof originalOptions.presel !== "undefined" &&
              originalOptions.presel.length > 0
          ) {
            if (Array.isArray(originalOptions.presel)) {
              originalPresel = originalOptions.presel;
            } else {
              originalPresel = originalOptions.presel.split(";");
            }
          }

          if (disabled && format.includes("select")) {
            options.presel = value;
            originalPresel = value;
          }

          if (disabled && format.includes("table")) {
            if (Array.isArray(value) && value.length > 0) {
              options.presel = value.map((e) => e.text);
              originalPresel = value.map((e) => e.text);
            }
          }

          listFields.push({
            id: fieldIndex,
            title: modelForHash[`field${fieldIndex}label`],
            titleTranslate: modelForHash[`field${fieldIndex}label`],
            value: value,
            visuelValue: value,
            encodable,
            format,
            visuelFormat,
            options,
            originalPresel,
            mandatory: modelForHash[`field${fieldIndex}mandatory`],
            show,
            disabled,
            event: event,
            visualDefault
          });
        }
      });
      setFields([...listFields]);
      setViewInputSearchCode(false);
    } else {
      setFields([]);
      setViewInputSearchCode(false);
    }
  }, [model]);

  const handleViewInputSearchCode = () => {
    setActiveSearch(false);
  };

  const handleSearchPlugCode = () => {
    setActiveSearch(true);
  };

  useEffect(() => {
    if (!reset && activeSearch) {
      setTimeout(() => {
        if (searchPlugcode.length > 0) {
          if (searchPlugcode.startsWith("PF")) {
            props.attemptGetPlugformViaCodeOfflineRequest(
                searchPlugcode,
                searchPlugcodeFolder,
                searchPlugcodeFiche
            );
          } else {
            props.attemptGetPlugnoteViaCodeOfflineRequest(searchPlugcode);
          }
        }
      }, 1000);
    }
  }, [reset]);

  useEffect(() => {
    if (reset) {
      setNameModel("");
      setModel(null);
      setModelLinked(null);
      setModelForHash(null);
      setDataLinked(null);
      setFields([]);

      props.attemptGetPlugformViaCodeOfflineReset();
      props.attemptGetPlugnoteViaCodeOfflineReset();
      setReset(false);
    }
  }, [activeSearch]);

  useEffect(() => {
    if (!reset) {
      if (
          props.plugform !== null &&
          props.plugformlink !== null &&
          props.plugformplug !== null &&
          !props.fetching
      ) {
        setNameModel(props.plugform.label);
        setModelLinked(props.plugformlink);
        setModel(props.plugform);
        setModelForHash(props.plugform);
        setDataLinked(props.plugformplug);
      } else if (
          props.plugform !== null &&
          props.plugformlink === null &&
          props.plugformplug === null &&
          !props.fetching
      ) {
        setNameModel(props.plugform.label);
        setModel(props.plugform);
        setModelForHash(props.plugform);
        setModelLinked(null);
        setDataLinked(null);
      }
    }
  }, [props.plugform]);

  return (
      <div className={classes.root}>
        <MenuOfflineComponent />
        {!viewInputSearchCode &&
        typeof fields != "undefined" &&
        fields.length > 0 && (
            <Plugforms
                mode="external-shared-create"
                model={modelForHash}
                shared={true}
                fields={fields}
                attemptGetDataUnique={props.attemptGetDataUnique}
                handleEditValue={handleEditValue}
                handleEditDateTime={handleEditDateTime}
                plugnoteAnnexes={plugnoteAnnexes}
                nameModel={nameModel}
                searchPlugcode={searchPlugcode}
                viewInputSearchCode={viewInputSearchCode}
                plugnote={plugnote}
                handleExitCreatePlugnote={handleExitCreatePlugnote}
                handleSaveCreatePlugnote={handleSaveCreatePlugnote}
                openSave={openSave}
                openClassify={openClassify}
                handleCloseClassify={handleCloseClassify}
                handleCloseSave={handleCloseSave}
                handleChangeEmail={handleChangeEmail}
                handleSaveCreatePlugnoteConfirm={handleSaveCreatePlugnoteConfirm}
                copy={copy}
                email={email}
                emailValid={emailValid}
                //handleSaveUpdatePlugnote = {handleSaveUpdatePlugnote}
                isOpenModalListAnnexes={isOpenModalListAnnexes}
                _handleOpenModalListAnnexes={_handleOpenModalListAnnexes}
                _handleCloseModalListAnnexes={_handleCloseModalListAnnexes}
                handleChangeLocalisation={handleChangeLocalisation}
                //isAuthorisedToEdit = {isAuthorisedToEdit}
                downloadEmployeeData={downloadEmployeeData}
                //handleUpdateAnnexe = {handleUpdateAnnexe}
                handleModalAnnexeOpen={handleModalAnnexeOpen}
                handleDeleteAnnexe={handleDeleteAnnexe}
                handleModalListAnnexesVersionOpen={
                  handleModalListAnnexesVersionOpen
                }
                handlePreviewAnnexe={handlePreviewAnnexe}
                handlePreviewPdfAnnexe={handlePreviewPdfAnnexe}
                onChangeHandlerDrop={onChangeHandlerDrop}
                renderAnnexeIconPreview={renderAnnexeIconPreview}
                renderAnnexeImagePreview={renderAnnexeImagePreview}
                environment={environment}
                //fullNamePlugnote = {fullNamePlugnote}
                //disabled= {locked}
                isOpenModalAnnexe={isOpenModalAnnexe}
                handleModalAnnexeClose={handleModalAnnexeClose}
                handleEditPhoneValue={handleEditPhoneValue}
                handleChangeBoolean={handleChangeBoolean}
                handleChangePassword={handleChangePassword}
                handleChangePlugcode={handleChangePlugcode}
                handleChangePlugcodeInternal={handleChangePlugcodeInternal}
                handleChangeRadioTable={handleChangeRadioTable}
                handleChangeInputTable={handleChangeInputTable}
                handleUpdateInputTable={handleUpdateInputTable}
                handleDeleteInputTable={handleDeleteInputTable}
                handleChangeKeyword={handleChangeKeyword}
                selectedAnnexe={selectedAnnexe}
                handleSaveFictifComment={handleSaveFictifComment}
                setFields={setFields}
                handleChangeRadio={handleChangeRadio}
                handleNoteOpen = {handleNoteOpen}
                handleCreateNote = {handleCreateNote}
                handleUpdateNote = {handleUpdateNote}
                handleNoteClose = {handleNoteClose}
                handleChangeExtData={handleChangeExtData}
                handleChangeTableValue={handleChangeTableValue}
                handleChangeTableRowsValue={handleChangeTableRowsValue}
            />
        )}

        {
          openNote && !viewInputSearchCode &&
          <ModalNoteComponent selectedNote={selectedNote} open={openNote} close={handleNoteClose}
                              handleSaveNote={handleSaveNote} handleUpdateNote={handleUpdateNote}/>
        }

        <Dialog
            open={openModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{t('global.plugnotes')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {
                t('plugnote-create-update.plug-form-mandatory-fields-empty')
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            { cancel &&
            <Button onClick={handleClickCancelModal} color="primary">
              {
                t('global.cancel')
              }
            </Button>
            }
            <Button onClick={handleClickContinueModal} color="primary" autoFocus>
              {
                t('global.ok')
              }
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

// redux providing state takeover
const mapStateToProps = (state) => {
  return {
    plugformlink: state.plugcode.plugformlink || null,
    plugformplug: state.plugcode.plugformplug || null,
    plugform: state.plugcode.plugform || null,
    plugnote: state.plugcode.plugnote || null,
    plugformowner: state.plugcode.plugformowner || null,
    plugnoteowner: state.plugcode.plugnoteowner || null,
    fetching: state.data.fetching,
    progress: state.annexe.progress,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    attemptCreateGalleryRequest: (gallery) =>
        dispatch(postPlugcodeGalleryRequest(gallery)),
    attemptCreateData: (modelHash, userHash, formData) => {
      return dispatch(postCreateDataRequest(modelHash, userHash, formData));
    },
    attemptUpdateData: (modelHash, userHash, formData) => {
      return dispatch(putUpdateDataRequest(modelHash, userHash, formData));
    },
    attemptPostAnnexes: (
        modelhash,
        userhash,
        datahash,
        modelshared,
        newdata,
        formdata,
        formfile
    ) =>
        dispatch(
            postAnnexesRequest(
                modelhash,
                userhash,
                datahash,
                modelshared,
                newdata,
                formdata,
                formfile
            )
        ),
    attemptDeleteAnnexe: (annexehash, userhash) =>
        dispatch(delDeleteAnnexeRequest(annexehash, userhash)),
    attemptAddNoteAnnexe: (
        modelhash,
        datahash,
        userhash,
        name,
        note,
        newdata,
        formdata
    ) =>
        dispatch(
            postAddNoteRequest(
                modelhash,
                datahash,
                userhash,
                name,
                note,
                newdata,
                formdata
            )
        ),
    attemptEditNoteAnnexe: (annexehash, notehash, name, note) =>
        dispatch(putEditNoteRequest(annexehash, notehash, name, note)),
    attemptCreatePFCodePlugnote: (payload, files, model) =>
        dispatch(postCreatePFCodePlugnotePublicRequest(payload, files, model)),
    attemptGetPlugnoteViaCodeOfflineRequest: (plugcode) =>
        dispatch(getPlugnoteViaCodeOfflineRequest(plugcode)),
    attemptGetPlugnoteViaCodeOfflineReset: () =>
        dispatch(getPlugnoteViaCodeOfflineReset()),
    attemptGetPlugformViaCodeOfflineRequest: (plugcode, folder, fiche) =>
        dispatch(getPlugformViaCodeOfflineRequest(plugcode, folder, fiche)),
    attemptGetPlugformViaCodeOfflineReset: () =>
        dispatch(getPlugformViaCodeOfflineReset()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlugExternalCreateFromCodeV2);
