// actions types is object with the
// key: GET_DATA_REQUEST -> value: describes the action
export const Types = {
    RESET_ALL_DATA: 'reset_all_data',

    GET_FIELDS_REQUEST: 'get_fields_request',
    GET_FIELDS_SUCCESS: 'get_fields_success',
    GET_FIELDS_FAILURE: 'get_fields_failure',
    GET_FIELDS_ERROR: 'get_fields_error',
};

/*
Token
 */
// function that returns an object literal
export const getFieldsRequest = () => ({
    type: Types.GET_FIELDS_REQUEST,
});

// function that returns an object literal
export const getFieldsSuccess = (data) => ({
    type: Types.GET_FIELDS_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getFieldsFailure = () => ({
    type: Types.GET_FIELDS_FAILURE,
});

// function that returns an object literal
export const getFieldsError = () => ({
    type: Types.GET_FIELDS_ERROR,
});

export const resetAllData = () => ({
    type: Types.RESET_ALL_DATA,
});

