import {call, fork, put, takeEvery} from 'redux-saga/effects';
import * as actions from '../actions/external';
import * as api from '../api/externalApi';
import {history} from '../hooks/history'
import {trackPromise} from "react-promise-tracker";
import {confirmAlert} from "react-confirm-alert";
import i18n from "i18next";

export const redirectToBase = () => window.location.href = 'https://www.plugnotes.com'
const myGenericTrackedWrapper = (fn, args1, args2, args3) => trackPromise(fn(args1,args2, args3));

function forwardTo(location) {
    history.push(location);
}

export function * postCreatePFCodePlugnote (action) {
    // Récupération des annexes publiques
    const publicFiles = action.files;

    // Création des formData pour RNFetchBlob avec vérif de l'existence du fichier
    let formData = [];
    let formNote = [];
    let index = 0;
    while (index < publicFiles.length) {

        const file = publicFiles[index];
        const comment = !!file.comment ? file.comment : '';

        if(file.type === 'note'){
            // Contenu de la note

            formNote.push({
                comment : file.comment,
                content: file.Webdoc.content,
                name: file.name,
            });

        }else{
            formData.push({
                name: 'file[]',
                filename: file.name+'.'+file.type,
                type : file.type,
                comment : comment,
                data: file.data
            })

        }

        index++;
    }


    let newFormData = new FormData();

    // Contenu des notes

    if(formNote.length > 0){
        newFormData.append('notes', JSON.stringify({
            notes : formNote
        }));
    }

    let comments = [];

    if(formData.length > 0){
        formData.map((item, index) => {
            newFormData.append('file[]', item.data, item.filename);
            if(item.comment.length > 0){
                comments.push({"name" : item.filename, "index" : index, "comment" : item.comment });
            }
        })
    }

    newFormData.append('content', JSON.stringify({
        ...action.payload.dataForHash,
        hash: action.model.hash,
        email: action.payload.email,
        plugkey: action.payload.plugkey
    }));


    try {
        const response = yield call(myGenericTrackedWrapper,api.postCreateExternalPlugnotePFCode,newFormData);

        if(response.status === 200 && response.data.result === 'ok'){
            yield put(actions.postCreatePFCodePlugnoteSuccess());
            yield call(forwardTo, '/plugcodes');
        }else{
            yield put(actions.postCreatePFCodePlugnoteFailure());
        }
    }catch (e) {
        yield put(actions.postCreatePFCodePlugnoteError());

    }
}

export function * postOldCreatePFCodePlugnote (action) {
    // Récupération des annexes publiques
    const publicFiles = action.files;

    // Création des formData pour RNFetchBlob avec vérif de l'existence du fichier
    let formData = [];
    let formNote = [];
    let index = 0;
    while (index < publicFiles.length) {

        const file = publicFiles[index];
        const comment = !!file.comment ? file.comment : '';

        if(file.type === 'note'){
            // Contenu de la note

            formNote.push({
                comment : file.comment,
                content: file.Webdoc.content,
                name: file.name,
            });

        }else{
            formData.push({
                name: 'file[]',
                filename: file.name+'.'+file.type,
                type : file.type,
                comment : comment,
                data: file.data
            })

        }

        index++;
    }


    let newFormData = new FormData();

    // Contenu des notes

    if(formNote.length > 0){
        newFormData.append('notes', JSON.stringify({
            notes : formNote
        }));
    }

    let comments = [];

    if(formData.length > 0){
        formData.map((item, index) => {
            newFormData.append('file[]', item.data, item.filename);
            if(item.comment.length > 0){
                comments.push({"name" : item.filename, "index" : index, "comment" : item.comment });
            }
        })
    }

    newFormData.append('content', JSON.stringify({
        ...action.payload.dataForHash,
        hash: action.model.hash,
        email: action.payload.email,
        plugkey: action.payload.plugkey
    }));


    try {
        const response = yield call(myGenericTrackedWrapper,api.postCreateExternalPlugnotePFCode,newFormData);

        if(response.status === 200 && response.data.result === 'ok'){
            yield put(actions.postCreatePFCodePlugnoteSuccess());
            yield call(forwardTo, '/old-plugcodes');
        }else{
            yield put(actions.postCreatePFCodePlugnoteFailure());
        }
    }catch (e) {
        yield put(actions.postCreatePFCodePlugnoteError());

    }
}

export function * postCreatePFCodePlugnotePublic (action) {
    // Récupération des annexes publiques
    const publicFiles = action.files;

    // Création des formData pour RNFetchBlob avec vérif de l'existence du fichier
    let formData = [];
    let formNote = [];
    let index = 0;
    while (index < publicFiles.length) {

        const file = publicFiles[index];
        const comment = !!file.comment ? file.comment : '';

        if(file.type === 'note'){
            // Contenu de la note

            formNote.push({
                comment : file.comment,
                content: file.Webdoc.content,
                name: file.name,
            });

        }else{
            formData.push({
                name: 'file[]',
                type : file.type,
                filename: file.name+'.'+file.type,
                comment : comment,
                data: file.data
            })

        }

        index++;
    }

    let newFormData = new FormData();

    // Contenu des notes

    if(formNote.length > 0){
        newFormData.append('notes', JSON.stringify({
            notes : formNote
        }));
    }

    let comments = [];

    if(formData.length > 0){
        formData.map((item, index) => {
            newFormData.append('file[]', item.data, item.filename);
            if(item.comment.length > 0){
                comments.push({"name" : item.filename, "index" : index, "comment" : item.comment });
            }
        })
    }

    newFormData.append('content', JSON.stringify({
        ...action.payload.dataForHash,
        hash: action.model.hash,
        email: action.payload.email,
        plugkey: action.payload.plugkey,
        copy: action.payload.copy,
        userindex :  action.payload.userindex
    }));



    if(typeof action.userhash !== "undefined" && action.userhash !== null){
        try {
            const response = yield call(myGenericTrackedWrapper,api.postCreateExternalPlugnotePFCode,newFormData);

            if(response.status === 200 && response.data.result === 'ok'){
                yield put(actions.postCreatePFCodePlugnotePublicSuccess());
                confirmAlert({
                    title: i18n.t('common:global.plugnotes'),
                    message: i18n.t('common:plugcode.create-success'),
                    buttons: [
                        {
                            label: i18n.t('common:global.ok'),
                        }
                    ]
                });

            }
            else{
                yield put(actions.postCreatePFCodePlugnotePublicFailure());

                confirmAlert({
                    title: i18n.t('common:global.plugnotes'),
                    message: i18n.t('common:plugcode.create-error'),
                    buttons: [
                        {
                            label: i18n.t('common:global.ok'),
                        }
                    ]
                });

            }
        }
        catch (e) {
            yield put(actions.postCreatePFCodePlugnotePublicError());

            confirmAlert({
                title: i18n.t('common:global.plugnotes'),
                message: i18n.t('common:plugcode.create-error'),
                buttons: [
                    {
                        label: i18n.t('common:global.ok'),
                    }
                ]
            });

        }
    }
    else{
        try {
            const response = yield call(myGenericTrackedWrapper,api.postCreateExternalPlugnotePFCode,newFormData);

            if(response.status === 200 && response.data.result === 'ok'){
                yield put(actions.postCreatePFCodePlugnotePublicSuccess());

                const promise = new Promise((resolve, reject) => {
                    confirmAlert({
                        title: i18n.t('common:global.plugnotes'),
                        message: i18n.t('common:plugcode.create-success'),
                        buttons: [
                            {
                                label: i18n.t('common:global.ok'),
                                onClick: () => resolve(true)
                            }
                        ]
                    });
                });

                const respUser = yield call(() => promise);

                if(respUser){
                    yield call(redirectToBase);
                }

            }else{
                yield put(actions.postCreatePFCodePlugnotePublicFailure());

                const promise = new Promise((resolve, reject) => {
                    confirmAlert({
                        title: i18n.t('common:global.plugnotes'),
                        message: i18n.t('common:plugcode.create-error'),
                        buttons: [
                            {
                                label: i18n.t('common:global.ok'),
                                onClick: () => resolve(true)
                            }
                        ]
                    });
                });

                const respUser = yield call(() => promise);

                if(respUser){
                    yield call(redirectToBase);
                }

            }
        }
        catch (e) {
            yield put(actions.postCreatePFCodePlugnotePublicError());

            const promise = new Promise((resolve, reject) => {
                confirmAlert({
                    title: i18n.t('common:global.plugnotes'),
                    message: i18n.t('common:plugcode.create-error'),
                    buttons: [
                        {
                            label: i18n.t('common:global.ok'),
                            onClick: () => resolve(true)
                        }
                    ]
                });
            });

            const respUser = yield call(() => promise);

            if(respUser){
                yield call(redirectToBase);
            }

        }
    }
}

export function * postCreatePFCodePlugnoteInternal (action) {
    // Récupération des annexes publiques
    const publicFiles = action.files;

    // Création des formData pour RNFetchBlob avec vérif de l'existence du fichier
    let formData = [];
    let formNote = [];
    let index = 0;
    while (index < publicFiles.length) {

        const file = publicFiles[index];
        const comment = !!file.comment ? file.comment : '';

        if(file.type === 'note'){
            // Contenu de la note

            formNote.push({
                comment : file.comment,
                content: file.Webdoc.content,
                name: file.name,
            });

        }else{
            formData.push({
                name: 'file[]',
                type : file.type,
                filename: file.name+'.'+file.type,
                comment : comment,
                data: file.data
            })

        }

        index++;
    }

    let newFormData = new FormData();

    // Contenu des notes

    if(formNote.length > 0){
        newFormData.append('notes', JSON.stringify({
            notes : formNote
        }));
    }

    let comments = [];

    if(formData.length > 0){
        formData.map((item, index) => {
            newFormData.append('file[]', item.data, item.filename);
            if(item.comment.length > 0){
                comments.push({"name" : item.filename, "index" : index, "comment" : item.comment });
            }
        })
    }

    newFormData.append('content', JSON.stringify({
        ...action.payload.dataForHash,
        hash: action.model.hash,
        email: action.payload.email,
        plugkey: action.payload.plugkey,
        copy: action.payload.copy,
        userindex :  action.payload.userindex
    }));


    if(action.userhash !== null){
        try {
            const response = yield call(myGenericTrackedWrapper,api.postCreateInternalPlugnotePFCode,newFormData);

            if(response.status === 200 && response.data.result === 'ok'){
                yield put(actions.postCreatePFCodePlugnotePublicSuccess());
                confirmAlert({
                    title: i18n.t('common:global.plugnotes'),
                    message: i18n.t('common:plugcode.create-success'),
                    buttons: [
                        {
                            label: i18n.t('common:global.ok'),
                        }
                    ]
                });

            }
            else{
                yield put(actions.postCreatePFCodePlugnotePublicFailure());

                confirmAlert({
                    title: i18n.t('common:global.plugnotes'),
                    message: i18n.t('common:plugcode.create-error'),
                    buttons: [
                        {
                            label: i18n.t('common:global.ok'),
                        }
                    ]
                });

            }
        }
        catch (e) {
            yield put(actions.postCreatePFCodePlugnotePublicError());

            confirmAlert({
                title: i18n.t('common:global.plugnotes'),
                message: i18n.t('common:plugcode.create-error'),
                buttons: [
                    {
                        label: i18n.t('common:global.ok'),
                    }
                ]
            });

        }
    }
    else{
        try {
            const response = yield call(myGenericTrackedWrapper,api.postCreateExternalPlugnotePFCode,newFormData);

            if(response.status === 200 && response.data.result === 'ok'){
                yield put(actions.postCreatePFCodePlugnotePublicSuccess());

                const promise = new Promise((resolve, reject) => {
                    confirmAlert({
                        title: i18n.t('common:global.plugnotes'),
                        message: i18n.t('common:plugcode.create-success'),
                        buttons: [
                            {
                                label: i18n.t('common:global.ok'),
                                onClick: () => resolve(true)
                            }
                        ]
                    });
                });

                const respUser = yield call(() => promise);

                if(respUser){
                    yield call(redirectToBase);
                }

            }else{
                yield put(actions.postCreatePFCodePlugnotePublicFailure());

                const promise = new Promise((resolve, reject) => {
                    confirmAlert({
                        title: i18n.t('common:global.plugnotes'),
                        message: i18n.t('common:plugcode.create-error'),
                        buttons: [
                            {
                                label: i18n.t('common:global.ok'),
                                onClick: () => resolve(true)
                            }
                        ]
                    });
                });

                const respUser = yield call(() => promise);

                if(respUser){
                    yield call(redirectToBase);
                }

            }
        }
        catch (e) {
            yield put(actions.postCreatePFCodePlugnotePublicError());

            const promise = new Promise((resolve, reject) => {
                confirmAlert({
                    title: i18n.t('common:global.plugnotes'),
                    message: i18n.t('common:plugcode.create-error'),
                    buttons: [
                        {
                            label: i18n.t('common:global.ok'),
                            onClick: () => resolve(true)
                        }
                    ]
                });
            });

            const respUser = yield call(() => promise);

            if(respUser){
                yield call(redirectToBase);
            }

        }
    }


}

export function * postCreatePlugnoteInternal (action) {
    // Récupération des annexes publiques
    const publicFiles = action.files;

    // Création des formData pour RNFetchBlob avec vérif de l'existence du fichier
    let formData = [];
    let formNote = [];
    let index = 0;
    while (index < publicFiles.length) {

        const file = publicFiles[index];
        const comment = !!file.comment ? file.comment : '';

        if(file.type === 'note'){
            // Contenu de la note

            formNote.push({
                comment : file.comment,
                content: file.Webdoc.content,
                name: file.name,
            });

        }else{
            formData.push({
                name: 'file[]',
                type : file.type,
                filename: file.name+'.'+file.type,
                comment : comment,
                data: file.data
            })

        }

        index++;
    }

    let newFormData = new FormData();

    // Contenu des notes

    if(formNote.length > 0){
        newFormData.append('notes', JSON.stringify({
            notes : formNote
        }));
    }

    let comments = [];

    if(formData.length > 0){
        formData.map((item, index) => {
            newFormData.append('file[]', item.data, item.filename);
            if(item.comment.length > 0){
                comments.push({"name" : item.filename, "index" : index, "comment" : item.comment });
            }
        })
    }

    newFormData.append('content', JSON.stringify({
        ...action.payload.dataForHash,
        hash: action.model.hash,
        email: action.payload.email,
        plugkey: action.payload.plugkey,
        copy: action.payload.copy,
        userindex :  action.payload.userindex
    }));


    if(action.userhash !== null){
        try {
            const response = yield call(myGenericTrackedWrapper,api.postCreateInternalPlugnote,newFormData);

            if(response.status === 200 && response.data.result === 'ok'){
                yield put(actions.postCreatePFCodePlugnotePublicSuccess());
                confirmAlert({
                    title: i18n.t('common:global.plugnotes'),
                    message: i18n.t('common:plugcode.create-success'),
                    buttons: [
                        {
                            label: i18n.t('common:global.ok'),
                        }
                    ]
                });

            }
            else{
                yield put(actions.postCreatePFCodePlugnotePublicFailure());

                confirmAlert({
                    title: i18n.t('common:global.plugnotes'),
                    message: i18n.t('common:plugcode.create-error'),
                    buttons: [
                        {
                            label: i18n.t('common:global.ok'),
                        }
                    ]
                });

            }
        }
        catch (e) {
            yield put(actions.postCreatePFCodePlugnotePublicError());

            confirmAlert({
                title: i18n.t('common:global.plugnotes'),
                message: i18n.t('common:plugcode.create-error'),
                buttons: [
                    {
                        label: i18n.t('common:global.ok'),
                    }
                ]
            });

        }
    }else{
        try {
            const response = yield call(myGenericTrackedWrapper,api.postCreateExternalPlugnotePFCode,newFormData);

            if(response.status === 200 && response.data.result === 'ok'){
                yield put(actions.postCreatePFCodePlugnotePublicSuccess());

                const promise = new Promise((resolve, reject) => {
                    confirmAlert({
                        title: i18n.t('common:global.plugnotes'),
                        message: i18n.t('common:plugcode.create-success'),
                        buttons: [
                            {
                                label: i18n.t('common:global.ok'),
                                onClick: () => resolve(true)
                            }
                        ]
                    });
                });

                const respUser = yield call(() => promise);

                if(respUser){
                    yield call(redirectToBase);
                }

            }else{
                yield put(actions.postCreatePFCodePlugnotePublicFailure());

                const promise = new Promise((resolve, reject) => {
                    confirmAlert({
                        title: i18n.t('common:global.plugnotes'),
                        message: i18n.t('common:plugcode.create-error'),
                        buttons: [
                            {
                                label: i18n.t('common:global.ok'),
                                onClick: () => resolve(true)
                            }
                        ]
                    });
                });

                const respUser = yield call(() => promise);

                if(respUser){
                    yield call(redirectToBase);
                }

            }
        }
        catch (e) {
            yield put(actions.postCreatePFCodePlugnotePublicError());

            const promise = new Promise((resolve, reject) => {
                confirmAlert({
                    title: i18n.t('common:global.plugnotes'),
                    message: i18n.t('common:plugcode.create-error'),
                    buttons: [
                        {
                            label: i18n.t('common:global.ok'),
                            onClick: () => resolve(true)
                        }
                    ]
                });
            });

            const respUser = yield call(() => promise);

            if(respUser){
                yield call(redirectToBase);
            }

        }
    }


}

export function * postCreatePlugnoteInternalBase64 (action) {

    let payload = {
        ...action.payload.dataForHash,
        hash: action.payload.hash,
        email: action.payload.email,
        plugkey: action.payload.plugkey,
    };

    try {
        const response = yield call(myGenericTrackedWrapper,api.postCreateInternalPlugnoteBase64,payload);

        if(response.status === 200 && response.data.result === true){
            yield put(actions.postCreatePlugnoteInternalBase64Success());
            confirmAlert({
                title: i18n.t('common:global.plugnotes'),
                message: i18n.t('common:plugcode.create-success'),
                buttons: [
                    {
                        label: i18n.t('common:global.ok'),
                    }
                ]
            });
        }else{
            yield put(actions.postCreatePlugnoteInternalBase64Failure());
            confirmAlert({
                title: i18n.t('common:global.plugnotes'),
                message: i18n.t('common:plugcode.create-failure'),
                buttons: [
                    {
                        label: i18n.t('common:global.ok'),
                    }
                ]
            });
        }
    }
    catch (e) {
        yield put(actions.postCreatePlugnoteInternalBase64Error());
        confirmAlert({
            title: i18n.t('common:global.plugnotes'),
            message: i18n.t('common:plugcode.create-error'),
            buttons: [
                {
                    label: i18n.t('common:global.ok'),
                }
            ]
        });
    }
}

function* watchFetchExternal() {
    // create watcher of fetchData function
    yield takeEvery(actions.Types.POST_CREATE_PF_CODE_PLUGNOTE_REQUEST, postCreatePFCodePlugnote);
    yield takeEvery(actions.Types.POST_CREATE_PLUGNOTE_INTERNAL_BASE64_REQUEST, postCreatePlugnoteInternalBase64);
    yield takeEvery(actions.Types.POST_OLD_CREATE_PF_CODE_PLUGNOTE_REQUEST, postOldCreatePFCodePlugnote);
    yield takeEvery(actions.Types.POST_CREATE_PF_CODE_PLUGNOTE_PUBLIC_REQUEST, postCreatePFCodePlugnotePublic);
    yield takeEvery(actions.Types.POST_CREATE_PF_CODE_PLUGNOTE_INTERNAL_REQUEST, postCreatePFCodePlugnoteInternal);
    yield takeEvery(actions.Types.POST_CREATE_PLUGNOTE_INTERNAL_REQUEST, postCreatePlugnoteInternal);
}

const ExternalSagas = [
    fork(watchFetchExternal)
];

export default ExternalSagas;
