import React from 'react';
import {Content, Header} from "rsuite";
import CssBaseline from "@material-ui/core/CssBaseline";

import {makeStyles, useTheme} from "@material-ui/core/styles";
import {connect} from "react-redux";

import { HyperFormula } from 'hyperformula';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor : 'white',
    },
    content: {
        //width : `calc(100% - ${drawerWidth}px)`,
        width : '100%',
        backgroundColor : 'white',
        padding: '64px 0px',
    },
    pointer:{
        cursor: 'pointer'
    }
}));

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

function Test(props) {

    const classes = useStyles();
    const theme = useTheme();

    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState({
        precisionRounding: 4,
        licenseKey: 'gpl-v3'
    });
    const loading = open && options.length === 0;
    const [hash, setHash] = React.useState(props.user);
    const [values, setValues] = React.useState([]);


    // define the data
    const data = [['10', '20', '30', '=SUM(A1:C1)']];

// build an instance with defined options and data
    const hfInstance = HyperFormula.buildFromArray(data, options);

// call getCellValue to get the calculation results
    const mySum = hfInstance.getCellValue({ col: 2, row: 0, sheet: 0 });

// print the result in the browser's console

    return (
        <div className={classes.root}>
            <CssBaseline />
            <main className={classes.content}>
                <Header>
                </Header>
                <br/>
                <Content >
                    <div style={{"textAlign" : "center"}}>
                        Welcome to the test page
                    </div>
                    <div style={{"margin" : "20px"}}>
                        <span>{mySum}</span>
                    </div>
                </Content>
            </main>
        </div>
    );
}


// redux providing state takeover
const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Test)
