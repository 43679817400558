import {api} from "./index";

export let
    postListFiltersByModel,
    deleteFilter,
    getListFiltersByModel;


postListFiltersByModel = (payload) => api.post('/filters/sends/lists/filters.json', payload);
getListFiltersByModel = (modelhash, userhash) => api.get('/filters/list/filters.json?modelhash=' + modelhash + '&userhash=' + userhash);
deleteFilter = (id) => api.delete('/filters/filters.json?id=' + id);
