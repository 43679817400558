import {api} from "./index";

export let
    getModelShares,
    postModelShares,
    deleteModelShares,
    putModelShares,
    putShareDataWithEmailUser,
    deleteShareDataWithEmailUser,
    postShareDataWithEmailUser,
    getShareDataWithEmailUser,
    deleteAllInviteModelShares;

postShareDataWithEmailUser = (payload) =>  api.post('datashare/datas/shares', payload);
putShareDataWithEmailUser = (payload) =>  api.put('datashare/datas/shares', payload);
getShareDataWithEmailUser = (datahash, email) =>  api.get('datashare/data/with/email/user.json?dh=' + datahash + '&email=' + email);
deleteShareDataWithEmailUser = (hash) =>  api.delete('datashare/datas/shares.json?hash=' + hash);
getModelShares = (modelHash,corporate) => api.get('shareds/models.json?mh=' + modelHash + '&corporate=' + corporate);
postModelShares = (payload) => api.post('shareds/models.json', payload);
deleteAllInviteModelShares = (modelSharedModId,modelSharedOwnerId) => api.delete('shareds/allinvitebymodel.json?modelsharedmodid=' + modelSharedModId + '&modelsharedownerid=' + modelSharedOwnerId);
deleteModelShares = (modelSharedHash) => api.delete('shareds/models.json?modelsharedhash=' + modelSharedHash);
putModelShares = (payload) => api.put('shareds/models.json', payload);

