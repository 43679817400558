import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import logo from "../../assets/images/logos/plugnotes_logo_color.png";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Container} from "rsuite";



const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    logoContainer:{
        display: 'inline-block',
        float: 'left',
    },
    logo:{
        width: '35%',
    },
    formContainer: {
        padding: '20px',
        border: 'solid',
        borderRadius: '10px',
        margin: '20px',
        borderColor: 'darkgray',
        boxShadow: '5px 5px 5px grey',
    },
    displayFlex:{
        display : 'flex',
        flexDirection : 'row'
    },
    marginLeftAuto:{
        marginLeft : 'auto'
    }
}));


function Maintenance() {

    const classes = useStyles();
    const { t, i18n } = useTranslation(['common', 'login']);

  return (
      <Grid>
          <div className="container_signin">
              <div className="logoSignin">
                  <img className="" src={logo} alt="Plugnotes" />
              </div>

              <div>

                  <div className="popup signin">
                      <div className="popup_wrapper flexSpacebetween">

                          <div className="popup_wrapper_content_login">
                              <div className="sous-header_content">
                                  <div>
                                        <span className="titres">
                                            {t('global.maintenance')}
                                        </span>
                                  </div>
                              </div>
                              <div className="sous-header_content lightgrey">
                                  <div className="line flex_style">
                                  </div>
                              </div>
                          </div>

                          <div className="popup_wrapper_content_login">
                              <article>
                                  <h1>We&rsquo;ll be back soon!</h1>
                                  <div>
                                      <p>Sorry for the inconvenience but we&rsquo;re performing some maintenance at the moment. If you need to you can always <a href="mailto:contact@plugnotes.com">contact us</a>, otherwise we&rsquo;ll be back online shortly!</p>
                                      <p>&mdash; The Team</p>
                                  </div>
                              </article>
                          </div>

                      </div>

                  </div>
              </div>

          </div>
      </Grid>
  );
}

export default Maintenance;
