import qs from "querystringify";
import {api} from "./index";

export let
    postGeneratePlugcodePlugform,
    postGeneratePlugcodePlugnote,
    postGenerateMultiplePlugcodePlugnote,
    updatePlugcodePlugform,
    updatePlugcodePlugnote,
    updatePsPlugcodePlugnote,
    getPlugformWithCodeLogoff,
    getPlugformWithCodeAndFolderLogoff,
    getPlugnoteWithCodeLogoff,
    deleteGeneratePlugcodePlugform,
    postClassifyInItsPlugform,
    postClassifyInAnotherPlugform,
    postClassifyInAnotherPlugformFromPlugList,
    deleteGeneratePlugcodePlugnote,
    postCheckEmail,
    getPlugformWithCodeLogin,
    postUpdatePlugcodeUser,
    delDeletePlugcodeUser,
    getPlugnoteWithCodeLogin,
    getCorporateUsersGroups;

getCorporateUsersGroups = (group) => api.get('corporate/users/groups' + qs.stringify({group : group}, '?'));
postGeneratePlugcodePlugform = (payload) => api.post('pfcode/generatepfs.json', payload);
postGeneratePlugcodePlugnote = (payload) =>  api.post('pncode/generatepns.json', payload);
postGenerateMultiplePlugcodePlugnote = (payload) =>  api.post('pncode/generatemultiplepns.json', payload);
deleteGeneratePlugcodePlugform = (mh, pfc) => api.delete('pfcode/modeldelete.json' + qs.stringify({mh, pfc}, '?'));
deleteGeneratePlugcodePlugnote = (dh, pnc) => api.delete('pncode/delete.json' + qs.stringify({dh, pnc}, '?'));
updatePlugcodePlugform = (payload) => api.put('pfcode/update.json', payload);
updatePlugcodePlugnote = (payload) => api.put('pncode/updates.json', payload);
updatePsPlugcodePlugnote = (payload) => api.put('pncode/ps/updates.json', payload);
getPlugnoteWithCodeLogin = (plugcode, userhash) => api.get('pncode/datacodelogin.json' + qs.stringify({
    code: encodeURIComponent(plugcode),
    uh: userhash
}, '?'));
getPlugnoteWithCodeLogoff = (plugcode) => api.get('pncode/datacodelogoff.json' + qs.stringify({
    code: encodeURIComponent(plugcode),
}, '?'));
// Classer dans un autre Plugform
postClassifyInAnotherPlugform = (userHash, pnc, modelHash, payload) =>
    api.post('classifies/datas/inanotherplugforms.json' + qs.stringify({
        uh: userHash,
        pnc,
        mh: modelHash
    }, '?'), payload);
postClassifyInAnotherPlugformFromPlugList =  (userHash, dataHash, modelHash, payload) =>
    api.post('classifies/datas/inanotherplugformfrompluglists.json' + qs.stringify({
        uh: userHash,
        dh: dataHash,
        mh: modelHash
    }, '?'), payload);
getPlugformWithCodeAndFolderLogoff = (plugcode, folder, plug,owner) => api.get('pfcode/modelcodelogoff.json' + qs.stringify({
    code: encodeURIComponent(plugcode),
    folder : encodeURIComponent(folder),
    plug : encodeURIComponent(plug),
    owner : encodeURIComponent(owner),
}, '?'));
getPlugformWithCodeLogoff = (plugcode) => api.get('pfcode/modelcodelogoff.json' + qs.stringify({code: encodeURIComponent(plugcode)}, '?'));
getPlugformWithCodeLogin = (plugcode, userhash) => api.get('pfcode/modelcodelogin.json' + qs.stringify({
    code: encodeURIComponent(plugcode),
    uh : encodeURIComponent(userhash)
}, '?'));
postClassifyInItsPlugform = (userhash, pnc, datahash) => api.post('classifies/datas/withplugforms.json' + qs.stringify({dh: datahash, uh: userhash, pnc}, '?'));
// Vérification si un mail existe
postCheckEmail = (payload) => api.post('pncode/checkemails.json', payload);
// mise à jour des ayants droits
postUpdatePlugcodeUser = (payload) => api.post('pncode/updateusers.json', payload);
// suppression des ayants droits
delDeletePlugcodeUser = (payload) => api.post('pncode/deleteusers.json', payload);
