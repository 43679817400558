import React from 'react';
import {useTranslation} from "react-i18next";

export default function HeaderInfos(props) {

    const { t, i18n } = useTranslation(['common', 'models', 'global']);

    return (
    <div style={{
            height : '60px',
            backgroundColor: '#E54F62',
        }}>
            <div style={{
                justifyContent: 'center',
                color: 'white',
                textAlign : 'center'
            }}>
                <span>{t('models.edit-mode')}</span><br/>
                <span>{t('models.edit-mode-2')}</span>
            </div>
        </div>
    );
}
