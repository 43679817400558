import React, {useState} from "react"
import {useTranslation} from "react-i18next";
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {connect} from "react-redux";
import {getCurrentEnvironnement, getModel, getPlugnote, getToken} from "../../utils/Common";
import '@contentful/forma-36-react-components/dist/styles.css';
import {trackPromise} from "react-promise-tracker";
import axios from "axios";
import ApplicationConfig from "../../config/ApplicationConfig";
import * as moment from "moment";
import {delDeleteAnnexeVersionModalRequest, delDeleteAnnexeVersionRequest} from "../../actions/annexe";
import {confirmAlert} from "react-confirm-alert";

const zeroPad = (num, places) => String(num).padStart(places, '0');

function getModalStyle() {
    const top = 5;

    return {
        top: `${top}%`,
        margin:'auto',
        overflow: 'auto',
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    popup: {
        position: 'absolute',
        //width: '50%',
        //height : '50%',
        //backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        //boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        width: '80%',
        height: '50%',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    marginLeft:{
        marginLeft : '10px'
    },
    pointer:{
        cursor: 'pointer'
    }
}));

const ModalListAnnexesVersionComponent = (props) => {

    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [t, i18n] = useTranslation('common');

    const environment = getCurrentEnvironnement();
    const plugnote = getPlugnote();
    const model = getModel();

    const handleCloseModal = () => {
        props.close();
    };

    const [url, setUrl] = useState(props.url + '#view=fit');
    const [modal, setModal] = useState(typeof props.modal  !== undefined ? props.modal : false);



    const downloadAnneexeVersionData = (annexe) => {

        // Fetch the dynamically generated excel document from the server.
        trackPromise(
            axios.get(ApplicationConfig.apiUrl + 'annexe/version.json?annexehash=' + annexe.hash  + '&version=' + annexe.lastversion, {crossdomain: true,
                'headers': {
                    'X-AUTH-TOKEN': getToken(),
                }
            }).then((response) => {
                if(response.data.result){
                    const fileNameHeader = "x-suggested-filename";
                    const suggestedFileName = response.headers[fileNameHeader];
                    const userindex = zeroPad(plugnote.userindex, 5); // "000005"
                    const PN = model.isModelShared ? 'PNS-' : 'PN-';
                    const effectiveFileName = (suggestedFileName === undefined ? PN + environment.envIndex + '-' + userindex + '-' + annexe.name + '-' + moment().format('YYMMDDHHmmss') + '.' + annexe.type : suggestedFileName);
                    var a = document.createElement("a"); //Create <a>
                    a.href = response.data.base64; //Image Base64 Goes here
                    a.download = effectiveFileName; //File name Here
                    a.click(); //Downloaded file

                }
            }).catch((response) => {
                console.error("Could not Download the Excel report from the backend.", response);
            })
        )

    };

    const handleDeleteAnnexeVersion = (annexe) => {
        confirmAlert({
            title: t('global.confirm'),
            message: t('plugnote.confirm-delete-annexe'),
            buttons: [
                {
                    label: t('global.yes'),
                    onClick: () =>  modal ? props.attemptDeleteAnnexeModal(annexe.hash, environment.userhash, annexe.lastversion) :  props.attemptDeleteAnnexe(annexe.hash, environment.userhash, annexe.lastversion)
                },
                {
                    label: t('global.cancel'),
                }
            ]
        });
    };

    return (
        <Modal
            keepMounted={false}
            open={props.open}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div style={modalStyle} className={classes.popup}>
                <div className = "scrollbar-hidden popup_wrapper flexSpacebetween" >
                    <div className="popup_wrapper_content">
                        <div className="sous-header_modal_content">
                            <div>
                            </div>
                            <div onClick={handleCloseModal}>
                                    <span id="icon-close" className="material-icons md-50 lightgrey">
                                    close
                                    </span>
                            </div>

                        </div>
                        <div className="sous-header_content lightgrey">
                            <div className="line flex_style">
                            </div>
                        </div>
                        <div className="popup_wrapper_content">
                            <div className="model">
                                <div className="modelContent">
                                    {
                                        props.list.map((annexe, index) => {
                                            return (
                                                <div key={index} style={{marginBottom : '10px'}}>
                                                    <div>
                                                        {annexe.name} - version :  {annexe.lastversion} - date : {moment(annexe.creationdate).format('YY/MM/DD H:m')}
                                                        <span id="icon-file_copy" className="material-icons md-25 float_right" onClick={(event) => handleDeleteAnnexeVersion(annexe)}>
                                                        delete
                                                    </span>
                                                        <span id="icon-file_copy" className="material-icons md-25 float_right" onClick={(event) => downloadAnneexeVersionData(annexe)}>
                                                        get_app
                                                    </span>
                                                    </div>
                                                    <div>
                                                        {
                                                            annexe.creatorName.length > 0 &&
                                                            <span>Créateur : {annexe.creatorName}</span>
                                                        }

                                                        {
                                                            annexe.editorName.length > 0 &&
                                                            <span> - Editeur : {annexe.editorName}</span>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Modal>
    )
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        list : state.annexe.listVersion
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        attemptDeleteAnnexe : (annexehash, userhash, version) => dispatch(delDeleteAnnexeVersionRequest(annexehash, userhash,version)),
        attemptDeleteAnnexeModal : (annexehash, userhash, version) => dispatch(delDeleteAnnexeVersionModalRequest(annexehash, userhash,version)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalListAnnexesVersionComponent)
