// create initial state for reducers
import {Types} from "../actions/share";

const INIT_STATE = {
    listSharesModelByUser : [],
    sharesByModel : [],
    fetching : false,
    model : null,
};


// reducer function to transform state
export default function share(state = INIT_STATE, action) {

    switch (action.type) {
        case Types.RESET_ALL_DATA : return INIT_STATE;
        case Types.REDUX_GENERATE_SHARE_RESET: return INIT_STATE;
        case Types.GET_MODEL_SHARES_REQUEST: {
            return {
                sharesByModel : [],
                fetching: true,
                model : action.model,
            }
        }
        case Types.POST_MODEL_SHARES_SUCCESS: {
            let list = state.sharesByModel;
            list.push(action.payload.data[0]);
            return {
                sharesByModel : list,
                fetching: true,
                model : state.model,
            }
        }
        case Types.PUT_MODEL_SHARES_SUCCESS: {

            let dataUpdated = action.payload.data;

            const list = state.sharesByModel.map((item) => {
                if (item.modelsshared_hash === dataUpdated.modelsharedhash) {
                    return {
                        ...item,
                        modelsshared_addAnnexe : Number(dataUpdated.add_annexe),
                    modelsshared_readAnnexe :  Number(dataUpdated.read_annexe),
                    modelsshared_readFiche :  Number(dataUpdated.read_fiche),
                    modelsshared_addFiche :  Number(dataUpdated.add_fiche),
                    modelsshared_corporate :  Number(dataUpdated.corporate),
                    modelsshared_deleteFiche :  Number(dataUpdated.delete_fiche),
                    modelsshared_delete_Annexe :  Number(dataUpdated.delete_annexe),
                    modelsshared_editAnnexe :  Number(dataUpdated.edit_annexe),
                    modelsshared_editFiche :  Number(dataUpdated.edit_fiche),
                    }
                } else {
                    return item
                }
            });

            return {
                sharesByModel : list,
                fetching: true,
                model : state.model,
            }
        }

        case Types.DEL_MODEL_SHARE_SUCCESS: {
            let list = state.sharesByModel.filter((item, index) => {
                if(item.modelsshared_hash !== action.payload.data){
                    return item;
                }
            });



            list.filter(Boolean);

            return {
                sharesByModel : list,
                fetching: true,
                model : state.model,
            }
        }

        case Types.GET_MODEL_SHARES_SUCCESS: {
            return {
                sharesByModel: action.payload.data,
                fetching : false,
                model : state.model,
            }
        }
        case Types.DEL_ALL_INVITE_MODEL_SHARES_SUCCESS:{
            return {
                sharesByModel: [],
                fetching : false,
                model : state.model,
            }
        }
        default: return state;
    }
};
