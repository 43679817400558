import React from "react"
import {useTranslation} from "react-i18next";
import {Button, ButtonToolbar} from "rsuite";

const LanguageComponent = () => {

    const [t, i18n] = useTranslation('common');
    return (
        <div>
            <ButtonToolbar>
            <Button appearance="default" onClick={() => i18n.changeLanguage('fr')}>fr</Button>
            <Button appearance="default" onClick={() => i18n.changeLanguage('en')}>en</Button>
            </ButtonToolbar>
        </div>
    )
};

export default LanguageComponent