import qs from "querystringify";
import {api} from "./index";


export let
    updateAnnexe,
    postAddNote,
    updateNote,
    getAnnexesVersion,
    getAnnexeVersion,
    deleteAnnexeVersion,
    postAnnexesVersion,
    postAnnexes,
    deleteAnnexe;

deleteAnnexe = (userHash, annexeHash) => {
    const query = {userhash: userHash, annexehash: annexeHash};
    return api.delete('annexes.json' + qs.stringify(query, '?'));
};
deleteAnnexeVersion = (userHash, annexeHash, version) => {
    const query = {userhash: userHash, annexehash: annexeHash, version : version};
    return api.delete('annexes/version.json' + qs.stringify(query, '?'));
};
updateAnnexe = (annexeHash, comment) => {
    const query = {annexehash: annexeHash};
    return api.put('annexes.json' + qs.stringify(query, '?'), {comment: comment});
};
postAddNote = (userhash, modelhash, datahash, payload) => api.post('annexes/notes.json?userhash=' + userhash + '&modelhash=' + modelhash + '&datahash=' + datahash, payload);
updateNote = (annexehash, notehash, payload) => api.put('annexes/notes.json?annexehash=' + annexehash + '&notehash=' + notehash, payload);
getAnnexesVersion = (annexehash) => api.get('annexes/version.json?annexehash=' + annexehash);
getAnnexeVersion = (annexehash, version) => api.get('annexe/version.json?annexehash=' + annexehash + '&version=' + version);
postAnnexesVersion = (modelhash, userhash, datahash, annexehash,modelshared,payload) => api.post('v2annexesfiles.json?userhash=' + userhash + '&datahash=' + datahash + '&modelhash=' + modelhash + '&annexehash=' + annexehash + '&modelshared='+ modelshared, payload, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});
postAnnexes = (modelhash, userhash, datahash,modelshared,payload) => api.post('annexes.json?userhash=' + userhash + '&datahash=' + datahash + '&modelhash=' + modelhash + '&modelshared='+ modelshared, payload, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});
