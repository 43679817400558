import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import {isIOS, isSafari} from 'react-device-detect';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    datePickerIcon: {
        "& .MuiIconButton-root": {
            padding: 0
        }
    },
}));


const FormatNowComponent = (props) => {
    const classes = useStyles();

    let value = props.field.value;

    if(isIOS || isSafari){
        let date1 = new Date();
        //value = moment(date1).format(format1);
        value = date1;
    }
    const AgendaIcon = () => {
        return(
            <span className={`${classes.agendaIcon} material-icons`}>
                calendar_today
            </span>
        )
    }

    const [selectedDate, handleDateChange] = useState(value);
    const [disabled, setDisabled] = React.useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    //2021-10-18 09:04:53
    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    return (
        <KeyboardDateTimePicker
            keyboardIcon={<AgendaIcon/>}
            InputAdornmentProps={{ position: 'start' }}
            KeyboardButtonProps={{
                'aria-label': 'change date'
            }}
            disabled={true}
            variant="inline"
            ampm={false}
            fullWidth={true}
            className={`${classes.datePickerIcon}`}
            format="dd-MM-yyyy HH:mm"
            value={selectedDate || ''}
            onChange={(date, value) => {
                handleDateChange(date);
                props.handleEditDateTime(date, value, props.field, props.index);
            }}
            InputProps={{ disableUnderline: true }}
        />
    );
};


// redux providing state takeover
const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatNowComponent);





