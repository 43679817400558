import React from 'react';
import {Provider} from 'react-redux';
import {render} from 'react-dom';
import './index.css';
import App from './App';
import {usePromiseTracker} from "react-promise-tracker";
import Loader from 'react-loader-spinner';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import {PersistGate} from 'redux-persist/integration/react';
import 'core-js/stable';


import {MuiPickersUtilsProvider} from '@material-ui/pickers';
// pick a date util library
//import MomentUtils from '@date-io/moment';
import DateFnsUtils from '@date-io/date-fns';
//import LuxonUtils from '@date-io/luxon';
import { unregister } from './serviceWorker';

import {persistor, store} from "./store"

const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();
    return (
        promiseInProgress &&
            <div
                style={{
                    position : "fixed",
                    backgroundColor : "rgba(192,192,192,0.2)",
                    top : 0,
                    bottom : 0,
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex : '9999'
                }}>
                <div
                    style={{
                        width: "100%",
                        height: "100",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <Loader type="Bars" color="#58C3C1" height="150" width="150" />
                </div>
            </div>
    );
};


//ReactDOM.render(<App />, document.getElementById('root'));

unregister();

render(
    <div>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <I18nextProvider i18n={i18next}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <App/>
                    </MuiPickersUtilsProvider>
                </I18nextProvider>
            </PersistGate>
        </Provider>
        <LoadingIndicator/>
    </div>,
    document.getElementById('root'));
