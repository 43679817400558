import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {fieldTypes} from "../../utils/Compatibility";
import {camelCase} from "lodash";
import {useTranslation} from "react-i18next";
import {Close, Search} from "@material-ui/icons";
import Images from "../../assets/themes/Images";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {sortValue} from "../../utils/utils";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import {postSendListFilterRequest} from "../../actions/filter";
import {getCurrentEnvironnement} from "../../utils/Common";


const useStyles = makeStyles((theme) => ({
    popup: {
        position: "absolute",
        //width: '50%',
        //height : '50%',
        //backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        //boxShadow: theme.shadows[5],
        //padding: theme.spacing(2, 4, 3),
        minWidth: "50%",
        maxWidth: "90%",
        backgroundColor: "white",
        width: "820px",
        border: "1px solid #DEDEDE",
        boxSizing: "border-box",
        boxShadow:
            "0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)",
        borderRadius: "20px",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "90%",
        margin: "auto",
    },
    marginLeft: {
        marginLeft: "10px",
    },
    buttonSaveComment: {
        width: "91%",
        padding: '8px 20px',
        color: 'white',
        backgroundColor: '#58c3c1',
        border: 'none',
        borderRadius: '3px',
        margin: '5px auto',
        cursor: 'pointer',
    },
}));

function getModalStyle() {
    const top = 5;

    return {
        top: `${top}%`,
        margin: "auto",
        overflow: "auto",
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

const FilterSelectionSaveComponent = (props) => {

    const { t, i18n } = useTranslation(['common', 'models', 'global', 'plugnote']);

    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const environment = getCurrentEnvironnement();


    const [filterSet, setFilterSet] = useState(props.filterSet);
    const [text, setText] = useState("");
    const [description, setDescription] = useState("");
    const [modelhash, setModelhash] = useState(props.modelHash);
    const [clef, setClef] = useState(props.clef);
    const [userhash, setUserhash] = useState(environment.userhash);


    const handleSave = () => {
        let payload = {
            filters : filterSet,
            modelhash : modelhash,
            clef : clef,
            userhash : userhash,
            text : text,
            description : description
        }
        props.attempListFilterSaveByModel(payload);
        props.close(payload);
    }

    return (
        <Modal
            keepMounted={false}
            open={props.open}
            onClose={props.close}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div style={modalStyle} className={classes.popup}>
                <div className="scrollbar-hidden popup_wrapper flexSpacebetween">
                    <div className="popup_wrapper_content">
                        <div className="sous-header_modal_content">
                            <div>
                                <span className="titres">
                                {t("common:filters.title")} <br />
                                </span>
                            </div>
                            <div onClick={props.close}>
                                <span
                                    id="icon-close"
                                    className="material-icons md-50 lightgrey"
                                >
                                  close
                                </span>
                            </div>
                        </div>
                        <div className="sous-header_content lightgrey">
                            <div className="line flex_style"></div>
                        </div>
                        <div className="flex_display_direction_row justify_content_space align-items-center">
                            <InputLabel style={{fontWeight : 'bold'}} shrink>{ t('common:filters.label') }</InputLabel>
                        </div>
                        <TextField
                            id="outlined-multiline-static"
                            //label={t("search.plug-search-annexe-comment-type-title")}
                            multiline={false}
                            minRows={1}
                            value={text}
                            variant="outlined"
                            fullWidth={true}
                            onChange={(event) => setText(event.target.value)}
                        />
                        <div className="flex_display_direction_row justify_content_space align-items-center">
                            <InputLabel style={{fontWeight : 'bold'}} shrink>{ t('common:filters.description') }</InputLabel>
                        </div>
                        <TextField
                            id="outlined-multiline-static"
                            //label={t("search.plug-search-annexe-comment-type-title")}
                            multiline
                            minRows={4}
                            value={description}
                            variant="outlined"
                            fullWidth={true}
                            onChange={(event) => setDescription(event.target.value)}
                        />
                    </div>
                    <button
                        className={classes.buttonSaveComment}
                        onClick={ handleSave }
                        disabled={text.length === 0 || filterSet.length === 0}
                    >
                        {t("common:multiple.save")}
                    </button>
                </div>
            </div>
        </Modal>
    );
};


// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        mainHash : state?.user?.profil?.hash || null
    }
};

const mapDispatchToProps = (dispatch) => ({
    attempListFilterSaveByModel: (payload) => dispatch(postSendListFilterRequest(payload)),

});

export default connect(mapStateToProps, mapDispatchToProps)(FilterSelectionSaveComponent);





