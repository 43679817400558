import React, {useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%'
    },
    margin_left_5:{
        marginLeft : '5px',
    },
    input_add_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    }
}));


const TemplateCategoryMultipleSelectComponent = (props) => {

    const classes = useStyles();

    let tmpSelection = props.selection.filter((item, value) => {
        if(item.bcmodels_isChecked){
            return item;
        }
    })


    const [selected, setSelected] = useState(props.selection.length > 0 ? tmpSelection : []);

    return (
        <div className={classes.margin_5}>
            { props.selection.map((value,index) => {
                    return (
                        <div key={index}>
                            <input
                                type="checkbox"
                                id={'checkbox-'+index}
                                name={value.bcmodelslgs_titreModel}
                                checked={selected.includes(value)}
                                onChange={(event) => {
                                    if(!selected.includes(value)){
                                        setSelected([...selected, value]);
                                    }else{
                                        let tmp = selected.filter(item => item !== value);
                                        setSelected(tmp);
                                    }
                                    props.handleChangeSelectionTemplate(props.indexCategorie,index);
                                }}
                            /><label className={classes.margin_left_5} htmlFor={'checkbox-'+index}>{value.bcmodelslgs_titreModel}</label>
                        </div>
                    )
                })
            }
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplateCategoryMultipleSelectComponent);





