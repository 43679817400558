import React, {useState} from 'react';
import {connect} from 'react-redux';

import {postLostPasswordRequest} from '../../actions/user';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useTranslation} from "react-i18next";

import logo from '../../assets/images/logos/plugnotes_logo_color.png';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    logoContainer:{
        display: 'inline-block',
        float: 'left',
    },
    logo:{
        width: '35%',
    },
    formContainer: {
        padding: '20px',
        border: 'solid',
        borderRadius: '10px',
        margin: '20px',
        borderColor: 'darkgray',
        boxShadow: '5px 5px 5px grey',
    }
}));

function LostPassword(props) {
    const email = useFormInput('');
    const [error, setError] = useState(null);



    // handle button click of login form
    const handleResetPassword = () => {
        props.attemptResetPassword(email.value, i18n.language.substr(0, 2));
    };

    const handleLogin = () => {
        props.history.push('/');
    };


    const classes = useStyles();
    const { t, i18n } = useTranslation(['common', 'login']);

    return (
        <Grid>
            <div className="container_signin">
                <div className="logoSignin">
                    <img className="" src={logo} alt="Plugnotes" />
                </div>

                <div>

                    <div className="popup signin">
                        <div className="popup_wrapper flexSpacebetween">

                            <div className="popup_wrapper_content">
                                <div className="sous-header_content">
                                    <div>
                                        <span className="titres">
                                            {t('login.lost-password-or-login')}
                                        </span>
                                    </div>
                                </div>
                                <div className="sous-header_content lightgrey">
                                    <div className="line flex_style">
                                    </div>
                                </div>
                            </div>

                            <div className="popup_wrapper_content">

                                <ul className="inputWrapper">
                                    <li>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            name="email"
                                            label="Email"
                                            type="text"
                                            id="email"
                                            {...email}
                                            autoComplete="off"
                                        />
                                    </li>
                                    <li onClick={handleLogin}><span className="cliquables grey margin-left-auto">{t('login.connection')}</span></li>

                                </ul>

                            </div>

                            <div className="popup_wrapper_content align-items-center">

                                <div className="confirmation_pop-up">
                                    <button className="button_signin"
                                            onClick={handleResetPassword}
                                            disabled={props.fetching}
                                    >{props.fetching ? t('global.loading') : t('login.reset-password')}</button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
        </Grid>
    );

}

const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);

    const handleChange = e => {
        setValue(e.target.value);
    }
    return {
        value,
        onChange: handleChange
    }
}


// redux providing state takeover
const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
    attemptResetPassword: (email, language) => dispatch(postLostPasswordRequest(email, language)),

});

export default connect(mapStateToProps, mapDispatchToProps)(LostPassword)