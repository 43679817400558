export const MailExportWithoutDataTemplateFR = ({ }) => {
    return `Bonjour,\n
En ouvrant le fichier ci-joint dans votre tableur (excel,..), vous pouvez créer et importer des nouvelles Plugnotes dans le dossier mentionné ci-dessus.\n
Veuillez respecter les instructions suivantes:\n
\n    
        1. Lisez et respectez les instructions spécifiques à chaque format reprises dans l’entête des colonnes,\n
        2. Ne modifiez ou n’effacez pas les lignes d’entêtes,\n
        3. Il est nécessaire de garder au moins les 5 premiers caractères du nom de template (A0xx_) et de garder le même format (XLS - Classeur excel 97-2003),\n
        4. Réimportez le fichier complété via la 3eme icone (avec les 2 fleches) des outils du plugform d'origine. (A gauche)\n
\n     
On reste à votre disposition en cas de questions.\n
Bon travail !\n
L'équipe de Plugnotes`
};
