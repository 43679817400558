import React from "react"
import {useTranslation} from "react-i18next";
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {connect} from "react-redux";
import TextField from "@material-ui/core/TextField";
import '@contentful/forma-36-react-components/dist/styles.css';
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import {getListContactsRequest} from "../../actions/repertory";

function getModalStyle() {
    const top = 5;

    return {
        top: `${top}%`,
        margin:'auto',
        overflow: 'auto',
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    popup: {
        position: 'absolute',
        //width: '50%',
        //height : '50%',
        //backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        //boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        width: '820px',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    marginLeft:{
        marginLeft : '10px'
    }
}));

const ModalSearchInRepertoriesComponent = (props) => {

    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [t, i18n] = useTranslation('common');

    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [hash, setHash] = React.useState(props.user);
    const [loading, setLoading] = React.useState(open && options.length === 0);
    const [values, setValues] = React.useState([]);
    const [value, setValue] = React.useState("");

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            // const response = await fetch('https://country.register.gov.uk/records.json?page-size=5000');
            // await sleep(1e3); // For demo purposes.
            // const countries = await response.json();

            props.attemptRetrieveListContact(hash);

        })();

        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        // if (!open) {
        //     setOptions([]);
        // }
    }, [open]);

    React.useEffect(() => {
        if(typeof props.list_contact !== "undefined" && props.list_contact.length > 0){
            setOptions(Object.keys(props.list_contact).map((key) => props.list_contact[key]));
            setLoading(false);
        }else{
            setOptions([]);
            setLoading(false);
        }
    }, [props.list_contact])


    const handleCloseModal = () => {
        setValue("");
        setValues([]);
        props.close();
    };

    const handleConfirm = () => {
        if(props.multiple){
            props.onChange(values);
        }else{

            props.onChange(value);
        }
        handleCloseModal();

    }

    return (
        <Modal
            keepMounted={false}
            open={props.open}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div key={props.mid} style={modalStyle} className={classes.popup}>
                <div className = "scrollbar-hidden popup_wrapper-email" >
                    <div className="popup_wrapper_content">
                        <div className="sous-header_modal_content">
                            <div>
                                <span className="titres">
                                    <span>Titre</span>
                                     <br/>
                                </span>
                            </div>
                            <div onClick={handleCloseModal}>
                                    <span id="icon-close" className="material-icons md-50 lightgrey">
                                    close
                                    </span>
                            </div>

                        </div>
                        <div className="sous-header_content lightgrey">
                            <div className="line flex_style">
                            </div>
                        </div>
                    </div>
                    <div className="popup_wrapper_content">
                        <div style={{"margin" : "20px"}}>
                            {
                                props.multiple ?
                                <Autocomplete
                                    id="asynchronous-demo"
                                    multiple
                                    style={{ width: '100%' }}
                                    open={open}
                                    onOpen={() => {
                                        setOpen(true);
                                        setLoading(
                                            true
                                        )
                                    }}
                                    onClose={() => {
                                        setOpen(false);
                                        setLoading(
                                            false
                                        )
                                    }}
                                    onChange={(event, value) => setValues(value)} // prints the selected value
                                    getOptionSelected={(option, value) => option.lastname === value.lastname &&  option.firstname === value.firstname &&  option.email === value.email }
                                    getOptionLabel={(option) => option.lastname + ' ' + option.firstname + ' | ' + option.email}
                                    options={options}
                                    loading={loading}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Asynchronous"
                                            variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                                :
                                    <Autocomplete
                                        id="asynchronous-demo"
                                        style={{ width: '100%' }}
                                        open={open}
                                        onOpen={() => {
                                            setOpen(true);
                                            setLoading(
                                                true
                                            )
                                        }}
                                        onClose={() => {
                                            setOpen(false);
                                            setLoading(
                                                false
                                            )
                                        }}
                                        onChange={(event, value) => setValue(value.email)} // prints the selected value
                                        getOptionSelected={(option, value) => option.lastname === value.lastname &&  option.firstname === value.firstname &&  option.email === value.email }
                                        getOptionLabel={(option) => option.lastname + ' ' + option.firstname + ' | ' + option.email}
                                        options={options}
                                        loading={loading}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Asynchronous"
                                                variant="outlined"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                            }

                        </div>

                        <div>
                            {
                                values.map((item, index) => {
                                    return(
                                        <div key={index} className="flex_display_direction_row flexSpacebetween">
                                            <span>{item.lastname}</span>
                                            <span>{item.firstname}</span>
                                            <span>{item.email}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {
                            value.length > 0 || values.length > 0 &&
                            <button className="button fullWidth" onClick={handleConfirm}>{t('common:global:confirm')}</button>
                        }
                    </div>
                </div>
            </div>
        </Modal>
    )
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        list_contact: state.repertory.list_contact,
        user : state.user.profil.hash
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        attemptRetrieveListContact : (hash) => dispatch(getListContactsRequest(hash))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalSearchInRepertoriesComponent)
