import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import { Tooltip } from "@material-ui/core";
import colors from "../../assets/themes/Colors";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    datePickerIcon: {
        "& .MuiIconButton-root": {
            padding: 0
        }
    },
    timeDelButton: {
        height:'22px',
        width: '12px',
        color: colors.main,
        alignItems: 'center',
        marginRight: '3px'
    },
    timeNowButton: {
        height:'20px',
        width: '20px',
        color: colors.main,
        alignItems: 'center',
        border: '2.5px solid',
        borderColor: colors.main,
        borderRadius: '22px',
        fontSize: "18px",
        fontWeight: '900',
    },
    timeContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    endButton: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        alignSelf: 'center'
    },
}));


const FormatAgendaComponent = (props) => {
    const classes = useStyles();

    const [selectedDate, handleDateChange] = useState(props.field.value !== null && props.field.value.length > 0 ? props.field.value : null);
    const [disabled, setDisabled] = React.useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);
    //console.log('agenda', selectedDate);

    const deleteDate = () => {
        handleDateChange(null)
        props.handleEditDateTime(null, '', props.field, props.index);
    }

    return (
    <div className={classes.timeContainer}>
        <KeyboardDateTimePicker
            InputAdornmentProps={{ position: 'start' }}
            disabled={disabled}
            variant="inline"
            ampm={false}
            fullWidth={true}
            placeholder="YYYY-MM-dd HH:mm"
            id={'date-picker-inline-'+props.field.id}
            className={`${classes.datePickerIcon}`}
            //label={props.field.title}
            //value={selectedDate}
            //onChange={handleDateChange}
            format="yyyy-MM-dd HH:mm"
            value={selectedDate}
            onChange={(date, value) => {
                //console.log("time", value);
                //console.log("date", date);
                handleDateChange(date);
                props.handleEditDateTime(date, value, props.field, props.index);
            }}
            InputProps={{ disableUnderline: true }}
        />
        {!disabled && (selectedDate === "" || selectedDate !== null) ?
            <Tooltip title="Delete" placement="top" className={classes.endButton} >
                <span className={`${classes.timeDelButton} material-icons`} onClick={() => deleteDate()}>highlight_off</span>
            </Tooltip>

        : null}
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatAgendaComponent);





