import i18n from 'i18next';
import common_en from "./en/common";
import common_fr from "./fr/common";
import common_nl from "./nl/common";
import {getLanguage} from "../utils/Common";
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
    fallbackLng: ['en','nl', 'fr'],
    lng: getLanguage() === null ? 'en' : getLanguage(),
    resources: {
        en: {
            common: common_en,               // 'common' is our custom namespace
        },
        nl: {
            common: common_nl,
        },
        fr: {
            common: common_fr,
        }
    },
    interpolation: {
        escapeValue: false,
    },
    wait: true,
});
export default i18n;
