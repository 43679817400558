import {Types} from '../actions/plugcode';

// create initial state for reducers
const INIT_STATE = {
    model : null,
    plugform : null,
    plugformowner : null,
    plugnote : null,
    plugnoteowner : null,
    plugformlink : null,
    plugformplug : null,
    group : null,
    corporate : [],
    subgroup : [],
    listusersgroup : [],
    fetching : false,
};

// reducer function to transform state
export default function plugcode(state = INIT_STATE, action) {
    switch(action.type) {
        case Types.RESET_ALL_DATA : return INIT_STATE;
        case Types.POST_GENERATE_PLUGFORMCODE_REQUEST: {
            return {
                ...state,
                model : action.model,
            }
        }

        case Types.POST_GENERATE_PLUGNOTECODE_REQUEST: {
            return {
                ...state,
                plugnote : action.plugnote,
            }
        }

        case Types.UPDATE_GENERATE_PLUGFORMCODE_REQUEST: {
            return {
                ...state,
                model : action.model,
            }
        }

        case Types.UPDATE_GENERATE_PLUGNOTECODE_REQUEST: {
            return {
                plugnote : action.plugnote,
            }
        }

        case Types.UPDATE_GENERATE_PS_PLUGNOTECODE_REQUEST: {
            return {
                plugnote : action.plugnote,
            }
        }

        case Types.POST_GENERATE_PLUGFORMCODE_SUCCESS: {
            return {
                ...state,
                model: action.payload,
            }
        }

        case Types.GET_PLUGFORMCODE_SUCCESS: {
            return {
                ...state,
                model: action.payload,
            }
        }

        case Types.POST_GENERATE_PLUGNOTECODE_SUCCESS: {
            return {
                ...state,
                plugnote: action.payload,
            }
        }

        case Types.UPDATE_GENERATE_PLUGFORMCODE_SUCCESS: {
            return {
                ...state,
                model: action.payload,
            }
        }

        case Types.UPDATE_GENERATE_PLUGNOTECODE_SUCCESS: {
            return {
                ...state,
                plugnote: action.payload,
            }
        }

        case Types.DELETE_GENERATE_PLUGFORMCODE_SUCCESS: {
            return {
                ...state,
                model: action.payload,
            }
        }

        case Types.DELETE_GENERATE_PLUGNOTECODE_SUCCESS: {
            return {
                ...state,
                plugnote: action.payload,
            }
        }

        case Types.REDUX_GENERATE_PLUGFORMCODE_RESET: {
            return {
                model: null,
            }
        }

        case Types.REDUX_GENERATE_PLUGNOTECODE_RESET: {
            return {
                ...state,
                plugnote: null,
            }
        }

        case Types.GET_PLUGFORM_VIA_CODE_REQUEST:{
            return {
                ...state,
                plugform : null,
                plugformowner : null,
            }
        }

        case Types.GET_PLUGFORM_VIA_CODE_SUCCESS:{
            return {
                ...state,
                plugform : action.payload.data.data,
                plugformowner : action.payload.data.proprio
            }
        }

        case Types.GET_PLUGFORM_VIA_CODE_FAILURE:{
            return {
                ...state,
                plugform : null,
                plugformowner : null
            }
        }

        case Types.GET_PLUGFORM_VIA_CODE_ERROR:{
            return {
                ...state,
                plugform : null,
                plugformowner : null
            }
        }

        case Types.GET_PLUGFORM_VIA_CODE_OFFLINE_REQUEST:{
            return {
                ...state,
                plugform : null,
                plugformowner : null,
                plugformlink : null,
                plugformplug : null,
                fetching : true,
            }
        }

        case Types.GET_PLUGFORM_VIA_CODE_OFFLINE_SUCCESS:{
            return {
                ...state,
                plugform : action.payload.data.data,
                plugformlink : action.payload.data.link,
                plugformplug : action.payload.data.plug,
                plugformowner : action.payload.data.proprio,
                fetching : false,
            }
        }

        case Types.GET_PLUGFORM_VIA_CODE_OFFLINE_FAILURE:{
            return {
                ...state,
                plugform : null,
                plugformowner : null,
                plugformlink : null,
                plugformplug : null,
                fetching : false,
            }
        }

        case Types.GET_PLUGFORM_VIA_CODE_OFFLINE_ERROR:{
            return {
                ...state,
                plugform : null,
                plugformowner : null,
                plugformlink : null,
                plugformplug : null,
                fetching : false,
            }
        }

        case Types.GET_PLUGFORM_VIA_CODE_OFFLINE_RESET:{
            return {
                ...state,
                plugform : null,
                plugformowner : null,
                plugformlink : null,
                plugformplug : null,
                fetching : false,
            }
        }

        case Types.GET_PLUGNOTE_VIA_CODE_REQUEST:{
            return {
                ...state,
                plugnote : null,
                plugnoteowner : null
            }
        }

        case Types.GET_PLUGNOTE_VIA_CODE_SUCCESS:{
            return {
                ...state,
                plugnote : action.payload.data.data,
                plugform : action.payload.data.model,
                plugnoteowner : null
            }
        }

        case Types.GET_PLUGNOTE_VIA_CODE_FAILURE:{
            return {
                ...state,
                plugnote : null,
                plugnoteowner : null
            }
        }

        case Types.GET_PLUGNOTE_VIA_CODE_ERROR:{
            return {
                ...state,
                plugnote : null,
                plugnoteowner : null
            }
        }

        case Types.GET_PLUGNOTE_VIA_CODE_OFFLINE_REQUEST:{
            return {
                ...state,
                plugnote : null,
                plugnoteowner : null,
                fetching : true,
            }
        }

        case Types.GET_PLUGNOTE_VIA_CODE_OFFLINE_SUCCESS:{
            return {
                ...state,
                plugnote : action.payload.data.data,
                plugform : action.payload.data.model,
                plugnoteowner : null,
                fetching : false,
            }
        }

        case Types.GET_PLUGNOTE_VIA_CODE_OFFLINE_RESET:{
            return {
                ...state,
                plugnote : null,
                plugform : null,
                plugnoteowner : null,
                fetching : false,
            }
        }

        case Types.GET_PLUGNOTE_VIA_CODE_OFFLINE_FAILURE:{
            return {
                ...state,
                plugnote : null,
                plugnoteowner : null,
                fetching : false
            }
        }

        case Types.GET_PLUGNOTE_VIA_CODE_OFFLINE_ERROR:{
            return {
                ...state,
                plugnote : null,
                plugnoteowner : null,
                fetching : false,
            }
        }

        case Types.POST_ADD_PLUGCODE_PLUGNOTE_USER_SUCCESS: {
            return {
                ...state,
                plugnote: action.payload,
            }
        }

        case Types.DEL_DELETE_PLUGCODE_PLUGNOTE_USER_SUCCESS: {
            return {
                ...state,
                plugnote: action.payload,
            }
        }

        case Types.GET_CORPORATE_USERS_GROUPS_SUCCESS: {
            return {
                ...state,
                group: action.payload1,
                corporate: action.payload2,
                subgroup: action.payload3,
                listusersgroup : action.payload4,
            }
        }

        default: return state;
    }
};
